import React, { useState } from "react";

import { BotaoDeletar, BotaoEditar, BotaoGrupo } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import api from "../../../../service/api";
import ModalAcidenteRisco from "./ModalAcidenteRisco";
import { isEmpty } from "../../../../components/Util";

const ListaRisco = ({ riscos, buscarRiscos, acidenteRiscos, permissaoEditar }) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={riscos}
        colunas={[
          { nome: "Código", width: "5%", text: "center" },
          { nome: "Risco", width: "67%" },
          { nome: "Severidade", width: "20%" },
          permissaoEditar && { nome: "Ações", width: "8%", text: "center" },
        ]}
      >
        {riscos.map((risco) => (
          <Risco
            key={risco.id}
            risco={risco}
            buscarRiscos={buscarRiscos}
            acidenteRiscos={acidenteRiscos}
            permissaoEditar={permissaoEditar}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const Risco = ({ risco, buscarRiscos, acidenteRiscos, permissaoEditar }) => {
  const [editando, setEditando] = useState(false);

  async function deletarRisco(risco) {
    const riscoAcidenteVinculo = acidenteRiscos.find((apreRisco) => apreRisco.risco_acidente_id === risco.id);

    if (window.confirm(`Tem certeza que deseja excluir o risco: ${risco.definicao}?`)) {
      if (riscoAcidenteVinculo) {
        let status = { status: "I" };

        api.put(`/baseDados/acidenteBaseDados/editarRisco/${risco.id}`, status).then(() => {
          api.delete(`/baseDados/acidenteBaseDados/deletarRiscoPerigosVinculados/${risco.id}`).then(() => {
            buscarRiscos();
            setEditando(false);
          });
        });
      } else {
        api
          .delete(`/baseDados/acidenteBaseDados/deletarRiscoPerigosVinculados/${risco.id}`)
          .then(() => {
            api
              .delete(`/baseDados/acidenteBaseDados/deletarRisco/${risco.id}`)
              .then(() => {
                buscarRiscos();
                setEditando(false);
              })
              .catch((erro) => {
                alert(erro.response.data.mensagem);
              });
          })
          .catch((erro) => {
            alert(erro.response.data.mensagem);
          });
      }
    }
  }
  return (
    <>
      <tr key={risco.id}>
        <td className="text-center align-middle">{risco.codigo}</td>
        <td className="align-middle">{risco.definicao}</td>
        <td className="align-middle">{isEmpty(risco.severidade) ? "Nenhuma severidade cadastrada" : risco.grau_risco + " - " + risco.severidade}</td>
        {permissaoEditar && (
          <>
            <td className="text-center align-middle">
              <BotaoGrupo>
                <BotaoEditar onClick={() => setEditando(true)} disabled={risco.id === "19" && true} />
                <BotaoDeletar onClick={() => deletarRisco(risco)} disabled={risco.id === "19" && true} />
              </BotaoGrupo>
            </td>
          </>
        )}
      </tr>

      {editando && (
        <ModalAcidenteRisco
          show={editando}
          setShow={setEditando}
          titulo={`Editar: ${risco.definicao}`}
          corTitulo="primary"
          risco={risco}
          buscarRiscos={buscarRiscos}
          acidenteRiscos={acidenteRiscos}
        />
      )}
    </>
  );
};

export default ListaRisco;
