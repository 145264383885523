import React, { useState, useEffect } from "react";
import { Card, Nav } from "react-bootstrap";
import { useParams } from "react-router-dom";

import api from "../../../service/api";
import { isEmpty } from "../../../components/Util";
import FormasRiscos from "./FormasRiscos";
import Dica from "../../../components/Dica";
import CardPadrao from "../../template/CardPadrao";
import Template from "../../template/Template";

const Setores = () => {
  const [setores, setSetores] = useState([]);
  const [setorAtivo, setSetorAtivo] = useState({});
  const [carregandoSetores, setCarregandoSetores] = useState(true);

  const { ppraId } = useParams();
  const page = "Reconhecimento / matemática";

  useEffect(() => {
    carregarSetores(ppraId);
    setSetorAtivo({});
  }, [ppraId]);

  async function carregarSetores(ppraId) {
    await api
      .get("/ppra/setores/obterSetores", {
        params: { ppra_id: ppraId },
      })
      .then((resposta) => {
        setSetores(resposta.data);
        setCarregandoSetores(false);
      })
      .catch((e) => console.log(e));
  }

  return (
    <Template page={page} backTo={`/pgr/${ppraId}/fisicoQuimicoBiologico`}>
      {() => (
        <CardPadrao
          titulo="Lista de setores"
          itens={setores}
          carregando={carregandoSetores}
        >
          <Card>
            <Card.Header>
              <Nav variant="tabs">
                {setores.map((setor) => (
                  <Nav.Item key={setor.setor_id}>
                    <Dica id={setor.id} conteudo={setor.nome}>
                      <Nav.Link
                        className="text-primary"
                        href={"#" + setor.setor_id}
                        onClick={() => setSetorAtivo(setor)}
                      >
                        {setor.numero}
                      </Nav.Link>
                    </Dica>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
            <Card.Body>
              {!isEmpty(setorAtivo) ? (
                <FormasRiscos setor={setorAtivo} />
              ) : (
                <h5 className="text-muted text-center">
                  Para visualizar os riscos, <strong>clique</strong> em um dos
                  setores listados acima!
                </h5>
              )}
            </Card.Body>
          </Card>
        </CardPadrao>
      )}
    </Template>
  );
};

export default Setores;
