import React, { useState } from "react";

import "./reconhecimentos-matematicas.css";

import Botao, { BotaoGrupo, BotaoVoltar } from "../../../components/Botao";
import Atividade from "./Atividade";
import Funcoes from "./Funcoes";
import LimiteAmbiente from "./limites-quimico/LimiteAmbiente";
import Matematica from "./Matematica";
import ToolTipItem from "./ToolTipItem";
import TabelaPadrao from "../../../components/TabelaPadrao";
// import Epcs from "./medidas-controle/epcs/Epcs";
import MedidasTrabalho from "./medidas-controle/medidas-trabalho/MedidasTrabalho";
import SeveridadeAmbiente from "./SeveridadeAmbiente";

const TabelaMatematica = ({
  itemNome,
  indexAmbiente,
  setor,
  ambiente,
  atividades,
  formaRisco,
  isQuimico,
  carregarAmbientes,
  setCarregandoAmbientes,
  medidasOrganizacionaisTrabalho,
}) => {
  const [mostrarFuncoesAmbientes, setMostrarFuncoesAmbientes] = useState(false);

  return (
    <TabelaPadrao
      noOverflowAuto
      colunas={[
        { nome: "Ambiente / " + itemNome, width: "25%" },
        { nome: "Risco à Saúde", width: "25%" },
        { nome: "P", width: "5%" },
        { nome: "S", width: "5%" },
        { nome: "GR", width: "5%" },
        { nome: "Medidas de controle necessárias", width: "25%" },
        { nome: "Ações", width: "3%" },
      ]}
      itens={atividades}
    >
      <tr className="table-success">
        <td>
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              {setor.numero}.{ambiente.numero || ++indexAmbiente}{" "}
              {ambiente.nome} {ambiente.local}
            </div>
            <div className="col text-end">
              <BotaoGrupo>
                <Botao
                  icone="print"
                  definicao="PDF"
                  cor="secondary"
                  alvo={
                    process.env.REACT_APP_API_URL +
                    `/gro/pgr/pdf/quadros/gerarRevisao/${setor.ppra_id}/${setor.setor_id}/${ambiente.local_id}`
                  }
                />
                {mostrarFuncoesAmbientes ? (
                  <BotaoVoltar
                    onClick={() => setMostrarFuncoesAmbientes(false)}
                  />
                ) : (
                  <ToolTipItem
                    item={ambiente}
                    isQuimico={isQuimico}
                    isAmbiente={true}
                    onClick={() => setMostrarFuncoesAmbientes(true)}
                  />
                )}
              </BotaoGrupo>
            </div>
          </div>
        </td>
        <Matematica
          setor={setor}
          atividade={ambiente}
          formaRisco={formaRisco}
          isQuimico={isQuimico}
          riscosSaude={!isQuimico && ambiente.riscos_saude}
          carregarAmbientes={carregarAmbientes}
          setCarregandoAmbientes={setCarregandoAmbientes}
          isAmbiente
        />
      </tr>
      {mostrarFuncoesAmbientes && (
        <tr>
          <Funcoes funcoes={ambiente.funcoes} isAmbiente/>
          <td className="align-top" colSpan="6">
            <MedidasTrabalho
              item={ambiente}
              setor={setor}
              formaRisco={formaRisco}
              carregarAmbientes={carregarAmbientes}
            />
            {/* <Epcs
              item={ambiente}
              setor={setor}
              formaRisco={formaRisco}
              carregarAmbientes={carregarAmbientes}
            /> */}
            {isQuimico && (
              <LimiteAmbiente
                ambiente={ambiente}
                setor={setor}
                formaRisco={formaRisco}
                carregarAmbientes={carregarAmbientes}
              />
            )}
            <SeveridadeAmbiente
              ambiente={ambiente}
              setor={setor}
              formaRisco={formaRisco}
              carregarAmbientes={carregarAmbientes}
            />
          </td>
        </tr>
      )}
      {atividades.map((atividade, index) => (
        <Atividade
          key={index}
          atividade={atividade}
          ambiente={ambiente}
          setor={setor}
          formaRisco={formaRisco}
          indexAmbiente={indexAmbiente}
          indexAtividade={++index}
          carregarAmbientes={carregarAmbientes}
          isQuimico={isQuimico}
          medidasOrganizacionaisTrabalho={medidasOrganizacionaisTrabalho}
          riscosSaude={ambiente.riscos_saude}
        />
      ))}
    </TabelaPadrao>
  );
};

export default TabelaMatematica;
