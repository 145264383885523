import React, { useEffect, useState } from "react";

import Loader from "../../components/Loader";
import Paginacao from "../../components/Paginacao";
import CreatableFilter from "../../components/CreatableFilter";
import Filter from "../../components/Filter";
import TrocarStatus from "./components/TrocarStatus";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Botao from "../../components/Botao";

const TemplateBaseDados = ({
  item,
  children,
  carregandoItens,
  totalItens,
  itemsCountPerPage = 50,
  itensFiltrados,
  handleFilter,
  handleAdiciona,
  isModal,
  handleClick,
  handleSelect,
  filtro,
  filtroHorario,
  qtdNaoValidados,
  statusItens,
  setStatusItens,
  body,
  options,
  defaultValue,
  disableAddButton,
  changePage,
  handleImport,
  disabled,
  naoAdicionar,
  id = 'input-template'
}) => {
  
  const [activePage, setActivePage] = useState(1);

  const indexOfLastPost = activePage * itemsCountPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsCountPerPage;
  const currentItems = itensFiltrados.slice(indexOfFirstPost, indexOfLastPost);

  const history = useHistory();

  useEffect(() => {
    setActivePage(1);
  }, [filtro, changePage]);

  useEffect(() => {
    if (history.location.search === "") {
      setActivePage(1);
    } else {
      let arraySearch = history.location.search.split("=");
      setActivePage(parseInt(arraySearch[1]));
    }
  }, [history.location.search]);

  return (
    <>
      
      <div className="card px-4 pt-4">
        <TotalItems
          carregandoItens={carregandoItens}
          totalItensFiltrados={itensFiltrados.length}
          totalItens={totalItens}
          item={item}
        />
        {filtroHorario ? (
          <FiltroTempo
            filtroHorario={filtroHorario}
            handleClick={handleClick}
          />
        ) : (handleAdiciona && !handleSelect) ||
          (handleClick && !handleSelect && !naoAdicionar) ? (
          <CreatableFilter
            id={id}
            handleFilter={handleFilter}
            handleAdiciona={handleAdiciona}
            disableAddButton={!isModal ? filtro === "" : false}
            isModal={isModal}
            handleClick={handleClick}
            defaultValue={filtro}
          />
        ) : handleSelect ? (
          <SelectComFiltro
            handleSelect={handleSelect}
            options={options}
            filtro={filtro}
            defaultValue={defaultValue}
            handleFilter={handleFilter}
            handleClick={handleClick}
            isModal={isModal}
            handleAdiciona={handleAdiciona}
            disableAddButton={disableAddButton}
          />
        ) : (
          <Filter handleFilter={handleFilter} defaultValue={filtro} />
        )}
        {qtdNaoValidados >= 0 && (
          <div className="row mb-2 d-flex justify-content-between">
            <div className="col-5">
              <div>
                <span className="pb-3 mr-2 badge bg-pill badge-success">
                  {" "}
                </span>
                {item} validados
              </div>
              {qtdNaoValidados > 0 && (
                <div>
                  <span className="pb-3 mr-2 badge bg-pill badge-danger">
                    {" "}
                  </span>
                  {item} não validados
                </div>
              )}
            </div>
            {body}
            {statusItens && (
              <div className="col-3">
                <div className="float-end">
                  <span className="mr-3">Mostrar</span>
                  {["Ativos", "Inativos"].map((check, index) => (
                    <div
                      key={index}
                      className="form-check custom-control-inline"
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"checkStatus" + check}
                        value={check === "Ativos" ? "1" : "0"}
                        onChange={(e) =>
                          TrocarStatus(
                            statusItens,
                            setStatusItens,
                            e.target.value,
                            e.target.checked
                          )
                        }
                        defaultChecked={check === "Ativos"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"checkStatus" + check}
                      >
                        {check}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="row">
          <div className="col">
            {totalItens > itemsCountPerPage && (
              <Paginacao
                activePage={activePage}
                itemsCountPerPage={itemsCountPerPage}
                itensFiltrados={itensFiltrados}
                setActivePage={setActivePage}
              />
            )}
          </div>
          <div className="col">
            {handleImport ? (
              <Botao
                className="mb-5 float-end"
                conteudo="Importar"
                cor="primary"
                icone="file-import"
                onClick={handleImport}
                disabled={disabled}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {children(currentItems, activePage)}
        {totalItens > itemsCountPerPage && (
          <Paginacao
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            itensFiltrados={itensFiltrados}
            setActivePage={setActivePage}
          />
        )}
      </div>
    </>
  );
};

const TotalItems = ({
  carregandoItens,
  totalItensFiltrados,
  totalItens,
  item,
}) => {
  return (
    <div>
      <h6>
        Exibindo{" "}
        <span className="badge bg-secondary">
          {carregandoItens ? <Loader small={true} /> : totalItensFiltrados}
        </span>{" "}
        de um total de{" "}
        <span className="badge bg-secondary">
          {carregandoItens ? <Loader small={true} /> : totalItens}
        </span>{" "}
        {item}
      </h6>
    </div>
  );
};

const FiltroTempo = ({ filtroHorario, handleClick }) => {
  return (
    <div className="row">
      <div className="col-6">
        <Filter
          handleFilter={(e) =>
            filtroHorario.filtrarHoraInicial(e.target.value) ||
            filtroHorario.filtrarHoraTotal(e.target.value)
          }
          type="time"
        />
      </div>
      <div className="col-6">
        <Filter
          handleFilter={(e) => filtroHorario.filtrarHoraFinal(e.target.value)}
          type="time"
          adiciona
          isModal
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

const SelectComFiltro = ({
  handleSelect,
  options,
  defaultValue,
  handleFilter,
  filtro,
  handleClick,
  isModal,
  handleAdiciona,
  disableAddButton,
}) => {
  return (
    <div className="row">
      <div className="col">
        <CreatableFilter
          handleFilter={handleFilter}
          handleAdiciona={handleAdiciona}
          disableAddButton={disableAddButton}
          isModal={isModal}
          handleClick={handleClick}
          defaultValue={filtro}
        />
      </div>
      <div className="col">
        <Select
          className="mt-3"
          onChange={handleSelect}
          options={options}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default TemplateBaseDados;
