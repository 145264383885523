import React, { useEffect, useState } from "react";

import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";
import ListaGhe from "./ListaGhe";
import ModalGhe from "./ModalGhe";

const Perigo = ({ permissaoEditar }) => {
  const [gruposGhe, setGruposGhe] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "grupos Ghe";

  useEffect(() => {
    api.get("/baseDados/ergonomiaBaseDados/buscarGruposGhe").then((resposta) => {
      setGruposGhe(resposta.data);
    });
  }, []);

  const gruposFiltrados = gruposGhe.filter((perigo) => filtrar(perigo.nome, filtro));

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  function obterDados(){
    api.get("/baseDados/ergonomiaBaseDados/buscarGruposGhe").then((resposta) => {
      setGruposGhe(resposta.data);
    });
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={gruposFiltrados}
        totalItens={gruposGhe.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={() => {
          permissaoEditar && setShowModalCadastro(true);
        }}
      >
        {(currentItems) => (
          <ListaGhe gruposGhe={currentItems} permissaoEditar={permissaoEditar} obterDados={obterDados} />
        )}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalGhe
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Perigo"
          corTitulo="success"
          obterDados={obterDados}
        />
      )}
    </>
  );
};

export default Perigo;
