import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Dica = ({ colocacao = "top", id, conteudo, children }) => {
  return (
    <OverlayTrigger
      key={colocacao}
      placement={colocacao}
      overlay={
        <Tooltip id={`tooltip-${id}`}>
          <strong>{conteudo}</strong>
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default Dica;
