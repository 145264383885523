import React, { useEffect, useState } from "react";

import { BotaoAdicionar, BotaoRemover } from "../../../../../components/Botao";
import { filtrar } from "../../../../../components/Util";
import ModalPadrao from "../../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../../components/TabelaPadrao";
import api from "../../../../../service/api";
import Filtros from "../../../components/Filtros";

const AdicionarMedidasTrabalho = ({
  showAdicionarMedidasTrabalho,
  setShowAdicionarMedidasTrabalho,
  mostraQuantidade,
  item,
  formaRisco,
  carregarAmbientes,
  setor,
}) => {
  const [medidasTrabalho, setMedidasTrabalho] = useState([]);
  const [medidasTrabalhoAdicionadas, setMedidasTrabalhoAdicionadas] = useState(
    []
  );

  const [filtroMedidasTrabalho, setFiltroMedidasTrabalho] = useState("");
  const [
    filtroMedidasTrabalhoAdicionadas,
    setFiltroMedidasTrabalhoAdicionadas,
  ] = useState("");

  const [carregandoMedidasTrabalho, setCarregandoMedidasTrabalho] =
    useState(true);
  const [
    carregandoMedidasTrabalhoAdicionados,
    setCarregandoMedidasTrabalhoAdicionados,
  ] = useState(true);

  const medidasTrabalhoFiltrados = medidasTrabalho.filter((medidaTrabalho) =>
    filtrar(medidaTrabalho.definicao, filtroMedidasTrabalho)
  );

  const medidasTrabalhoFiltradosAdicionados = medidasTrabalhoAdicionadas.filter(
    (medidaTrabalho) =>
      filtrar(medidaTrabalho.definicao, filtroMedidasTrabalhoAdicionadas)
  );

  const filtrarMedidasTrabalho = (newFilter) => {
    setFiltroMedidasTrabalho(newFilter);
  };

  const filtrarMedidasTrabalhoAdicionadas = (newFilter) => {
    setFiltroMedidasTrabalhoAdicionadas(newFilter);
  };

  let tarefaId = item.tarefa_id ? item.tarefa_id : null;
  let produtoQuimicoId = item.produto_quimico_id
    ? item.produto_quimico_id
    : null;

  useEffect(() => {
    carregar(
      item.local_quadro_id,
      formaRisco.id,
      true,
      tarefaId,
      produtoQuimicoId
    );
    carregar(
      item.local_quadro_id,
      formaRisco.id,
      false,
      tarefaId,
      produtoQuimicoId
    );
  }, [item, formaRisco.id, tarefaId, produtoQuimicoId]);

  const carregar = async (
    localQuadroId,
    formaRiscoId,
    carregarAdicionados,
    tarefaId = null,
    produtoQuimicoId = null
  ) => {
    let url = "";
    let params = {
      local_quadro_id: localQuadroId,
      forma_risco_id: formaRiscoId,
      tarefa_id: tarefaId,
      produto_quimico_id: produtoQuimicoId,
    };

    if (carregarAdicionados) {
      setCarregandoMedidasTrabalhoAdicionados(true);
      url = "/ppra/MedidaControle/obterMedidaOrganizacionalTrabalhoPpra";
    } else {
      setCarregandoMedidasTrabalho(true);
      url = "/ppra/MedidaControle/obterMedidaOrganizacionalTrabalho";
    }

    await api
      .get(url, {
        params: params,
      })
      .then((resposta) => {
        if (carregarAdicionados) {
          setMedidasTrabalhoAdicionadas(resposta.data);
          setCarregandoMedidasTrabalhoAdicionados(false);
        } else {
          setMedidasTrabalho(resposta.data);
          setCarregandoMedidasTrabalho(false);
        }
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  return (
    <ModalPadrao
      show={showAdicionarMedidasTrabalho}
      setShow={setShowAdicionarMedidasTrabalho}
      tituloSpan="Adicionar Medidas Trabalho"
      handleHide={() =>
        carregarAmbientes(
          setor.setor_id,
          setor.ppra_id,
          formaRisco.risco_id,
          formaRisco.id
        )
      }
    >
      <Filtros
        itens={[
          {
            placeholder: "Medidas de trabalho não adicionadas",
            filtrar: filtrarMedidasTrabalho,
            atualizarItens: () => {
              setCarregandoMedidasTrabalho(true);
              carregar(
                item.local_quadro_id,
                formaRisco.id,
                false,
                tarefaId,
                produtoQuimicoId
              );
            },
          },
          {
            placeholder: "Medidas de trabalho adicionadas",
            filtrar: filtrarMedidasTrabalhoAdicionadas,
          },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaMedidasTrabalho
            adicionaMedidasTrabalho
            item={item}
            medidasTrabalho={medidasTrabalhoFiltrados}
            formaRiscoId={formaRisco.id}
            tarefaId={tarefaId}
            produtoQuimicoId={produtoQuimicoId}
            carregando={carregandoMedidasTrabalho}
            carregar={carregar}
            setCarregandoMedidasTrabalho={setCarregandoMedidasTrabalho}
            setCarregandoMedidasTrabalhoAdicionados={
              setCarregandoMedidasTrabalhoAdicionados
            }
          />
        </div>
        <div className="col">
          <TabelaMedidasTrabalho
            item={item}
            medidasTrabalho={medidasTrabalhoFiltradosAdicionados}
            formaRiscoId={formaRisco.id}
            tarefaId={tarefaId}
            produtoQuimicoId={produtoQuimicoId}
            carregando={carregandoMedidasTrabalhoAdicionados}
            carregar={carregar}
            setCarregandoMedidasTrabalho={setCarregandoMedidasTrabalho}
            setCarregandoMedidasTrabalhoAdicionados={
              setCarregandoMedidasTrabalhoAdicionados
            }
            mostraQuantidade={mostraQuantidade}
          />
        </div>
      </div>
    </ModalPadrao>
  );
};

const TabelaMedidasTrabalho = ({ medidasTrabalho, carregando, ...other }) => {
  return (
    <TabelaPadrao
      colunas={[
        { nome: "Definição" },
        {
          nome: "Ações",
          width: "10%",
          text: "center",
        },
      ]}
      itens={medidasTrabalho}
      carregando={carregando}
    >
      {medidasTrabalho.map((medidaTrabalho, index) => (
        <MedidaTrabalho
          key={index}
          medidaTrabalho={medidaTrabalho}
          {...other}
        />
      ))}
    </TabelaPadrao>
  );
};

const MedidaTrabalho = ({
  adicionaMedidasTrabalho,
  item,
  medidaTrabalho,
  formaRiscoId,
  tarefaId,
  produtoQuimicoId,
  carregar,
  setCarregandoMedidasTrabalho,
  setCarregandoMedidasTrabalhoAdicionados,
  mostraQuantidade,
}) => {
  const handleClick = async (operacao, id) => {
    setCarregandoMedidasTrabalho(true);
    setCarregandoMedidasTrabalhoAdicionados(true);

    let url = "";

    if (operacao === "adicionar") {
      url = api.post("ppra/MedidaControle/inserir", {
        medida_organizacional_trabalho_id: medidaTrabalho.id,
        local_quadro_id: item.local_quadro_id,
        forma_risco_id: formaRiscoId,
        tarefa_id: tarefaId,
        produto_quimico_id: produtoQuimicoId,
        atividades: item.atividades,
        quimicos: item.quimicos,
      });
    } else if (operacao === "deletar") {
      url = api.delete(
        `ppra/MedidaControle/deletar${tarefaId ? "/" + id : ""}`,
        !tarefaId && {
          params: {
            medida_organizacional_trabalho_id: medidaTrabalho.id,
            local_quadro_id: item.local_quadro_id,
            forma_risco_id: formaRiscoId,
            tarefa_id: tarefaId,
            produto_quimico_id: produtoQuimicoId,
          },
        }
      );
    }

    await url
      .then(() => {
        carregar(
          item.local_quadro_id,
          formaRiscoId,
          false,
          tarefaId,
          produtoQuimicoId
        );
        carregar(
          item.local_quadro_id,
          formaRiscoId,
          true,
          tarefaId,
          produtoQuimicoId
        );
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
        setCarregandoMedidasTrabalho(false);
        setCarregandoMedidasTrabalhoAdicionados(false);
      });
  };

  return (
    <tr>
      <td>
        {medidaTrabalho.definicao}
        {mostraQuantidade ? " (" + medidaTrabalho.quantidade + ")" : ""}
      </td>
      <td className="text-center">
        {adicionaMedidasTrabalho ? (
          <BotaoAdicionar onClick={() => handleClick("adicionar")} />
        ) : (
          <BotaoRemover
            onClick={() =>
              handleClick(
                "deletar",
                medidaTrabalho.medida_organizacional_trabalho_ppra_id
              )
            }
          />
        )}
      </td>
    </tr>
  );
};

export default AdicionarMedidasTrabalho;
