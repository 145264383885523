import React, { useEffect, useState } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import Select from "react-select";

import { dadosToken, isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import LogoCliente from "../../../../components/LogoCliente";
import Loader from "../../../../components/Loader";
import Botao, { BotaoVoltar } from "../../../../components/Botao";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";

const HeaderObjetivosMetas = () => {
  const [dados, setDados] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [selecionaPpra, setSelecionaPpra] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [revisoes, setRevisoes] = useState([]);
  const [anos, setAnos] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState();

  const { ppraId, revisao_id, clienteId } = useParams();

  let history = useHistory();
  let match = useRouteMatch();

  let isCliente = !(dadosToken().tipo !== "2");

  useEffect(() => {
    if (isEmpty(ppraId)) {
      api
        .get(`/ppras/carregarDadosCabecalhoObjetivosMetas/${revisao_id}`)
        .then((resposta) => {
          setDados(resposta.data);
          setCarregando(false);
        })
        .catch(() => {
          setCarregando(false);
        });
    } else {
      api.get(`/ppras/carregarPpraPorId/${ppraId}`).then((resposta) => {
        setDados(resposta.data);
        setCarregando(false);
      });
    }
  }, [ppraId, revisao_id]);

  function trocarPGR() {
    setSelecionaPpra(true);
    setClienteSelecionado(clienteId);
    if (isCliente) {
      api.get(`/clientes/obterClienteComEstabelecimentos?cliente_codigo=${clienteId}`).then((resposta) => {
        setEstabelecimentos(resposta.data);
        api
          .get(`/clientes/obterRevisoesPgr?cliente_codigo=${clienteId}&&estabelecimento_codigo=${dados.codigoEstabelecimento}`)
          .then((resposta) => {
            setRevisoes(resposta.data);
          });
      });
    } else {
      api.get("/clientes").then((resposta) => {
        setClientes(resposta.data);
        api.get(`/clientes/obterClienteComEstabelecimentos?cliente_codigo=${clienteId}`).then((resposta) => {
          setEstabelecimentos(resposta.data);
          api
            .get(`/clientes/obterRevisoesPgr?cliente_codigo=${clienteId}&&estabelecimento_codigo=${dados.codigoEstabelecimento}`)
            .then((resposta) => {
              setRevisoes(resposta.data);

              if (!isEmpty(revisao_id)) {
                api.get(`/clientes/obterObjetivosMetasAnos?revisaoId=${revisao_id}`).then((resposta) => {
                  setAnos(resposta.data);
                });
              }
            });
        });
      });
    }
  }

  function buscarEstabelecimentos(clienteId) {
    api.get(`/clientes/obterClienteComEstabelecimentos?cliente_codigo=${clienteId}`).then((resposta) => {
      setEstabelecimentos(resposta.data);
      setClienteSelecionado(clienteId);
    });
  }

  function buscarRevisoes(estabelecimentoId) {
    api
      .get(`/clientes/obterRevisoesPgr?cliente_codigo=${clienteSelecionado}&&estabelecimento_codigo=${estabelecimentoId}`)
      .then((resposta) => {
        setRevisoes(resposta.data);
      });
  }

  function buscarAnos(ppraId) {
    api.get(`/clientes/obterObjetivosMetasAnos?ppraId=${ppraId}`).then((resposta) => {
      setAnos(resposta.data);
    });
  }

  return (
    <div className="card mx-5 p-2" style={{ fontSize: "13.5px" }}>
      <div className="row">
        {carregando ? (
          <Loader />
        ) : (
          <>
            <div className="d-flex align-items-center col-2">
              <LogoCliente nomeFoto={`cli_${clienteId}.jpg`} maxWidth="135px" maxHeight="40px" />
            </div>
            {!isEmpty(dados) && (
              <>
                <div className="d-flex align-items-center col-3">
                  <span className="fw-bold mr-1">Cli.:</span>
                  {selecionaPpra && !isCliente ? (
                    <Select
                      className="flex-fill"
                      name="cliente"
                      onChange={(e) => buscarEstabelecimentos(e.value)}
                      options={clientes.map(({ codigo, nome_fantasia }) => ({
                        value: codigo,
                        label: nome_fantasia,
                      }))}
                      defaultValue={{
                        label: dados.nomeCliente,
                        value: dados.cliente_codigo,
                      }}
                    />
                  ) : (
                    dados.nomeCliente
                  )}
                </div>

                <div className="d-flex align-items-center col-3">
                  <span className="fw-bold mr-1">Estab.:</span>
                  {selecionaPpra ? (
                    <Select
                      className="flex-fill"
                      name="estabelecimento"
                      onChange={(e) => buscarRevisoes(e.value)}
                      options={
                        estabelecimentos.cliente_estabelecimentos &&
                        estabelecimentos.cliente_estabelecimentos.map((clienteEstabelecimento) => ({
                          value: clienteEstabelecimento.cod_estabelecimento,
                          label: clienteEstabelecimento.estabelecimento.nome_generico,
                        }))
                      }
                      defaultValue={{
                        label: dados.nomeEstabelecimento,
                        value: dados.codigoEstabelecimento,
                      }}
                    />
                  ) : (
                    dados.nomeEstabelecimento
                  )}
                </div>

                <div className={`d-flex align-items-center ${isEmpty(revisao_id) ? "col-2" : "col"}`}>
                  <span className="fw-bold mr-1">Rev.:</span>
                  {selecionaPpra ? (
                    <Select
                      className="flex-fill"
                      name="revisao"
                      onChange={(e) => {
                        if (isEmpty(revisao_id)) {
                          history.replace({
                            pathname: generatePath(match.path, { clienteId: clienteSelecionado, ppraId: e.value }),
                          });
                          setSelecionaPpra(false);
                        } else {
                          buscarAnos(e.value);
                        }
                      }}
                      options={
                        revisoes.revisoes &&
                        revisoes.revisoes.map(({ id, revisao }) => ({
                          value: id,
                          label: revisao,
                        }))
                      }
                      defaultValue={{
                        label: dados.revisao,
                        value: dados.idPpra,
                      }}
                    />
                  ) : (
                    dados.revisao
                  )}
                </div>
                {!isEmpty(dados.ano) && (
                  <div className="d-flex align-items-center col">
                    <span className="fw-bold mr-1">Ano.:</span>
                    {selecionaPpra ? (
                      <Select
                        className="flex-fill"
                        name="anos"
                        onChange={(e) => {
                          history.replace({
                            pathname: generatePath(match.path, { clienteId: clienteSelecionado, revisao_id: e.value }),
                          });
                          setSelecionaPpra(false);
                        }}
                        options={
                          anos &&
                          anos.map((item) => ({
                            label: item.ano,
                            value: item.id,
                          }))
                        }
                        defaultValue={{
                          label: dados.ano,
                        }}
                      />
                    ) : (
                      dados.ano
                    )}
                  </div>
                )}

                <div className="col">
                  {selecionaPpra ? (
                    <BotaoVoltar
                      className="float-end"
                      onClick={() => {
                        setSelecionaPpra(false);
                        setClienteSelecionado(clienteId);
                      }}
                      temConteudo
                    />
                  ) : (
                    <Botao
                      type="button"
                      icone="exchange-alt"
                      cor="cinza"
                      onClick={() => trocarPGR()}
                      conteudo="Trocar"
                      className="float-end"
                      title="Trocar"
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderObjetivosMetas;
