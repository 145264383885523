import React, { useEffect, useState } from "react";
import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDados from "../../TemplateBaseDadosNavtabs";
import ListaErgonomiaPerigoMedidaControleNecessaria from "./ListaErgonomiaPerigoMedidaControleNecessaria";

const ErgonomiaPerigoMedidaControleNecessaria = ({ permissaoEditar }) => {
  const [perigosMedidaControleNecessaria, setPerigosMedidaControleNecessaria] = useState([]);
  const [filtro, setFiltro] = useState("");

  const item = "Perigo x Medidas de Controle Necessária";

  

  const perigosMedidaControleNecessariaFiltrados = perigosMedidaControleNecessaria.filter(
    (perigoRisco) => filtrar(perigoRisco.codigo, filtro) || filtrar(perigoRisco.definicao, filtro)
  );

  useEffect(() => {
    buscarPerigosMedidaControleNecessaria();
  }, []);

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarPerigosMedidaControleNecessaria() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarPerigosMedidaControleNecessaria")
      .then((resposta) => {
        setPerigosMedidaControleNecessaria(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDados
        item={item}
        itensFiltrados={perigosMedidaControleNecessariaFiltrados}
        totalItens={perigosMedidaControleNecessaria.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
      >
        {(currentItems) => (
          <ListaErgonomiaPerigoMedidaControleNecessaria
            perigosMedidaControleNecessaria={currentItems}
            buscarPerigosMedidaControleNecessaria={buscarPerigosMedidaControleNecessaria}
            permissaoEditar={permissaoEditar}
          />
        )}
      </TemplateBaseDados>
    </>
  );
};

export default ErgonomiaPerigoMedidaControleNecessaria;
