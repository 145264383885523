import React, { useState } from "react";
import { Col } from "react-bootstrap";

import Construcao from "../../../components/Construcao";
import ExternalLink from "../../../components/ExternalLink";
import Icone from "../../../components/Icone";

const Atalho = ({ col, ...other }) => {
  return col ? (
    <Col xs={col.xs} md={col.md} lg={col.lg}>
      <CorpoAtalho {...other} />
    </Col>
  ) : (
    <CorpoAtalho {...other} />
  );
};

const CorpoAtalho = ({
  alvo,
  bg = "primary",
  icone,
  titulo,
  border = "border border-",
  construcao,
  noExternal
}) => {
  const [backG, setBackG] = useState("");
  const [cursor, setCursor] = useState("");
  return (
    <a
      href={!construcao && alvo}
      target="_blank"
      rel="noopener noreferrer"
      className="text-decoration-none text-dark disabled"
    >
      <div
        className={`rounded bg-white d-flex ${backG} align-items-center my-1 ${
          border && border + bg
        }`}
        style={{ cursor: cursor, fontSize: "11pt" }}
        onMouseEnter={() => {
          setBackG("shadow");
          !construcao && setCursor("pointer");
        }}
        onMouseLeave={() => setBackG("")}
      >
        <div
          className={`bg-${bg} py-3 text-center`}
          style={{ minWidth: "2.5rem" }}
        >
          <Icone className="text-white" icone={icone} />
        </div>
        <div className={`flex-fill mx-2 ${construcao && "text-muted"}`}>
          {titulo}
        </div>
        <div className="mr-2 text-end">
          {construcao ? <Construcao /> : !noExternal && <ExternalLink/>}
          
        </div>
      </div>
    </a>
  );
};

export default Atalho;
