import React, { useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";

import api from "../../../service/api";
// import Botao from "../../../components/Botao";
// import { isEmpty } from "../../../components/Util";

const CadastraPontoColaborador = ({
  token,
  registros,
  carregarRegistrosColaborador,
  ObterDataAtual,
  ultimoRegistro,
}) => {
  const [status] = useState("");
  // const [carregando, setCarregando] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    // setCarregando(true);
    api
      .post("/pontoColaborador/inserir", {
        data: ObterDataAtual("AMD"),
        hora: moment().format("LTS"),
        situacao: status,
        cod_colaborador: token.id_colaborador,
        fk_entrada:
          status === "0"
            ? null
            : registros[registros.length - 1].cartao_ponto_id,
        status: "A",
      })
      .then(() => {
        carregarRegistrosColaborador();
        // setCarregando(false);
      })
      .catch((erro) => console.log(erro));
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="row pb-3">
        <div className="col ">
          <img
            src={
              token.foto
                ? process.env.REACT_APP_CAMINHO_UPLOAD +
                  `colaboradores/${token.foto}`
                : process.env.REACT_APP_SEGURE_URL + "imagens/user-image.png"
            }
            alt="Foto colaborador"
            width="100"
            height="100"
          />
        </div>
        {/* <div className="col">
          {isEmpty(registros) || ultimoRegistro.situacao === "1" ? (
            <Botao
              type="submit"
              cor="success"
              icone="sign-in"
              conteudo="Entrada"
              onClick={() => setStatus("0")}
              disabled={carregando}
            />
          ) : (
            <Botao
              type="submit"
              cor="danger"
              icone="sign-out"
              conteudo="Saída"
              onClick={() => setStatus("1")}
              disabled={carregando}
            />
          )}
        </div> */}
      </div>
    </form>
  );
};

export default CadastraPontoColaborador;
