import React, { useState } from "react";

import { BotaoDeletar, BotaoEditar, BotaoGrupo } from "../../../components/Botao";
import TabelaPadrao from "../../../components/TabelaPadrao";
import { isEmpty } from "../../../components/Util";
import api from "../../../service/api";
import ModalFormasRisco from "./ModalFormasRisco";

const ListaFormasRisco = ({ formasRisco, buscarFormaRisco, formasRiscoTipo, riscosFisicoQuimicoBiologico }) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={formasRisco}
        colunas={[
          !isEmpty(riscosFisicoQuimicoBiologico) && { nome: "Código ", width: "3%" },
          { nome: "Formas de Risco" },
          !isEmpty(riscosFisicoQuimicoBiologico) && { nome: "Risco" },
          { nome: "Ações", width: "12%", text: "center" },
        ]}
      >
        {formasRisco.map((formaRisco) => (
          <FormaRisco
            key={formaRisco.id}
            formaRisco={formaRisco}
            buscarFormaRisco={buscarFormaRisco}
            formasRiscoTipo={formasRiscoTipo}
            riscosFisicoQuimicoBiologico={riscosFisicoQuimicoBiologico}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const FormaRisco = ({ formaRisco, buscarFormaRisco, formasRiscoTipo, riscosFisicoQuimicoBiologico }) => {
  const [editando, setEditando] = useState(false);

  async function deletarFormasRisco(formaRisco, formasRiscoTipo) {
    if (window.confirm(`Tem certeza que deseja excluir a Forma de Risco: ${formaRisco.definicao}?`)) {
      let deletar;
      switch (formasRiscoTipo) {
        case 1:
          deletar = "deletarFormasRisco";
          break;
        case 2:
          deletar = "deletarFormasRiscoErgonomia";
          break;
        default:
          break;
      }

      await api
        .delete(`/baseDados/formasRisco/${deletar}/${formaRisco.id}`)
        .then(() => {
          buscarFormaRisco();
          setEditando(false);
        })
        .catch((erro) => {
          alert(erro.response.data.mensagem);
        });
    }
  }

  return (
    <>
      <tr key={formaRisco.id}>
        {!isEmpty(riscosFisicoQuimicoBiologico) && (
          <td className="align-middle text-center">{isEmpty(formaRisco.codigo) ? "-" : formaRisco.codigo}</td>
        )}
        <td className="align-middle">{formaRisco.definicao}</td>
        {!isEmpty(riscosFisicoQuimicoBiologico) && <td className="align-middle">{formaRisco.risco}</td>}

        <td className="text-center align-middle">
          <BotaoGrupo>
            <BotaoEditar onClick={() => setEditando(true)} />
            <BotaoDeletar onClick={() => deletarFormasRisco(formaRisco, formasRiscoTipo)} />
          </BotaoGrupo>
        </td>
      </tr>
      {editando && (
        <ModalFormasRisco
          show={editando}
          setShow={setEditando}
          titulo={`Editar: ${formaRisco.definicao}`}
          corTitulo="primary"
          formaRisco={formaRisco}
          buscarFormaRisco={buscarFormaRisco}
          formasRiscoTipo={formasRiscoTipo}
          riscosFisicoQuimicoBiologico={riscosFisicoQuimicoBiologico}
        />
      )}
    </>
  );
};

export default ListaFormasRisco;
