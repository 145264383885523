import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";

import { BotaoDeletar, BotaoEditar, BotaoGrupo } from "../../../../components/Botao";
import CreatableFilter from "../../../../components/CreatableFilter";
import { ModalSubmit } from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar } from "../../../../components/Util";
import Template from "../../../template/Template";
import api from "../../../../service/api";

const Equipamento = () => {
  const [showModal, setShowModal] = useState(false);
  const [equipamentos, setEquipamentos] = useState();
  const [filtroEquipamento, setFiltroEquipamento] = useState("");

  useEffect(() => {
    api.get("gro/pgr/EnsaioVedacao/obterEquipamento").then((resposta) => {
      setEquipamentos(resposta.data);
    });
  }, []);

  function carregarEquipamentos() {
    api.get("gro/pgr/EnsaioVedacao/obterEquipamento").then((resposta) => {
      setEquipamentos(resposta.data);
    });
  }

  const equipamentosFiltrados = equipamentos?.filter((filtro) => filtrar(filtro.nome, filtroEquipamento));

  return (
    <>
      <Template page="Equipamentos PPR">
        <div className="container-fluid">
          <div className="bg-white p-2">
            <ToastContainer autoClose={1000} pauseOnHover={false} />
            <div className="card bg-cinza text-white d-flex">
              <span className="align-middle">
                <h3 className="text-center">Equipamentos - Ensaio Vedação</h3>
              </span>
            </div>

            <div className="row">
              <div className="col">
                <CreatableFilter
                  placeholder="Pesquisar Equipamento ou Adicionar"
                  handleFilter={(e) => setFiltroEquipamento(e.target.value)}
                  handleClick={() => setShowModal(true)}
                  isModal={true}
                />
              </div>
            </div>

            <div className="card-body">
              <TabelaPadrao
                fontSize="15px"
                noOverflowAuto
                itens={equipamentos}
                colunas={[
                  {
                    nome: "Equipamento",
                    text: "center align-middle",
                  },
                  {
                    nome: "Modelo",
                    text: "center align-middle",
                  },
                  {
                    nome: "Ações",
                    width: "10%",
                    text: "center align-middle",
                  },
                ]}
              >
                {equipamentosFiltrados?.map((item, index) => (
                  <Equipamentos equipamento={item} key={index} carregarEquipamentos={carregarEquipamentos} />
                ))}
              </TabelaPadrao>
            </div>
          </div>
        </div>
        {showModal && (
          <ModalCadastro
            show={showModal}
            setShow={setShowModal}
            carregarEquipamentos={carregarEquipamentos}
            novoEquipamento={filtroEquipamento}
          />
        )}
      </Template>
    </>
  );
};

const ModalCadastro = ({ show, setShow, carregarEquipamentos, novoEquipamento }) => {
  const [equipamento, setEquipamento] = useState(novoEquipamento);
  const [modelo, setModelo] = useState();

  function inserirEquipamentos(equipamento, modelo) {
    api
      .post("gro/pgr/EnsaioVedacao/inserirEquipamento", {
        nome: equipamento,
        modelo: modelo,
      })
      .then(() => {
        carregarEquipamentos();
        setShow(false);
        toast.success("Equipamento adicionado!");
      });
  }

  return (
    <>
      <ModalSubmit
        show={show}
        setShow={setShow}
        tamanho="lg"
        tituloSpan="Cadastrar Equipamento"
        onSubmit={(e) => {
          e.preventDefault();
          inserirEquipamentos(equipamento, modelo);
        }}
      >
        <div className="row">
          <div className="col mb-2">
            <label>Nome equipamento:</label>
            <input
              type="text"
              className="form-control"
              defaultValue={novoEquipamento}
              onChange={(e) => setEquipamento(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col mb-2 mt-2">
            <label>Modelo:</label>
            <input type="text" className="form-control" onChange={(e) => setModelo(e.target.value)} />
          </div>
        </div>
      </ModalSubmit>
    </>
  );
};

const Equipamentos = ({ equipamento, carregarEquipamentos, permissaoEditar }) => {
  const { equipamentoId } = useState();
  const [editando, setEditando] = useState(false);

  function deletar(equipamentos) {
    api
      .delete(`gro/pgr/EnsaioVedacao/deletarEquipamento/${equipamentos}`)
      .then(() => {
        carregarEquipamentos(equipamentoId);
      })
      .then(() => {
        carregarEquipamentos();
        setEditando(false);
      });
  }
  return (
    <>
      <tr>
        <th className="text-center">{equipamento.nome}</th>
        <th className="text-center">{equipamento.modelo}</th>
        <th className="text-center">
          <BotaoGrupo>
            <BotaoEditar onClick={() => setEditando(true)} disabled={equipamento.id === "59" && true} />
            <BotaoDeletar className="ms-1" onClick={() => deletar(equipamento.id)} />
          </BotaoGrupo>
        </th>
      </tr>

      {editando && (
        <ModalEditar
          show={editando}
          setShow={setEditando}
          equipamento={equipamento}
          carregarEquipamentos={carregarEquipamentos}
        />
      )}
    </>
  );
};

const ModalEditar = ({ show, setShow, equipamento, carregarEquipamentos }) => {
  const [novoNomeEquipamento, setNovoNomeEquipamento] = useState(equipamento.nome);
  const [novoModeloEquipamento, setNovoModeloEquipamento] = useState(equipamento.modelo);
  function editarDados(id, novoNome, novoModelo) {
    api
      .put(`gro/pgr/EnsaioVedacao/editarEquipamento/${id}`, {
        nome: novoNome,
        modelo: novoModelo,
      })
      .then(() => {
        carregarEquipamentos();
        setShow(false);
        toast.success("Equipamento editado!");
      });
  }
  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      tituloSpan={`Editar ${equipamento.nome}`}
      tamanho={"lg"}
      equipamento={equipamento}
      carregarEquipamentos={carregarEquipamentos}
      onSubmit={() => editarDados(equipamento.id, novoNomeEquipamento, novoModeloEquipamento)}
    >
      <div className="row">
        <div className="col mb-2">
          <label>Nome equipamento:</label>
          <input
            type="text"
            id="nome"
            className="form-control"
            defaultValue={equipamento.nome}
            onChange={(e) => {
              setNovoNomeEquipamento(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-2 mt-2">
          <label>Modelo:</label>
          <input
            type="text"
            id="modelo"
            className="form-control"
            defaultValue={equipamento.modelo}
            onChange={(e) => {
              setNovoModeloEquipamento(e.target.value);
            }}
          />
        </div>
      </div>
    </ModalSubmit>
  );
};

export default Equipamento;
