import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ModalSubmit } from "../../../../components/ModalPadrao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";

const ModalMedidaControleNecessaria = ({
  show,
  setShow,
  titulo,
  corTitulo,
  medidaControleNecessaria,
  buscarMedidasControleNecessaria,
  novaMedidaControleNecessaria,
  proximoCodigo,
}) => {
  const [medidaTipo, setMedidaTipo] = useState([]);
  const [formasAcompanhamento, setFormasAcompanhmanto] = useState([]);
  const [salvarCodigo, setSalvarCodigo] = useState(medidaControleNecessaria ? medidaControleNecessaria.codigo : proximoCodigo);
  const [salvarMedidaControleNecessaria, setSalvarMedidaControleNecessaria] = useState(
    medidaControleNecessaria ? medidaControleNecessaria.definicao : novaMedidaControleNecessaria
  );
  const [salvarTipoMedidaControleNecessaria, setSalvarTipoMedidaControleNecessaria] = useState(
    medidaControleNecessaria?.tipo_medida_controle_id
  );
  const [salvarPrazoMedidaControleNecessaria, setSalvarPrazoMedidaControleNecessaria] = useState(
    medidaControleNecessaria?.prazo
  );
  const [salvarFormaAcompnhamentoMedidaControleNecessaria, setSalvarFormaAcompnhamentoMedidaControleNecessaria] =
    useState(medidaControleNecessaria?.forma_acompanhamento_id);

  useEffect(() => {
    buscarMedidasControleGrupo();
    buscarFormasAcompanhamento();
    // Encontrar o maior valor do código no array medidaControleNecessaria
  }, []);

  async function buscarMedidasControleGrupo() {
    await api.get("/baseDados/ergonomiaBaseDados/obterMedidasControleGrupo").then((resposta) => {
      setMedidaTipo(resposta.data);
    });
  }

  async function buscarFormasAcompanhamento() {
    await api.get("/baseDados/ergonomiaBaseDados/obterFormasAcompanhamento").then((resposta) => {
      setFormasAcompanhmanto(resposta.data);
    });
  }
  
  async function salvarBanco(e) {
    e.preventDefault();
    if (isEmpty(medidaControleNecessaria)) {
      api.post("/baseDados/ergonomiaBaseDados/inserirMedidaControleNecessaria", {
        codigo: salvarCodigo,
        definicao: salvarMedidaControleNecessaria,
        forma_acompanhamento_id: salvarFormaAcompnhamentoMedidaControleNecessaria,
        prazo: salvarPrazoMedidaControleNecessaria,
        tipo_medida_controle_id: salvarTipoMedidaControleNecessaria,
      }).then(() => {
        buscarMedidasControleNecessaria();
        setShow(false);
      })
    } else {
      api.put(`/baseDados/ergonomiaBaseDados/editarMedidaControle`, {
        id: medidaControleNecessaria.id,
        codigo: salvarCodigo,
        definicao: salvarMedidaControleNecessaria,
        forma_acompanhamento_id: salvarFormaAcompnhamentoMedidaControleNecessaria,
        prazo: salvarPrazoMedidaControleNecessaria,
        tipo_medida_controle_id: salvarTipoMedidaControleNecessaria,
      }).then(() => {
        buscarMedidasControleNecessaria();
        setShow(false);
      })

    }
  }

  let optionsTipoMedida = medidaTipo.map((item) => ({
    label: item.grupo,
    value: item.id,
  }));

  let optionsFormaAcompanhamento = formasAcompanhamento.map((item) => ({
    label: item.acompanhamento,
    value: item.id,
  }));

  function selecionaTipoMedida(medida_id) {
    if (medida_id === "1") {
      return { label: "EPC" };
    } else if (medida_id === "2") {
      return { label: "EPI" };
    } else if (medida_id === "3") {
      return { label: "ORG" };
    } else {
      return { label: "--" };
    }
  }

  
  function selecionaFormaAcompanhamento(forma_acompanhamento_id) {
    if (forma_acompanhamento_id === "1") {
      return { label: "Inspeção no local" };
    } else if (forma_acompanhamento_id === "3") {
      return { label: "Análise documental" };
    } else if (forma_acompanhamento_id === "5") {
      return { label: "Ficha EPI" };
    } else if (forma_acompanhamento_id === "7") {
      return { label: "Nenhum" };
    } else if (forma_acompanhamento_id === "11") {
      return { label: "Registro(assinatura)" };
    } else {
      return { label: "--" };
    }
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      titulo={titulo}
      corTitulo={corTitulo}
      tamanho="md"
      onSubmit={(e) => salvarBanco(e)}
    >
      <div className="row mb-3">
        <div className="col-4 d-flex">
        <label className="mt-2">Código:</label>
          <input
            type="text"
            id="codigo"
            name="codigo"
            className="ml-3 form-control"
            defaultValue={medidaControleNecessaria?.codigo ? medidaControleNecessaria?.codigo : salvarCodigo}
            onChange={(m) => {
              setSalvarCodigo(m.target.value);
            }}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>Medida de Controle Necessária:</label>
          <input
            type="text"
            id="medidaControleNecessaria"
            name="medidaControleNecessaria"
            className="form-control"
            defaultValue={medidaControleNecessaria ? salvarMedidaControleNecessaria : novaMedidaControleNecessaria}
            onChange={(m) => {
              setSalvarMedidaControleNecessaria(m.target.value);
            }}
            required
          />
        </div>
        <div className="col-4">
          <label htmlFor="tipoMedida">Medida Grupo</label>
          <Select
            options={optionsTipoMedida}
            onChange={(m) => {
              setSalvarTipoMedidaControleNecessaria(m.value);
            }}
            id="tipoMedida"
            defaultValue={selecionaTipoMedida(medidaControleNecessaria?.tipo_medida_controle_id)}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label>Prazo:</label>
          <small className="ml-2 text-muted">{"Número de meses"}</small>
          <input
            type="number"
            id="medidaControleNecessaria"
            name="medidaControleNecessaria"
            maxlength="2"
            className="form-control"
            defaultValue={medidaControleNecessaria?.prazo}
            onChange={(m) => {
              setSalvarPrazoMedidaControleNecessaria(m.target.value);
            }}
            required
          />
        </div>
        <div className="col">
          <label htmlFor="tipoMedida">Forma de Acompanhamento:</label>
          <Select
            options={optionsFormaAcompanhamento}
            onChange={(m) => {
              setSalvarFormaAcompnhamentoMedidaControleNecessaria(m.value);
            }}
            id="tipoMedida"
            defaultValue={selecionaFormaAcompanhamento(medidaControleNecessaria?.forma_acompanhamento_id)}
          />
        </div>
      </div>
    </ModalSubmit>
  );
};

export default ModalMedidaControleNecessaria;
