import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import Botao from "../../../../components/Botao";
import Template from "../../../template/Template";

const FisicoQuimicoBiologico = () => {
  const { ppraId } = useParams();

  return (
    <Template
      page="Painel: Físico, Químico, Biológico"
      backTo={"/ppra/" + ppraId + "/painel"}
    >
      {(ppra) => (
        <>
          <div className="container-fluid">
            <div className="bg-white p-3">
              <div className="row">
                <div className="col">
                  <div className="card bg-cinza text-white">
                    <span className="align-middle">
                      <h4 className="text-center">
                        Painel Físico, Químico, Biológico
                      </h4>
                    </span>
                  </div>
                  <div className="row mt-2" style={{ height: "250px" }}>
                    <div className="col">
                      <div className="btn-group-vertical w-100 h-100">
                        <div className="btn-group btn-group h-50">
                          <Botao
                            className="w-100 d-flex justify-content-center align-items-center"
                            conteudo={<h6>Biológicos</h6>}
                            cor="cinza"
                            onClick={() =>
                              window.open(
                                `/ppra/${ppraId}/reconhecimentos/biologicos`,
                                "_self"
                              )
                            }
                            outline
                          />
                          <Botao
                            className="w-100 d-flex justify-content-center align-items-center"
                            conteudo={<h6>Checklist</h6>}
                            cor="cinza"
                            onClick={() =>
                              window.open(`/pgr/${ppraId}/checklist#2`, "_self")
                            }
                            outline
                          />
                        </div>
                        <div className="btn-group btn-group h-50">
                          <Botao
                            className="w-100 d-flex justify-content-center align-items-center"
                            conteudo={<h6>LINACH</h6>}
                            cor="cinza"
                            onClick={() =>
                              window.open(`/ppra/${ppraId}/linachs`, "_self")
                            }
                            outline
                          />

                          <Botao
                            className="w-100 d-flex justify-content-center align-items-center"
                            conteudo={<h6>Produtos Químicos</h6>}
                            cor="cinza"
                            onClick={() =>
                              window.open(
                                `/ppra/${ppraId}/produtos-quimicos-ppra`,
                                "_self"
                              )
                            }
                            outline
                          />
                        </div>
                        <div className="btn-group btn-group h-50">
                          <Botao
                            className="w-100 d-flex justify-content-center align-items-center"
                            conteudo={<h6>Reconhecimento Ambientes</h6>}
                            cor="cinza"
                            onClick={() =>
                              window.open(
                                `/ppra/${ppraId}/reconhecimentos-ambientes`,
                                "_self"
                              )
                            }
                            outline
                          />
                          <Botao
                            className="w-100 d-flex justify-content-center align-items-center"
                            conteudo={<h6>Reconhecimento / Matemática</h6>}
                            cor="cinza"
                            onClick={() =>
                              window.open(
                                `/ppra/${ppraId}/reconhecimentos`,
                                "_self"
                              )
                            }
                            outline
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Template>
  );
};

export default FisicoQuimicoBiologico;
