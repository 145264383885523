import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge } from "react-bootstrap";

import ModalPadrao from "../../../components/ModalPadrao";
import TabelaPadrao from "../../../components/TabelaPadrao";
import Botao, { BotaoEditar, BotaoSalvar } from "../../../components/Botao";
import api from "../../../service/api";
import InputFile from "../../../components/InputFile";

const Upload = ({ programa }) => {
  const [showUpload, setShowUpload] = useState(false);
  const [showEditar, setShowEditar] = useState(false);
  const [programaSelecionado, setProgramaSelecionado] = useState();

  return (
    <>
      <div className="row mt-2">
        <TabelaPadrao
          colunas={[
            { nome: "Estabelecimento" },
            { nome: "Nome do Documento" },
            { nome: "Tipo do Documento" },
            { nome: "Filtros" },
            { nome: "Ações", width: "5%" },
            { nome: "Upload", width: "5%" },
          ]}
          itens={programa}
        >
          {programa.map((doc) => (
            <tr key={doc.id}>
              <td>{doc.estabelecimento}</td>
              <td>{doc.nome_documento}</td>
              <td>{doc.tipo_documento}</td>
              <td>
                {doc.filtros.length === 0
                  ? "Nenhum Filtro."
                  : doc.filtros.map((filtro) =>
                      doc.filtros[doc.filtros.length - 1] !== filtro ? (
                        <span key={filtro.id}>
                          {filtro.nome_filtro}
                          {", "}
                        </span>
                      ) : (
                        <span key={filtro.id}>
                          {filtro.nome_filtro}
                          {"."}
                        </span>
                      )
                    )}
              </td>

              <td className="text-center">
                <BotaoEditar
                  onClick={() => {
                    setShowEditar(true);
                    setProgramaSelecionado(doc);
                  }}
                />
              </td>
              <td className="text-center">
                <Botao
                  icone="upload"
                  onClick={() => {
                    setShowUpload(true);
                    setProgramaSelecionado(doc);
                  }}
                />
              </td>
            </tr>
          ))}
        </TabelaPadrao>
        {showUpload && (
          <ModalUpload
            show={showUpload}
            setShow={setShowUpload}
            programa={programaSelecionado}
          />
        )}
        {showEditar && (
          <ModalEditar
            show={showEditar}
            setShow={setShowEditar}
            programa={programaSelecionado}
            programas={programa}
          />
        )}
      </div>
    </>
  );
};

const ModalUpload = ({ show, setShow, programa }) => {
  const [revisao, setRevisao] = useState();
  const [ano, setAno] = useState();
  const [periodo, setPeriodo] = useState();
  const [responsavel, setResponsavel] = useState();
  const [setor, setSetor] = useState();
  const [funcao, setFuncao] = useState();
  const [funcionario, setFuncionario] = useState();
  const [arquivo, setArquivo] = useState();

  const [periodoInicio, setPeriodoInicio] = useState();
  const [periodoFim, setPeriodoFim] = useState();

  const dadosFiltros = [
    revisao ? revisao : null,
    ano ? ano : null,
    responsavel ? responsavel : null,
    setor ? setor : null,
    funcao ? funcao : null,
    funcionario ? funcionario : null,
    periodo ? periodo : null,
  ];

  const formataNome = (str) => {
    str = str.replace(/[ÀÁÂÃ]/g, "A");
    str = str.replace(/[àáâã]/g, "a");
    str = str.replace(/[ÈÉÊË]/g, "E");
    str = str.replace(/[èéêẽ]/g, "e");
    str = str.replace(/[ÌÍÎĨ]/g, "I");
    str = str.replace(/[ìíîĩ]/g, "i");
    str = str.replace(/[òóôõ]/g, "o");
    str = str.replace(/[ÒÓÔÕ]/g, "O");
    str = str.replace(/[ÙÚÛŨ]/g, "U");
    str = str.replace(/[ùúûũ]/g, "u");
    str = str.replace(/[/]/g, "_");
    str = str.replace(/[" "]/g, "_");
    str = str.replace(/[!@#$%&*<>,.;:?°º+|]/g, "");
    return str;
  };

  function inserirDadosFiltros(uploadId) {
    const dadosBanco = programa.filtros?.map((filtro) => ({
      gro_programa_filtro_documento_id: filtro.id,
      upload_id: uploadId,
      filtro_dados:
        dadosFiltros[
          dadosFiltros.findIndex(
            (dadosFiltro) => dadosFiltro?.id === filtro.gro_programa_filtro_id
          )
        ].dado,
    }));

    api.post("/gro/programa/inserirDadosFiltroDocumento", dadosBanco).then(
      () => toast.success("Upload Salvo com Sucesso!"),
      window.setTimeout(() => {
        setShow(false);
      }, 2000)
    );
  }

  function inserirUploadPrograma(e, arquivo) {
    if (periodoInicio > periodoFim) {
      toast.info(
        "Período inválido, período fim deve ser maior que período início!"
      );
    } else if (ano !== undefined && (ano.dado < 1501 || ano.dado > 2100)) {
      toast.info("Ano inválido, inserir somente entre 1501 a 2100.");
    } else if (arquivo === undefined) {
      toast.info("Insira um arquivo PDF.");
    } else {
      api
        .post("gro/programa/inserirUpload", {
          gro_programa_id: programa.id,
        })
        .then((resposta) => {
          inserirUpload(e, arquivo, resposta.data.dados.id);
        });
    }
  }

  async function inserirUpload(e, arquivo, id) {
    e.preventDefault();

    const novoNome = `${programa.tipo_documento}_${programa.nome_documento}_${programa.nome_fantasia}_${programa.estabelecimento}_${id}`;
    const formData = new FormData();
    formData.append("arquivo", arquivo);
    formData.append("nomeNovo", formataNome(novoNome));
    formData.append("nomeArquivo", arquivo.name);

    await api
      .post("/upload/inserirUploadPrograma", formData, {
        headers: {
          "Content-Type": `multipart/form-data;`,
        },
      })
      .then((resposta) => editarUrlArquivo(resposta.data.nomeArquivo, id))
      .catch((e) => alert(e.response.data.mensagem));
  }

  function editarUrlArquivo(urlArquivo, id) {
    api
      .put(`gro/programa/editarUrlArquivo/${id}`, {
        url_arquivo: urlArquivo,
      })
      .then(() => {
        inserirDadosFiltros(id);
      });
  }

  return (
    <ModalPadrao
      show={show}
      setShow={setShow}
      titulo={"Upload"}
      tituloSpan={programa.nome_documento}
      modalCentralizada
      tamanho="lg"
    >
      <h4>Cadastro de dados</h4>
      <div className="row">
        <div className="col">
          {programa.filtros.find(
            (filtro) => filtro.gro_programa_filtro_id === "1"
          ) !== undefined && (
            <div className="row mt-2">
              <label htmlFor="1" className="form-label">
                Revisão:
              </label>
              <input
                className="form-control"
                type="text"
                name="revisao"
                id="1"
                onChange={(e) =>
                  setRevisao({ id: e.target.id, dado: e.target.value })
                }
                required
              />
            </div>
          )}

          {programa.filtros.find(
            (filtro) => filtro.gro_programa_filtro_id === "2"
          ) !== undefined && (
            <div className="row mt-2">
              <label htmlFor="2" className="form-label">
                Ano:
              </label>
              <input
                className="form-control"
                type="number"
                min="1900"
                max="2900"
                name="ano"
                id="2"
                onChange={(e) =>
                  setAno({ id: e.target.id, dado: e.target.value })
                }
                required
              />
            </div>
          )}

          {programa.filtros.find(
            (filtro) => filtro.gro_programa_filtro_id === "3"
          ) !== undefined && (
            <div className="row mt-2">
              <label htmlFor="3" className="form-label">
                Período:
              </label>
              <div className="col">
                <input
                  className="form-control"
                  type="date"
                  name="periodo"
                  id="31"
                  onChange={(e) => setPeriodoInicio(e.target.value)}
                  required
                />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  type="date"
                  name="periodo"
                  id="32"
                  onChange={(e) => {
                    setPeriodoFim(e.target.value);
                    setPeriodo({
                      id: "3",
                      dado: periodoInicio + " A " + e.target.value,
                    });
                  }}
                  required
                />
              </div>
            </div>
          )}

          {programa.filtros.find(
            (filtro) => filtro.gro_programa_filtro_id === "4"
          ) !== undefined && (
            <div className="row mt-2">
              <label htmlFor="4" className="form-label">
                Responsável:
              </label>
              <input
                className="form-control"
                type="text"
                name="funcao"
                id="4"
                onChange={(e) =>
                  setResponsavel({ id: e.target.id, dado: e.target.value })
                }
                required
              />
            </div>
          )}

          {programa.filtros.find(
            (filtro) => filtro.gro_programa_filtro_id === "5"
          ) !== undefined && (
            <div className="row mt-2">
              <label htmlFor="5" className="form-label">
                Setor:
              </label>
              <input
                className="form-control"
                type="text"
                name="setor"
                id="5"
                onChange={(e) =>
                  setSetor({ id: e.target.id, dado: e.target.value })
                }
                required
              />
            </div>
          )}

          {programa.filtros.find(
            (filtro) => filtro.gro_programa_filtro_id === "6"
          ) !== undefined && (
            <div className="row mt-2">
              <label htmlFor="6" className="form-label">
                Função:
              </label>
              <input
                className="form-control"
                type="text"
                name="funcao"
                id="6"
                onChange={(e) =>
                  setFuncao({ id: e.target.id, dado: e.target.value })
                }
                required
              />
            </div>
          )}

          {programa.filtros.find(
            (filtro) => filtro.gro_programa_filtro_id === "7"
          ) !== undefined && (
            <div className="row mt-2">
              <label htmlFor="7" className="form-label">
                Funcionário:
              </label>
              <input
                className="form-control"
                type="text"
                name="funcionario"
                id="7"
                onChange={(e) =>
                  setFuncionario({ id: e.target.id, dado: e.target.value })
                }
                required
              />
            </div>
          )}
        </div>
        <div className="row mt-3">
          <div className="col">
            <InputFile
              nomeArquivo={arquivo}
              handleChange={(arquivo) => setArquivo(arquivo)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-end">
            <BotaoSalvar
              temConteudo
              onClick={(e) => {
                inserirUploadPrograma(e, arquivo);
              }}
            />
          </div>
        </div>
      </div>
    </ModalPadrao>
  );
};

const ModalEditar = ({ show, setShow, programas, programa }) => {
  return (
    <ModalPadrao
      show={show}
      setShow={setShow}
      tituloSpan={"Editar"}
      modalCentralizada
      tamanho="lg"
      handleHide={() => window.location.reload()}
    >
      <EditarPrograma programas={programas} item={programa} />
      <div className="row"></div>
    </ModalPadrao>
  );
};

const EditarPrograma = ({ item, programas }) => {
  const [clienteEstabelecimentos, setClienteEstabelecimentos] = useState([]);
  const [nomeDocumento, setNomeDocumento] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [carregarFiltros, setCarregarFiltros] = useState([]);
  const [clienteEstabelecimentoId, setClienteEstabelecimentoId] = useState(
    item.estabelecimento_id
  );
  const [nomeDocumentoId, setNomeDocumentoId] = useState(
    item.gro_programa_nome_documento_id
  );
  const [tipoDocumentoId, setTipoDocumentoId] = useState(
    item.gro_programa_tipo_documento_id
  );
  const [titulo, setTitulo] = useState(item.nome_documento);
  const [subTitulo, setSubTitulo] = useState(item.tipo_documento);

  const { clienteId } = useParams();
  useEffect(() => {
    async function buscarEstabelecimentos(clienteId) {
      await api
        .get(
          `/clientes/obterClienteComEstabelecimentos?cliente_codigo=${clienteId}`
        )
        .then((resposta) => {
          setClienteEstabelecimentos(resposta.data);
        });
    }

    api
      .get(`/gro/programa/buscarDadosEditarLinha?programa_id=${item.id}`)
      .then((resposta) => {
        setCarregarFiltros(resposta.data);
      });

    buscarEstabelecimentos(clienteId);
    buscarDadosEdicao();
  }, [clienteId, item.id]);

  const verificaFiltrosMarcados = (filtroId) => {
    for (let i = 0; i < item.filtros.length; i++) {
      if (filtroId === item.filtros[i].gro_programa_filtro_id) {
        return true;
      }
    }
  };

  function carregarItem() {
    api
      .get(`/gro/programa/buscarDadosEditarLinha?programa_id=${item.id}`)
      .then((resposta) => {
        setCarregarFiltros(resposta.data);
      });
  }

  const progSelecionado = programas.find(
    (prog) =>
      prog.estabelecimento_id === clienteEstabelecimentoId &&
      prog.gro_programa_nome_documento_id === nomeDocumentoId &&
      prog.gro_programa_tipo_documento_id === tipoDocumentoId
  );

  function EditarDocumento() {
    if (progSelecionado !== undefined) {
      toast.warning("Programa já cadastrado");
    } else {
      api
        .put(`/gro/programa/editar/${item.id}`, {
          estabelecimento_id: clienteEstabelecimentoId,
          gro_programa_nome_documento_id: nomeDocumentoId,
          gro_programa_tipo_documento_id: tipoDocumentoId,
        })
        .then(() => toast.success("Documento editado com sucesso!"));
    }
  }

  function adicionarFiltro(filtro_id) {
    api
      .post("/gro/programa/editarInserirFiltroDocumento", {
        gro_programa_filtro_id: filtro_id,
        gro_programa_id: item.id,
      })
      .then(() => {
        carregarItem();
        toast.success("Filtro adicionado!");
      });
  }

  function removerFiltro(filtro_id) {
    let posicao = carregarFiltros.findIndex(
      (filtro) => filtro.gro_programa_filtro_id === filtro_id
    );
    let id = carregarFiltros[posicao].id;

    api.delete(`/gro/programa/deletarFiltroDocumento?id=${id}`).then(() => {
      toast.success("Filtro removido!");
      carregarItem();
    });
  }

  const estabelecimentos =
    clienteEstabelecimentos.cliente_estabelecimentos?.map(
      (clienteEstabelecimento) => ({
        value: clienteEstabelecimento,
        label: clienteEstabelecimento.estabelecimento.nome_generico,
      })
    );

  const nomesdocumentos = nomeDocumento?.map((nomeDoc) => ({
    value: nomeDoc.id,
    label: nomeDoc.nome_documento,
  }));

  const tiposDocumentos = tipoDocumento?.map((tipDoc) => ({
    value: tipDoc.id,
    label: tipDoc.tipo_documento,
  }));

  async function buscarDadosEdicao() {
    await api.get("/gro/programa/buscarDadosEditar").then((resposta) => {
      setNomeDocumento(resposta.data.nome_documentos);
      setTipoDocumento(resposta.data.tipo_documentos);
      setFiltros(resposta.data.filtros);
    });
  }

  return (
    <>
      <div className="row">
        <h4>
          {titulo} <Badge className="ms-1">{subTitulo}</Badge>{" "}
        </h4>
      </div>
      <div className="row mt-3">
        <div className="col">
          <label htmlFor="selectElstab" className="form-label">
            Estabelecimento
          </label>
          <Select
            placeholder="Selecione"
            name="Estabelecimento"
            id="selectElstab"
            onChange={(option) => {
              setClienteEstabelecimentoId(option.value.codigo);
            }}
            options={estabelecimentos}
            defaultValue={{
              value: item.estabelecimento_id,
              label: item.estabelecimento,
            }}
          />
        </div>
        <div className="col">
          <label htmlFor="selectDoc" className="form-label">
            Documento
          </label>
          <Select
            placeholder="Selecione"
            name="Documento"
            id="selectDoc"
            onChange={(option) => {
              setNomeDocumentoId(option.value);
              setTitulo(option.label);
            }}
            options={nomesdocumentos}
            defaultValue={{
              value: item.gro_programa_nome_documento_id,
              label: item.nome_documento,
            }}
          />
        </div>
        <div className="col">
          <label htmlFor="selectTipoDoc" className="form-label">
            Tipo documento
          </label>
          <Select
            placeholder="Selecione"
            name="Tipo documento"
            id="selectTipoDoc"
            onChange={(option) => {
              setTipoDocumentoId(option.value);
              setSubTitulo(option.label);
            }}
            options={tiposDocumentos}
            defaultValue={{
              value: item.gro_programa_tipo_documento_id,
              label: item.tipo_documento,
            }}
          />
        </div>
        <div className="mt-1 ">
          <BotaoEditar
            className="mt-2 float-end"
            onClick={(e) => EditarDocumento(e)}
            temConteudo
          />
        </div>
      </div>

      <div className="row mt-3">
        <label htmlFor="filtroCad" className="form-label">
          Filtros (Editar filtros no documento)
        </label>

        <div className="col">
          <div className="row mt-2">
            <TabelaPadrao
              colunas={[
                { nome: "Filtros", width: "80%" },
                { nome: "Ação", width: "10%", text: "center" },
              ]}
              itens={filtros}
            >
              {filtros?.map((filtro) => (
                <tr key={filtro.id}>
                  <th>{filtro.nome_filtro}</th>
                  <th className="text-center">
                    <input
                      type="checkbox"
                      className="btn-check"
                      id={filtro.id + filtro.nome_filtro}
                      autoComplete="off"
                      onClick={(e) => {
                        e.target.checked
                          ? adicionarFiltro(filtro.id)
                          : window.confirm(
                              "Você tem certeza que deseja remover o filtro?"
                            )
                          ? removerFiltro(filtro.id)
                          : e.preventDefault();
                      }}
                      defaultChecked={verificaFiltrosMarcados(filtro.id)}
                    />
                    <label
                      className="btn btn-outline-primary p-3"
                      htmlFor={filtro.id + filtro.nome_filtro}
                    ></label>
                  </th>
                </tr>
              ))}
            </TabelaPadrao>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upload;