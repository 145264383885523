import React, { useEffect, useState } from "react";

import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";
import ListaAcidenteRisco from "./ListaAcidenteRisco";
import ModalAcidenteRisco from "./ModalAcidenteRisco";

const AcidenteRisco = ({ permissaoEditar }) => {
  const [riscos, setRiscos] = useState([]);
  const [acidenteRiscos, setAcidenteRiscos] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "Riscos Acidentes";

  useEffect(() => {
    buscarRiscos();
    buscarApreRiscoErgonomicos();
  }, []);

  const riscosFiltrados = riscos.filter(
    (risco) => filtrar(risco.codigo, filtro) || filtrar(risco.definicao, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarRiscos() {
    await api
      .get("/baseDados/acidenteBaseDados/buscarRiscos")
      .then((resposta) => {
        setRiscos(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarApreRiscoErgonomicos() {
    await api
      .get("/baseDados/acidenteBaseDados/buscarAcidenteRisco")
      .then((resposta) => {
        setAcidenteRiscos(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={riscosFiltrados}
        totalItens={riscos.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={() => permissaoEditar && setShowModalCadastro(true)}
      >
        {(currentItems) => (
          <ListaAcidenteRisco
            riscos={currentItems}
            buscarRiscos={buscarRiscos}
            acidenteRiscos={acidenteRiscos}
            permissaoEditar={permissaoEditar}
          />
        )}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalAcidenteRisco
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Risco Ergonômico"
          corTitutlo="success"
          buscarRiscos={buscarRiscos}
          novoRisco={filtro}
          acidenteRiscos={acidenteRiscos}
        />
      )}
    </>
  );
};

export default AcidenteRisco;
