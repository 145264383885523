import React from "react";

const SpanBadge = ({
  className,
  texto,
  tamanho,
  cor = "primary",
  icone,
  onClick,
  iconeCor,
}) => {
  return (
    <span
      className={`${className} badge bg-${cor}`}
      title={texto}
      onClick={onClick}
    >
      {icone && <i className={`fa fa-${icone}`} style={ iconeCor &&{color: iconeCor}} aria-hidden="true"></i>}{" "}
      {texto
        ? texto.substr(0, tamanho) + (texto.length > tamanho ? "..." : "")
        : "-"}
    </span>
  );
};

export default SpanBadge;
