import React, { useEffect, useState } from "react";

import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";
import ListaRisco from "./ListaRisco";
import ModalRisco from "./ModalRisco";

const Risco = ({ permissaoEditar }) => {
  const [riscos, setRiscos] = useState([]);
  const [apreRiscos, setApreRiscos] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "Riscos Ergonômicos";

  useEffect(() => {
    buscarRiscos();
    buscarApreRiscoErgonomicos();
  }, []);

  const riscosFiltrados = riscos.filter(
    (risco) => filtrar(risco.id, filtro) || filtrar(risco.riscos, filtro) || filtrar(risco.sintomas, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarRiscos() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarRiscos")
      .then((resposta) => {
        setRiscos(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarApreRiscoErgonomicos() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarApreRiscoErgonomicos")
      .then((resposta) => {
        setApreRiscos(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={riscosFiltrados}
        totalItens={riscos.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={() => permissaoEditar && setShowModalCadastro(true)}
      >
        {(currentItems) => (
          <ListaRisco
            riscos={currentItems}
            buscarRiscos={buscarRiscos}
            apreRiscos={apreRiscos}
            permissaoEditar={permissaoEditar}
          />
        )}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalRisco
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Risco Ergonômico"
          corTitutlo="success"
          buscarRiscos={buscarRiscos}
          novoRisco={filtro}
          apreRiscos={apreRiscos}
        />
      )}
    </>
  );
};

export default Risco;
