import jwt from "jsonwebtoken";
import moment from "moment";
import "moment/locale/pt-br";

import { getToken } from "../service/auth";
import api from "../service/api";

export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",

  background: isDragging ? "lightgray" : "",
  display: isDragging && "table",
  cursor: "pointer",
  ...draggableStyle,
});

export const TratarData = (data) => {
  if (data !== null) {
    var dataSeparada = data.split("-");
    return dataSeparada[2] + "/" + dataSeparada[1] + "/" + dataSeparada[0];
  }
};

export const ObterDataAtual = (formato) => {
  if (formato === "DMA") {
    return moment().format("L");
  } else {
    return moment().format("YYYY-MM-DD");
  }
};

export const formataNome = (str) => {
  str = str.replace(/[ÀÁÂÃ]/g, "A");
  str = str.replace(/[àáâã]/g, "a");
  str = str.replace(/[ÈÉÊË]/g, "E");
  str = str.replace(/[èéêẽ]/g, "e");
  str = str.replace(/[ÌÍÎĨ]/g, "I");
  str = str.replace(/[ìíîĩ]/g, "i");
  str = str.replace(/[òóôõ]/g, "o");
  str = str.replace(/[ÒÓÔÕ]/g, "O");
  str = str.replace(/[ÙÚÛŨ]/g, "U");
  str = str.replace(/[ùúûũ]/g, "u");
  str = str.replace(/[/]/g, "_");
  str = str.replace(/[" "]/g, "_");
  str = str.replace(/[!@#$%&*<>,.;:?°º+|]/g, "");

  return str;
};

export const FormatarHorario = (horario) => {
  let horarioSeparado = horario.split(":");

  return moment().hours(horarioSeparado[0]).minutes(horarioSeparado[1]).format("LT");
};

export const TornarPrimeiraLetraMaiuscula = (palavra) => {
  return palavra.charAt(0).toUpperCase() + palavra.slice(1);
};

export const TornarPrimeiraLetraMinuscula = (palavra) => {
  return palavra.charAt(0).toLowerCase() + palavra.slice(1);
};

export const PegarPrimeiraPalavraFrase = (frase) => {
  return frase.substring(0, frase.indexOf(" "));
};

export const isEmpty = (parametro) => {
  if (parametro === null || parametro === undefined) {
    return true;
  }
  if (Array.isArray(parametro)) {
    return parametro.length === 0;
  } else {
    return Object.keys(parametro).length === 0;
  }
};

export const substituirCaracteresEspeciais = (str) => {
  str = str.replace(/[ÀÁÂÃ]/, "A");
  str = str.replace(/[àáâã]/, "a");
  str = str.replace(/[ÈÉÊË]/, "E");
  str = str.replace(/[èéêẽ]/, "e");
  str = str.replace(/[ÌÍÎĨ]/, "I");
  str = str.replace(/[ìíîĩ]/, "i");
  str = str.replace(/[òóôõ]/, "o");
  str = str.replace(/[ÒÓÔÕ]/, "O");
  str = str.replace(/[ÙÚÛŨ]/, "U");
  str = str.replace(/[ùúûũ]/, "u");

  return str;
};

export const filtrar = (str, filter) => {
  str = substituirCaracteresEspeciais(str);
  filter = substituirCaracteresEspeciais(filter);

  return str.toLowerCase().indexOf(filter.toLowerCase()) > -1;
};

export const filtrarDataInicioFim = (dataInicio, dataFim, data) => {
  if (isEmpty(dataInicio) || isEmpty(dataFim)) {
    return true;
  } else {
    let dataInicioFormatada = moment(dataInicio).subtract(1, "days").format("YYYY-MM-DD");
    let dataFimFormatada = moment(dataFim).add(1, "days").format("YYYY-MM-DD");

    return moment(data).isBetween(dataInicioFormatada, dataFimFormatada);
  }
};

export const dadosToken = () => jwt.decode(getToken());

export const mostrarMensagem = (msg, setMensagem) => {
  setMensagem(msg);
  setTimeout(() => {
    setMensagem("");
  }, 2500);
};

export const formatarValor = (valor) => {
  valor = parseFloat(valor);
  var valorFormatado = valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
  return valorFormatado;
};

export const handleCorPriorizacao = (priorizacao) => {
  switch (priorizacao) {
    case "1":
      return 2303786;

    case "2":
      return 15158332;

    case "3":
      return 16776960;

    case "4":
      return 3447003;

    case "5":
      return 2067276;

    default:
      break;
  }
};

export async function botDiscord(data, mensagem, justificativa) {
  let usuario = dadosToken();
  let corPriorizacao = handleCorPriorizacao(data.priorizacao);

  let objetoJustificativa = {};
  if (justificativa) {
    objetoJustificativa = {
      title: justificativa,
      color: 14177041,
    };
  } else {
    objetoJustificativa = {
      title: "Não existe justificativa!",
      color: 14177041,
    };
  }

  function validarLink(link) {
    if (isEmpty(link.match("https://")) && isEmpty(link.match(".com"))) {
      return `https://${link}.com`;
    } else if (!isEmpty(link.match("https://")) && isEmpty(link.match(".com"))) {
      return `${link}.com`;
    } else if (isEmpty(link.match("https://")) && !isEmpty(link.match(".com"))) {
      return `https://${link}`;
    }
  }

  await api
    .post(
      "https://discordapp.com/api/webhooks/838844061644881950/F4OE1RT8NvoobAF9ERrqJEe__b3zKRzUCSDsMzJ67TPq13mRhN3EzCdgJzfQ4nDpUX-2",
      {
        name: "Mensageiro - TEGIS",
        avatar_url: "https://i.imgur.com/4M34hi2.png",
        content: mensagem,

        embeds: [
          {
            author: {
              name: usuario.nome,
              icon_url: `https://segure.com.br/uploads/colaboradores/${usuario.foto}`,
              url: "https://ticket.segure.com.br/",
            },
            title: data.sistema,
            url: validarLink(data.link),
            color: corPriorizacao,
            fields: [
              {
                name: data.titulo,
                value: data.solicitacao,
              },
            ],
            timestamp: new Date(),
            footer: {
              text: "© Tegis",
            },
          },
          objetoJustificativa,
        ],
      }
    )
    .catch((resposta) => console.log(resposta));
}
