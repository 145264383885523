import React from "react";
import Pagination from "react-js-pagination";

const Paginacao = ({
  activePage,
  itemsCountPerPage,
  itensFiltrados,
  pageRangeDisplayed,
  setActivePage,
}) => {

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }
  return (
    <Pagination
      itemClass="page-item"
      linkClass="page-link"
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={itensFiltrados.length}
      pageRangeDisplayed={!pageRangeDisplayed && 25}
      onChange={handlePageChange.bind(this)}
    />
  );
};

export default Paginacao;
