import React, { useEffect, useState } from "react";
import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDados from "../../TemplateBaseDadosNavtabs";
import ListaAcidentePerigoRisco from "./ListaAcidentePerigoRisco";

const AcidentePerigoRisco = ({ permissaoEditar }) => {
  const [perigosRiscos, setPerigoRiscos] = useState([]);
  const [filtro, setFiltro] = useState("");

  const item = "Perigo x Risco";

  const perigosRiscosFiltrados = perigosRiscos.filter(
    (perigoRisco) =>
      filtrar(perigoRisco.codigo, filtro) ||
      filtrar(perigoRisco.definicao, filtro)
  );

  useEffect(() => {
    buscarPerigosRiscos();
  }, []);

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarPerigosRiscos() {
    await api
      .get("/baseDados/acidenteBaseDados/buscarPerigosRiscos")
      .then((resposta) => {
        setPerigoRiscos(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDados
        item={item}
        itensFiltrados={perigosRiscosFiltrados}
        totalItens={perigosRiscos.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
      >
        {(currentItems) => (
          <ListaAcidentePerigoRisco
            perigosRiscos={currentItems}
            buscarPerigosRiscos={buscarPerigosRiscos}
            permissaoEditar={permissaoEditar}
          />
        )}
      </TemplateBaseDados>
    </>
  );
};

export default AcidentePerigoRisco;
