import React, { useState } from "react";
import { toast } from "react-toastify";

import Botao, { BotaoEditar, BotaoGrupo, BotaoSalvar } from "../../../../components/Botao";
import ModalPadrao from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { TratarData } from "../../../../components/Util";
import api from "../../../../service/api";

const Visualizacao = ({ checklist }) => {
  const [showEditar, setShowEditar] = useState(false);
  const [checklistSelecionado, setChecklistSelecionado] = useState();

  function deletarChecklist(id) {
    if (window.confirm("Você tem certeza que deseja deletar esta Checklist?")) {
      api.delete(`/gro/pgr/checklist/deletarChecklist?checklist_id=${id}`).then(
        () => toast.success("Checklist Excluído."),
        window.setTimeout(() => {
          window.location.reload();
        }, 2000)
      );
    }
  }

  return (
    <>
      <div className="row mt-4">
        <TabelaPadrao
          colunas={[
            { nome: "Data", width: "15%", text: "center" },
            { nome: "Nome Resp.Informação", width: "35%", text: "center" },
            { nome: "Função Responsável", width: "25%", text: "center" },
            { nome: "Visualizar", width: "5%", text: "center" },
            { nome: "Ações", width: "15%", text: "center" },
          ]}
          itens={checklist}
        >
          {checklist?.map((check) => (
            <tr key={check.id}>
              <td className="text-center"> {TratarData(check.data)}</td>
              <td className="text-center"> {check.nome_responsavel}</td>
              <td className="text-center"> {check.funcao_responsavel}</td>
              <td className="text-center">
                <Botao
                  icone="file-pdf"
                  cor="secondary"
                  alvo={process.env.REACT_APP_API_URL + "/gro/pgr/pdf/checklist/gerar/" + check.id}
                />
              </td>
              <td className="text-center">
                <BotaoGrupo>
                  <BotaoEditar
                    onClick={() => {
                      setShowEditar(true);
                      setChecklistSelecionado(check);
                    }}
                  />
                  <Botao
                    icone="trash"
                    cor="danger"
                    onClick={() => {
                      deletarChecklist(check.id);
                    }}
                  />
                </BotaoGrupo>
              </td>
            </tr>
          ))}
        </TabelaPadrao>
        {showEditar && (
          <ModalEditar show={showEditar} setShow={setShowEditar} checklistSelecionado={checklistSelecionado} />
        )}
      </div>
    </>
  );
};

const ModalEditar = ({ show, setShow, checklistSelecionado }) => {
  const [respostas, setRespostas] = useState(checklistSelecionado.respostas);
  const [nomeResponsavel, setNomeResponsavel] = useState(checklistSelecionado.nome_responsavel);
  const [funcao, setFuncao] = useState(checklistSelecionado.funcao_responsavel);
  const [data, setData] = useState(checklistSelecionado.data);

  function salvarResposta(perguntaId, resposta) {
    let novaObservacao = "";

    if (resposta === "N") {
      api.get(`/gro/pgr/checklist/obterPergunta/${perguntaId}`).then((response) => {
        novaObservacao = response.data.observacao_previa_se_nao;

        document.getElementById(`obs_${perguntaId}`).value = novaObservacao;
        atualizarRespostas(perguntaId, resposta, novaObservacao);
      });
    } else {
      novaObservacao = "";

      document.getElementById(`obs_${perguntaId}`).value = novaObservacao;
      atualizarRespostas(perguntaId, resposta, novaObservacao);
    }
  }

  function salvarObservacao(perguntaId, observacao) {
    if (respostas.find((resposta) => resposta.pergunta_id === perguntaId)) {
      let posicao = respostas.findIndex((resposta) => resposta.pergunta_id === perguntaId);
      const novasRespostas = [...respostas];
      novasRespostas[posicao].observacao = observacao;
      setRespostas(novasRespostas);
    } else {
      setRespostas([
        ...respostas,
        {
          pergunta_id: perguntaId,
          observacao: observacao,
        },
      ]);
    }
  }

  function atualizarRespostas(perguntaId, resposta, observacao) {
    if (respostas.find((resposta) => resposta.pergunta_id === perguntaId)) {
      let posicao = respostas.findIndex((resposta) => resposta.pergunta_id === perguntaId);
      const novasRespostas = [...respostas];
      novasRespostas[posicao].resposta = resposta;
      novasRespostas[posicao].observacao = observacao;
      setRespostas(novasRespostas);
    } else {
      setRespostas([
        ...respostas,
        {
          pergunta_id: perguntaId,
          resposta: resposta,
          observacao: observacao,
        },
      ]);
    }
  }

  function editarChecklist() {
    if (
      nomeResponsavel !== checklistSelecionado.nome_responsavel ||
      data !== checklistSelecionado.data ||
      funcao !== checklistSelecionado.funcao_responsavel
    ) {
      api
        .put(`/gro/pgr/checklist/editar/${checklistSelecionado.id}`, {
          nome_responsavel: nomeResponsavel,
          funcao_responsavel: funcao,
          data: data,
        })
        .then(editarRespostas());
    } else {
      editarRespostas();
    }
  }

  function editarRespostas() {
    const ajustaRespostasBanco = respostas?.map((resposta) => {
      return {
        id: resposta.id,
        checklist_id: resposta.checklist_id,
        pergunta_id: resposta.pergunta_id,
        resposta: resposta.resposta,
        observacao: resposta.observacao,
      };
    });

    api
      .put(`/gro/pgr/checklist/editarRespostas/`, {
        respostas: ajustaRespostasBanco,
      })
      .then(
        () => toast.success("Checklist editado com sucesso!"),
        window.setTimeout(() => {
          window.location.reload();
        }, 2000)
      );
  }

  return (
    <ModalPadrao show={show} setShow={setShow} tituloSpan={"Editar"} modalCentralizada>
      <>
        <div className="row mt-2">
          <div className="col">
            <label htmlFor="nomeResponsavel" className="form-label">
              Nome (Resp.Informação)
            </label>
            <input
              type="text"
              id="nomeResponsavel"
              className="form-control"
              placeholder="Nome Resp.Informação"
              defaultValue={checklistSelecionado.nome_responsavel}
              onChange={(nome) => {
                setNomeResponsavel(nome.target.value);
              }}
            />
          </div>
          <div className="col">
            <label htmlFor="funcaoResponsavel" className="form-label">
              Função (Resp.Informação)
            </label>
            <input
              type="text"
              id="funcaoResponsavel"
              className="form-control"
              placeholder="Função Resp.Informação"
              defaultValue={checklistSelecionado.funcao_responsavel}
              onChange={(funcao) => {
                setFuncao(funcao.target.value);
              }}
            />
          </div>
          <div className="col">
            <label htmlFor="data" className="form-label">
              Data
            </label>
            <input
              type="date"
              id="data"
              className="form-control"
              defaultValue={checklistSelecionado.data}
              onChange={(data) => {
                setData(data.target.value);
              }}
            />
          </div>
        </div>

        <div className="row mt-3">
          <TabelaPadrao
            colunas={[
              { nome: "Item", width: "5%", text: "center align-middle" },
              { nome: "Norma", width: "10%", text: "center align-middle" },
              { nome: "Pergunta", width: "40%", text: " align-middle" },
              { nome: "Sim", width: "5%", text: "center align-middle" },
              { nome: "Não", width: "5%", text: "center align-middle" },
              {
                nome: "Não Aplicavel",
                width: "5%",
                text: "center align-middle",
              },
              { nome: "Observação", width: "30%", text: " align-middle" },
            ]}
            itens={checklistSelecionado}
          >
            {checklistSelecionado.respostas?.map((resposta) => (
              <tr key={resposta.id}>
                <th className="text-center align-middle">{resposta.sequencia}</th>
                <th className="text-center align-middle">{resposta.norma}</th>
                <th className="align-middle">{resposta.pergunta}</th>
                <th className="text-center align-middle">
                  <input
                    type="radio"
                    id={`sim_${resposta.pergunta_id}`}
                    name={resposta.pergunta_id}
                    value="S"
                    className="form-check-input"
                    onChange={(e) => {
                      salvarResposta(resposta.pergunta_id, e.target.value);
                    }}
                    defaultChecked={resposta.resposta === "S" ? true : false}
                  />
                </th>
                <th className="text-center align-middle">
                  <input
                    type="radio"
                    id={`nao_${resposta.pergunta_id}`}
                    name={resposta.pergunta_id}
                    value="N"
                    className="form-check-input"
                    onChange={(e) => {
                      salvarResposta(resposta.pergunta_id, e.target.value);
                    }}
                    defaultChecked={resposta.resposta === "N" ? true : false}
                  />
                </th>
                <th className="text-center align-middle">
                  <input
                    type="radio"
                    id={`na_${resposta.pergunta_id}`}
                    name={resposta.pergunta_id}
                    value="NA"
                    className="form-check-input"
                    onChange={(e) => {
                      salvarResposta(resposta.pergunta_id, e.target.value);
                    }}
                    defaultChecked={resposta.resposta === "NA" ? true : false}
                  />
                </th>
                <th>
                  <input
                    type="text"
                    id={`obs_${resposta.pergunta_id}`}
                    name="Observação"
                    className="form-control"
                    defaultValue={resposta.observacao}
                    onChange={(e) => {
                      salvarObservacao(resposta.pergunta_id, e.target.value);
                    }}
                  />
                </th>
              </tr>
            ))}
          </TabelaPadrao>
        </div>
        <div className="row mt-2">
          <div className="col">
            <BotaoSalvar
              className={"float-end"}
              onClick={(e) => {
                editarChecklist(e);
              }}
              temConteudo
            />
          </div>
        </div>
      </>
    </ModalPadrao>
  );
};

export default Visualizacao;
