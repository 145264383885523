import React from "react";
import Icone from "./Icone";

const ExternalLink = ({ className }) => {
  return (
    <Icone
      className={`text-secondary ${className || ""}`}
      icone="external-link-alt"
    />
  );
};

export default ExternalLink;
