import React, { useEffect, useState } from "react";
import { isEmpty } from "./Util";

const InputFile = ({
  nomeArquivo,
  handleChange,
  disabled,
  required = true,
  icone = "upload",
  title = "Selecionar arquivo",
}) => {
  const [fraseInputFoto, setFraseInputFoto] = useState("Nenhum arquivo escolhido");

  useEffect(() => {
    if (!isEmpty(nomeArquivo?.name)) {
      setFraseInputFoto(nomeArquivo?.name);
    } else {
      setFraseInputFoto("Nenhum arquivo escolhido");
    }
  }, [nomeArquivo]);

  return (
    <>
      <label className={`btn btn-primary btn-sm mr-2 ${disabled && "disabled"}`} title={title}>
        <i className={`fas fa-${icone}`}></i> Envio
        <input
          type="file"
          onChange={(e) => handleChange(e.target.files[0])}
          name="arquivo"
          disabled={disabled}
          required={required}
          hidden
        />
      </label>
      {fraseInputFoto}
    </>
  );
};

export default InputFile;
