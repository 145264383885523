import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavTabs from "../../../components/NavTabs";
import { isEmpty, PegarPrimeiraPalavraFrase } from "../../../components/Util";
import api from "../../../service/api";
import Template from "../../template/Template";
import Atividades from "./cadastros-servico/Atividades";
import RequisitosAplicaveis from "./cadastros-servico/RequisitosAplicaveis";
import Servico from "./cadastros-servico/Servico";
import {
  urlAtividades,
  urlRequisitosAplicaveis,
  urlServicos,
} from "../../../routes/UrlBaseDados";

const CadastrosServico = () => {
  const [servico, setServico] = useState({});

  let { servicoId } = useParams();
  let nomesCadastros = ["Editar", "Atividades", "Requisitos Aplicáveis"];

  let acoes = [
    {
      id: 1,
      nome: nomesCadastros[0],
      icone: "pencil",
      body: <Servico servico={servico} setServico={setServico} />,
    },
    {
      id: 2,
      nome: nomesCadastros[1],
      icone: "list-alt",
      baseDados: {
        conteudo: nomesCadastros[1],
        alvo: urlAtividades,
      },
      body: <Atividades servico={servico} nomeCadastro={nomesCadastros[1]} />,
    },
    {
      id: 3,
      nome: nomesCadastros[2],
      icone: "list-alt",
      baseDados: {
        conteudo: PegarPrimeiraPalavraFrase(nomesCadastros[2]),
        alvo: urlRequisitosAplicaveis,
      },
      body: (
        <RequisitosAplicaveis
          servico={servico}
          nomeCadastro={PegarPrimeiraPalavraFrase(nomesCadastros[2])}
        />
      ),
    },
  ];

  useEffect(() => {
    buscarServico(servicoId);
  }, [servicoId]);

  const buscarServico = async (servicoId) => {
    await api
      .get(`/baseDados/servicos/obterServico/${servicoId}`)
      .then((resposta) => setServico(resposta.data))
      .catch((erro) => {
        console.log(erro);
      });
  };

  return (
    <Template backTo={urlServicos} page="Edição Serviço">
      <div className="mx-5">
        <h6 className="pt-4">
          Edição do serviço{" "}
          <p className="badge bg-primary">{servico.nome}</p>
        </h6>
        {!isEmpty(servico) && <NavTabs acoes={acoes} />}
      </div>
    </Template>
  );
};

export default CadastrosServico;
