import React, { useState } from "react";

import { BotaoDeletar, BotaoEditar, BotaoGrupo } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import api from "../../../../service/api";
import ModalMedidaControleNecessaria from "./ModalMedidaControleNecessaria";
import Icone from "../../../../components/Icone";

const ListaMedidaControleNecessaria = ({
  medidasControleNecessaria,
  buscarMedidasControleNecessaria,
  apreMedidasControleNecessaria,
  permissaoEditar,
}) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={medidasControleNecessaria}
        colunas={[
          { nome: "Código", text: "center" },
          { nome: "Grupo Medida", width: "10%", text: "center" },
          { nome: "Medida de controle Necessária" },
          { nome: "Forma de Acompanhamento"},
          { nome: 
            <>
              {" "}
              <span>Prazo </span>{" "}
              <Icone
                icone="info-circle"
                className={"text-primary"}
                title="Esse valor em meses indica o período que a empresa terá para implementar a medida de controle após o plano de ação ser criado."
              />
            </>
          },
          permissaoEditar && { nome: "Ações", width: "8%", text: "center" },
        ]}
      >
        {medidasControleNecessaria.map((medidaControleNecessaria) => (
          <MedidaControleNecessaria
            key={medidaControleNecessaria.id}
            medidaControleNecessaria={medidaControleNecessaria}
            buscarMedidasControleNecessaria={buscarMedidasControleNecessaria}
            apreMedidasControleNecessaria={apreMedidasControleNecessaria}
            permissaoEditar={permissaoEditar}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const MedidaControleNecessaria = ({
  medidaControleNecessaria,
  buscarMedidasControleNecessaria,
  apreMedidasControleNecessaria,
  permissaoEditar,
}) => {
  const [editando, setEditando] = useState(false);

  const medidaControleNecessariaVinculoApre = apreMedidasControleNecessaria.find(
    (apreMedidaControleNecessaria) => apreMedidaControleNecessaria.acao_id === medidaControleNecessaria.id
  );

  async function deletarMedidaControleNecessaria() {
    if (
      window.confirm(
        `Tem certeza que deseja excluir a medida de controle necessária: ${medidaControleNecessaria.definicao}?`
      )
    ) {
      if (medidaControleNecessariaVinculoApre) {
        let status = { status: "I" };

        api
          .put(`/baseDados/ergonomiaBaseDados/editarMedidasControleNecessaria/${medidaControleNecessaria.id}`, status)
          .then(() => {
            buscarMedidasControleNecessaria();
            setEditando(false);
          })
          .catch((erro) => {
            alert(erro.response.data.mensagem);
          });
      } else {
        api
          .delete(`/baseDados/ergonomiaBaseDados/deletarMedidasControleNecessaria/${medidaControleNecessaria.id}`)
          .then(() => {
            buscarMedidasControleNecessaria();
            setEditando(false);
          })
          .catch((erro) => {
            alert(erro.responde.data.mensagem);
          });
      }
    }
  }

  return (
    <>
      <tr key={medidaControleNecessaria.id}>
        <td className="text-center align-middle">{medidaControleNecessaria.codigo}</td>
        <td className="text-center align-middle">{medidaControleNecessaria.grupo}</td>
        <td className="align-middle">{medidaControleNecessaria.definicao}</td>
        <td>{medidaControleNecessaria.acompanhamento}</td>
        <td>{(medidaControleNecessaria.prazo === "0" || medidaControleNecessaria.prazo === "1" ) ? (medidaControleNecessaria.prazo + " mês") : (medidaControleNecessaria.prazo + " meses") }</td>
        {permissaoEditar && (
          <>
            <td className="text-center align-middle">
              <BotaoGrupo>
                <BotaoEditar onClick={() => setEditando(true)} disabled={medidaControleNecessaria.id === "1" && true} />
                <BotaoDeletar
                  onClick={() => deletarMedidaControleNecessaria(medidaControleNecessaria)}
                  disabled={medidaControleNecessaria.id === "1" && true}
                />
              </BotaoGrupo>
            </td>
          </>
        )}
      </tr>

      {editando && (
        <ModalMedidaControleNecessaria
          show={editando}
          setShow={setEditando}
          titulo={`Editar: ${medidaControleNecessaria.definicao}`}
          corTitulo="primary"
          medidaControleNecessaria={medidaControleNecessaria}
          buscarMedidasControleNecessaria={buscarMedidasControleNecessaria}
          apreMedidasControleNecessaria={apreMedidasControleNecessaria}
        />
      )}
    </>
  );
};

export default ListaMedidaControleNecessaria;
