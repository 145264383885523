import React from "react";
import { Link } from "react-router-dom";

import Icone from "../../../components/Icone";

const ItemMenu = ({
  target,
  alvo,
  icone,
  conteudo,
  title = "",
  cor = "cinza",
  corTexto = "white",
  tamanhoIcone,
}) => {
  let styleLink = "text-white text-decoration-none";
  return (
    <div className={`btn btn-${cor} text-${corTexto} text-left`}>
      {target ? (
        <a
          href={alvo}
          className={styleLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icone className="text-info" title={title} icone={icone} style={{ fontSize: tamanhoIcone }} /> {conteudo}
        </a>
      ) : (
        <Link to={alvo} className={styleLink}>
          <Icone className="text-info" title={title} icone={icone} style={{ fontSize: tamanhoIcone }} /> {conteudo}
        </Link>
      )}
    </div>
  );
};

export default ItemMenu;
