import React, { useEffect, useState } from "react";
import Select from "react-select";

import { ModalSubmit } from "../../../components/ModalPadrao";
import api from "../../../service/api";

const ModalEfeitoSaude = ({
  show,
  setShow,
  titulo,
  corTitulo,
  efeitoSaude,
  carregarEfeitosSaude,
}) => {
  const [intensidades, setIntensidades] = useState([]);
  const [adjetivos, setAdjetivos] = useState([]);
  const [doencas, setDoencas] = useState([]);
  const [locais, setLocais] = useState([]);
  const [sintomas, setSintomas] = useState([]);

  const [intensidade, setIntensidade] = useState(
    efeitoSaude ? { label: efeitoSaude.intensidade } : null
  );
  const [adjetivo, setAdjetivo] = useState(
    efeitoSaude ? { label: efeitoSaude.adjetivo } : null
  );
  const [doenca, setDoenca] = useState(
    efeitoSaude ? { label: efeitoSaude.doenca } : null
  );
  const [local, setLocal] = useState(
    efeitoSaude ? { label: efeitoSaude.local_efesintomaito_saude } : null
  );
  const [sintoma, setSintoma] = useState(
    efeitoSaude ? { label: efeitoSaude.sintoma } : null
  );

  useEffect(() => {
    carregarIntenciades();
    carregarAdjetivos();
    carregarDoencas();
    carregarLocais();
    carregarSintomas();
  }, []);

  const carregarIntenciades = async () => {
    await api
      .get("/baseDados/efeitosSaude/obterIntensidades")
      .then((resposta) => {
        setIntensidades(resposta.data);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  const carregarAdjetivos = async () => {
    await api
      .get("/baseDados/efeitosSaude/obterAdjetivo")
      .then((resposta) => {
        setAdjetivos(resposta.data);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  const carregarDoencas = async () => {
    await api
      .get("/baseDados/efeitosSaude/obterDoenca")
      .then((resposta) => {
        setDoencas(resposta.data);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  const carregarLocais = async () => {
    await api
      .get("/baseDados/efeitosSaude/obterLocal")
      .then((resposta) => {
        setLocais(resposta.data);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  const carregarSintomas = async () => {
    await api
      .get("/baseDados/efeitosSaude/obterSintoma")
      .then((resposta) => {
        setSintomas(resposta.data);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  const valuesIntenciade = intensidades.map((intensidade) => ({
    label: intensidade.nome,
    value: intensidade.id,
  }));

  const valuesAdjetivo = adjetivos.map((adjetivo) => ({
    label: adjetivo.nome,
    value: adjetivo.id,
  }));

  const valuesDoenca = doencas.map((doenca) => ({
    label: doenca.nome,
    value: doenca.id,
  }));

  const valuesLocal = locais.map((local) => ({
    label: local.nome,
    value: local.id,
  }));

  const valuesSintoma = sintomas.map((sintoma) => ({
    label: sintoma.nome,
    value: sintoma.id,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    let dados = {
      intensidade_id: intensidade ? intensidade.value : null,
      adjetivo_id: adjetivo.value,
      doenca_id: doenca.value,
      local_efeito_saude_id: local ? local.value : null,
      sintoma_id: sintoma ? sintoma.value : null,
    };

    let url = efeitoSaude
      ? api.put(`/baseDados/efeitosSaude/editar/${efeitoSaude.id}`, dados)
      : api.post("/baseDados/efeitosSaude/inserir", dados);

    await url.then(() => {
      carregarEfeitosSaude();
      setShow(false);
    });
  };

  const handleSelect = (option, select) => {
    switch (select.name) {
      case "intensidade_id":
        setIntensidade(option);
        break;
      case "adjetivo_id":
        setAdjetivo(option);
        break;
      case "doenca_id":
        setDoenca(option);
        break;
      case "local_efeito_saude_id":
        setLocal(option);
        break;
      case "sintoma_id":
        setSintoma(option);
        break;
      default:
        break;
    }
  };

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      tituloSpan={titulo}
      corTitulo={corTitulo}
      tamanho="lg"
      onSubmit={(e) => handleSubmit(e)}
      disabled={!adjetivo || !doenca}
    >
      <div className="row mb-3">
        <div className="col">
          <label>Intensidade:</label>
          <div>
            <Select
              name="intensidade_id"
              placeholder="Selecione a intensidade"
              options={valuesIntenciade}
              onChange={handleSelect}
              value={intensidade}
            />
          </div>
        </div>
        <div className="col">
          <label>Adjetivo:</label>
          <div>
            <Select
              name="adjetivo_id"
              placeholder="Selecione o adjetivo"
              options={valuesAdjetivo}
              onChange={handleSelect}
              value={adjetivo}
            />
          </div>
        </div>
        <div className="col">
          <label>Doença:</label>
          <div>
            <Select
              name="doenca_id"
              placeholder="Selecione a doença"
              options={valuesDoenca}
              onChange={handleSelect}
              value={doenca}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>Local:</label>
          <div>
            <Select
              name="local_efeito_saude_id"
              placeholder="Selecione o local"
              options={valuesLocal}
              onChange={handleSelect}
              value={local}
            />
          </div>
        </div>
        <div className="col">
          <label>Sintomas:</label>
          <div>
            <Select
              name="sintoma_id"
              placeholder="Selecione o sintoma"
              options={valuesSintoma}
              onChange={handleSelect}
              value={sintoma}
            />
          </div>
        </div>
      </div>
    </ModalSubmit>
  );
};

export default ModalEfeitoSaude;
