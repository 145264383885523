const urlBaseDados = "/base-dados/";

export const urlCas = urlBaseDados + "cas";

export const urlElementos = urlBaseDados + "elementos";

export const urlFiltrosMascaras = urlBaseDados + "filtros";

export const urlFuncionariosClientes = urlBaseDados + "funcionarios-clientes";

export const urlFuncoesClientes = urlBaseDados + "funcoes-clientes";

export const urlLinach = urlBaseDados + "linach";

export const urlMetodosAnalise = urlBaseDados + "metodos-analise";

export const urlProdutosQuimicos = urlBaseDados + "produtos-quimicos";

export const urlFrasesPerigo = urlBaseDados + "frases-perigo";

export const urlAtividades = urlBaseDados + "atividades";

export const urlAmbientes = urlBaseDados + "ambientes";

export const urlAtividadesEspeciais = urlBaseDados + "atividades-especiais";

export const urlEfeitosSaude = urlBaseDados + "efeitos-saude";

export const urlEpis = urlBaseDados + "epis";

export const urlEpcs = urlBaseDados + "epcs";

export const urlPerigos = urlBaseDados + "perigos";

export const urlDanos = urlBaseDados + "danos";

export const urlFerramentas = urlBaseDados + "ferramentas";

export const urlEquipamentosProcessos = urlBaseDados + "equipamentos-processos";

export const urlServicos = urlBaseDados + "servicos";

export const urlRequisitosAplicaveis = urlBaseDados + "requisitos-aplicaveis";

export const urlMedidasOrganizacionais =
  urlBaseDados + "medidas-organizacionais";

export const urlJornadasTrabalho = urlBaseDados + "jornadas-trabalho";

export const urlChecklistPerguntas = urlBaseDados + "checklist-perguntas";

export const urlFabricanteQuimico = urlBaseDados + "fabricante-quimico";

export const urlBaseDadosErgonomia = urlBaseDados + "ergonomia";

export const urlBaseDadosAcidente = urlBaseDados + "acidente";

export const urlBaseDadosFormasRisco = urlBaseDados + "formas-risco";

export const urlBaseDadosObjetivosMetas = urlBaseDados + "objetivos-metas";

export const urlBaseDadosRiscosLocal = urlBaseDados + "riscos-local";

export const urlBaseDadosFormaAplicacao = urlBaseDados + "forma-aplicacao";