import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";

import { BotaoSalvar } from "../../../../components/Botao";
import api from "../../../../service/api";
import Template from "../../../template/Template";
import { isEmpty } from "../../../../components/Util";
import LogoClienteEnsaioVedacao from "../../../../components/LogoClienteEnsaioVedacao";

const Cadastrar = () => {
  const [funcionario, setFuncionario] = useState();
  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState();
  const [funcao, setFuncao] = useState();
  const [epis, setEpis] = useState();
  const [epiSelecionado, setEpiSelecionado] = useState();
  const [cas, setCas] = useState();
  const [casSelecionada, setCasSelecionada] = useState();
  const [equipamentos, setEquipamento] = useState();
  const [equipamentoSelecionado, setEquipamentoSelecionado] = useState();
  const [validadeCalibracaoSelecionada, setValidadeCalibracaoSelecionada] = useState();
  const [dataSelecionada, setDataSelecionada] = useState();
  const [dataEntregaSelecionada, setDataEntregaSelecionada] = useState();
  const [observacaoTexto, setObservacaoTexto] = useState();
  const [caracter, setCaracter] = useState();
  const [duracaoTreinamento, setDuracaoTreinamento] = useState();
  const [responsavel, setResponsavel] = useState();
  const [selecionarResponsavel, setSelecionarResponsavel] = useState();
  const [carregando, setCarregando] = useState(true);

  const { clienteId, clienteEstabelecimentoId } = useParams();

  let history = useHistory();

  useEffect(() => {
    buscarDados(clienteId);
  }, [clienteId]);

  function salvarDados(e) {
    let dados = {
      funcionario_cliente_id: funcionarioSelecionado,
      funcao_cliente_id: funcao?.value,
      ca_id: casSelecionada,
      equipamento_ppr_id: equipamentoSelecionado,
      data_calibracao: validadeCalibracaoSelecionada,
      data: dataSelecionada,
      data_entrega: dataEntregaSelecionada,
      observacao: observacaoTexto,
      duracao_treinamento: duracaoTreinamento,
      colaborador_id: selecionarResponsavel?.value,
      cliente_estabelecimento_id: clienteEstabelecimentoId,
    };
    if (isEmpty(selecionarResponsavel)) {
      toast.warning("Selecione Responsável");
    } else if (isEmpty(funcionarioSelecionado)) {
      toast.warning("Selecione Funcionário");
    } else if (isEmpty(casSelecionada)) {
      toast.warning("Selecione um CAs");
    } else {
      api.post("gro/pgr/EnsaioVedacao/inserirEnsaioVedacao", dados).then(() => {
        toast.success("Funcionário Cadastrado!");
        window.setTimeout(() => {
          history.goBack();
        }, 2000);
      });
    }
  }

  function buscarDados(clienteId) {
    api
      .get(
        `gro/pgr/EnsaioVedacao/buscarDadosFuncionarios?cliente_id=
        ${clienteId}`
      )
      .then((resposta) => {
        setFuncionario(resposta.data);
      });
    api.get("gro/pgr/EnsaioVedacao/buscarEpi").then((resposta) => {
      setEpis(resposta.data);
    });
    api.get("/gro/pgr/EnsaioVedacao/obterEquipamento").then((resposta) => {
      setEquipamento(resposta.data);
    });
    api.get("gro/pgr/EnsaioVedacao/buscarCas").then((resposta) => {
      setCas(resposta.data);
    });
    api.get("gro/pgr/EnsaioVedacao/obterResponsavel").then((resposta) => {
      setResponsavel(resposta.data);
      setCarregando(false);
    });
  }
  const optionFuncionario = funcionario?.map((item) => ({
    label: item.nome,
    value: item.id,
    value_funcao: item.funcao_cliente_id,
    funcao: item.funcao,
  }));
  const optionEpis = epis?.map((item) => ({
    label: item.nome + " " + item.tipo,
    value: item.id,
  }));
  const optionEquipamento = equipamentos?.map((item) => ({
    label: item.nome + " " + item.modelo,
    value: item.id,
  }));
  const optionCas = cas?.map((item) => ({
    label: item.codigo + " (" + item.tamanho + ")",
    value: item.id,
    epi_id: item.epi_id,
  }));
  const optionCasFiltrados = optionCas?.filter((ca) => ca.epi_id === epiSelecionado);
  const optionResponsavel = responsavel?.map((item) => ({
    label: item.nome,
    value: item.codigo,
  }));
  function inputHandler() {
    setCaracter(730 - (observacaoTexto?.length + 1));
  }
  return (
    !carregando && (
      <>
        <Template page="Ensaio Vedação" backTo={`/gro/${clienteId}/pgr/ensaio-vedacao/${clienteEstabelecimentoId}`}>
          <LogoClienteEnsaioVedacao clienteId={clienteId} clienteEstabelecimentoId={clienteEstabelecimentoId} />
          <div className="container-fluid mt-3">
            <div style={{padding:"10px"}} className="bg-white">
              <div className="card bg-cinza text-white d-flex">
                <span className="align-middle">
                  <h3 className="text-center">Ensaio de Vedação - Novo Cadastro</h3>
                </span>
              </div>
              <div className="row mt-2 border rounded border-gray">
                <div className="col">
                  <ToastContainer autoClose={3000} pauseOnHover={false} />
                  <div className="field">
                    <div className="row">
                      <div className="col ms-2 mt-3">
                        <label>
                          <strong>Funcionário</strong>
                        </label>
                        <Select
                          id="selectFuncionario"
                          name="Funcionario"
                          placeholder="Selecione"
                          onChange={(option) => {
                            setFuncao({
                              label: option.funcao,
                              value: option.value_funcao,
                            });
                            setFuncionarioSelecionado(option.value);
                          }}
                          options={optionFuncionario}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#f8f9fa",
                            }),
                          }}
                        />
                      </div>
                      <div className="col me-2 mt-3">
                        <label>
                          <strong>Função</strong>
                        </label>
                        <Select
                          id="selectFuncao"
                          name="Funcao"
                          placeholder="Selecione a Função"
                          value={funcao}
                          isDisabled={true}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#f8f9fa",
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col ms-2 mt-3">
                        <label>
                          <strong>EPI</strong>
                        </label>
                        <Select
                          id="selectEPI"
                          name="EPI"
                          placeholder="Selecione o EPI"
                          onChange={(option) => {
                            setEpiSelecionado(option.value);
                          }}
                          options={optionEpis}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#f8f9fa",
                            }),
                          }}
                        />
                      </div>
                      <div className="col me-2 mt-3">
                        <label>
                          <strong>CAs</strong>
                        </label>
                        <Select
                          id="selectCAs"
                          name="CAs"
                          placeholder="Selecione o CAs"
                          onChange={(option) => {
                            setCasSelecionada(option.value);
                          }}
                          options={optionCasFiltrados}
                          isDisabled={epiSelecionado ? false : true}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#f8f9fa",
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col ms-2 mt-3">
                        <label>
                          <strong>Equipamento</strong>
                        </label>
                        <Select
                          id="selectEquipamento"
                          name="Equipamento"
                          placeholder="Selecione o Equipamento"
                          onChange={(option) => {
                            setEquipamentoSelecionado(option.value);
                          }}
                          options={optionEquipamento}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#f8f9fa",
                            }),
                          }}
                        />
                      </div>
                      <div className="col me-2 mt-3">
                        <label>
                          <strong>Validade da Calibração</strong>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="validade"
                          id="inputValidadeCalibração"
                          onChange={(e) => {
                            setValidadeCalibracaoSelecionada(e.target.value);
                          }}
                          style={{ backgroundColor: "#f8f9fa" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col ms-2 mt-3">
                        <label>
                          <strong>Data do Teste</strong>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="data_teste"
                          id="inputDataDoTeste"
                          onChange={(e) => {
                            setDataSelecionada(e.target.value);
                          }}
                          style={{ backgroundColor: "#f8f9fa" }}
                        />
                      </div>
                      <div className="col me-2 mt-3">
                        <label>
                          <strong>Data de Entrega</strong>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="data_entrega"
                          id="inputDataDeEntrega"
                          onChange={(e) => {
                            setDataEntregaSelecionada(e.target.value);
                          }}
                          style={{ backgroundColor: "#f8f9fa" }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col ms-2 mt-3">
                        <label>
                          <strong>Observação </strong>
                          {!isEmpty(observacaoTexto) && (
                            <>
                              <h7 style={{ fontSize: "12px", color: "grey" }}>{caracter} caracteres restantes</h7>
                            </>
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="observacao"
                          id="inputObservacao"
                          placeholder="Observação"
                          onChange={(e) => {
                            inputHandler();
                            setObservacaoTexto(e.target.value);
                          }}
                          maxLength={730}
                          style={{ backgroundColor: "#f8f9fa" }}
                        />
                      </div>
                      <div className="col mt-3">
                        <label>
                          <strong>Treinamento(Minutos)</strong>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="Treinamento"
                          id="inputTreinamento"
                          placeholder="Duração do Treinamento"
                          onChange={(e) => setDuracaoTreinamento(e.target.value)}
                          style={{ backgroundColor: "#f8f9fa" }}
                        />
                      </div>
                      <div className="col me-2 mt-3">
                        <label>
                          <strong>Responsável</strong>
                        </label>
                        <Select
                          id="selectResponsavel"
                          name="Responsavel"
                          placeholder="Selecione o responsável"
                          onChange={(e) => setSelecionarResponsavel(e)}
                          options={optionResponsavel}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#f8f9fa",
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-3 mb-3 me-2 float-end">
                      <BotaoSalvar temConteudo onClick={(e) => salvarDados(e)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Template>
      </>
    )
  );
};

export default Cadastrar;
