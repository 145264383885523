import React, { useEffect, useState } from "react";
import Select from "react-select";

import { ModalSubmit } from "../../../../components/ModalPadrao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import Loader from "../../../../components/Loader";

const ModalRisco = ({
  show,
  setShow,
  titulo,
  corTitulo,
  risco,
  buscarRiscos,
  novoRisco,
  apreRiscos,
}) => {
  const [codigo, setCodigo] = useState(risco && risco.codigo);
  const [sintomas, setSintomas] = useState(risco && risco.sintomas);
  const [salvarRisco, setSalvarRisco] = useState(risco ? risco.riscos : novoRisco);
  const [severidade, setSeveridade] = useState(risco && risco.severidade_id)
  const [severidadeSelecionada, setSeveridadeSelecionada] = useState()
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    api.get("/baseDados/ergonomiaBaseDados/buscarSeveridade").then((resposta) => {
      setSeveridade(resposta.data)
      setCarregando(false)
    })
  }, [])

  async function salvarBanco(e) {
    e.preventDefault();
    if (!isEmpty(codigo && salvarRisco && sintomas)) {
      if (isEmpty(risco)) {
        const riscoVinculoApre = !isEmpty(risco)
          ? apreRiscos.find(
              (apreRisco) => apreRisco.riscos_ergonomia_id === risco.id
            )
            ? true
            : false
          : false;
  
        let dados = {
          codigo: codigo,
          riscos: salvarRisco,
          severidade_id: severidadeSelecionada,
          sintomas: sintomas,
        };
  
        let status = { status: "I" };
  
        let url = risco
          ? riscoVinculoApre
            ? (api.put(
                `/baseDados/ergonomiaBaseDados/editarRisco/${risco.id}`,
                status
              ),
              api.post("/baseDados/ergonomiaBaseDados/inserirRisco", dados)).then(
                (resposta) => {
                  let novoRiscoId = {
                    riscos_ergonomia_id: resposta.data.dados.id,
                  };
                  api.put(
                    `/baseDados/ergonomiaBaseDados/editarRiscoPerigosVinculados/${risco.id}`,
                    novoRiscoId
                  );
                }
              )
            : api.put(
                `/baseDados/ergonomiaBaseDados/editarRisco/${risco.id}`,
                dados
              )
          : api.post("/baseDados/ergonomiaBaseDados/inserirRisco", dados);
  
        await url
          .then(() => {
            buscarRiscos();
            setShow(false);
          })
          .catch((erro) => alert(erro));
      } else {
        if (codigo === risco?.codigo && salvarRisco === risco?.riscos) {
          api.put(`/baseDados/ergonomiaBaseDados/editarSeveridadeRisco`, {
            severidade_id: severidadeSelecionada,
            risco_acidente_id: risco.id,
          }).then(() => {
            buscarRiscos();
            setShow(false)
          })
        } else {
          const riscoVinculoApre = !isEmpty(risco)
          ? apreRiscos.find(
              (apreRisco) => apreRisco.riscos_ergonomia_id === risco.id
            )
            ? true
            : false
          : false;
  
        let dados = {
          codigo: codigo,
          riscos: salvarRisco,
          severidade_id: severidadeSelecionada,
          sintomas: sintomas,
        };
  
        let status = { status: "I" };
  
        let url = risco
          ? riscoVinculoApre
            ? (api.put(
                `/baseDados/ergonomiaBaseDados/editarRisco/${risco.id}`,
                status
              ),
              api.post("/baseDados/ergonomiaBaseDados/inserirRisco", dados)).then(
                (resposta) => {
                  let novoRiscoId = {
                    riscos_ergonomia_id: resposta.data.dados.id,
                  };
                  api.put(
                    `/baseDados/ergonomiaBaseDados/editarRiscoPerigosVinculados/${risco.id}`,
                    novoRiscoId
                  );
                }
              )
            : api.put(
                `/baseDados/ergonomiaBaseDados/editarRisco/${risco.id}`,
                dados
              )
          : api.post("/baseDados/ergonomiaBaseDados/inserirRisco", dados);
  
        await url
          .then(() => {
            buscarRiscos();
            setShow(false);
          })
          .catch((erro) => alert(erro));
        }
      }
    }
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      titulo={titulo}
      corTitulo={corTitulo}
      tamanho="md"
      onSubmit={(e) => salvarBanco(e)}
    >
      {carregando ? (
        <Loader/>
      ) : (
        <div className="mb-3">
        <label>Código:</label>
        <input
          type="text"
          id="codigo"
          name="codigo"
          className="form-control"
          defaultValue={risco && risco.codigo}
          onChange={(r) => {
            setCodigo(r.target.value);
          }}
          required
        />

        <label>Risco:</label>
        <input
          type="text"
          id="perigo"
          name="perigo"
          className="form-control"
          defaultValue={risco ? salvarRisco : novoRisco}
          onChange={(r) => {
            setSalvarRisco(r.target.value);
          }}
          required
        />

        <label>Severidade:</label>
        <Select
          onChange={(e) => {
            setSeveridadeSelecionada(e.value)
          }}

          options={severidade.map((e) => ({
            label: e.grau_risco + " - " + e.definicao,
            value: e.id,
            peso: e.peso,
          }))}
          defaultValue={!isEmpty(risco) &&  (!isEmpty(risco.severidade_id) && {label: risco.severidade_grau_risco + " - " + risco.severidade})}
        />

        <label>Sintomas:</label>
        <textarea
          className="form-control"
          id="textAreaSintomas"
          rows="3"
          defaultValue={risco && risco.sintomas}
          onChange={(r) => {
            setSintomas(r.target.value);
          }}
          required
        />
      </div>
      )}
      
    </ModalSubmit>
  );
};

export default ModalRisco;
