import React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

import NavTabs from "../../../components/NavTabs";
import Template from "../../template/Template";
import Perigo from "./perigo/Perigo";
import Risco from "./risco/Risco";
import MedidaControleNecessaria from "./medidaControleNecessaria/MedidaControleNecessaria";
import PerigoRisco from "./perigoRisco/PerigoRisco";
import ErgonomiaPerigoMedidaControleNecessaria from "./perigoMedidaControleNecessaria/ErgonomiaPerigoMedidaControleNecessaria";
import { dadosToken, isEmpty } from "../../../components/Util";
import Ghe from "./ghe/Ghe";


// import GhePerigoGrupo from "./ghePerigoGrupo/GhePerigoGrupo";

const Ergonomia = () => {

  const permissaoEditar = isEmpty(dadosToken().permissoes.find((item) => {
    return item === "baseDadosErgonomia";
  })) ? false : true;
  
  const acoes = [
    {
      id: 1,
      nome: "Perigo",
      body: <Perigo permissaoEditar={permissaoEditar} />,
    },
    {
      id: 2,
      nome: "Riscos",
      body: <Risco permissaoEditar={permissaoEditar} />,
    },
    {
      id: 3,
      nome: "Medidas de Controle",
      body: <MedidaControleNecessaria permissaoEditar={permissaoEditar} />,
    },
    {
      id: 4,
      nome: "Perigo X Risco",
      body: <PerigoRisco permissaoEditar={permissaoEditar} />,
    },
    {
      id: 5,
      nome: "Perigo x Medidas de Controle Necessárias",
      body: <ErgonomiaPerigoMedidaControleNecessaria permissaoEditar={permissaoEditar} />,
    },
    {
      id: 6,
      nome: "GHE",
      body: <Ghe permissaoEditar={permissaoEditar} />,
    },
  ];

  return (
    <>
      <Template page="Ergonomia - base-dados">
        <>
          <div className="container-fluid justify-content-between">
            <div className="bg-white p-2">
              <div className="card bg-cinza text-white">
                <span className="align-middle">
                  <h3 className="text-center">
                  <i className="fas fa-street-view"></i> Base de Dados Ergonomia
                  </h3>
                </span>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <NavTabs acoes={acoes} />
                  <ToastContainer autoClose={1000} pauseOnHover={false} />
                </div>
              </div>
            </div>
          </div>
        </>
      </Template>
    </>
  );
};

export default Ergonomia;
