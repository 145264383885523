import React, { useState } from "react";

import { BotaoAdicionar } from "../../../components/Botao";
import AdicionarSeveridade from "./severidade/AdicionarSeveridade";

const SeveridadeAmbiente = ({ ambiente, setor, formaRisco, carregarAmbientes }) => {
  const [show, setShow] = useState(false);
  
  return (
    <div className="row mb-2">
      <div className="col-1">
        <BotaoAdicionar className="mr-1" onClick={() => setShow(true)} disabled={ambiente.quantidade_matematicas < 1} />
      </div>

      <div className="col-auto gx-0">
        <span className="text-muted mr-1">Severidades</span>
      </div>
      {show && (
        <AdicionarSeveridade
          show={show}
          setShow={setShow}
          ambiente={ambiente}
          setor={setor}
          formaRisco={formaRisco}
          carregarAmbientes={carregarAmbientes}
        />
      )}
    </div>
  );
};

export default SeveridadeAmbiente;
