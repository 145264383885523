import React, { useEffect, useState } from "react";

import CadastraPontoColaborador from "./CadastraPontoColaborador";
import api from "../../../service/api";
import { ObterDataAtual, dadosToken } from "../../../components/Util";
import TabelaPadao from "../../../components/TabelaPadrao";

const PontoColaborador = () => {
  const [registros, setRegistros] = useState([]);

  useEffect(() => {
    carregarRegistrosColaborador();
  }, []);

  async function carregarRegistrosColaborador() {
    const resposta = await api.get(
      `/pontoColaborador?id_colaborador=${
        dadosToken().id_colaborador
      }&&data=${ObterDataAtual("AMD")}`
    );
    setRegistros(resposta.data);
  }

  return (
    <div className="container">
      <CadastraPontoColaborador
        token={dadosToken()}
        registros={registros}
        carregarRegistrosColaborador={carregarRegistrosColaborador}
        ObterDataAtual={ObterDataAtual}
        ultimoRegistro={registros[registros.length - 1]}
      />
      <div className="pb-3">Registros de hoje ({ObterDataAtual("DMA")})</div>
      <TabelaPadao
        height="30vh"
        colunas={[{ nome: "Hora" }, { nome: "Descrição" }]}
        itens={registros}
      >
        {registros.map((registro, index) => (
          <ListaPontosColaborador key={index} registro={registro} />
        ))}
      </TabelaPadao>
    </div>
  );
};

const ListaPontosColaborador = ({ registro }) => {
  return (
    <tr>
      <td className="text-center align-middle">{registro.hora}</td>
      <td className="text-center align-middle">
        {registro.situacao === "0" ? "Entrada" : "Saída"}
      </td>
    </tr>
  );
};

export default PontoColaborador;
