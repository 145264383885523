import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import NavTabs from "../../../components/NavTabs";
import api from "../../../service/api";
import Template from "../../template/Template";
import Cadastro from "./checklist/Cadastro";
import Visualizacao from "./checklist/Visualizacao";
import Modelo from "./checklist/Modelo";

const Checklist = () => {
  const [checklist, setChecklist] = useState([]);
  const [perguntas, setPerguntas] = useState([]);
  const [perguntasInativas, setPerguntasInativas] = useState([]);

  const { ppraId } = useParams();
  let history = useHistory();

  useEffect(() => {
    buscarPerguntas();
    buscarPerguntasInativas();
    buscarChecklist(ppraId);
  }, [ppraId]);

  async function buscarPerguntas() {
    await api.get("/baseDados/checklistPerguntas").then((resposta) => {
      setPerguntas(resposta.data.filter((dado) => dado.status === "A"));
    });
  }

  async function buscarPerguntasInativas() {
    await api.get("/baseDados/checklistPerguntas").then((resposta) => {
      setPerguntasInativas(resposta.data.filter((dado) => dado.status === "I"));
    });
  }

  async function buscarChecklist(ppraId) {
    await api
      .get(`/gro/pgr/checklist/buscarChecklist?ppra_id=${ppraId}`)
      .then((resposta) => {
        setChecklist(resposta.data);
      });
  }

  const acoes = [
    {
      id: 1,
      nome: "Modelo Geral",
      icone: "edit",
      body: (
        <>
          <Modelo
            ppraId={ppraId}
            perguntas={perguntas}
            history={history}
            checklist={checklist}
          />
        </>
      ),
    },
    {
      id: 2,
      nome: "Cadastro Cliente",
      icone: "plus-square",
      body: (
        <>
          <Cadastro
            ppraId={ppraId}
            history={history}
            perguntas={perguntas}
            perguntasInativas={perguntasInativas}
          />
        </>
      ),
    },
    {
      id: 3,
      nome: "Lista",
      icone: "list-alt",
      body: (
        <>
          <Visualizacao checklist={checklist} />
        </>
      ),
    },
  ];

  return (
    <>
      <Template
        page="Checklist"
        backTo={`/pgr/${ppraId}/fisicoQuimicoBiologico`}
      >
        {(ppra) => (
          <>
            <div className="container-fluid justify-content-between">
              <div className="bg-white p-2">
                <div className="card bg-cinza text-white">
                  <span className="align-middle">
                    <h3 className="text-center">Checklist</h3>
                  </span>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <ToastContainer autoClose={2000} pauseOnHover={false} />
                    <NavTabs acoes={acoes} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Template>
    </>
  );
};

export default Checklist;
