import React, { useState } from "react";

import {
  BotaoDeletar,
  BotaoEditar,
  BotaoGrupo,
} from "../../../components/Botao";
import TabelaPadrao from "../../../components/TabelaPadrao";
import Dica from "../../../components/Dica";
import api from "../../../service/api";
import ModalEfeitoSaude from "./ModalEfeitoSaude";

const ListaEfeitosSaude = ({ efeitosSaude, carregarEfeitosSaude }) => {
  return (
    <TabelaPadrao
      noOverflowAuto
      fontSize="14px"
      itens={efeitosSaude}
      colunas={[
        { nome: "Intensidade", width: "10%" },
        { nome: "Adjetivo", width: "15%" },
        { nome: "Doença ", width: "20%" },
        { nome: "Local ", width: "10%" },
        { nome: "Sintomas ", width: "37%" },
        { nome: "Ações", width: "8%", text: "center" },
      ]}
    >
      {efeitosSaude.map((efeitoSaude) => (
        <EfeitoSaude
          key={efeitoSaude.id}
          efeitoSaude={efeitoSaude}
          carregarEfeitosSaude={carregarEfeitosSaude}
        />
      ))}
    </TabelaPadrao>
  );
};

const EfeitoSaude = ({ efeitoSaude, carregarEfeitosSaude }) => {
  const [editando, setEditando] = useState(false);

  const deletar = async (efeitoSaude) => {
    if (window.confirm(`Tem certeza que deseja remover este efeito à saúde?`)) {
      await api
        .delete(`/baseDados/efeitosSaude/deletar/${efeitoSaude.id}`)
        .then(() => {
          carregarEfeitosSaude();
          setEditando(false);
        })
        .catch((erro) => {
          alert(erro.response.data.mensagem);
        });
    }
  };

  let onClick = () => setEditando(true);
  let cursor = { cursor: "pointer" };

  return (
    <>
      <tr>
        <td onClick={onClick} style={cursor}>
          {efeitoSaude.intensidade ? efeitoSaude.intensidade : "-"}
        </td>
        <td onClick={onClick} style={cursor}>
          {efeitoSaude.adjetivo}
        </td>
        <td onClick={onClick} style={cursor}>
          {efeitoSaude.doenca}
        </td>
        <td onClick={onClick} style={cursor}>
          {efeitoSaude.local_efesintomaito_saude
            ? efeitoSaude.local_efesintomaito_saude
            : "-"}
        </td>
        <td onClick={onClick} style={cursor}>
          {efeitoSaude.sintoma ? efeitoSaude.sintoma : "-"}
        </td>
        <td className="text-center">
          <BotaoGrupo>
            <Dica conteudo="Editar">
              <span>
                <BotaoEditar onClick={onClick} title=" " />
              </span>
            </Dica>
            <Dica conteudo={<div className="text-danger">Deletar</div>}>
              <span>
                <BotaoDeletar onClick={() => deletar(efeitoSaude)} title=" " />
              </span>
            </Dica>
          </BotaoGrupo>
        </td>
      </tr>
      {editando && (
        <ModalEfeitoSaude
          show={editando}
          setShow={setEditando}
          titulo="Editar efeito à saúde"
          corTitulo="primary"
          efeitoSaude={efeitoSaude}
          carregarEfeitosSaude={carregarEfeitosSaude}
        />
      )}
    </>
  );
};

export default ListaEfeitosSaude;
