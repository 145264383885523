import React, { useState } from "react";
import Select from "react-select";
import { Link, generatePath } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import Botao, { BotaoVoltar } from "../../../../components/Botao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import LogoCliente from "../../../../components/LogoCliente";
import { verificaPermissaoCliente } from "../../../../service/auth";

const HeaderPpra = ({ reconhecimento = false, history, match, desabilitaTroca, clienteId, isObjetivosMetas }) => {
  const [selecionaPpra, setSelecionaPra] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [clientesEstab, setClientesEstab] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [revisoes, setRevisoes] = useState([]);

  let urlPainel = reconhecimento ? "/ppra/" + reconhecimento.idPpra + "/painel" : verificaPermissaoCliente(clienteId);

  async function buscarClientes() {
    await api
      .get("/clientes")
      .then((resposta) => {
        setClientes(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscaEstabelecimento(clienteId) {
    await api
      .get(`/clientes/obterClienteComEstabelecimentos?cliente_codigo=${clienteId}`)
      .then((resposta) => {
        setClientesEstab(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarContratos(clienteId) {
    await api.get(`/clientes/obterClienteComEstabelecimentos?cliente_codigo=${clienteId}`).then((resposta) => {
      setContratos(resposta.data.contratos);
    });
  }

  async function buscaRevisao(clienteId, estabelecimentoId, contratoId = null) {
    let url = `/clientes/obterRevisoesPgr?cliente_codigo=${clienteId}&&estabelecimento_codigo=${estabelecimentoId}`;

    if (contratoId) {
      url += `&contrato_codigo=${contratoId}`;
    }

    await api
      .get(url)
      .then((resposta) => {
        setRevisoes(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  function trocarPpra() {
    setSelecionaPra(true);
    buscarClientes();
    buscaEstabelecimento(reconhecimento.cliente_codigo);
    buscarContratos(reconhecimento.cliente_codigo);
    buscaRevisao(
      reconhecimento.cliente_codigo,
      reconhecimento.codigoEstabelecimento,
      reconhecimento.contrato ? reconhecimento.contrato.codigo : null
    );
  }

  async function handleSelect(objeto, acao) {
    switch (acao.action) {
      case "select-option":
        if (acao.name === "cliente") {
          buscaEstabelecimento(objeto.value, objeto.label);
        } else if (acao.name === "contrato") {
          buscaRevisao(objeto.value.cod_cliente, objeto.value.cod_estabelecimento, objeto.value.codigo);
        } else if (acao.name === "estabelecimento") {
          buscarContratos(objeto.value.cod_cliente);
          buscaRevisao(objeto.value.cod_cliente, objeto.value.estabelecimento.codigo);
        } else {
          history.replace({
            pathname: generatePath(match.path, { ppraId: objeto.value }),
          });
          setSelecionaPra(!selecionaPpra);
        }

        break;

      case "clear":
        buscaRevisao(acao.removedValues[0].value.cod_cliente, acao.removedValues[0].value.cod_estabelecimento);
        break;

      default:
        break;
    }
  }

  return (
    <div className="card mx-5 p-2" style={{ fontSize: "13.5px" }}>
      <Row>
        <Options col={2}>
          
            <Link to={urlPainel}>
              <LogoCliente nomeFoto={reconhecimento.cliente_foto} maxWidth="135px" maxHeight="40px" />
            </Link>
        </Options>
        <Options col={isEmpty(reconhecimento.contrato) && isEmpty(contratos) ? "" : 3} nome="Cli">
          {selecionaPpra ? (
            <Select
              className="flex-fill"
              name="cliente"
              onChange={handleSelect}
              options={clientes.map(({ codigo, nome_fantasia }) => ({
                value: codigo,
                label: nome_fantasia,
              }))}
              defaultValue={{
                label: reconhecimento.nomeCliente,
                value: reconhecimento.cliente_codigo,
              }}
            />
          ) : (
            reconhecimento.nomeCliente
          )}
        </Options>

        <Options col={isEmpty(reconhecimento.contrato) && isEmpty(contratos) ? "" : 2} nome="Estab">
          {selecionaPpra ? (
            <Select
              className="flex-fill"
              name="estabelecimento"
              onChange={handleSelect}
              options={
                clientesEstab.cliente_estabelecimentos &&
                clientesEstab.cliente_estabelecimentos.map((clienteEstabelecimento) => ({
                  value: clienteEstabelecimento,
                  label: clienteEstabelecimento.estabelecimento.nome_generico,
                }))
              }
              defaultValue={{
                label: reconhecimento.nomeEstabelecimento,
                value: reconhecimento.codigoEstabelecimento,
              }}
            />
          ) : (
            reconhecimento.nomeEstabelecimento
          )}
        </Options>
        {(!isEmpty(reconhecimento.contrato) || !isEmpty(contratos)) && (
          <Options nome="Cont." col={2}>
            {selecionaPpra ? (
              <Select
                isClearable
                className="flex-fill"
                name="contrato"
                onChange={handleSelect}
                options={contratos.map((contrato) => ({
                  value: contrato,
                  label: contrato.descricao,
                }))}
                defaultValue={
                  !isEmpty(reconhecimento.contrato) && {
                    label: reconhecimento.contrato.descricao,
                    value: reconhecimento.contrato,
                  }
                }
              />
            ) : !isEmpty(reconhecimento.contrato) ? (
              reconhecimento.contrato.descricao
            ) : (
              "-"
            )}
          </Options>
        )}
        <Options nome="Rev" col={2}>
          {selecionaPpra ? (
            <Select
              className="flex-fill"
              name="revisao"
              onChange={handleSelect}
              options={
                revisoes.revisoes &&
                revisoes.revisoes.map(({ id, revisao }) => ({
                  value: id,
                  label: revisao,
                }))
              }
              defaultValue={{
                label: reconhecimento.revisao,
                value: reconhecimento.idPpra,
              }}
            />
          ) : (
            reconhecimento.revisao
          )}
        </Options>
            
        <Options col={1}>
          {selecionaPpra ? (
              <BotaoVoltar onClick={() => setSelecionaPra(false)} temConteudo />
            ) : (
              <Botao
                type="button"
                icone="exchange-alt"
                cor="cinza"
                conteudo="Trocar"
                onClick={() => trocarPpra()}
                title="Trocar PGR"
                disabled={desabilitaTroca}
              />
            )}
        </Options>
      </Row>
    </div>
  );
};

const Options = ({ nome, children, col = "" }) => {
  return (
    <Col xs={col} className="d-flex align-items-center">
      {nome && <span className="fw-bold mr-1">{nome}.:</span>}
      {children}
    </Col>
  );
};

export default HeaderPpra;
