import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { isEmpty } from "../../../components/Util";
import { urlProdutosQuimicos } from "../../../routes/UrlBaseDados";
import NavTabs from "../../../components/NavTabs";
import api from "../../../service/api";
import Template from "../../template/Template";
import ProdutoQuimico from "./ProdutoQuimico";
import Riscos from "./tabs/Riscos";

const TabsProdutoQuimico = () => {
  const [produto, setProduto] = useState({});

  let { produtoQuimicoId } = useParams();

  useEffect(() => {
    buscarProdutoQuimico(produtoQuimicoId);
  }, [produtoQuimicoId]);

  const buscarProdutoQuimico = async (produtoQuimicoId) => {
    await api
      .get("/baseDados/produtosQuimicos/" + produtoQuimicoId)
      .then((resposta) => setProduto(resposta.data))
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  let nomesCadastros = ["Editar", "Formas de risco"];

  let acoes = [
    {
      id: 1,
      nome: nomesCadastros[0],
      icone: "pencil",
      body: (
        <ProdutoQuimico
          produto={produto}
          buscarProdutoQuimico={buscarProdutoQuimico}
        />
      ),
    },
    {
      id: 2,
      nome: (
        <>
          {nomesCadastros[1]}{" "}
          <span className="badge bg-primary">
            {produto.quantidade_riscos}
          </span>
        </>
      ),
      icone: "exclamation-triangle",
      body: <Riscos produto={produto} />,
    },
  ];

  return (
    <Template
      backTo={urlProdutosQuimicos + "?pg=" + +localStorage.getItem("?pg")}
      page="Edição do produto químico"
    >
      <div className="mx-5">
        <h6 className="pt-4">
          Edição do produto químico{" "}
          <p className="badge bg-primary">{produto.nome}</p>
        </h6>
        {!isEmpty(produto) && <NavTabs acoes={acoes} />}
      </div>
    </Template>
  );
};

export default TabsProdutoQuimico;
