import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import api from "../../../service/api";
import { BotaoSalvar } from "../../../components/Botao";
import TabelaPadrao from "../../../components/TabelaPadrao";

const Cadastro = ({
  clienteId,
  clienteEstabelecimentos,
  nomeDocumento,
  tipoDocumento,
  filtros,
  history,
  programa,
}) => {
  const [filtrosSelecionado, setFiltrosSelecionado] = useState([]);
  const [clienteEstabelecimentoId, setClienteEstabelecimentoId] = useState();
  const [nomeDocumentoId, setNomeDocumentoId] = useState();
  const [tipoDocumentoId, setTipoDocumentoId] = useState();
  const estabelecimentos =
    clienteEstabelecimentos.cliente_estabelecimentos?.map(
      (clienteEstabelecimento) => ({
        value: clienteEstabelecimento,
        label: clienteEstabelecimento.estabelecimento.nome_generico,
      })
    );

  const nomesdocumentos = nomeDocumento?.map((item) => ({
    value: item.id,
    label: item.nome_documento,
  }));
  const tiposDocumentos = tipoDocumento?.map((item) => ({
    value: item.id,
    label: item.tipo_documento,
  }));

  function SalvarDocumento() {
    const buscaPrograma = programa.find(
      (prog) =>
        prog.estabelecimento_id === clienteEstabelecimentoId &&
        prog.gro_programa_nome_documento_id === nomeDocumentoId &&
        prog.gro_programa_tipo_documento_id === tipoDocumentoId
    );

    if (buscaPrograma !== undefined) {
      toast.warning("Programa já cadastrado");
    } else {
      api
        .post("/gro/programa/inserir", {
          estabelecimento_id: clienteEstabelecimentoId,
          cliente_id: clienteId,
          gro_programa_nome_documento_id: nomeDocumentoId,
          gro_programa_tipo_documento_id: tipoDocumentoId,
        })
        .then((resposta) => {
          api
            .post("gro/programa/inserirFiltroDocumento", {
              filtrosSelecionado: filtrosSelecionado,
              gro_programa_id: resposta.data.dados.id,
            })
            .then(
              () => toast.success("Documento salvo com sucesso!"),
              window.setTimeout(() => {
                history.push("#2");
                window.location.reload();
              }, 2000)
            );
        });
    }
  }

  function removerFiltro(filtro_id) {
    let index = filtrosSelecionado.findIndex(
      (filtrosSelecionado) =>
        filtrosSelecionado.gro_programa_filtro_id === filtro_id
    );
    filtrosSelecionado.splice(index, 1);
  }

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <label htmlFor="selectElstab" className="form-label">
            Estabelecimento
          </label>
          <Select
            placeholder="Selecione"
            name="Estabelecimento"
            id="selectElstab"
            onChange={(option) => {
              setClienteEstabelecimentoId(option.value.codigo);
            }}
            options={estabelecimentos}
          />
        </div>
        <div className="col">
          <label htmlFor="selectDoc" className="form-label">
            Documento
          </label>
          <Select
            placeholder="Selecione"
            name="Documento"
            id="selectDoc"
            onChange={(option) => {
              setNomeDocumentoId(option.value);
            }}
            options={nomesdocumentos}
          />
        </div>
        <div className="col">
          <label htmlFor="selectTipoDoc" className="form-label">
            Tipo documento
          </label>
          <Select
            placeholder="Selecione"
            name="Tipo documento"
            id="selectTipoDoc"
            onChange={(option) => {
              setTipoDocumentoId(option.value);
            }}
            options={tiposDocumentos}
          />
        </div>
      </div>
      <div className="row mt-3">
        <label htmlFor="filtroCad" className="form-label">
          Filtros (adicionar filtros no documento)
        </label>

        <div className="col">
          <div className="row mt-2">
            <TabelaPadrao
              colunas={[
                { nome: "Filtros", width: "80%" },
                { nome: "Ação", width: "10%", text: "center" },
              ]}
              itens={filtros}
            >
              {filtros?.map((filtro) => (
                <tr key={filtro.id}>
                  <th>{filtro.nome_filtro}</th>
                  <th className="text-center">
                    <input
                      type="checkbox"
                      className="btn-check"
                      id={filtro.id + filtro.nome_filtro}
                      autoComplete="off"
                      onChange={(e) =>
                        e.target.checked
                          ? setFiltrosSelecionado([
                              ...filtrosSelecionado,
                              {
                                gro_programa_filtro_id: filtro.id,
                              },
                            ])
                          : removerFiltro(filtro.id)
                      }
                    />
                    <label
                      className="btn btn-outline-primary p-3"
                      htmlFor={filtro.id + filtro.nome_filtro}
                    ></label>
                  </th>
                </tr>
              ))}
            </TabelaPadrao>
          </div>
        </div>
      </div>
      <div className="mt-4 ">
        <BotaoSalvar
          className="mt-2 float-end"
          onClick={(e) => SalvarDocumento(e)}
          temConteudo
        />
      </div>
    </>
  );
};

export default Cadastro;