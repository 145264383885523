import React, { useState } from "react";

import { ModalSubmit } from "../../../components/ModalPadrao";
import { isEmpty } from "../../../components/Util";
import api from "../../../service/api";

const ModalFabricanteQuimico = ({
  show,
  setShow,
  titulo,
  corTitulo,
  fabricante,
  buscarFabricantesQuimicos,
  novoFabricante,
}) => {
  const [salvarFabricante, setSalvarFabricante] = useState(
    fabricante ? fabricante.nome : novoFabricante
  );
  const [cnpj, setCnpj] = useState(fabricante ? fabricante.cnpj : null);
  const [caracter, setCaracter] = useState(18 - cnpj?.length);

  const salvarBanco = async (e) => {
    e.preventDefault();
    if (!isEmpty(salvarFabricante)) {
      if (!isEmpty(cnpj)) {
        let dados = {
          nome: salvarFabricante,
          cnpj: cnpj,
        };

        let url = fabricante
          ? api.put(
              `/baseDados/FabricantesQuimicos/editar/${fabricante.id}`,
              dados
            )
          : api.post("/baseDados/FabricantesQuimicos/inserir", dados);

        await url
          .then(() => {
            buscarFabricantesQuimicos();
            setShow(false);
          })
          .catch((erro) => alert(erro.response.data.mensagem));
      }
    }
  };

  function inputHandler(f) {
    setCaracter(18 - (f.target.value.length));
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      titulo={titulo}
      corTitulo={corTitulo}
      tamanho="md"
      onSubmit={(e) => salvarBanco(e)}
    >
      <div className="mb-3">
        <label>Fabricante:</label>
        <input
          type="text"
          id="fabricante"
          name="fabricante"
          className="form-control"
          defaultValue={fabricante ? salvarFabricante : novoFabricante}
          onChange={(f) => {
            setSalvarFabricante(f.target.value);
          }}
          required
        />
      </div>
      <div className="mb-3">
        <label>CNPJ:</label>
        {!isEmpty(cnpj) && (<>
        <h7> {caracter} caracteres restantes</h7>
        </>)}
        <input
          type="text"
          id="cnpj"
          name="cnpj"
          className="form-control"
          defaultValue={cnpj}
          onChange={(f) => {
            inputHandler(f);
            setCnpj(f.target.value);
          }}
          maxLength={18}
        />
      </div>
    </ModalSubmit>
  );
};

export default ModalFabricanteQuimico;
