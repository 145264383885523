import React from "react";

import Botao, { BotaoAtualizar, BotaoBaseDados } from "./Botao";
import Dica from "./Dica";

const Filter = ({
  id,
  type = "search",
  placeholder = "Pesquisar",
  handleFilter,
  defaultValue,
  atualizarItens,
  botaoBaseDados,
  info,
  adiciona,
  isModal,
  handleClick,
  disableAddButton,
  botaoAdicional,
  texto,
}) => {
  return (
    <div className="input-group my-3">
      {info && (
        <Dica colocacao="right" conteudo={info}>
          <div className="input-group-prepend">
            <div className="input-group-text badge-warning">
              <i className="fa fa-info-circle"></i>
            </div>
          </div>
        </Dica>
      )}
      {botaoAdicional}
      <div className="input-group-prepend">
        <div className="input-group-text">
          <i className="fa fa-search"></i>
        </div>
      </div>
      <input
        type={type}
        className="form-control"
        id={"filter-" + id}
        placeholder={placeholder}
        onChange={handleFilter}
        defaultValue={defaultValue}
        autoFocus
      />
      {atualizarItens && <BotaoAtualizar onClick={atualizarItens} />}
      {botaoBaseDados && <BotaoBaseDados alvo={botaoBaseDados.alvo} title={botaoBaseDados.title} onInput />}
      {adiciona && (
        <div className="input-group-append">
          <Botao
            cor="success"
            icone={!texto && "plus"}
            conteudo={texto}
            type={isModal ? "button"  : "submit"}
            onClick={isModal && handleClick}
            disabled={disableAddButton}
          />
        </div>
      )}
    </div>
  );
};

export default Filter;
