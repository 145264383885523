import React, { useState } from "react";

import { BotaoVoltar } from "../../../components/Botao";
import { isEmpty } from "../../../components/Util";
import Limite from "./limites-quimico/Limite";
import Funcoes from "./Funcoes";
import Matematica from "./Matematica";
import ToolTipItem from "./ToolTipItem";
// import Epcs from "./medidas-controle/epcs/Epcs";
import MedidasTrabalho from "./medidas-controle/medidas-trabalho/MedidasTrabalho";
import Severidade from "./Severidade";

const Atividade = ({
  atividade,
  setor,
  indexAmbiente,
  indexAtividade,
  formaRisco,
  isQuimico,
  medidasOrganizacionaisTrabalho,
  carregarAmbientes,
  ambiente,
  ...other
}) => {
  const [mostrarFuncoes, setMostrarFuncoes] = useState(false);
  
  return (
    <>
      <tr>
        <td>
          <div className="d-flex justify-content-between">
            <div>
              {setor.numero}.{ambiente.numero || indexAmbiente}.{indexAtividade}) {atividade.nome}
              {isQuimico &&
                (atividade.tarefa_id ? (
                  <>
                    <br />
                    <span className="badge bg-primary mr-1">Atividade</span>
                    {atividade.atividade_nome}
                  </>
                ) : (
                  <span className="badge bg-primary ml-1">Químico de área</span>
                ))}
              {atividade.ferramentas &&
                !isEmpty(atividade.ferramentas) &&
                atividade.ferramentas.map((ferramenta) => <MostrarFerramenta ferramenta={ferramenta} />)}
            </div>
            <div className="m-1 border-secondary  border-right">
                {mostrarFuncoes ? (
                  <BotaoVoltar onClick={() => setMostrarFuncoes(false)} />
                ) : (
                  <ToolTipItem
                    // isLoading={carregandoBotao}
                    item={atividade}
                    isQuimico={isQuimico}
                    isAmbiente={false}
                    onClick={() =>  
                      // atividade.matematica ? 
                      setMostrarFuncoes(true) 
                      // : verificaMatematica(atividade, formaRisco) 
                    }
                  />
                )}
              </div>
          </div>
        </td>
        <Matematica
          matematica={atividade.matematica}
          setor={setor}
          atividade={atividade}
          formaRisco={formaRisco}
          isQuimico={isQuimico}
          carregarAmbientes={carregarAmbientes}
          setMostrarFuncoes={setMostrarFuncoes}
          {...other}
        />
      </tr>
      <tr>
        {mostrarFuncoes && (
          <>
            <Funcoes funcoes={atividade.funcoes} />
            <td className="align-top" colSpan="6"> 
              <MedidasTrabalho
                item={atividade}
                setor={setor}
                formaRisco={formaRisco}
                carregarAmbientes={carregarAmbientes}
              />
              {/* <Epcs
                item={atividade}
                setor={setor}
                formaRisco={formaRisco}
                carregarAmbientes={carregarAmbientes}
              /> */}
              <Severidade matematica={atividade?.matematica} riscoId={atividade?.matematica?.risco_id} nomeAtividade={atividade?.nome} setor={setor} formaRisco={formaRisco} carregarAmbientes={carregarAmbientes}/>
              {/* <Probabilidade matematicaId={atividade.matematica.id} riscoId={atividade.matematica.risco_id} nomeAtividade={atividade.nome} setor={setor} formaRisco={formaRisco} carregarAmbientes={carregarAmbientes} /> */}
              {isQuimico && (
                <Limite quimico={atividade} setor={setor} formaRisco={formaRisco} carregarAmbientes={carregarAmbientes} />
              )}
            </td>
          </>
        )}
      </tr>
    </>
  );
};

const MostrarFerramenta = ({ ferramenta }) => {
  return (
    <span key={ferramenta.ferramenta_id} className="badge bg-success mx-1">
      {ferramenta.nome} {!isEmpty(ferramenta.tipo) && " " + ferramenta.tipo} {!isEmpty(ferramenta.acionamento) && " - " + ferramenta.acionamento}
    </span>
  );
};

export default Atividade;
 