// Imports padrões
import React from "react";
import ReactDOM from "react-dom";

// Estilização
import "bootstrap/dist/css/bootstrap.css";
import "./css/style.css";
import "bootstrap/dist/js/bootstrap.js";

// Rotas
import Routes from "./routes";

ReactDOM.render(<Routes />, document.getElementById("root"));
