import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Botao, { BotaoSalvar } from "../../../../components/Botao";
import {
  dadosToken,
  isEmpty,
  mostrarMensagem,
} from "../../../../components/Util";
import { urlServicos } from "../../../../routes/UrlBaseDados";
import api from "../../../../service/api";

const Servico = ({ servico, setServico }) => {
  const [nome, setNome] = useState(servico.nome);
  const [status, setStatus] = useState(servico.status);
  const [respostaFunc, setRespostaFunc] = useState(false);
  const [validado, setValidado] = useState(servico.validado);
  const [usuario, setUsuario] = useState(servico.usuario);

  let isAdm = dadosToken().tipo === "1";
  let history = useHistory();

  const submit = async (e) => {
    e.preventDefault();
    api
      .put(`/baseDados/servicos/editar/${servico.id}`, {
        nome: nome,
        status: status,
        validado: validado,
        user_id: dadosToken().user_id,
      })
      .then((resposta) => {
        setServico(resposta.data.dados);
        setUsuario(resposta.data.dados.usuario);
        mostrarMensagem(resposta.data.mensagem, setRespostaFunc);
        goToServicos();
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  const validarServico = () => {
    let validado = "1";
    api
      .put(`/baseDados/servicos/editar/${servico.id}`, {
        validado: validado,
        user_id: dadosToken().user_id,
      })
      .then((resposta) => {
        setValidado(validado);
        setServico(resposta.data.dados);
        mostrarMensagem(resposta.data.mensagem, setRespostaFunc);
        goToServicos();
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  const verificaColuna = (coluna, respostaTrue, respostaFalse) => {
    if (coluna === true || coluna === "1") {
      return respostaTrue;
    } else {
      return respostaFalse;
    }
  };

  const goToServicos = () => {
    history.push(urlServicos);
  };

  return (
    <form>
      <div className="form-group">
        <label htmlFor="inputNomeServico">Nome do serviço</label>
        <input
          type="text"
          className="form-control"
          id="inputNomeServico"
          placeholder="Digite o nome do serviço"
          defaultValue={nome}
          onChange={(e) => setNome(e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-2">
            <label htmlFor="switchStatus">Status</label>
            <Form.Check
              type="switch"
              id="switchStatus"
              label={verificaColuna(status, "Ativo", "Inativo")}
              defaultChecked={verificaColuna(status, true, false)}
              onChange={(e) => setStatus(e.target.checked)}
            />
          </div>
          <div className="col">
            {verificaColuna(validado, true, false) && !isEmpty(usuario) && (
              <>
                <label>Validado</label>
                <div>
                  Serviço validado por{" "}
                  <span className="badge bg-success">
                    {usuario.nome_usuario}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        {respostaFunc && (
          <div className="col">
            <span style={{ color: "green" }}>{respostaFunc}</span>
          </div>
        )}
        <div className="col">
          <BotaoSalvar
            className="float-end"
            onClick={submit}
            disabled={nome === ""}
            temConteudo
          />
          {verificaColuna(validado, false, true) && isAdm && (
            <Botao
              className="float-end mr-2"
              conteudo="Validar serviço"
              icone="check"
              onClick={validarServico}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default Servico;
