import React, { useState } from "react";

import {
  BotaoDeletar,
  BotaoEditar,
  BotaoGrupo,
} from "../../../components/Botao";
import TabelaPadrao from "../../../components/TabelaPadrao";
import { isEmpty } from "../../../components/Util";
import api from "../../../service/api";
import ModalFabricanteQuimico from "./ModalFabricanteQuimico";

const ListaFabricanteQuimico = ({
  fabricantes,
  buscarFabricantesQuimicos,
  produtosQuimicos,
}) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={fabricantes}
        colunas={[
          { nome: "Item", text: "center" },
          { nome: "Fabricante" },
          { nome: "CNPJ" },
          { nome: "Ações" },
        ]}
      >
        {fabricantes.map((fabricante, index) => (
          <Fabricante
            key={fabricante.id}
            fabricante={fabricante}
            buscarFabricantesQuimicos={buscarFabricantesQuimicos}
            index={index}
            produtosQuimicos={produtosQuimicos}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const Fabricante = ({
  fabricante,
  buscarFabricantesQuimicos,
  index,
  produtosQuimicos,
}) => {
  const [editando, setEditando] = useState(false);

  const vinculosProdutosQuimicos = produtosQuimicos.filter(
    (produtoQuimico) => produtoQuimico.fabricante_quimico_id === fabricante.id
  );

  const deletarFabricante = async (fabricante) => {
    if (
      window.confirm(
        `Tem certeza que deseja excluir o fabricante: ${fabricante.nome}?`
      )
    ) {
      await api
        .delete(`/baseDados/FabricantesQuimicos/deletar/${fabricante.id}`)
        .then(() => {
          buscarFabricantesQuimicos();
          setEditando(false);
        })
        .catch((erro) => {
          alert(erro.responde.data.mensagem);
        });
    }
  };

  return (
    <>
      <tr key={fabricante.id}>
        <td className="text-center align-middle">{index + 1}</td>
        <td className="text-center align-middle">{fabricante.nome}</td>
        <td className="text-center align-middle">{fabricante.cnpj}</td>
        <td className="text-center align-middle">
          <BotaoGrupo>
            <BotaoEditar
              onClick={() => setEditando(true)}
            />
            <BotaoDeletar
              onClick={() => deletarFabricante(fabricante)}
              disabled={!isEmpty(vinculosProdutosQuimicos)}
            />
          </BotaoGrupo>
        </td>
      </tr>
      {editando && (
        <ModalFabricanteQuimico
          show={editando}
          setShow={setEditando}
          titulo={`Editar: ${fabricante.nome}`}
          corTitulo="primary"
          fabricante={fabricante}
          buscarFabricantesQuimicos={buscarFabricantesQuimicos}
        />
      )}
    </>
  );
};

export default ListaFabricanteQuimico;
