import React from "react";
import { Dropdown } from "react-bootstrap";

import Construcao from "../../../components/Construcao";
import ExternalLink from "../../../components/ExternalLink";
import Icone from "../../../components/Icone";

const ItemDropdown = ({ icone, conteudo, itens }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="cinza" id="nav-dropdown">
        <Icone className="text-info" icone={icone} /> {conteudo}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {itens.map((item, index) => (
          <Dropdown.Item
            key={index}
            href={item.alvo && !item.construcao ? item.alvo : ""}
            className="p-3"
            target="_blank"
            rel="noopener noreferrer"
            disabled={item.construcao ? true : false}
            onClick={item.modal}
          >
            <Icone
              className="text-cinza text-center"
              style={{ width: "30px" }}
              icone={item.icone}
            />
            {item.conteudo}
            {item.construcao && <Construcao />}
            {item.external && <ExternalLink className="ml-2" />}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ItemDropdown;
