import React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

import NavTabs from "../../../components/NavTabs";
import Template from "../../template/Template";
import AcidentePerigo from "./perigo/AcidentePerigo";
import AcidenteRisco from "./risco/AcidenteRisco";
import AcidenteMedidaControleNecessaria from "./medidaControleNecessaria/AcidenteMedidaControleNecessaria";
import AcidentePerigoRisco from "./perigoRisco/AcidentePerigoRisco";
import AcidentePerigoMedidaControleNecessaria from "./perigoMedidaControleNecessaria/AcidentePerigoMedidaControleNecessaria";
import { dadosToken, isEmpty } from "../../../components/Util";
import GheAcidente from "./perigoMedidaControleNecessaria/GheAcidente";

const Acidente = () => {

  const permissaoEditar = isEmpty(dadosToken().permissoes.find((item) => {
    return item === "baseDadosErgonomia";
  })) ? false : true;

  const acoes = [
    {
      id: 1,
      nome: "Perigo",
      body: <AcidentePerigo permissaoEditar={permissaoEditar}/>,
    },
    {
      id: 2,
      nome: "Riscos",
      body: <AcidenteRisco permissaoEditar={permissaoEditar}/>,
    },
    {
      id: 3,
      nome: "Medidas de Controle",
      body: <AcidenteMedidaControleNecessaria permissaoEditar={permissaoEditar}/>,
    },
    {
      id: 4,
      nome: "Perigo x Risco",
      body: <AcidentePerigoRisco permissaoEditar={permissaoEditar}/>,
    },
    {
      id: 5,
      nome: "Perigo x Medidas de Controle Necessárias",
      body: <AcidentePerigoMedidaControleNecessaria permissaoEditar={permissaoEditar}/>,
    },
    {
      id: 6,
      nome: "GHE",
      body: <GheAcidente permissaoEditar={permissaoEditar} />,
    }
  ];

  return (
    <>
      <Template page="Acidente - base-dados">
        <>
          <div className="container-fluid justify-content-between">
            <div className="bg-white p-2">
              <div className="card bg-cinza text-white">
                <span className="align-middle">
                  <h3 className="text-center">
                    <i className="fas fa-car-crash"></i> Base de Dados Acidente
                  </h3>
                </span>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <NavTabs acoes={acoes} />
                  <ToastContainer autoClose={1000} pauseOnHover={false} />
                </div>
              </div>
            </div>
          </div>
        </>
      </Template>
    </>
  );
};

export default Acidente;
