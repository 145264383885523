import React, { useState } from "react";

import { ModalSubmit } from "../../../../components/ModalPadrao";
import api from "../../../../service/api";

const ModalAcidentePerigo = ({
  show,
  setShow,
  titulo,
  corTitulo,
  perigo,
  buscarPerigos,
  novoPerigo,
  acidentePerigo,
}) => {
  const [salvarPerigo, setSalvarPerigo] = useState(perigo ? perigo.definicao : novoPerigo);
  const [codigo, setCodigo] = useState(perigo && perigo.codigo);

  async function salvarBanco(e) {
    e.preventDefault();

    if (perigo) {
      api
        .put(`/baseDados/acidenteBaseDados/editarPerigoVinculos/${perigo.id}`, {
          definicao: salvarPerigo,
          codigo: codigo,
        })
        .then(() => {
          buscarPerigos();
          setShow(false);
        })
        .catch((erro) => console.log(erro.response.data.mensagem));
    } else {
      api
        .post("/baseDados/acidenteBaseDados/inserirPerigo", {
          definicao: salvarPerigo,
          codigo: codigo,
        })
        .then(() => {
          buscarPerigos();
          setShow(false);
        })
        .catch((erro) => console.log(erro.response.data.mensagem));
    }
  }

  return (
    <>
      <ModalSubmit
        show={show}
        setShow={setShow}
        titulo={titulo}
        corTitulo={corTitulo}
        tamanho="md"
        onSubmit={(e) => salvarBanco(e)}
      >
        {
          <div className="mb-3">
            <label>Código:</label>
            <input
              type="text"
              id="codigo"
              name="codigo"
              className="form-control"
              defaultValue={perigo && perigo.codigo}
              onChange={(p) => {
                setCodigo(p.target.value);
              }}
              required
            />

            <label>Perigo:</label>
            <input
              type="text"
              id="perigo"
              name="perigo"
              className="form-control"
              defaultValue={perigo ? salvarPerigo : novoPerigo}
              onChange={(p) => {
                setSalvarPerigo(p.target.value);
              }}
              required
            />
          </div>
        }
      </ModalSubmit>
    </>
  );
};

export default ModalAcidentePerigo;
