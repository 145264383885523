import React, { useEffect, useState } from "react";

import {
  BotaoDeletar,
  BotaoEditar,
  BotaoGrupo,
  BotaoSalvar,
  BotaoVoltar,
} from "../../../components/Botao";
import { isEmpty } from "../../../components/Util";
import api from "../../../service/api";
import TabelaPadrao from "../../../components/TabelaPadrao";

const ListaAtividadesEspeciais = ({
  atividadesEspeciais,
  carregarAtividades,
  carregandoAtividadesEspeciais,
  setCarregandoAtividadesEspeciais,
}) => {
  return (
    <TabelaPadrao
      noOverflowAuto
      fontSize="14px"
      colunas={[
        { nome: "Nome", width: "92%" },
        { nome: "Ações", width: "8%", text: "center" },
      ]}
      itens={atividadesEspeciais}
      carregando={carregandoAtividadesEspeciais}
    >
      {atividadesEspeciais.map((atividadesEspeciais) => (
        <AtividadeEspecial
          key={atividadesEspeciais.id}
          atividadeEspecial={atividadesEspeciais}
          carregarAtividades={carregarAtividades}
          carregarAtividadesEspeciais={carregandoAtividadesEspeciais}
          setCarregandoAtividadesEspeciais={setCarregandoAtividadesEspeciais}
        />
      ))}
    </TabelaPadrao>
  );
};

function AtividadeEspecial({
  atividadeEspecial,
  carregarAtividades,
  setCarregandoAtividadesEspeciais,
  ...other
}) {
  const [nome, setNome] = useState("");
  const [editando, setEditando] = useState(false);
  const [deletar, setDeletar] = useState(false);
  const [dependeciasOutrasTabelas, setAtividadesOutrasTabelas] = useState([]);

  async function editarAtividadeEspecial(evento, atividadeEspecial) {
    evento.preventDefault();
    await api
      .put(`/baseDados/AtividadesEspeciais/editar/${atividadeEspecial.id}`, {
        nome: nome !== "" ? nome : atividadeEspecial.nome,
      })
      .then(() => {
        setCarregandoAtividadesEspeciais(true);
        carregarAtividades();
        setEditando(false);
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
        setCarregandoAtividadesEspeciais(false);
      });
  }

  async function carregarDependenciasOutrasTabelas(atividadeEspecial) {
    await api
      .get(
        `/baseDados/AtividadesEspeciais/obterDenpenciasOutrasTabelas?atividadeEspecial_id=${atividadeEspecial.id}`
      )
      .then((resposta) => {
        setAtividadesOutrasTabelas(resposta.data);
        setDeletar(!deletar);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <tr>
        <td>
          {editando ? (
            <input
              className="form-control form-control-sm"
              type="text"
              id={"inputNomeatividadeEspecial-" + atividadeEspecial.id}
              defaultValue={atividadeEspecial.nome}
              onChange={(e) => setNome(e.target.value)}
              autoFocus
            />
          ) : (
            atividadeEspecial.nome
          )}
          {deletar && (
            <DependeciasOutrasTabelas
              dependeciasOutrasTabelas={dependeciasOutrasTabelas}
              atividadeEspecial={atividadeEspecial}
              deletar={deletar}
              setDeletar={setDeletar}
              carregarAtividades={carregarAtividades}
              setCarregandoAtividadeEspecial={setCarregandoAtividadesEspeciais}
              {...other}
            />
          )}
        </td>
        <td className="text-center">
          <BotaoGrupo>
            {editando ? (
              nome === "" || nome === atividadeEspecial.nome ? (
                <BotaoVoltar onClick={() => setEditando(false)} />
              ) : (
                <BotaoSalvar
                  onClick={(e) => editarAtividadeEspecial(e, atividadeEspecial)}
                />
              )
            ) : (
              <BotaoEditar onClick={() => setEditando(true)} />
            )}
            {deletar ? (
              <BotaoVoltar onClick={() => setDeletar(!deletar)} />
            ) : (
              <BotaoDeletar
                onClick={() =>
                  carregarDependenciasOutrasTabelas(atividadeEspecial)
                }
              />
            )}
          </BotaoGrupo>
        </td>
      </tr>
    </>
  );
}

const DependeciasOutrasTabelas = ({
  atividadeEspecial,
  deletar,
  setDeletar,
  carregarAtividades,
  dependeciasOutrasTabelas,
}) => {
  useEffect(() => {
    if (dependeciasOutrasTabelas.length === 0) {
      async function deletarAtividadeEspecial(atividadeEspecial) {
        setDeletar(!deletar);
        if (
          window.confirm(
            `Tem certeza que deseja excluir a atividade especial: ${atividadeEspecial.nome}?`
          )
        ) {
          await api
            .delete(
              `/baseDados/AtividadesEspeciais/deletar/${atividadeEspecial.id}`
            )
            .then(() => carregarAtividades())
            .catch((erro) => alert(erro.response.data.mensagem));
        }
      }
      deletarAtividadeEspecial(atividadeEspecial);
    }
  }, [
    dependeciasOutrasTabelas.length,
    atividadeEspecial,
    setDeletar,
    deletar,
    carregarAtividades,
  ]);

  return (
    !isEmpty(dependeciasOutrasTabelas) && (
      <div style={{ color: "red" }}>
        Existem ({" "}
        {dependeciasOutrasTabelas.map((tabela, index) =>
          dependeciasOutrasTabelas.length - 1 !== index ? tabela + ", " : tabela
        )}{" "}
        ) ligados nessa atividade especial!
      </div>
    )
  );
};

export default ListaAtividadesEspeciais;
