import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";

import api from "../../service/api";
import Template from "../template/Template";
import NavTabs from "../../components/NavTabs";
import Cadastro from "./Programas/Cadastro";
import Visualizacao from "./Programas/Visualizacao";
import Upload from "./Programas/Upload";

const Programas = () => {
  const [clienteEstabelecimentos, setClienteEstabelecimentos] = useState([]);
  const [nomeDocumento, setNomeDocumento] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [programa, setPrograma] = useState([]);

  const { clienteId } = useParams();
  let history = useHistory();
  useEffect(() => {
    async function buscarEstabelecimentos(clienteId) {
      await api
        .get(
          `/clientes/obterClienteComEstabelecimentos?cliente_codigo=${clienteId}`
        )
        .then((resposta) => {
          setClienteEstabelecimentos(resposta.data);
        });
    }
    buscarEstabelecimentos(clienteId);
    buscarNomeDocumento();
    buscarTipoDocumento();
    buscarFiltro(clienteId);
    buscarPrograma(clienteId);
  }, [clienteId]);

  async function buscarNomeDocumento() {
    await api.get("/gro/programa/buscarNomeDocumento").then((resposta) => {
      setNomeDocumento(resposta.data);
    });
  }

  async function buscarTipoDocumento() {
    await api.get("/gro/programa/buscarTipoDocumento").then((resposta) => {
      setTipoDocumento(resposta.data);
    });
  }

  async function buscarFiltro() {
    await api.get("/gro/programa/buscarFiltro").then((resposta) => {
      setFiltros(resposta.data);
    });
  }

  async function buscarPrograma(clienteId) {
    await api
      .get(`/gro/programa/buscarPrograma?cliente_id=${clienteId}`)
      .then((resposta) => {
        setPrograma(resposta.data);
      });
  }

  const acoes = [
    {
      id: 1,
      nome: "Cadastro",
      icone: "plus-square",
      body: (
        <>
          <Cadastro
            clienteId={clienteId}
            clienteEstabelecimentos={clienteEstabelecimentos}
            nomeDocumento={nomeDocumento}
            tipoDocumento={tipoDocumento}
            filtros={filtros}
            programa={programa}
            history={history}
          />
        </>
      ),
    },
    {
      id: 2,
      nome: "Upload (PDF)",
      icone: "list-alt",
      body: (
        <>
          <Upload programa={programa} />
        </>
      ),
    },
    {
      id: 3,
      nome: "Visualização (PDF)",
      icone: "list-alt",
      body: (
        <>
          <Visualizacao
            clienteEstabelecimentos={clienteEstabelecimentos}
            nomeDocumento={nomeDocumento}
            tipoDocumento={tipoDocumento}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Template page="Programas" backTo={"/gro/" + clienteId + "/painel"}>
        <div className="container-fluid">
          <div className="bg-white p-2">
            <div className="card bg-cinza text-white">
              <span className="align-middle">
                <h3 className="text-center">Documentos (Upload)</h3>
              </span>
            </div>
            <div className="row mt-3">
              <div className="col">
                <ToastContainer autoClose={2000} pauseOnHover={false} />
                <NavTabs acoes={acoes} />
              </div>
            </div>
          </div>
        </div>
      </Template>
    </>
  );
};

export default Programas;
