import React, { useEffect, useState } from "react";

import { BotaoAdicionar, BotaoRemover } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import Filtros from "../../../ppra/components/Filtros";

let atividadesFiltradas;
let atividadesServicoFiltradas;

const Atividades = ({ servico, nomeCadastro }) => {
  const servicoId = servico.id;

  const [atividades, setAtividades] = useState([]);
  const [atividadesServico, setAtividadesServico] = useState([]);

  const [filtroAtividades, setFiltroAtividades] = useState("");
  const [filtroAtividadesServico, setFiltroAtividadesServico] = useState("");

  const [carregandoAtividades, setCarregandoAtividades] = useState(true);
  const [carregandoAtividadesServico, setCarregandoAtividadesServico] =
    useState(true);

  atividadesFiltradas = atividades.filter((atividade) =>
    filtrar(atividade.nome, filtroAtividades)
  );

  atividadesServicoFiltradas = atividadesServico.filter((atividadeServico) =>
    filtrar(atividadeServico.atividade.nome, filtroAtividadesServico)
  );

  useEffect(() => {
    carregarAtividades(servicoId);
    carregarAtividadesServico(servicoId);
  }, [servicoId]);

  function carregarAtividades(servicoId) {
    api
      .get("/baseDados/servico/atividades", {
        params: { servico_id: servicoId },
      })
      .then((resposta) => {
        setCarregandoAtividades(false);
        setAtividades(resposta.data);
      })
      .catch((erro) => console.log(erro));
  }

  function carregarAtividadesServico(servicoId) {
    api
      .get("/baseDados/servico/atividades/obterAtividadesServico", {
        params: { servico_id: servicoId },
      })
      .then((resposta) => {
        setCarregandoAtividadesServico(false);
        setAtividadesServico(resposta.data);
      })
      .catch((erro) => console.log(erro));
  }

  function filtrarAtividades(newFilter) {
    setFiltroAtividades(newFilter);
  }

  function filtrarAtividadesServico(newFilter) {
    setFiltroAtividadesServico(newFilter);
  }

  return (
    <>
      <Filtros
        itens={[
          {
            placeholder: `${nomeCadastro} não adicionadas`,
            filtrar: filtrarAtividades,
            atualizarItens: () => {
              setCarregandoAtividades(true);
              carregarAtividades(servicoId);
            },
            carregando: carregandoAtividades,
          },
          {
            placeholder: `${nomeCadastro} adicionadas`,
            filtrar: filtrarAtividadesServico,
            carregando: carregandoAtividadesServico,
          },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: nomeCadastro },
              { nome: "Ação", width: "10px", text: "center" },
            ]}
            itens={atividades}
            itensFiltrados={atividadesFiltradas}
            filtro={filtroAtividades}
            carregando={carregandoAtividades}
          >
            {atividadesFiltradas.map((item) => (
              <Atividade
                key={item.id}
                item={item}
                atividades={atividadesFiltradas}
                servico={servico}
                servicoId={servicoId}
                carregarAtividadesServico={carregarAtividadesServico}
                carregarAtividades={carregarAtividades}
                setCarregandoAtividadesServico={setCarregandoAtividadesServico}
                setCarregandoAtividades={setCarregandoAtividades}
                adicionaAtividade
              />
            ))}
          </TabelaPadrao>
        </div>
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: nomeCadastro },
              { nome: "Ação", width: "10%", text: "center" },
            ]}
            itens={atividadesServico}
            itensFiltrados={atividadesServicoFiltradas}
            filtro={filtroAtividadesServico}
            carregando={carregandoAtividadesServico}
          >
            {atividadesServicoFiltradas.map((item) => (
              <Atividade
                key={item.id}
                item={item}
                atividades={atividadesServicoFiltradas}
                servicoId={servicoId}
                carregarAtividadesServico={carregarAtividadesServico}
                carregarAtividades={carregarAtividades}
                setCarregandoAtividadesServico={setCarregandoAtividadesServico}
                setCarregandoAtividades={setCarregandoAtividades}
              />
            ))}
          </TabelaPadrao>
        </div>
      </div>
    </>
  );
};

const Atividade = ({
  item,
  index,
  adicionaAtividade,
  servicoId,
  carregarAtividadesServico,
  carregarAtividades,
  setCarregandoAtividadesServico,
  setCarregandoAtividades,
}) => {
  async function adicionar() {
    setCarregandoAtividadesServico(true);
    setCarregandoAtividades(true);
    await api
      .post("baseDados/servico/atividades/inserir", {
        atividade_id: item.id,
        gro_servico_id: servicoId,
      })
      .then(() => {
        carregarAtividades(servicoId);
        carregarAtividadesServico(servicoId);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
    carregarAtividadesServico(servicoId);
    carregarAtividades(servicoId);
  }

  async function deletar(servicoId, atividadeServico) {
    setCarregandoAtividades(true);
    setCarregandoAtividadesServico(true);
    if (
      window.confirm(
        `Tem certeza que deseja remover a atividade: ${atividadeServico.atividade.nome}?`
      )
    ) {
      await api
        .delete(`baseDados/servico/atividades/deletar/${atividadeServico.id}`)
        .then(() => {
          carregarAtividadesServico(servicoId);
          carregarAtividades(servicoId);
        })
        .catch((erro) => alert(erro));
    } else {
      setCarregandoAtividades(false);
      setCarregandoAtividadesServico(false);
    }
  }

  return (
    <>
      <tr key={index}>
        <td>{!adicionaAtividade ? item.atividade.nome : item.nome}</td>
        <td>
          <div className="text-center">
            {!adicionaAtividade ? (
              <BotaoRemover onClick={() => deletar(servicoId, item)} />
            ) : (
              <BotaoAdicionar onClick={() => adicionar(item)} />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default Atividades;
