import React, { useState } from "react";

import {
  BotaoDeletar,
  BotaoEditar,
  BotaoGrupo,
} from "../../../components/Botao";
import TabelaPadrao from "../../../components/TabelaPadrao";
import api from "../../../service/api";
import ModalChecklistPerguntas from "./ModalChecklistPerguntas";

const ListaChecklistPerguntas = ({
  perguntas,
  buscarChecklistPerguntas,
  checklistModelos,
}) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={perguntas}
        colunas={[
          { nome: "Sequência", text: "center" },
          { nome: "Norma", text: "center" },
          { nome: "Pergunta", width: "25%" },
          { nome: "Observação", text: "center" },
          { nome: "Grupo Medida", text: "center" },
          { nome: "Forma Acompanhamento", text: "center" },
          { nome: "Tipo", text: "center" },
          { nome: "Ações", text: "center" },
        ]}
      >
        {perguntas.map((pergunta) => (
          <Pergunta
            key={pergunta.id}
            perguntas={perguntas}
            pergunta={pergunta}
            buscarChecklistPerguntas={buscarChecklistPerguntas}
            checklistModelos={checklistModelos}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const Pergunta = ({
  perguntas,
  pergunta,
  buscarChecklistPerguntas,
  checklistModelos,
}) => {
  const [editando, setEditando] = useState(false);

  const perguntaVinculoModelo = checklistModelos.find((checklistModelo) =>
    checklistModelo.perguntas.find(
      (checklistModeloPergunta) =>
        checklistModeloPergunta.checklist_pergunta_id === pergunta.id
    )
  )
    ? true
    : false;

  const deletarPergunta = async (pergunta) => {
    if (
      window.confirm(
        `Tem certeza que deseja excluir a pergunta: ${pergunta.pergunta}?`
      )
    ) {
      if (perguntaVinculoModelo) {
        let status = { sequencia: null, status: "I" };

        api
          .put(`/baseDados/checklistPerguntas/editar/${pergunta.id}`, status)
          .then(() => {
            ordenaPerguntas(pergunta)
          });
      } else {
        await api
          .delete(`/baseDados/checklistPerguntas/deletar/${pergunta.id}`)
          .then(() => {
            ordenaPerguntas(pergunta)
          })
          .catch((erro) => {
            alert(erro.reponse.data.mensagem);
          });
      }
    }
  };

  function ordenaPerguntas(item) {

    perguntas.splice(item.sequencia - 1, 1);

    let perguntasOrdenadas = [];
    perguntas.forEach((pergunta, index) => {
      perguntasOrdenadas.push({ id: pergunta.id, sequencia: ++index });
    });

    api
      .put(`/baseDados/checklistPerguntas/editar/`, {
        perguntasOrdenadas: perguntasOrdenadas,
      })
      .then(() => {
        buscarChecklistPerguntas();
        setEditando(false);
      });
  }

  return (
    <>
      <tr key={pergunta.id}>
        <td className="text-center align-middle">{pergunta.sequencia}</td>
        <td className="text-center align-middle">{pergunta.norma}</td>
        <td className="align-middle">{pergunta.pergunta}</td>
        <td className="text-center align-middle">{pergunta.observacao_previa_se_nao}</td>
        <td className="text-center align-middle">{pergunta.grupo}</td>
        <td className="text-center align-middle"> {pergunta.acompanhamento}</td>
        <td className="text-center align-middle">{pergunta.tipo_plano_acao}</td>
        <td className="text-center align-middle">
          <BotaoGrupo>
            <BotaoEditar onClick={() => setEditando(true)} />
            <BotaoDeletar onClick={() => deletarPergunta(pergunta)} />
          </BotaoGrupo>
        </td>
      </tr>
      {editando && (
        <ModalChecklistPerguntas
          show={editando}
          setShow={setEditando}
          titulo={`Editar: ${pergunta.pergunta}`}
          corTitulo="primary"
          perguntas={perguntas}
          pergunta={pergunta}
          buscarChecklistPerguntas={buscarChecklistPerguntas}
          checklistModelos={checklistModelos}
        />
      )}
    </>
  );
};

export default ListaChecklistPerguntas;
