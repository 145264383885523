import React, { useEffect, useState } from "react";

import { filtrar } from "../../../components/Util";
import api from "../../../service/api";
import TemplateBaseDados from "../TemplateBaseDados";
import ListaChecklistPerguntas from "./ListaChecklistPerguntas";
import ModalChecklistPerguntas from "./ModalChecklistPerguntas";

const ChecklistPerguntas = () => {
  const [perguntas, setPerguntas] = useState([]);
  const [checklistModelos, setChecklistModelos] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);

  const item = "Checklist Perguntas";

  useEffect(() => {
    buscarChecklistPerguntas();
    buscarModelos();
  }, []);

  const perguntasFiltradas = perguntas.filter(
    (pergunta) =>
      filtrar(pergunta.id, filtro) ||
      filtrar(pergunta.norma, filtro) ||
      filtrar(pergunta.pergunta, filtro) ||
      filtrar(pergunta.observacao_previa_se_nao, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  const buscarChecklistPerguntas = async () => {
    await api
      .get("/baseDados/checklistPerguntas")
      .then((resposta) => {
        setPerguntas(resposta.data.filter((dado) => dado.status === "A"));
      })
      .catch((e) => console.log(e));
  };

  async function buscarModelos() {
    await api.get(`/gro/pgr/checklist/buscarModelos`).then((resposta) => {
      setChecklistModelos(resposta.data);
    });
  }
  return (
    <>
      <TemplateBaseDados
        item={item}
        itensFiltrados={perguntasFiltradas}
        totalItens={perguntas.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={(e) => {
          setShowModalCadastro(true);
        }}
      >
        {(currentItems) => (
          <ListaChecklistPerguntas
            perguntas={currentItems}
            buscarChecklistPerguntas={buscarChecklistPerguntas}
            checklistModelos={checklistModelos}
          />
        )}
      </TemplateBaseDados>
      {showModalCadastro && (
        <ModalChecklistPerguntas
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Pergunta do Checklist"
          corTitulo="success"
          perguntas={perguntas}
          buscarChecklistPerguntas={buscarChecklistPerguntas}
          checklistModelos={checklistModelos}
          novaPergunta={filtro}
        />
      )}
    </>
  );
};

export default ChecklistPerguntas;
