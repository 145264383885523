import React, { useEffect, useState } from "react";
import { Card, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Botao from "./Botao";
import Icone from "./Icone";
import { isEmpty } from "./Util";

const NavTabs = ({ acoes }) => {
  let history = useHistory();
  let hashUrl = history.location.hash;
  let corBorda = "primary";

  const [acaoAtiva, setAcaoAtiva] = useState({});

  useEffect(() => {
    let numeroSelecionado = hashUrl.replace("#", "");
    let cadastroSelecionado = acoes[parseInt(--numeroSelecionado)];
    setAcaoAtiva(cadastroSelecionado);
  }, [acoes, hashUrl]);

  if (acaoAtiva && !isEmpty(acaoAtiva.bordaCor)) {
    corBorda = acaoAtiva.bordaCor;
  }

  return (
    <Card>
      <Card.Header >
        <Nav fill variant="tabs" defaultActiveKey={hashUrl}>
          {acoes.map((acao) => (
            <Nav.Item key={acao.id} >
              <Nav.Link
                className={
                  `text-primary fw-bold border-2 border-${corBorda} ` +
                  (!isEmpty(acaoAtiva) && acaoAtiva.id === acao.id
                    ? "border-bottom-0"
                    : "border-top-0 border-end-0 border-start-0")
                }
                href={"#" + acao.id}
                onClick={() => setAcaoAtiva(acao)}
              >
                <Icone icone={acao.icone} /> {acao.nome}
              </Nav.Link>
            </Nav.Item>
          ))}
          {!isEmpty(acaoAtiva) && !isEmpty(acaoAtiva.baseDados) && (
            <div key={acaoAtiva.id} className="ml-2">
              <Botao
                icone="database"
                cor="cinza"
                title={`Base de dados de ${acaoAtiva.baseDados.conteudo}`}
                conteudo={acaoAtiva.baseDados.conteudo}
                alvo={acaoAtiva.baseDados.alvo}
              />
            </div>
          )}
        </Nav>
      </Card.Header>
      <Card.Body>
        {isEmpty(acaoAtiva) ? "Selecione o módulo desejado" : acaoAtiva.body}
      </Card.Body>
    </Card>
  );
};

export default NavTabs;
