import React, { useState } from 'react'
import { useEffect } from 'react';
import api from '../../../../service/api';
import { filtrar } from '../../../../components/Util';
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";
import ListaGheAcidente from './ListaGheAcidente';
import { ModalSubmit } from '../../../../components/ModalPadrao';

const GheAcidente = ({permissaoEditar}) => {
    const [gruposGhe, setGrupoGhe] = useState([]);
    const [filtro, setFiltro] = useState("");
    const [showModalCadastro, setShowModalCadastro] = useState();
    const item = "Grupos Ghe";


    useEffect(() => {
        api.get("/baseDados/acidenteBaseDados/buscarGrupoGheAcidente").then((resposta) => {
          setGrupoGhe(resposta.data);
        });
    }, [])

    const gruposFiltrados = gruposGhe.filter((perigo) => filtrar(perigo.nome, filtro));

    const handleFilter = (newFilter) => {
      setFiltro(newFilter);
    };


  function obterDados() {
    api.get("/baseDados/acidenteBaseDados/buscarGrupoGheAcidente").then((resposta) => {
      setGrupoGhe(resposta.data);
    });
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={gruposFiltrados}
        totalItens={gruposGhe.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={() => {
          permissaoEditar && setShowModalCadastro(true);
        }}
      >
        {() => <ListaGheAcidente gruposGhe={gruposGhe} permissaoEditar={permissaoEditar} obterDados={obterDados} />}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalGheAcidente
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Perigo"
          corTitulo="success"
          obterDados={obterDados}
        />
      )}
    </>
  );
}

const ModalGheAcidente = ({show, setShow, obterDados}) => {
    const [nome, setNome] = useState();
    const [tipoServico, setTipoServico] = useState();

    function salvarDados() {
      api
        .post("/baseDados/acidenteBaseDados/inserirGrupoGheAcidente", {
          nome: nome,
          tipo_servico: tipoServico,
        })
        .then(() => {
          obterDados();
          setShow(false)
        });
    }

     return (
       <ModalSubmit
         show={show}
         setShow={setShow}
         tamanho={"md"}
         onSubmit={(e) => salvarDados(e)}
         titulo={"Criar Grupo"}
       >
         <div className="row">
           <div className="col">
             <label htmlFor="nome"> Nome: </label>
             <input type="text" id="nome" className="form-control" onChange={(e) => setNome(e.target.value)} />
           </div>
         </div>
         <div className="row mt-2">
           <div className="col">
             <label htmlFor="adm">Administrativo</label>
             <input
               className="form-check-input ml-2"
               type="radio"
               name="tipo_servico"
               id="adm"
               onChange={() => setTipoServico("A")}
             />
             <label htmlFor="op" className="ml-4">
               Operacional
             </label>
             <input
               className="form-check-input ml-2"
               type="radio"
               name="tipo_servico"
               id="op"
               onChange={() => setTipoServico("O")}
             />
           </div>
         </div>
       </ModalSubmit>
     );
 }

export default GheAcidente