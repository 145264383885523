import React, { useEffect, useState } from "react";

import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavTabs from "../../TemplateBaseDadosNavtabs";
import ListaFormasRisco from "../ListaFormasRisco";
import ModalFormasRisco from "../ModalFormasRisco";

const FormasRiscoErgonomia = () => {
  const [formasErgonomia, setFormasErgonomia] = useState([]);

  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "Formas de Risco - Ergonomia";

  useEffect(() => {
    buscarFormasRiscoErgonomia();
  }, []);

  const formasErgonomiaFiltrados = formasErgonomia.filter(
    (formaRiscoFisicoQuimicoBiologico) =>
      filtrar(formaRiscoFisicoQuimicoBiologico.id, filtro) ||
      filtrar(formaRiscoFisicoQuimicoBiologico.definicao, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarFormasRiscoErgonomia() {
    await api
      .get("/baseDados/formasRisco/buscarFormasRiscoErgonomia")
      .then((resposta) => {
        setFormasErgonomia(resposta.data);
      })
      .catch((e) => console.log(e));
  }
  return (
    <>
      <TemplateBaseDadosNavTabs
        item={item}
        itensFiltrados={formasErgonomiaFiltrados}
        totalItens={formasErgonomia.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={(e) => {
          setShowModalCadastro(true);
        }}
      >
        {(currentItems) => (
          <ListaFormasRisco
            formasRisco={currentItems}
            buscarFormaRisco={buscarFormasRiscoErgonomia}
            formasRiscoTipo={2}
          />
        )}
      </TemplateBaseDadosNavTabs>
      {showModalCadastro && (
        <ModalFormasRisco
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Formas de Risco - Ergonomia"
          corTitulo="success"
          buscarFormaRisco={buscarFormasRiscoErgonomia}
          novaFormaRisco={filtro}
          formasRiscoTipo={2}
        />
      )}
    </>
  );
};

export default FormasRiscoErgonomia;
