import React, { useEffect, useState } from "react";

import { BotaoAdicionar, BotaoRemover } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import Filtros from "../../../ppra/components/Filtros";

const Riscos = ({ produto }) => {
  const [formasRiscos, setFormasRiscos] = useState([]);
  const [formasRiscosProdutos, setFormasRiscosProdutos] = useState([]);

  const [filtroFormasRiscos, setFiltroFormasRiscos] = useState("");
  const [filtroFormasRiscosProdutos, setFiltroFormasRiscosProdutos] =
    useState("");

  const [carregandoFormasRiscos, setCarregandoFormasRiscos] = useState(false);
  const [carregandoFormasRiscosProdutos, setCarregandoFormasRiscosProdutos] =
    useState(false);

  const formasRiscosFiltrados = formasRiscos.filter((formaRiscos) =>
    filtrar(formaRiscos.definicao, filtroFormasRiscos)
  );

  const formasRiscosProdutosFiltrados = formasRiscosProdutos.filter(
    (formaRiscosProduto) =>
      filtrar(
        formaRiscosProduto.forma_risco.definicao,
        filtroFormasRiscosProdutos
      )
  );

  function filtrarFormasRiscos(newFilter) {
    setFiltroFormasRiscos(newFilter);
  }

  function filtrarFormasRiscosProdutos(newFilter) {
    setFiltroFormasRiscosProdutos(newFilter);
  }

  useEffect(() => {
    carregarFormasRiscos(produto.id);
    carregarFormasRiscosProdutos(produto.id);
  }, [produto.id]);

  async function carregarFormasRiscos(produtoId) {
    await api
      .get(`/baseDados/ProdutosFormasRiscos/obterFormasRiscos`, {
        params: { produto_id: produtoId },
      })
      .then((resposta) => {
        setFormasRiscos(resposta.data);
        setCarregandoFormasRiscos(false);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  async function carregarFormasRiscosProdutos(produtoId) {
    await api
      .get(`/baseDados/ProdutosFormasRiscos`, {
        params: { produto_id: produtoId },
      })
      .then((resposta) => {
        setFormasRiscosProdutos(resposta.data);
        setCarregandoFormasRiscosProdutos(false);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  return (
    <>
      <Filtros
        itens={[
          {
            placeholder: "Formas de riscos não adicionadas",
            filtrar: filtrarFormasRiscos,
            atualizarItens: () => {
              setCarregandoFormasRiscos(true);
              carregarFormasRiscos(produto.id);
            },
          },
          {
            placeholder: "Formas de riscos adicionadas",
            filtrar: filtrarFormasRiscosProdutos,
          },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaFormasRiscos
            adicionaFormasRiscos
            formasRiscos={formasRiscosFiltrados}
            produtoId={produto.id}
            carregarFormasRiscosProdutos={carregarFormasRiscosProdutos}
            carregarFormasRiscos={carregarFormasRiscos}
            setCarregandoFormasRiscosProdutos={
              setCarregandoFormasRiscosProdutos
            }
            setCarregandoFormasRiscos={setCarregandoFormasRiscos}
            carregando={carregandoFormasRiscos}
          />
        </div>
        <div className="col">
          <TabelaFormasRiscos
            formasRiscos={formasRiscosProdutosFiltrados}
            produtoId={produto.id}
            carregarFormasRiscosProdutos={carregarFormasRiscosProdutos}
            carregarFormasRiscos={carregarFormasRiscos}
            setCarregandoFormasRiscosProdutos={
              setCarregandoFormasRiscosProdutos
            }
            setCarregandoFormasRiscos={setCarregandoFormasRiscos}
            carregando={carregandoFormasRiscosProdutos}
          />
        </div>
      </div>
    </>
  );
};

const TabelaFormasRiscos = ({
  formasRiscos,
  carregando,
  adicionaFormasRiscos,
  ...other
}) => {
  return (
    <TabelaPadrao
      colunas={[
        { nome: "Definição" },
        { nome: "Ação", width: "10%", text: "center" },
      ]}
      itens={formasRiscos}
      carregando={carregando}
    >
      {formasRiscos.map((formaRiscos, index) => (
        <FormaRiscos
          key={index}
          formaRiscos={formaRiscos}
          adicionaFormasRiscos={adicionaFormasRiscos}
          {...other}
        />
      ))}
    </TabelaPadrao>
  );
};

const FormaRiscos = ({
  formaRiscos,
  adicionaFormasRiscos,
  produtoId,
  carregarFormasRiscosProdutos,
  carregarFormasRiscos,
  setCarregandoFormasRiscosProdutos,
  setCarregandoFormasRiscos,
}) => {
  async function adicionarFormaRiscos(produtoId, formaRiscosId) {
    setCarregandoFormasRiscosProdutos(true);
    setCarregandoFormasRiscos(true);
    await api
      .post("baseDados/ProdutosFormasRiscos/inserir", {
        produto_quimico_id: produtoId,
        forma_risco_id: formaRiscosId,
      })
      .then(() => {
        carregarFormasRiscosProdutos(produtoId);
        carregarFormasRiscos(produtoId);
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
      });
  }

  async function deletarProdutoFormaRisco(produtoFormaRisco) {
    if (
      window.confirm(
        `Tem certeza que deseja remover a forma de risco: ${produtoFormaRisco.forma_risco.definicao}?`
      )
    ) {
      setCarregandoFormasRiscos(true);
      setCarregandoFormasRiscosProdutos(true);
      await api
        .delete(
          `baseDados/ProdutosFormasRiscos/deletar/${produtoFormaRisco.id}`
        )
        .then(() => {
          carregarFormasRiscosProdutos(produtoFormaRisco.produto_quimico_id);
          carregarFormasRiscos(produtoFormaRisco.produto_quimico_id);
        })
        .catch((erro) => {
          alert(erro.response.data.mensagem);
          setCarregandoFormasRiscos(false);
          setCarregandoFormasRiscosProdutos(false);
        });
    }
  }

  return (
    <tr>
      <td>
        {adicionaFormasRiscos
          ? formaRiscos.definicao
          : formaRiscos.forma_risco.definicao}{" "}
        <span className="badge bg-warning">
          {adicionaFormasRiscos
            ? formaRiscos.risco.nome
            : formaRiscos.forma_risco.risco.nome}
        </span>
      </td>
      <td>
        <div className="text-center">
          {adicionaFormasRiscos ? (
            <BotaoAdicionar
              title="Adicionar forma de risco"
              onClick={() => adicionarFormaRiscos(produtoId, formaRiscos.id)}
            />
          ) : (
            <BotaoRemover
              title="Remover forma de risco"
              onClick={() => deletarProdutoFormaRisco(formaRiscos)}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default Riscos;
