import React, { useEffect, useState } from "react";

import { BotaoAdicionar, BotaoRemover } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import Filtros from "../../../ppra/components/Filtros";

const RequisitosAplicaveis = ({ servico, nomeCadastro }) => {
  const servicoId = servico.id;

  const [requisitos, setRequisito] = useState([]);
  const [requisitosServico, setRequisitosServico] = useState([]);

  const [filtroRequisitos, setFiltroRequisitos] = useState("");
  const [filtroRequisitosServico, setFiltroRequisitosServico] = useState("");

  const [carregandoRequisitos, setCarregandoRequisitos] = useState(true);
  const [carregandoRequisitosServico, setCarregandoRequisitosServico] =
    useState(true);

  let requisitosFiltrados = requisitos.filter((requisito) =>
    filtrar(requisito.nome, filtroRequisitos)
  );

  let requisitosServicoFiltrados = requisitosServico.filter(
    (requisitoServico) =>
      filtrar(requisitoServico.requisito.nome, filtroRequisitosServico)
  );

  useEffect(() => {
    carregarRequisitos(servicoId);
    carregarRequisitosServico(servicoId);
  }, [servicoId]);

  function carregarRequisitos(servicoId) {
    api
      .get(
        "/baseDados/servico/requisitosAplicaveis/obterRequisitosAplicaveis",
        {
          params: { servico_id: servicoId },
        }
      )
      .then((resposta) => {
        setCarregandoRequisitos(false);
        setRequisito(resposta.data);
      })
      .catch((erro) => console.log(erro));
  }

  function carregarRequisitosServico(servicoId) {
    api
      .get("/baseDados/servico/requisitosAplicaveis", {
        params: { servico_id: servicoId },
      })
      .then((resposta) => {
        setCarregandoRequisitosServico(false);
        setRequisitosServico(resposta.data);
      })
      .catch((erro) => console.log(erro));
  }

  function filtrarRequisitos(newFilter) {
    setFiltroRequisitos(newFilter);
  }

  function filtrarRequisitosServico(newFilter) {
    setFiltroRequisitosServico(newFilter);
  }

  return (
    <>
      <Filtros
        itens={[
          {
            placeholder: `${nomeCadastro} não adicionados`,
            filtrar: filtrarRequisitos,
            atualizarItens: () => {
              setCarregandoRequisitos(true);
              carregarRequisitos(servicoId);
            },
            carregando: carregandoRequisitos,
          },
          {
            placeholder: `${nomeCadastro} adicionados`,
            filtrar: filtrarRequisitosServico,
            carregando: carregandoRequisitosServico,
          },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: nomeCadastro },
              { nome: "Ação", width: "10px", text: "center" },
            ]}
            itens={requisitos}
            itensFiltrados={requisitosFiltrados}
            filtro={filtroRequisitos}
            carregando={carregandoRequisitos}
          >
            {requisitosFiltrados.map((item) => (
              <Requisito
                key={item.id}
                item={item}
                requisitos={requisitosFiltrados}
                servico={servico}
                servicoId={servicoId}
                carregarRequisitosServico={carregarRequisitosServico}
                carregarRequisitos={carregarRequisitos}
                setCarregandoRequisitosServico={setCarregandoRequisitosServico}
                setCarregandoRequisitos={setCarregandoRequisitos}
                adicionaRequisito
              />
            ))}
          </TabelaPadrao>
        </div>
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: nomeCadastro },
              { nome: "Ação", width: "10px", text: "center" },
            ]}
            itens={requisitosServico}
            itensFiltrados={requisitosServicoFiltrados}
            filtro={filtroRequisitosServico}
            carregando={carregandoRequisitosServico}
          >
            {requisitosServicoFiltrados.map((item) => (
              <Requisito
                key={item.id}
                item={item}
                requisitos={requisitosServicoFiltrados}
                servicoId={servicoId}
                carregarRequisitosServico={carregarRequisitosServico}
                carregarRequisitos={carregarRequisitos}
                setCarregandoRequisitosServico={setCarregandoRequisitosServico}
                setCarregandoRequisitos={setCarregandoRequisitos}
              />
            ))}
          </TabelaPadrao>
        </div>
      </div>
    </>
  );
};

const Requisito = ({
  item,
  index,
  adicionaRequisito,
  servicoId,
  carregarRequisitosServico,
  carregarRequisitos,
  setCarregandoRequisitosServico,
  setCarregandoRequisitos,
}) => {
  async function adicionar() {
    setCarregandoRequisitosServico(true);
    setCarregandoRequisitos(true);
    await api
      .post("baseDados/servico/requisitosAplicaveis/inserir", {
        gro_requisito_aplicavel_id: item.id,
        gro_servico_id: servicoId,
      })
      .then(() => {
        carregarRequisitos(servicoId);
        carregarRequisitosServico(servicoId);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
    carregarRequisitosServico(servicoId);
    carregarRequisitos(servicoId);
  }

  async function deletar(servicoId, requisitoServico) {
    setCarregandoRequisitos(true);
    setCarregandoRequisitosServico(true);
    if (
      window.confirm(
        `Tem certeza que deseja remover a requisito: ${requisitoServico.requisito.nome}?`
      )
    ) {
      await api
        .delete(
          `baseDados/servico/requisitosAplicaveis/deletar/${requisitoServico.id}`
        )
        .then(() => {
          carregarRequisitosServico(servicoId);
          carregarRequisitos(servicoId);
        })
        .catch((erro) => alert(erro));
    } else {
      setCarregandoRequisitos(false);
      setCarregandoRequisitosServico(false);
    }
  }

  return (
    <>
      <tr key={index}>
        <td>{!adicionaRequisito ? item.requisito.nome : item.nome}</td>
        <td>
          <div className="text-center">
            {!adicionaRequisito ? (
              <BotaoRemover onClick={() => deletar(servicoId, item)} />
            ) : (
              <BotaoAdicionar onClick={() => adicionar(item)} />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default RequisitosAplicaveis;
