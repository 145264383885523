import React, { useState } from "react";

import {
  BotaoDeletar,
  BotaoEditar,
  BotaoGrupo,
} from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import api from "../../../../service/api";
import ModalRisco from "./ModalRisco";
import { isEmpty } from "../../../../components/Util";

const ListaRisco = ({ riscos, buscarRiscos, apreRiscos, permissaoEditar }) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={riscos}
        colunas={[
          { nome: "Código", width: "3%", text: "center" },
          { nome: "Risco", width: "12%", text: "center" },
          { nome: "Sintomas", width: "72%", text: "center" },
          { nome: "Severidade", width: "20%" },
          permissaoEditar &&
          { nome: "Ações", width: "8%", text: "center" },
        ]}
      >
        {riscos.map((risco) => (
          <Risco
            key={risco.id}
            risco={risco}
            buscarRiscos={buscarRiscos}
            apreRiscos={apreRiscos}
            permissaoEditar={permissaoEditar}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const Risco = ({ risco, buscarRiscos, apreRiscos, permissaoEditar }) => {
  const [editando, setEditando] = useState(false);

  async function deletarRisco(risco) {
    const riscoVinculoApre = apreRiscos.find(
      (apreRisco) => apreRisco.riscos_ergonomia_id === risco.id
    );

    if (
      window.confirm(`Tem certeza que deseja excluir o risco: ${risco.riscos}?`)
    ) {
      if (riscoVinculoApre) {
        let status = { status: "I" };

        api
          .put(`/baseDados/ergonomiaBaseDados/editarRisco/${risco.id}`, status)
          .then(() => {
            api
              .delete(
                `/baseDados/ergonomiaBaseDados/deletarRiscoPerigosVinculados/${risco.id}`
              )
              .then(() => {
                buscarRiscos();
                setEditando(false);
              });
          });
      } else {
        api
          .delete(
            `/baseDados/ergonomiaBaseDados/deletarRiscoPerigosVinculados/${risco.id}`
          )
          .then(() => {
            api
              .delete(`/baseDados/ergonomiaBaseDados/deletarRisco/${risco.id}`)
              .then(() => {
                buscarRiscos();
                setEditando(false);
              })
              .catch((erro) => {
                alert(erro.response.data.mensagem);
              });
          })
          .catch((erro) => {
            alert(erro.response.data.mensagem);
          });
      }
    }
  }

  return (
    <>
      <tr key={risco.id}>
        <td className="text-center align-middle">{risco.codigo}</td>
        <td className="align-middle">{risco.riscos}</td>
        <td className="align-middle">{risco.sintomas}</td>
        <td className="align-middle">{isEmpty(risco.severidade_id) ? "Nenhuma severidade cadastrada" : risco.severidade_grau_risco + " - " + risco.severidade}</td>
        {permissaoEditar && (
          <>
            <td className="text-center align-middle">
            <BotaoGrupo>
              <BotaoEditar
                onClick={() => setEditando(true)}
                disabled={risco.id === '14' && true}
              />
              <BotaoDeletar
                onClick={() => deletarRisco(risco)}
                disabled={risco.id === '14' && true}
              />
            </BotaoGrupo>
        </td>
          </>
        )}
      </tr>

      {editando && (
        <ModalRisco
          show={editando}
          setShow={setEditando}
          titulo={`Editar: ${risco.riscos}`}
          corTitulo="primary"
          risco={risco}
          buscarRiscos={buscarRiscos}
          apreRiscos={apreRiscos}
        />
      )}
    </>
  );
};

export default ListaRisco;
