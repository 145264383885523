import React, { useEffect, useState } from "react";
import Select from "react-select";

import { ModalSubmit } from "../../../../components/ModalPadrao";
import Loader from "../../../../components/Loader";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";

const ModalPerigo = ({ show, setShow, titulo, corTitulo, perigo, buscarPerigos, novoPerigo, aprePerigo }) => {
  const [salvarPerigo, setSalvarPerigo] = useState(perigo ? perigo.definicao : novoPerigo);
  const [codigo, setCodigo] = useState(perigo && perigo.codigo);
  const [perigoGrupoId, setPerigoGrupoId] = useState(perigo && perigo.perigo_grupo_id);
  const [gruposPerigo, setGruposPerigo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    buscarGruposPerigo();
  }, []);

  async function buscarGruposPerigo() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarGruposPerigo")
      .then((resposta) => {
        setGruposPerigo(
          resposta.data?.map((item) => ({
            value: item.id,
            label: item.grupo,
          }))
        );
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }

  async function salvarBanco(e) {
    e.preventDefault();

    if (perigo) {
      api
        .put(`/baseDados/ergonomiaBaseDados/editarPerigoVinculos/${perigo.id}`, {
          perigo_grupo_id: perigoGrupoId,
          codigo: codigo,
          definicao: salvarPerigo,
        })
        .then(() => {
          buscarPerigos();
          setShow(false);
        });
    } else {
      api
        .post("/baseDados/ergonomiaBaseDados/inserirPerigo", {
          perigo_grupo_id: perigoGrupoId,
          codigo: codigo,
          definicao: salvarPerigo,
        })
        .then(() => {
          buscarPerigos();
          setShow(false);
        });
    }
  }

  return (
    <>
      <ModalSubmit
        show={show}
        setShow={setShow}
        titulo={titulo}
        corTitulo={corTitulo}
        tamanho="md"
        onSubmit={(e) => salvarBanco(e)}
      >
        {isEmpty(gruposPerigo) && loading ? (
          <Loader />
        ) : (
          <div className="mb-3">
            <label>Código:</label>
            <input
              type="text"
              id="codigo"
              name="codigo"
              className="form-control"
              defaultValue={perigo && perigo.codigo}
              onChange={(p) => {
                setCodigo(p.target.value);
              }}
              required
            />

            <label htmlFor="perigoGrupo" className="form-label">
              Perigo Grupo:
            </label>
            <Select
              placeholder="Selecione Perigo Grupo"
              name="perigo grupo"
              id="perigoGrupo"
              defaultValue={
                perigo && {
                  value: perigo.perigo_grupo_id,
                  label: gruposPerigo.find((grupoPerigo) => grupoPerigo.value === perigo.perigo_grupo_id).label,
                }
              }
              onChange={(option) => {
                setPerigoGrupoId(option.value);
              }}
              options={gruposPerigo}
              required
            />

            <label>Perigo:</label>
            <input
              type="text"
              id="perigo"
              name="perigo"
              className="form-control"
              defaultValue={perigo ? salvarPerigo : novoPerigo}
              onChange={(p) => {
                setSalvarPerigo(p.target.value);
              }}
              required
            />
          </div>
        )}
      </ModalSubmit>
    </>
  );
};

export default ModalPerigo;
