import React from "react";

import Filter from "../../../components/Filter";

const Filtros = ({ itens, filterType }) => {
  return (
    <div className="row mb-2">
      {itens.map((item, index) => (
        <div key={index} className="col">
          <Filter
            id={index}
            placeholder={item.placeholder}
            handleFilter={(e) => item.filtrar(e.target.value)}
            type={!filterType && "text"}
            atualizarItens={item.atualizarItens}
            botaoBaseDados={item.botaoBaseDados}
            info={item.info}
            botaoAdicional={item.botaoAdicional}
            defaultValue={item.filtro}
          />
        </div>
      ))}
    </div>
  );
};

export default Filtros;
