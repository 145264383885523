import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-bootstrap";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { BotaoAdicionar, BotaoDeletar, BotaoEditar, BotaoGerenciar } from "../../../../components/Botao";
import { filtrar } from "../../../../components/Util";
import ModalPadrao, { ModalSubmit } from "../../../../components/ModalPadrao";
import Filtros from "../../../ppra/components/Filtros";
import api from "../../../../service/api";
import Loader from "../../../../components/Loader";

const ListaGheAcidente = ({ gruposGhe, permissaoEditar, obterDados }) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={gruposGhe}
        colunas={[
          { nome: "Nome", width: "15%", text: "center" },
          { nome: "Perigos", width: "50%", text: "center" },
          { nome: "Tipo Serviço", width: "10%", text: "center" },
          permissaoEditar && { nome: "Ações", width: "3%", text: "center" },
        ]}
      >
        {gruposGhe.map((item) => (
          <GrupoGhe index={item.id} grupo={item} obterDados={obterDados} permissaoEditar={permissaoEditar} />
        ))}
      </TabelaPadrao>
    </>
  );
};

const GrupoGhe = ({ grupo, obterDados, index, permissaoEditar }) => {
  const [editando, setEditando] = useState(false);
  const [gerenciar, setGerenciando] = useState(false);

  function deletar() {
    api.delete(`/baseDados/acidenteBaseDados/deletarGrupoGheAcidente/${grupo.id}`).then(() => {
      obterDados();
    });
  }

  return (
    <React.Fragment key={index}>
      <tr order={index}>
        <td>{grupo.nome}</td>
        <td>
          {grupo.perigos.map((item) => (
            <ul>
              <li style={{ listStyleType: "none" }}>
                {item.codigo}) {item.definicao}
              </li>
            </ul>
          ))}
        </td>
        <td className="text-center">{grupo.tipo_servico === "A" ? "Administrativo" : "Operacional"}</td>
        {permissaoEditar && (
          <td>
            <td className="text-center">
              <ol style={{ listStyleType: "none" }}>
                <li>
                  <BotaoEditar onClick={() => setEditando(true)} />
                </li>
                <li className="mt-2">
                  <BotaoGerenciar onClick={() => setGerenciando(true)} />
                </li>
                <li className="mt-2">
                  <BotaoDeletar onClick={() => deletar()} />
                </li>
              </ol>
            </td>
          </td>
        )}
      </tr>
      {editando && (
        <ModalEditar
          show={editando}
          setShow={setEditando}
          obterDados={obterDados}
          grupo={grupo}
          corTitulo="primary"
          titulo={`Editar: ${grupo.nome}`}
        />
      )}
      {gerenciar && (
        <ModalGerenciar
          show={gerenciar}
          setShow={setGerenciando}
          buscarDados={obterDados}
          grupo={grupo}
          corTitulo="primary"
          titulo={`Editar: ${grupo.nome}`}
        />
      )}
    </React.Fragment>
  );
};

const ModalEditar = ({ grupo, obterDados, show, setShow }) => {
  const [nome, setNome] = useState();
  const [tipoServico, setTipoServico] = useState();

  function salvarDados() {
    api
      .put(`/baseDados/acidenteBaseDados/editarGrupoGheAcidente/${grupo.id}`, {
        nome: nome,
        tipo_servico: tipoServico,
      })
      .then(() => {
        obterDados();
        setShow(false);
      });
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      tamanho={"md"}
      onSubmit={(e) => salvarDados(e)}
      titulo={`Editar: ${grupo.nome}`}
    >
      <div className="row">
        <div className="col">
          <label htmlFor="nomeghe"> Nome: </label>
          <input
            type="text"
            className="form-control"
            id="nomeghe"
            defaultValue={grupo?.nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label htmlFor="adm">Administrativo</label>
          <input
            className="form-check-input ml-2"
            type="radio"
            name="tipo_servico"
            id="adm"
            defaultChecked={grupo?.tipo_servico === "A"}
            onChange={(e) => setTipoServico("A")}
          />
          <label htmlFor="op" className="ml-4">
            Operacional
          </label>
          <input
            className="form-check-input ml-2"
            type="radio"
            name="tipo_servico"
            id="op"
            defaultChecked={grupo?.tipo_servico === "O"}
            onChange={(e) => setTipoServico("O")}
          />
        </div>
      </div>
    </ModalSubmit>
  );
};

const ModalGerenciar = ({ show, setShow, grupo, buscarDados }) => {
  const [perigo, setPerigo] = useState();
  const [filtroPerigo, setFiltroPerigo] = useState("");
  const [filtroPerigos, setFiltroPerigos] = useState("");
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    api.get(`/baseDados/acidenteBaseDados/buscarPerigoAcidente?grupoGhe_id=${grupo.id}`).then((resposta) => {
      setPerigo(resposta.data);
      setCarregando(false);
    });
  }, [grupo.id]);

  function obterDados() {
    api.get(`/baseDados/acidenteBaseDados/buscarPerigoAcidente?grupoGhe_id=${grupo.id}`).then((resposta) => {
      setPerigo(resposta.data);
      setCarregando(false);
    });
  }

  let perigosCadastrados = perigo?.perigos?.filter(
    (filtro) => filtrar(filtro.definicao, filtroPerigo) || filtrar(filtro.codigo, filtroPerigo)
  );

  function filtrarPerigo(newFilter) {
    setFiltroPerigo(newFilter);
  }

  let PerigosNaoCadastrados = perigo?.nao_cadastrado?.filter(function (filtro) {
    return (
      perigo?.perigos?.filter(function (item) {
        return item?.perigo_definicao_id === filtro?.id;
      }).length === 0
    );
  });

  let perigos = PerigosNaoCadastrados?.filter(
    (filtro) => filtrar(filtro.definicao, filtroPerigos) || filtrar(filtro.codigo, filtroPerigos)
  );

  function filtrarPerigos(newFilter) {
    setFiltroPerigos(newFilter);
  }

  return (
    <ModalPadrao handleHide={buscarDados} show={show} setShow={setShow} titulo={`Gerenciar Grupo ${grupo.nome}`}>
      <Filtros
        itens={[
          { placeholder: "Não Cadastrados", filtrar: filtrarPerigos },
          { placeholder: "Cadastrados", filtrar: filtrarPerigo },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: "N°", text: "center" },
              { nome: "Perigos não cadastrados:" },
              { nome: "Ações", width: "3%", text: "center" },
            ]}
            itens={perigos}
            itensFiltrados={perigos}
            filtro={filtroPerigos}
            carregando={carregando}
          >
            {carregando ? (
              <Loader />
            ) : (
              <>
                {perigos?.map((item, index) => (
                  <Perigo
                    key={index}
                    item={item}
                    grupo={grupo}
                    obterDados={obterDados}
                    carregando={setCarregando}
                    adiciona
                  />
                ))}
              </>
            )}
          </TabelaPadrao>
        </div>
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: "N°", width: "7%", text: "center" },
              { nome: "Perigos Cadastrados:" },
              { nome: "Ações", width: "3%", text: "center" },
            ]}
            itens={perigosCadastrados}
            itensFiltrados={perigosCadastrados}
            filtro={filtroPerigo}
            carregando={carregando}
          >
            {carregando ? (
              <Loader />
            ) : (
              <>
                {perigosCadastrados?.map((item, index) => (
                  <Perigo key={index} item={item} grupo={grupo} obterDados={obterDados} setCarregando={setCarregando} />
                ))}
              </>
            )}
          </TabelaPadrao>
          <ToastContainer />
        </div>
      </div>
    </ModalPadrao>
  );
};

const Perigo = ({ item, grupo, obterDados, carregando, adiciona }) => {
  function inserirPerigo(item) {
    carregando(true);
    api
      .post(`/baseDados/acidenteBaseDados/inserirPerigoGheAcidente`, {
        perigo_definicao_id: item.id,
        ghe_grupo_acidente_id: grupo.id,
      })
      .then(() => {
        carregando(false);
        obterDados();
      });
  }

  function deletarPerigo(item) {
    api
      .delete(`/baseDados/acidenteBaseDados/deletarPerigoGheGrupoAcidente/${item.ghe_grupo_acidente_perigo_id}`)
      .then(() => {
        obterDados();
      });
  }

  return (
    <>
      <tr>
        <td className="text-center">{item.codigo}</td>
        <td>{item.definicao}</td>
        <td>
          {adiciona ? (
            <div className="row">
              <div className="col">
                <BotaoAdicionar title="Cadastrar perigo" onClick={() => inserirPerigo(item)} />
              </div>
            </div>
          ) : (
            <BotaoDeletar title="Deletar perigo" onClick={() => deletarPerigo(item)} />
          )}
        </td>
      </tr>
    </>
  );
};

export default ListaGheAcidente;
