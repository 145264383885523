import React, { useState } from "react";
import Select from "react-select";

import { ModalSubmit } from "../../../components/ModalPadrao";
import { isEmpty } from "../../../components/Util";
import api from "../../../service/api";

const ModalFormasRisco = ({
  show,
  setShow,
  titulo,
  corTitulo,
  formaRisco,
  buscarFormaRisco,
  novaFormaRisco,
  formasRiscoTipo,
  riscosFisicoQuimicoBiologico,
}) => {
  const [salvarFormaRisco, setSalvarFormaRisco] = useState(formaRisco ? formaRisco.definicao : novaFormaRisco);
  const [riscoId, setRiscoId] = useState(formaRisco && riscosFisicoQuimicoBiologico && formaRisco.risco_id);
  const [codigo, setCodigo] = useState(formaRisco && riscosFisicoQuimicoBiologico && formaRisco.risco_id);

  async function salvarBanco(e) {
    e.preventDefault();
    if (!isEmpty(salvarFormaRisco)) {
      let dados;

      riscosFisicoQuimicoBiologico
        ? (dados = {
            codigo: codigo,
            definicao: salvarFormaRisco,
            risco_id: riscoId,
            reconhecimento: "",
          })
        : (dados = {
            definicao: salvarFormaRisco,
          });

      var editar;
      var inserir;

      switch (formasRiscoTipo) {
        case 1:
          editar = "editarFormasRisco";
          inserir = "inserirFormasRisco";
          break;
        case 2:
          editar = "editarFormasRiscoErgonomia";
          inserir = "inserirFormasRiscoErgonomia";
          break;
        default:
          break;
      }

      let url = formaRisco
        ? api.put(`/baseDados/formasRisco/${editar}/${formaRisco.id}`, dados)
        : api.post(`/baseDados/formasRisco/${inserir}`, dados);

      await url
        .then(() => {
          buscarFormaRisco();
          setShow(false);
        })
        .catch((erro) => console.log(erro));
    }
  }

  return (
    <>
      <ModalSubmit show={show} setShow={setShow} titulo={titulo} corTitulo={corTitulo} tamanho="md" onSubmit={(e) => salvarBanco(e)}>
        <div className="mb-3">
          {riscosFisicoQuimicoBiologico && (
            <>
              <div className="mb-3">
                <label>Código</label>
                <input
                  maxLength={"10"}
                  type="text"
                  id="codigo"
                  name="codigo"
                  className="form-control"
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                  defaultValue={
                    formaRisco?.codigo
                  }
                />
              </div>

              <div className="mb-3">
                <label>Risco</label>
                <Select
                  placeholder="Selecione Risco"
                  name="risco"
                  id="risco"
                  defaultValue={
                    formaRisco && {
                      value: formaRisco.risco_id,
                      label: riscosFisicoQuimicoBiologico.find((risco) => risco.value === formaRisco.risco_id).label,
                    }
                  }
                  onChange={(option) => {
                    setRiscoId(option.value);
                  }}
                  options={riscosFisicoQuimicoBiologico}
                  required
                />
              </div>
            </>
          )}

          <label>Forma de Risco</label>
          <input
            type="text"
            id="formaRisco"
            name="forma risco"
            className="form-control"
            defaultValue={formaRisco ? salvarFormaRisco : novaFormaRisco}
            onChange={(f) => {
              setSalvarFormaRisco(f.target.value);
            }}
            required
          />
        </div>
      </ModalSubmit>
    </>
  );
};

export default ModalFormasRisco;
