import React, { useEffect, useState } from "react";

import { BotaoAdicionar, BotaoRemover } from "../../../../components/Botao";
import ModalPadrao from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import Filtros from "../../components/Filtros";

var quimicosId = [];

const AdicionarLimitesPpraAmbiente = ({
  showAdicionaLimitePpraAmbiente,
  setShowAdicionaLimitePpraAmbiente,
  formaRisco,
  ambiente,
  carregarAmbientes,
  setor,
  observacao,
}) => {
  const [limites, setLimitesAmbiente] = useState([]);
  const [limitesPpraAmbiente, setLimitesPpraAmbiente] = useState([]);

  const [filtroLimitesAmbiente, setFiltroLimitesAmbiente] = useState("");
  const [filtroLimitesPpraAmbiente, setFiltroLimitesPpraAmbiente] =
    useState("");

  const [carregandoLimitesAmbiente, setCarregandoLimitesAmbiente] =
    useState(true);
  const [carregandoLimitesPpraAmbiente, setCarregandoLimitesPpraAmbiente] =
    useState(true);

  let limitesFiltrados = limites.filter((limite) =>
    filtrar(limite.elemento_nome, filtroLimitesAmbiente)
  );

  let limitesPpraFiltradosAmbiente = limitesPpraAmbiente.filter((limitePpra) =>
    filtrar(limitePpra.elemento_nome, filtroLimitesPpraAmbiente)
  );

  function filtrarLimitesAmbiente(newFilter) {
    setFiltroLimitesAmbiente(newFilter);
  }

  function filtrarLimitesPpraAmbiente(newFilter) {
    setFiltroLimitesPpraAmbiente(newFilter);
  }

  var quimicosIdRepetidos = [];
  ambiente.quimicos.map((quimico) =>
    quimicosIdRepetidos.push(quimico.produto_quimico_id)
  );

  quimicosId = quimicosIdRepetidos.filter(function (quimico, i) {
    return quimicosIdRepetidos.indexOf(quimico) === i;
  });

  useEffect(() => {
    carregarLimitesAmbiente(
      ambiente.local_quadro_id,
      formaRisco.id,
      quimicosId
    );
    carregarLimitesPpraAmbiente(
      ambiente.local_quadro_id,
      formaRisco.id,
      quimicosId
    );
  }, [ambiente.local_quadro_id, formaRisco.id]);

  async function carregarLimitesAmbiente(
    localQuadroId,
    formaRiscoId,
    quimicosId
  ) {
    await api
      .get(`/ppra/limite/obterLimites`, {
        params: {
          local_quadro_id: localQuadroId,
          forma_risco_id: formaRiscoId,
          quimicosId: quimicosId,
        },
      })
      .then((resposta) => {
        setLimitesAmbiente(resposta.data);
        setCarregandoLimitesAmbiente(false);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  async function carregarLimitesPpraAmbiente(
    localQuadroId,
    formaRiscoId,
    quimicosId
  ) {
    await api
      .get(`/ppra/limite/obterLimitesReconhecimento`, {
        params: {
          local_quadro_id: localQuadroId,
          forma_risco_id: formaRiscoId,
          quimicosId: quimicosId,
        },
      })
      .then((resposta) => {
        setLimitesPpraAmbiente(resposta.data);
        setCarregandoLimitesPpraAmbiente(false);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  return (
    <ModalPadrao
      show={showAdicionaLimitePpraAmbiente}
      setShow={setShowAdicionaLimitePpraAmbiente}
      id={"modalAdicionaLimitesAmbiente-" + ambiente.local_quadro_id}
      titulo={"Adicionar limites"}
      handleHide={() =>
        carregarAmbientes(
          setor.setor_id,
          setor.ppra_id,
          formaRisco.risco_id,
          formaRisco.id
        )
      }
    >
      <Filtros
        itens={[
          {
            placeholder: "Limites não adicionados",
            filtrar: filtrarLimitesAmbiente,
            atualizarItens: () => {
              setCarregandoLimitesAmbiente(true);
              carregarLimitesAmbiente(
                ambiente.local_quadro_id,
                formaRisco.id,
                quimicosId
              );
            },
            carregando: carregandoLimitesAmbiente,
          },
          {
            placeholder: "Limites adicionados",
            filtrar: filtrarLimitesPpraAmbiente,
            carregando: carregandoLimitesPpraAmbiente,
          },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaPadrao
            colunas={[
              {
                nome: (
                  <>
                    Nome do elemento
                    <span className="badge bg-primary ml-2">
                      Tipo de limite
                    </span>
                  </>
                ),
              },
              { nome: "Ação", text: "center", width: "10%" },
            ]}
            itens={limites}
            itensFiltrados={limitesFiltrados}
            filtro={filtroLimitesAmbiente}
            carregando={carregandoLimitesAmbiente}
          >
            {limites.map((limite, index) => (
              <Limite
                key={index}
                limite={limite}
                index={index}
                adiciona
                limites={limitesFiltrados}
                observacao={observacao}
                ambiente={ambiente}
                formaRiscoId={formaRisco.id}
                carregarLimitesPpraAmbiente={carregarLimitesPpraAmbiente}
                carregarLimitesAmbiente={carregarLimitesAmbiente}
                setCarregandoLimitesPpraAmbiente={
                  setCarregandoLimitesPpraAmbiente
                }
                setCarregandoLimitesAmbiente={setCarregandoLimitesAmbiente}
                quimicosId={quimicosId}
              />
            ))}
          </TabelaPadrao>
        </div>
        <div className="col">
          <TabelaPadrao
            colunas={[
              {
                nome: (
                  <>
                    Nome do elemento
                    <span className="badge bg-primary ml-2">
                      Tipo de limite
                    </span>
                  </>
                ),
              },
              { nome: "Ações", text: "center", width: "16%" },
            ]}
            itens={limitesPpraAmbiente}
            itensFiltrados={limitesPpraFiltradosAmbiente}
            filtro={filtroLimitesPpraAmbiente}
            carregando={carregandoLimitesPpraAmbiente}
          >
            {limitesPpraFiltradosAmbiente.map((limite, index) => (
              <Limite
                key={index}
                limite={limite}
                index={index}
                limites={limitesPpraFiltradosAmbiente}
                ambiente={ambiente}
                formaRiscoId={formaRisco.id}
                carregarLimitesPpraAmbiente={carregarLimitesPpraAmbiente}
                carregarLimitesAmbiente={carregarLimitesAmbiente}
                setCarregandoLimitesPpraAmbiente={
                  setCarregandoLimitesPpraAmbiente
                }
                setCarregandoLimitesAmbiente={setCarregandoLimitesAmbiente}
                quimicosId={quimicosId}
              />
            ))}
          </TabelaPadrao>
        </div>
      </div>
    </ModalPadrao>
  );
};

const Limite = ({
  limite,
  index,
  adiciona,
  ambiente,
  formaRiscoId,
  carregarLimitesPpraAmbiente,
  carregarLimitesAmbiente,
  setCarregandoLimitesPpraAmbiente,
  setCarregandoLimitesAmbiente,
  quimicosId,
}) => {
  async function adicionarLimitePpra(
    ambiente,
    limiteId,
    formaRiscoId,
    quimicosId
  ) {
    var item = {
      limite_id: limiteId,
      local_quadro_id: ambiente.local_quadro_id,
      forma_risco_id: formaRiscoId,
    };

    item.produto_quimico_id = ambiente.quimicos.map(
      (quimico) => quimico.produto_quimico_id
    );
    item.tarefa_id = ambiente.quimicos.map((quimico) =>
      quimico.tarefa_id ? quimico.tarefa_id : null
    );

    setCarregandoLimitesPpraAmbiente(true);
    setCarregandoLimitesAmbiente(true);
    await api
      .post("ppra/limite/inserir", item)
      .then(() => {
        carregarLimitesPpraAmbiente(
          ambiente.local_quadro_id,
          formaRiscoId,
          quimicosId
        );
        carregarLimitesAmbiente(
          ambiente.local_quadro_id,
          formaRiscoId,
          quimicosId
        );
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
      });
  }

  async function deletarLimitePpra(limite, ambiente, formaRiscoId, quimicosId) {
    if (
      window.confirm(
        `Tem certeza que deseja remover a limite: ${limite.elemento_nome} deste quimico?`
      )
    ) {
      setCarregandoLimitesAmbiente(true);
      setCarregandoLimitesPpraAmbiente(true);
      var url = `ppra/limite/deletar/${limite.limite_reconhecimento_id}`;
      if (limite.limite_reconhecimento_id.includes(",")) {
        limite.limite_reconhecimento_id =
          limite.limite_reconhecimento_id.split(",");
        var params = "";
        limite.limite_reconhecimento_id.map(
          (limiteId) => (params += `limitesId[]=${limiteId}&`)
        );
        url = `ppra/limite/deletar?${params}`;
      }
      await api
        .delete(url)
        .then(() => {
          carregarLimitesPpraAmbiente(
            ambiente.local_quadro_id,
            formaRiscoId,
            quimicosId
          );
          carregarLimitesAmbiente(
            ambiente.local_quadro_id,
            formaRiscoId,
            quimicosId
          );
        })
        .catch((erro) => {
          alert(erro.response.data.mensagem);
          setCarregandoLimitesAmbiente(false);
          setCarregandoLimitesPpraAmbiente(false);
        });
    }
  }

  return (
    <>
      <tr key={index}>
        <td>
          {limite.elemento_nome} {limite.nomeNorma} ({limite.nomeParteNorma}) -{" "}
          {limite.valor}
          {limite.quantidade && " (" + limite.quantidade + ")"}
          {limite.nomeTipoLimite && (
            <span className="badge bg-primary ml-2">
              {limite.nomeTipoLimite}
            </span>
          )}
        </td>
        <td>
          <div className="text-center">
            {adiciona ? (
              <BotaoAdicionar
                title="Adicionar limite"
                onClick={() =>
                  adicionarLimitePpra(
                    ambiente,
                    limite.limite_id,
                    formaRiscoId,
                    quimicosId
                  )
                }
              />
            ) : (
              <BotaoRemover
                title="Remover limite"
                onClick={() =>
                  deletarLimitePpra(limite, ambiente, formaRiscoId, quimicosId)
                }
              />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default AdicionarLimitesPpraAmbiente;
