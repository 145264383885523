import React from "react";
import { Modal } from "react-bootstrap";

import { BotaoSalvar } from "./Botao";
import SpanBadge from "./SpanBadge";

const ModalPadrao = ({
  show,
  setShow,
  tamanho,
  tituloSpan,
  modalCentralizada,
  corTitulo,
  titulo,
  children,
  handleHide,
  animation,
  footer = false,
  noHideOnEsc = false,
  backgroundColor = "#FFFFFF",
}) => {
  function handleClose() {
    setShow(false);
    handleHide && handleHide();
  }

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      // background-color
      size={tamanho ? tamanho : "xl"}
      aria-labelledby="example-custom-modal-styling-title"
      animation={animation ? true : false}
      centered={modalCentralizada}
      backdrop={noHideOnEsc ? "static" : true}
      keyboard={noHideOnEsc ? false : true}
    >
      <Modal.Header closeButton style={{ backgroundColor: backgroundColor }}>
        <Modal.Title id="example-custom-modal-styling-title">
          {titulo} {tituloSpan && <span className={`badge bg-${corTitulo ? corTitulo : "primary"}`}>{tituloSpan}</span>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: backgroundColor }}>{children}</Modal.Body>
      {footer && <Modal.Footer style={{ backgroundColor: backgroundColor }}>{footer}</Modal.Footer>}
    </Modal>
  );
};

export const ModalSubmit = ({
  show,
  setShow,
  tamanho,
  tituloSpan,
  tamanhoSpan,
  corTitulo,
  modalCentralizada,
  titulo,
  children,
  onSubmit,
  disabled,
  isLoading,
  handleHide,
  animation,
}) => {
  if (!tamanhoSpan) {
    switch (tamanho) {
      case "sm":
        tamanhoSpan = "22";
        break;
      case "md":
        tamanhoSpan = "40";
        break;
      case "lg":
        tamanhoSpan = "69";
        break;
      case "xl":
        tamanhoSpan = "103";
        break;

      default:
        break;
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        handleHide && handleHide();
      }}
      size={tamanho ? tamanho : "xl"}
      aria-labelledby="example-custom-modal-styling-title"
      animation={animation ? true : false}
      centered={modalCentralizada}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {titulo} {tituloSpan && <SpanBadge texto={tituloSpan} cor={corTitulo} tamanho={tamanhoSpan} />}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>{onSubmit && <BotaoSalvar disabled={disabled} isLoading={isLoading} temConteudo />}</Modal.Footer>
      </form>
    </Modal>
  );
};

export const ModalSucesso = ({ show, setShow, mensagem }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="sm"
      aria-labelledby="example-custom-modal-styling-title"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <span className="text-center" style={{ fontSize: "30px", color: "green" }}>
            <i className="far fa-check-circle"></i> Salvo
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{mensagem}</Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={() => setShow(false)}
          className="btn btn-success btn-block btn-sm"
          data-dismiss="modal"
        >
          <i className="fa fa-check" aria-hidden="true"></i> OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalErro = ({ show, setShow, mensagem }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="sm"
      aria-labelledby="example-custom-modal-styling-title"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <span className="text-center" style={{ fontSize: "30px", color: "red" }}>
            <i className="far fa-times-circle"></i> Erro ao salvar
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{mensagem}</Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={() => setShow(false)}
          className="btn btn-danger btn-block btn-sm"
          data-dismiss="modal"
        >
          <i className="fa fa-times" aria-hidden="true"></i> OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPadrao;
