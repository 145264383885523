import React, { useState } from "react";

import { BotaoAdicionar } from "../../../../components/Botao";
import { isEmpty } from "../../../../components/Util";
import AdicionarLimitesPpraAmbiente from "./AdicionarLimitesPpraAmbiente";

export default function LimiteAmbiente({ ambiente, ...other }) {
  const [
    showAdicionaLimitePpraAmbiente,
    setShowAdicionaLimitePpraAmbiente,
  ] = useState(false);

  return (
    <>
    <div className="row mb-2">
      <div className="col-1">
      <BotaoAdicionar
          className="mr-1"
          onClick={() => setShowAdicionaLimitePpraAmbiente(true)}
        />
      </div>

      <div className="col-auto gx-0">
        <span className="align-text-bottom col-3 text-muted p-0">Limites</span>
        
    </div>

    <div className="col-auto gx-0">
          {!isEmpty(ambiente.limites_reconhecimento) &&
            ambiente.limites_reconhecimento.map((limite) => (
              <span
                key={limite.limite_reconhecimento_id}
                className="badge bg-primary mr-1"
              >
                {limite.elemento_nome} {limite.nomeNorma} ({limite.nomeParteNorma}
                ) - {limite.valor} -{" "}
                {limite.nomeTipoLimite && limite.nomeTipoLimite} (
                {limite.quantidade})
              </span>
            ))}

    </div>
        
      </div>
      {showAdicionaLimitePpraAmbiente && (
        <AdicionarLimitesPpraAmbiente
          showAdicionaLimitePpraAmbiente={showAdicionaLimitePpraAmbiente}
          setShowAdicionaLimitePpraAmbiente={setShowAdicionaLimitePpraAmbiente}
          ambiente={ambiente}
          {...other}
        />
      )}
    </>
  );
}
