import React, { useEffect, useState } from "react";

import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";
import ListaPerigo from "./ListaPerigo";
import ModalPerigo from "./ModalPerigo";

const Perigo = ({ permissaoEditar }) => {
  const [perigos, setPerigos] = useState([]);
  const [gruposPerigo, setGruposPerigo] = useState([]);
  const [aprePerigo, setAprePerigo] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "Perigo";

  useEffect(() => {
    buscarPerigos();
    buscarGruposPerigo();
    buscarAprePerigo();
  }, []);
  const perigosFiltrados = perigos.filter(
    (perigo) =>
      filtrar(perigo.codigo, filtro) || filtrar(perigo.perigo_grupo_id, filtro) || filtrar(perigo.definicao, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarPerigos() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarPerigos")
      .then((resposta) => {
        setPerigos(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarGruposPerigo() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarGruposPerigo")
      .then((resposta) => {
        setGruposPerigo(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarAprePerigo() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarAprePerigo")
      .then((resposta) => {
        setAprePerigo(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={perigosFiltrados}
        totalItens={perigos.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={() => {
          permissaoEditar && setShowModalCadastro(true);
        }}
      >
        {(currentItems) => (
          <ListaPerigo
            perigos={currentItems}
            gruposPerigo={gruposPerigo}
            buscarPerigos={buscarPerigos}
            aprePerigo={aprePerigo}
            permissaoEditar={permissaoEditar}
          />
        )}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalPerigo
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Perigo"
          corTitulo="success"
          buscarPerigos={buscarPerigos}
          novoPerigo={filtro}
          aprePerigo={aprePerigo}
        />
      )}
    </>
  );
};

export default Perigo;
