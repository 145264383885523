import React, { useEffect, useState } from "react";

import Icone from "./Icone";

const ScrollTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 300) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  return (
    <div
      className={`position-fixed bottom-0 end-0 p-3 ${
        showScroll ? "visible" : "invisible"
      }`}
    >
      <span onClick={scrollTop}>
        <Icone
          style={{ cursor: "pointer" }}
          className="fas fa-3x text-info"
          icone="arrow-alt-circle-up"
        />
      </span>
    </div>
  );
};

export default ScrollTopButton;
