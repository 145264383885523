import React, { useState } from "react";

import { BotaoAdicionar, BotaoEditar } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { isEmpty } from "../../../../components/Util";
import ModalPerigoRisco from "./ModalPerigoRisco";

const ListaPerigoRisco = ({ perigosRiscos, buscarPerigosRiscos, permissaoEditar }) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={perigosRiscos}
        colunas={[
          { nome: "Código", width: "5%", text: "center" },
          { nome: "Perigo Grupo", width: "15%" },
          { nome: "Perigo" },
          { nome: "Riscos" },
          permissaoEditar && { nome: "Ações", width: "8%", text: "center" },
        ]}
      >
        {perigosRiscos.map((perigoRisco) => (
          <PerigoRisco
            key={perigoRisco.id}
            perigoRisco={perigoRisco}
            buscarPerigosRiscos={buscarPerigosRiscos}
            permissaoEditar={permissaoEditar}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const PerigoRisco = ({ perigoRisco, buscarPerigosRiscos, permissaoEditar }) => {
  const [editando, setEditando] = useState(false);
  const [showModalCadastro, setShowModalCadastro] = useState(false);

  return (
    <>
      <tr key={perigoRisco.id}>
        <td className="text-center align-middle">{perigoRisco.codigo}</td>
        <td className="align-middle">{perigoRisco.grupo}</td>
        <td className="align-middle">{perigoRisco.definicao}</td>
        <td className="align-middle">
          {isEmpty(perigoRisco.riscos) ? (
            <b>Nenhum risco vinculado.</b>
          ) : (
            perigoRisco.riscos.map((risco) =>
              perigoRisco.riscos[perigoRisco.riscos.length - 1] !== risco ? (
                <span key={risco.id}>
                  {risco.riscos}
                  {", "}
                </span>
              ) : (
                <span key={risco.id}>
                  {risco.riscos}
                  {"."}
                </span>
              )
            )
          )}
        </td>
        {permissaoEditar && (
          <>
            <td className="text-center align-middle">
              {isEmpty(perigoRisco.riscos) ? (
                <BotaoAdicionar onClick={() => setShowModalCadastro(true)} disabled={perigoRisco.id === "59" && true} />
              ) : (
                <BotaoEditar onClick={() => setEditando(true)} disabled={perigoRisco.id === "59" && true} />
              )}
            </td>
          </>
        )}
      </tr>
      {editando && (
        <ModalPerigoRisco
          show={editando}
          setShow={setEditando}
          titulo={"Editar vinculos Perigo x Risco"}
          corTitulo="primary"
          perigoRisco={perigoRisco}
          buscarPerigosRiscos={buscarPerigosRiscos}
        />
      )}
      {showModalCadastro && (
        <ModalPerigoRisco
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo={"Cadastrar vinculos Perigo x Risco"}
          corTitulo="success"
          perigoRisco={perigoRisco}
          buscarPerigosRiscos={buscarPerigosRiscos}
        />
      )}
    </>
  );
};

export default ListaPerigoRisco;
