import React, { useState } from "react";

import Loader from "../../../components/Loader";
import TabelaMatematica from "./TabelaMatematica";

const Ambientes = ({
  ambiente,
  indexAmbiente,
  formaRisco,
  setor,
  carregarAmbientes,
  medidasOrganizacionaisTrabalho,
}) => {
  const [isQuimico] = useState(formaRisco.risco_id === "2");
  const [carregandoAmbientes, setCarregandoAmbientes] = useState(false);

  if (carregandoAmbientes) {
    return <Loader />;
  }

  return (
    <TabelaMatematica
      itemNome={isQuimico ? "Químico" : "Atividade"}
      index={indexAmbiente}
      setor={setor}
      ambiente={ambiente}
      atividades={ambiente.quimicos ? ambiente.quimicos : ambiente.atividades}
      formaRisco={formaRisco}
      isQuimico={ambiente.quimicos ? true : false}
      carregarAmbientes={carregarAmbientes}
      setCarregandoAmbientes={setCarregandoAmbientes}
      indexAmbiente={indexAmbiente}
      medidasOrganizacionaisTrabalho={medidasOrganizacionaisTrabalho}
    />
  );
};

export default Ambientes;
