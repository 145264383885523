import React, { useState } from "react";

import { BotaoAdicionar } from "../../../../components/Botao";
import { isEmpty } from "../../../../components/Util";
import AdicionarLimitesPpra from "./AdicionarLimitesPpra";

const Limite = ({ quimico, ...other }) => {
  const [showAdicionaLimitePpra, setShowAdicionaLimitePpra] = useState(false);

  return (
    <div className="row mb-2">
      <div className="col-1">
        <BotaoAdicionar onClick={() => setShowAdicionaLimitePpra(true)} />
      </div>
      <div className="col-1 gx-0">
        <span className="text-muted">Limites</span>
      </div>
      <div className="col gx-0 ">
        {!isEmpty(quimico.limites_reconhecimento) &&
          quimico.limites_reconhecimento.map((limite) => (
            <span
              key={limite.limite_reconhecimento_id}
              className="badge bg-primary mr-1"
            >
              {limite.elemento_nome} {limite.nomeNorma} ({limite.nomeParteNorma}
              ) - {limite.valor}
              {limite.nomeTipoLimite && " - " + limite.nomeTipoLimite}
            </span>
          ))}
      </div>
      {showAdicionaLimitePpra && (
        <AdicionarLimitesPpra
          showAdicionaLimitePpra={showAdicionaLimitePpra}
          setShowAdicionaLimitePpra={setShowAdicionaLimitePpra}
          quimico={quimico}
          {...other}
        />
      )}
    </div>
  );
};

export default Limite;
