import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-bootstrap";
import Select from "react-select";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar } from "../../../../components/Util";
import { BotaoAdicionar, BotaoDeletar, BotaoEditar, BotaoGerenciar } from "../../../../components/Botao";
import ModalPadrao, { ModalSubmit } from "../../../../components/ModalPadrao";
import Filtros from "../../../ppra/components/Filtros";
import api from "../../../../service/api";
import Loader from "../../../../components/Loader";

const ListaGhe = ({ gruposGhe, obterDados, permissaoEditar }) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={gruposGhe}
        colunas={[
          { nome: "Nome", width: "15%", text: "center" },
          { nome: "Perigos", width: "50%", text: "center" },
          { nome: "Tipo Serviço", width: "10%", text: "center" },
          permissaoEditar && { nome: "Ações", width: "3%", text: "center" },
        ]}
      >
        {gruposGhe.map((item) => (
          <GrupoGhe key={item.id} grupo={item} obterDados={obterDados} permissaoEditar={permissaoEditar} />
        ))}
      </TabelaPadrao>
    </>
  );
};

const GrupoGhe = ({ grupo, obterDados, permissaoEditar }) => {
  const [editando, setEditando] = useState(false);
  const [gerenciar, setGerenciando] = useState(false);

  function deletar() {
    api.delete(`/baseDados/ergonomiaBaseDados/deletarGrupoGhe/${grupo.id}`).then(() => {
      obterDados();
    });
  }

  function selecionarCor(resultado) {
    let cor = "";
    let text = "#262626";
    switch (parseInt(resultado)) {
      case 1:
        // Azul
        cor = "#0099DD";
        break;
      case 2:
        //verde
        cor = "#04D939";
        break;
      case 3:
        //amarelo
        cor = "#EAF205";
        break;
      case 4:
        //laranja
        cor = "#F28705";
        break;
      case 5:
        //vermelho
        cor = "#BF3604";
        break;
      default:
        cor = "black";
    }

    return { backgroundColor: cor, color: text };
  }

  function selecionarResultado(resultado) {
    let resposta = "";
    switch (parseInt(resultado)) {
      case 1:
        resposta = "Insignificante";
        break;
      case 2:
        resposta = "Leve";
        break;
      case 3:
        resposta = "Moderado";
        break;
      case 4:
        resposta = "Alto";
        break;
      case 5:
        resposta = "Grave";
        break;
      default:
        resposta = " - ";
    }

    return resposta;
  }
  
  return (
    <>
      <tr key={grupo.id}>
        <td>{grupo.nome}</td>
        <td>
          {grupo.perigos.map((item) => (
            <ul>
              <li style={{ listStyleType: "none" }}>
                {item.codigo}) {item.definicao}
                <span className="badge ms-1" style={selecionarCor(item.grau_risco)}>
                  {selecionarResultado(item.grau_risco)}
                </span>
              </li>
            </ul>
          ))}
        </td>
        <td className="text-center">{grupo.tipo_servico === "A" ? "Administrativo" : "Operacional"}</td>
        {permissaoEditar && (
          <td>
            <td className="text-center">
              <td className="text-center">
                <ol style={{ listStyleType: "none" }}>
                  <li>
                    <BotaoEditar onClick={() => setEditando(true)} />
                  </li>
                  <li className="mt-2">
                    <BotaoGerenciar onClick={() => setGerenciando(true)} />
                  </li>
                  <li className="mt-2">
                    <BotaoDeletar onClick={() => deletar()} />
                  </li>
                </ol>
              </td>
            </td>
          </td>
        )}
      </tr>
      {editando && (
        <ModalEditar
          show={editando}
          setShow={setEditando}
          obterDados={obterDados}
          grupo={grupo}
          corTitulo="primary"
          titulo={`Editar: ${grupo.nome}`}
        />
      )}
      {gerenciar && (
        <ModalGerenciar
          show={gerenciar}
          setShow={setGerenciando}
          obterDados={obterDados}
          grupo={grupo}
          corTitulo="primary"
          titulo={`Editar: ${grupo.nome}`}
        />
      )}
    </>
  );
};

const ModalEditar = ({ grupo, obterDados, show, setShow }) => {
  const [nome, setNome] = useState();
  const [tipoServico, setTipoServico] = useState();

  function salvarDados() {
    api
      .put(`/baseDados/ergonomiaBaseDados/editarGrupoGhe/${grupo.id}`, {
        nome: nome,
        tipo_servico: tipoServico,
      })
      .then(() => {
        obterDados();
        setShow(false);
      });
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      tamanho={"md"}
      onSubmit={(e) => salvarDados(e)}
      titulo={`Editar: ${grupo.nome}`}
    >
      <div className="row">
        <div className="col">
          <label htmlFor="nome"> Nome: </label>
          <input
            type="text"
            id="nome"
            className="form-control"
            defaultValue={grupo?.nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label htmlFor="adm">Administrativo</label>
          <input
            className="form-check-input ml-2"
            type="radio"
            name="tipo_servico"
            id="adm"
            defaultChecked={grupo?.tipo_servico === "A"}
            onChange={(e) => setTipoServico("A")}
          />
          <label htmlFor="op" className="ml-4">
            Operacional
          </label>
          <input
            className="form-check-input ml-2"
            type="radio"
            name="tipo_servico"
            id="op"
            defaultChecked={grupo?.tipo_servico === "O"}
            onChange={(e) => setTipoServico("O")}
          />
        </div>
      </div>
    </ModalSubmit>
  );
};

const ModalGerenciar = ({ show, setShow, grupo, obterDados }) => {
  const [perigo, setPerigo] = useState();
  const [filtroPerigo, setFiltroPerigo] = useState("");
  const [filtroPerigosNaoCadastrados, setFiltroPerigosNaoCadastrados] = useState("");
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    api.get(`/baseDados/ergonomiaBaseDados/buscarDadosPerigo?grupoGhe_id=${grupo.id}`).then((resposta) => {
      setPerigo(resposta.data);
      setCarregando(false);
    });
  }, [grupo.id]);

  function buscarDados() {
    api.get(`/baseDados/ergonomiaBaseDados/buscarDadosPerigo?grupoGhe_id=${grupo.id}`).then((resposta) => {
      setPerigo(resposta.data);
      setCarregando(false);
    });
  }

  let perigosCadastrados = perigo?.perigos?.filter(
    (filtro) => filtrar(filtro.definicao, filtroPerigo) || filtrar(filtro.codigo, filtroPerigo)
  );

  function filtrarPerigos(newFilter) {
    setFiltroPerigo(newFilter);
  }

  let PerigosNaoCadastrados = perigo?.nao_cadastrado?.filter((filtro) => {
    return (
      perigo?.perigos?.filter(function (item) {
        return item.perigo_definicao_id === filtro.id;
      }).length === 0
    );
  });
  let perigos = PerigosNaoCadastrados?.filter(
    (filtro) =>
      filtrar(filtro.definicao, filtroPerigosNaoCadastrados) || filtrar(filtro.codigo, filtroPerigosNaoCadastrados)
  );

  function filtrarPerigoNaoCadastrado(newFilter) {
    setFiltroPerigosNaoCadastrados(newFilter);
  }

  return (
    <ModalPadrao handleHide={obterDados} show={show} setShow={setShow} titulo={`Gerenciar Grupo ${grupo.nome}`}>
      <Filtros
        itens={[
          { placeholder: "Não Cadastrados", filtrar: filtrarPerigoNaoCadastrado },
          { placeholder: "Cadastrados", filtrar: filtrarPerigos },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: "N°", text: "center" },
              { nome: "Perigos não cadastrados:" },
              { nome: "Ações:", width: "7%", text: "center" },
            ]}
            itens={perigos}
            itensFiltrados={perigos}
            filtro={filtroPerigosNaoCadastrados}
            carregando={carregando}
          >
            {carregando ? (
              <Loader />
            ) : (
              <>
                {perigos?.map((item, index) => (
                  <Perigo
                    key={index}
                    item={item}
                    grupo={grupo}
                    buscarDados={buscarDados}
                    carregando={setCarregando}
                    adiciona
                  />
                ))}
              </>
            )}
          </TabelaPadrao>
        </div>
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: "N°", text: "center" },
              { nome: "Perigos Cadastrados:" },
              { nome: "Grau de Risco:", width: "27%" },
              { nome: "Ações:", width: "3%", text: "center" },
            ]}
            itens={perigosCadastrados}
            itensFiltrados={perigosCadastrados}
            filtro={filtroPerigo}
            carregando={carregando}
          >
            {carregando ? (
              <Loader />
            ) : (
              <>
                {perigosCadastrados?.map((item, index) => (
                  <Perigo key={index} item={item} grupo={grupo} buscarDados={buscarDados} carregando={setCarregando} />
                ))}
              </>
            )}
          </TabelaPadrao>
          <ToastContainer />
        </div>
      </div>
    </ModalPadrao>
  );
};

const Perigo = ({ item, grupo, buscarDados, adiciona, carregando }) => {
  async function inserirPerigo(item) {
    carregando(true);
    await api
      .post(`/baseDados/ergonomiaBaseDados/inserirPerigoGrupo`, {
        perigo_definicao_id: item.id,
        ghe_grupo_ergonomia_id: grupo.id,
      })
      .then(() => {
        buscarDados();
        carregando(false);
      });
  }

  async function deletarPerigo(item) {
    carregando(true);
    await api
      .delete(`/baseDados/ergonomiaBaseDados/deletarPerigoGhe/${item.ghe_grupo_ergonomia_perigo_id}`)
      .then(() => {
        buscarDados();
        carregando(false);
      });
  }

  async function editar(grauRisco) {
    await api
      .put(`/baseDados/ergonomiaBaseDados/editarPerigoGrauRisco/${item.ghe_grupo_ergonomia_perigo_id}`, {
        grau_risco: grauRisco,
      })
      .then(() => {
        buscarDados();
        carregando(false);
      });
  }

  function selecionarGrauRisco(grauRisco) {
    if (grauRisco === "1") {
      return { label: "Insignificante" };
    } else if (grauRisco === "2") {
      return { label: "Leve" };
    } else if (grauRisco === "3") {
      return { label: "Moderado" };
    } else if (grauRisco === "4") {
      return { label: "Alto" };
    } else if (grauRisco === "5") {
      return { label: "Grave" };
    }
  }

  let optionsGrauRisco = [
    {
      label: "Insignificante",
      value: "1",
    },
    {
      label: "Leve",
      value: "2",
    },
    {
      label: "Moderado",
      value: "3",
    },
    {
      label: "Alto",
      value: "4",
    },
    {
      label: "Grave",
      value: "5",
    },
  ];

  return (
    <>
      <tr>
        <td className="text-center">{item.codigo}</td>
        <td>{item.definicao}</td>
        {!adiciona && (
          <td>
            <Select
              options={optionsGrauRisco}
              onChange={(e) => {
                editar(e.value);
              }}
              defaultValue={selecionarGrauRisco(item.grau_risco)}
            />
          </td>
        )}
        <td>
          {adiciona ? (
            <div className="row">
              <div className="col">
                <BotaoAdicionar title="Cadastrar perigo" onClick={() => inserirPerigo(item)} />
              </div>
            </div>
          ) : (
            <BotaoDeletar title="Deletar perigo" onClick={() => deletarPerigo(item)} />
          )}
        </td>
      </tr>
    </>
  );
};

export default ListaGhe;
