import React, { useState } from "react";
import { useEffect } from "react";

import { filtrar, isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";
import ListaFormasRisco from "../ListaFormasRisco";
import ModalFormasRisco from "../ModalFormasRisco";

const FormasRiscoFisicoQuimicoBiologico = () => {
  const [
    formasRiscoFisicoQuimicoBiologico,
    setFormasRiscoFisicoQuimicoBiologico,
  ] = useState([]);
  const [riscosFisicoQuimicoBiologico, setRiscosFisicoQuimicoBiologico] =
    useState([]);
  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "Formas de Risco - Físico, Químico, Biológico";

  useEffect(() => {
    buscarFormasRiscoFisicoQuimicoBiologico();
    buscarRiscoFisicoQuimicoBiologico();
  }, []);

  const formasRiscoFisicoQuimicoBiologicoFiltrados =
    formasRiscoFisicoQuimicoBiologico.filter(
      (formaRiscoFisicoQuimicoBiologico) =>
        filtrar(formaRiscoFisicoQuimicoBiologico.id, filtro) ||
        filtrar(formaRiscoFisicoQuimicoBiologico.definicao, filtro) ||
        (!isEmpty(formaRiscoFisicoQuimicoBiologico.codigo) && filtrar(formaRiscoFisicoQuimicoBiologico.codigo, filtro))
    );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarFormasRiscoFisicoQuimicoBiologico() {
    await api
      .get("/baseDados/formasRisco/buscarFormasRisco")
      .then((resposta) => {
        setFormasRiscoFisicoQuimicoBiologico(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarRiscoFisicoQuimicoBiologico() {
    await api
      .get("/baseDados/formasRisco/buscarRiscoFisicoQuimicoBiologico")
      .then((resposta) => {
        setRiscosFisicoQuimicoBiologico(
          resposta.data?.map((item) => ({
            value: item.id,
            label: item.nome,
          }))
        );
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={formasRiscoFisicoQuimicoBiologicoFiltrados}
        totalItens={formasRiscoFisicoQuimicoBiologico.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={(e) => {
          setShowModalCadastro(true);
        }}
      >
        {(currentItems) => (
          <ListaFormasRisco
            formasRisco={currentItems}
            buscarFormaRisco={buscarFormasRiscoFisicoQuimicoBiologico}
            formasRiscoTipo={1}
            riscosFisicoQuimicoBiologico={riscosFisicoQuimicoBiologico}
          />
        )}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalFormasRisco
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Formas de Risco - Físico, Químico, Biológico"
          corTitulo="success"
          buscarFormaRisco={buscarFormasRiscoFisicoQuimicoBiologico}
          novaFormaRisco={filtro}
          formasRiscoTipo={1}
          riscosFisicoQuimicoBiologico={riscosFisicoQuimicoBiologico}
        />
      )}
    </>
  );
};

export default FormasRiscoFisicoQuimicoBiologico;
