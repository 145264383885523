import React, { useState } from "react";

import { BotaoDeletar, BotaoEditar, BotaoGrupo } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import ModalPerigo from "./ModalPerigo";

const ListaPerigo = ({ perigos, gruposPerigo, buscarPerigos, aprePerigo, permissaoEditar }) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={perigos}
        colunas={[
          { nome: "Código", width: "5%", text: "center" },
          { nome: "Perigo Grupo", width: "15%" },
          { nome: "Perigo" },
          permissaoEditar && { nome: "Ações", width: "8%", text: "center" },
        ]}
      >
        {perigos.map((perigo) => (
          <Perigo
            key={perigo.id}
            perigo={perigo}
            gruposPerigo={gruposPerigo}
            buscarPerigos={buscarPerigos}
            aprePerigo={aprePerigo}
            permissaoEditar={permissaoEditar}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const Perigo = ({ perigo, gruposPerigo, buscarPerigos, aprePerigo, permissaoEditar }) => {
  const [editando, setEditando] = useState(false);

  const perigoVinculoApre = aprePerigo.find((perigoApre) => perigoApre.perigo_definicao_id === perigo.id);

  async function deletarPerigo(perigo) {
    if (window.confirm(`Tem certeza que deseja excluir o Perigo: ${perigo.definicao}?`)) {
      if (perigoVinculoApre) {
        let status = { status: "I" };

        api.put(`/baseDados/ergonomiaBaseDados/editarPerigo/${perigo.id}`, status).then(() => {
          api
            .delete(`/baseDados/ergonomiaBaseDados/deletarPerigoRiscosVinculados/${perigo.id}`)
            .then(() => {
              api.delete(`/baseDados/ergonomiaBaseDados/deletarMedidaControleNecessariaVinculadaPerigo/${perigo.id}`);
            })
            .then(() => {
              buscarPerigos();
              setEditando(false);
            });
        });
      } else {
        await api.delete(`/baseDados/ergonomiaBaseDados/deletarPerigoRiscosVinculados/${perigo.id}`).then(() => {
          api
            .delete(`/baseDados/ergonomiaBaseDados/deletarMedidaControleNecessariaVinculadaPerigo/${perigo.id}`)
            .then(() => {
              api
                .delete(`/baseDados/ergonomiaBaseDados/deletarPerigo/${perigo.id}`)
                .then(() => {
                  buscarPerigos();
                  setEditando(false);
                })
                .catch((erro) => {
                  alert(erro.response.data.mensagem);
                });
            })
            .catch((erro) => {
              alert(erro.response.data.mensagem);
            });
        });
      }
    }
  }

  return (
    <>
      {!isEmpty(gruposPerigo) && (
        <>
          <tr key={perigo.id}>
            <td className="text-center align-middle">{perigo.codigo}</td>
            <td className="align-middle">
              {gruposPerigo.find((grupoPerigo) => grupoPerigo.id === perigo.perigo_grupo_id).grupo}
            </td>
            <td className="align-middle">{perigo.definicao}</td>
            {permissaoEditar && (
              <>
                <td className="text-center align-middle">
                  <BotaoGrupo>
                    <BotaoEditar onClick={() => setEditando(true)} disabled={perigo.id === "59" && true} />
                    <BotaoDeletar onClick={() => deletarPerigo(perigo)} disabled={perigo.id === "59" && true} />
                  </BotaoGrupo>
                </td>
              </>
            )}
          </tr>
          {editando && (
            <ModalPerigo
              show={editando}
              setShow={setEditando}
              titulo={`Editar: ${perigo.definicao}`}
              corTitulo="primary"
              perigo={perigo}
              buscarPerigos={buscarPerigos}
              aprePerigo={aprePerigo}
            />
          )}
        </>
      )}
    </>
  );
};
export default ListaPerigo;
