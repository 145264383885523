import React, { useEffect, useState } from "react";
import Select from "react-select";

import { ModalSubmit } from "../../../../components/ModalPadrao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import Loader from "../../../../components/Loader";

const ModalAcidenteRisco = ({ show, setShow, titulo, corTitulo, risco, buscarRiscos, novoRisco, acidenteRiscos }) => {
  const [codigo, setCodigo] = useState(risco && risco.codigo);
  const [salvarRisco, setSalvarRisco] = useState(risco ? risco.definicao : novoRisco);
  const [severidade, setSeveridade] = useState();
  const [severidadeSelecionada, setSeveridadeSelecionada] = useState(risco && risco.severidade_id);
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    api.get(`/baseDados/acidenteBaseDados/buscarSeveridade`).then((resposta) => {
      setSeveridade(resposta.data);
      setCarregando(false);
    });
  }, []);

  async function salvarBanco(e) {
    e.preventDefault();
    if (!isEmpty(codigo && salvarRisco)) {
      if (codigo === risco?.codigo && salvarRisco === risco?.definicao) {
        api.put(`/baseDados/acidenteBaseDados/editarSeveridadeRisco`, {
          severidade_id: severidadeSelecionada,
          risco_acidente_id: risco.id,
        }).then(() => {
          buscarRiscos();
          setShow(false);
        })
      } else {
        const riscoAcidenteVinculo = !isEmpty(risco)
        ? acidenteRiscos.find((apreRisco) => apreRisco.risco_acidente_id === risco.id)
          ? true
          : false
        : false;

      let dados = {
        codigo: codigo,
        definicao: salvarRisco,
        severidade_id: severidadeSelecionada,
      };

      let status = { status: "I" };

      let url = risco
        ? riscoAcidenteVinculo
          ? (api.put(`/baseDados/acidenteBaseDados/editarRisco/${risco.id}`, status),
            api.post("/baseDados/acidenteBaseDados/inserirRisco", dados)).then((resposta) => {
              let novoRiscoId = {
                risco_acidente_id: resposta.data.dados.id,
              };
              api.put(`/baseDados/acidenteBaseDados/editarRiscoPerigosVinculados/${risco.id}`, novoRiscoId);
            })
          : api.put(`/baseDados/acidenteBaseDados/editarRisco/${risco.id}`, dados)
        : api.post("/baseDados/acidenteBaseDados/inserirRisco", dados);

      await url
        .then(() => {
          buscarRiscos();
          setShow(false);
        })
        .catch((erro) => alert(erro));
    }
      }
     
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      titulo={titulo}
      corTitulo={corTitulo}
      tamanho="md"
      onSubmit={(e) => salvarBanco(e)}
    >
      {carregando ? (
        <Loader />
      ) : (
        <div className="mb-3">
          <label htmlFor="codigo">Código:</label>
          <input
            type="text"
            id="codigo"
            name="codigo"
            className="form-control"
            defaultValue={risco && risco.codigo}
            onChange={(r) => {
              setCodigo(r.target.value);
            }}
            required
          />

          <label htmlFor="risco">Risco:</label>
          <input
            type="text"
            id="risco"
            name="risco"
            className="form-control"
            defaultValue={risco ? salvarRisco : novoRisco}
            onChange={(r) => {
              setSalvarRisco(r.target.value);
            }}
            required
          />

          <label htmlFor="severidade">Severidade: <span style={{fontSize: 12}}>Selecione a severidade que será padrão para esse risco</span></label>
          <Select
          onChange={(e) => {
            setSeveridadeSelecionada(e.value)
          }}
            options={severidade.map((e) => ({
              label: e.grau_risco + " - " + e.definicao,
              value: e.id,
              peso: e.peso,
            }))}
            defaultValue={!isEmpty(risco) && {label: risco.grau_risco + " - " + risco.severidade}}
          />
        </div>
      )}
    </ModalSubmit>
  );
};

export default ModalAcidenteRisco;
