import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Botao, { BotaoGrupo } from "../../components/Botao";
import { isEmpty } from "../../components/Util";
import Loader from "../../components/Loader";

const CardPadrao = ({ titulo, itens, botoes, carregando, children }) => {
  return (
    <Card className="p-3">
      <Row className="mb-3">
        <Col className="text-muted">
          {titulo}
          {!isEmpty(itens) && (
            <>
              {" "}
              (Quantidade: {carregando ? <Loader small /> : itens.length + ")"}
            </>
          )}
        </Col>
        {!isEmpty(botoes) && (
          <Col>
            <div className="d-flex justify-content-end">
              <BotaoGrupo>
                {botoes.map(
                  (
                    {
                      className,
                      cor = "secondary",
                      alvo,
                      titulo = "PDF",
                      icone = "print",
                      conteudo = "PDF",
                      onClick,
                      isLoading = false,
                    },
                    index
                  ) => (
                    <Botao
                      key={index}
                      className={className}
                      cor={cor}
                      alvo={alvo}
                      title={titulo}
                      icone={icone}
                      conteudo={conteudo}
                      onClick={onClick}
                      isLoading={isLoading}
                    />
                  )
                )}
              </BotaoGrupo>
            </div>
          </Col>
        )}
      </Row>
      {children}
    </Card>
  );
};

export default CardPadrao;
