import React, { useEffect, useState } from "react";

import { ModalSubmit } from "../../../components/ModalPadrao";
import { isEmpty } from "../../../components/Util";

import api from "../../../service/api";
import Select from "react-select";

const ModalChecklistPerguntas = ({
  show,
  setShow,
  titulo,
  corTitulo,
  perguntas,
  pergunta,
  buscarChecklistPerguntas,
  checklistModelos,
  novaPergunta,
  isEditar = false,
}) => {
  const [norma, setNorma] = useState(pergunta ? pergunta.norma : null);
  const [sequencia, setSequencia] = useState(pergunta ? pergunta.sequencia : null);
  const [salvarPergunta, setSalvarPergunta] = useState(pergunta ? pergunta.pergunta : novaPergunta);
  const [observacao, setObservacao] = useState(pergunta ? pergunta.observacao_previa_se_nao : null);
  const [tipoSelecionadoPlanoAcao, setTipoSelecionadoPlanoAcao] = useState(pergunta?.tipo_plano_acao);
  const [prazo, setPrazo] = useState(pergunta?.prazo);
  const [medidas, setMedidas] = useState([]);
  const [tipoMedidaNecessariaSelecionada, setTipoMedidaNecessariaSelecionada] = useState(
    pergunta?.medida_necessaria_grupo_id
  );
  const [formaAcompanhamento, setFormaAcompanhamento] = useState();
  const [salvarFormaAcompanhamento, setSalvarFormaAcompanhamento] = useState(pergunta?.forma_acompanhamento_id);

  useEffect(() => {
    obter();
  }, []);

  async function obter() {
    await api.get("/baseDados/checklistPerguntas/buscarMedidaControleGrupo").then((resposta) => {
      setMedidas(resposta.data);
    });
    await api.get("/baseDados/ChecklistPerguntas/buscarFormasAcompanhamento").then((resposta) => {
      setFormaAcompanhamento(resposta.data);
    });
  }

  const salvarBanco = async (e) => {
    if (
      (pergunta?.tipo_plano_acao !== tipoSelecionadoPlanoAcao ||
        pergunta?.prazo !== prazo ||
        pergunta?.medida_necessaria_grupo_id !== tipoMedidaNecessariaSelecionada ||
        pergunta?.observacao_previa_se_nao !== observacao ||
        pergunta?.forma_acompanhamento_id !== salvarFormaAcompanhamento) &&
      pergunta?.norma === norma
    ) {
      api
        .put(`/baseDados/checklistPerguntas/editar/${pergunta.id}`, {
          observacao_previa_se_nao: observacao,
          tipo_plano_acao: tipoSelecionadoPlanoAcao,
          prazo: prazo,
          medida_necessaria_grupo_id: tipoMedidaNecessariaSelecionada,
          forma_acompanhamento_id: salvarFormaAcompanhamento,
        })
        .then(() => {
          buscarChecklistPerguntas();
        });
    } else {
      e.preventDefault();
      const perguntaVinculoModelo = !isEmpty(pergunta)
        ? checklistModelos.find((checklistModelo) =>
            checklistModelo.perguntas.find(
              (checklistModeloPergunta) => checklistModeloPergunta.checklist_pergunta_id === pergunta.id
            )
          )
          ? true
          : false
        : false;

      let dados = {
        sequencia: sequencia,
        norma: norma,
        pergunta: salvarPergunta,
        observacao_previa_se_nao: observacao,
        tipo_plano_acao: tipoSelecionadoPlanoAcao,
        prazo: prazo,
        medida_necessaria_grupo_id: tipoMedidaNecessariaSelecionada,
      };
      let status = { status: "I" };

      pergunta
        ? perguntaVinculoModelo
          ? api.put(`/baseDados/checklistPerguntas/editar/${pergunta.id}`, status).then(() => {
              perguntas.splice(pergunta.sequencia - 1, 1);
              api.post("/baseDados/checklistPerguntas/inserir", dados).then((resposta) => {
                ordenaPerguntas(resposta.data.dados);
              });
            })
          : api.put(`/baseDados/checklistPerguntas/editar/${pergunta.id}`, dados).then((resposta) => {
              perguntas.splice(pergunta.sequencia - 1, 1);
              ordenaPerguntas(resposta.data.dados.find((dado) => dado.id === pergunta.id));
            })
        : api.post("/baseDados/checklistPerguntas/inserir", dados).then((resposta) => {
            ordenaPerguntas(resposta.data.dados);
          });
    }
  };

  function ordenaPerguntas(item) {
    perguntas.splice(item.sequencia - 1, 0, item);

    let perguntasOrdenadas = [];
    perguntas.forEach((pergunta, index) => {
      perguntasOrdenadas.push({ id: pergunta.id, sequencia: ++index });
    });

    api
      .put(`/baseDados/checklistPerguntas/editar/`, {
        perguntasOrdenadas: perguntasOrdenadas,
      })
      .then(() => {
        buscarChecklistPerguntas();
        setShow(false);
      });
  }

  let optionMedidas = medidas.map((item) => ({
    label: item.grupo,
    value: item.id,
  }));

  let optionFormasAcompanhamento = formaAcompanhamento?.map((item) => ({
    label: item.acompanhamento,
    value: item.id,
  }));

  function selecionaTipoMedida(medida_id) {
    if (medida_id === "1") {
      return { label: "EPC" };
    } else if (medida_id === "2") {
      return { label: "EPI" };
    } else if (medida_id === "3") {
      return { label: "ORG" };
    } else {
      return { label: "--" };
    }
  }

  function selecionaFormaAcompanhamento(forma_acompanhamento_id) {
    if (forma_acompanhamento_id === "1") {
      return { label: "Inspeção no local" };
    } else if (forma_acompanhamento_id === "3") {
      return { label: "Análise documental" };
    } else if (forma_acompanhamento_id === "5") {
      return { label: "Ficha EPI" };
    } else if (forma_acompanhamento_id === "7") {
      return { label: "Nenhum" };
    } else if (forma_acompanhamento_id === "11") {
      return { label: "Registro(assinatura)" };
    } else {
      return { label: "--" };
    }
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      tituloSpan={titulo}
      corTitulo={corTitulo}
      tamanho="md"
      onSubmit={(e) => {
        salvarBanco(e);
        setShow(false);
      }}
      isEditar={isEditar}
    >
      <div className="row">
        <div className="col">
          <label>Sequência:</label>
          <input
            type="text"
            id="sequencia"
            name="sequencia"
            className="form-control"
            defaultValue={sequencia}
            onChange={(e) => {
              setSequencia(e.target.value);
            }}
            required
            onInvalid={(e) => e.target.setCustomValidity("Digite uma sequência")}
          />
        </div>
        <div className="col">
          <div className="mb-3">
            <label>Forma de Acompanhamento:</label>
            <Select
              options={optionFormasAcompanhamento}
              defaultValue={selecionaFormaAcompanhamento(pergunta?.forma_acompanhamento_id)}
              onChange={(e) => {
                setSalvarFormaAcompanhamento(e.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>Norma:</label>
          <input
            type="text"
            id="norma"
            name="norma"
            className="form-control"
            defaultValue={norma}
            onChange={(e) => {
              setNorma(e.target.value);
            }}
            required
          />
        </div>
        <div className="col">
          <label htmlFor="prazo">Prazo: </label>
          <input
            className="form-control"
            type="number"
            name="prazo"
            id="prazo"
            defaultValue={pergunta?.prazo}
            onChange={(e) => setPrazo(e.target.value)}
          />
        </div>
        <div className="col">
          <label htmlFor="">Medida Grupo:</label>
          <Select
            options={optionMedidas}
            defaultValue={selecionaTipoMedida(pergunta?.medida_necessaria_grupo_id)}
            onChange={(e) => setTipoMedidaNecessariaSelecionada(e.value)}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>Pergunta:</label>
        <input
          type="text"
          id="pergunta"
          name="pergunta"
          className="form-control"
          defaultValue={pergunta ? salvarPergunta : novaPergunta}
          onChange={(e) => {
            setSalvarPergunta(e.target.value);
          }}
          required
        />
      </div>
      <div className="mb-3">
        <label>Observação:</label>
        <input
          type="text"
          id="observacao_previa_se_nao"
          name="observacao_previa_se_nao"
          className="form-control"
          defaultValue={pergunta?.observacao_previa_se_nao}
          onChange={(e) => {
            setObservacao(e.target.value);
          }}
          required
        />
      </div>
      <div className="row">
        <div className="col">
          <input
            className="mr-2 form-check-input "
            type="radio"
            name="tipo_checklist"
            id="acidente"
            defaultChecked={pergunta?.tipo_plano_acao === "A"}
            onChange={() => {
              setTipoSelecionadoPlanoAcao("A");
            }}
          />
          <label htmlFor="acidente">Acidente</label>
        </div>
        <div className="col">
          <input
            className="mr-2 form-check-input "
            type="radio"
            name="tipo_checklist"
            id="ergonomia"
            defaultChecked={pergunta?.tipo_plano_acao === "E"}
            onChange={() => {
              setTipoSelecionadoPlanoAcao("E");
            }}
          />
          <label htmlFor="ergonomia">Ergonomia</label>
        </div>
        <div className="col">
          <input
            className="mr-2 form-check-input "
            type="radio"
            name="tipo_checklist"
            id="fisicoQuimicoBiologico"
            defaultChecked={pergunta?.tipo_plano_acao === "F"}
            onChange={() => {
              setTipoSelecionadoPlanoAcao("F");
            }}
          />
          <label htmlFor="fisicoQuimicoBiologico">F, Q, B</label>
        </div>
      </div>
    </ModalSubmit>
  );
};

export default ModalChecklistPerguntas;
