import React from "react";

const LogoCliente = ({ nomeFoto, maxWidth, maxHeight }) => {
  return (
    <img
      className="mx-auto img-fluid"
      src={process.env.REACT_APP_CAMINHO_UPLOAD + "clientes/" + nomeFoto + `?${Math.floor(Math.random() * 100) + 1}`}
      style={{ maxHeight: maxHeight, maxWidth: maxWidth }}
      alt="Logo Cliente"
    />
  );
};

export default LogoCliente;
