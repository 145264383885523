import React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

import NavTabs from "../../../components/NavTabs";
import Template from "../../template/Template";
import FormasRiscoErgonomia from "./formasRisco/FormasRiscoErgonomia";
import FormasRiscoFisicoQuimicoBiologico from "./formasRisco/FormasRiscoFisicoQuimicoBiologico";

const FormasRisco = () => {
  const acoes = [
    {
      id: 1,
      nome: "Físico, Químico, Biológico",
      body: <FormasRiscoFisicoQuimicoBiologico />,
    },
    {
      id: 2,
      nome: "Ergonomia",
      body: <FormasRiscoErgonomia />,
    },
   
  ];

  return (
    <>
      <Template page="Formas de Risco - base-dados">
        <>
          <div className="container-fluid justify-content-between">
            <div className="bg-white p-2">
              <div className="card bg-cinza text-white">
                <span className="align-middle">
                  <h3 className="text-center">
                    <i className="fas fa-scroll"></i>
                    Base de Dados Formas de Risco
                  </h3>
                </span>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <NavTabs acoes={acoes} />
                  <ToastContainer autoClose={1000} pauseOnHover={false} />
                </div>
              </div>
            </div>
          </div>
        </>
      </Template>
    </>
  );
};

export default FormasRisco;
