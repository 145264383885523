import { useEffect, useState } from "react";
import Select from "react-select";

import { mostrarMensagem } from "../../components/Util";
import api from "../../service/api";
import { ModalSubmit } from "../../components/ModalPadrao";
import { BotaoDeletar } from "../../components/Botao";
import LogoCliente from "../../components/LogoCliente";
import InputFile from "../../components/InputFile";

const CadastraLogo = ({ show, setShow }) => {
  const [clientes, setClientes] = useState([]);
  const [cliFoto, setCliFoto] = useState("");
  const [fotoEscolhida, setFotoEscolhida] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [mostrarSucesso, setMostrarSucesso] = useState("");

  useEffect(() => {
    buscarClientes();
  }, []);

  function handleSelect(objeto, select) {
    if (select.action === "select-option") {
      setCodigo(objeto.value);
      filtraClienteSelecionado(objeto.value);
    }
  }

  function filtraClienteSelecionado(clienteCodigo) {
    let clienteSelecionado = {};

    clienteSelecionado = clientes.filter(
      (item) => item.codigo === clienteCodigo
    );
    setCliFoto(clienteSelecionado[0].foto);
  }

  //Busca de clientes para preencher o select
  async function buscarClientes() {
    await api.get("/clientes").then((resposta) => {
      setClientes(resposta.data);
    });
  }

  // Parte para cadastrar os logos
  async function uploadArquivo(e, arquivo) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("arquivo", arquivo);
    formData.append("cliente_codigo", codigo);
    formData.append("nomeArquivo", arquivo?.name);

    // upload da foto logo
    await api
      .post("/upload/inserirLogo", formData, {
        headers: {
          "Content-Type": `multipart/form-data;`,
        },
      })
      .then(() => editarLogo(codigo, `cli_${codigo}`, arquivo.name))
      .catch((e) => alert(e.response.data.mensagem));
  }

  async function editarLogo(codigo, nomeLogo, nomeArquivo) {
    await api
      .put(`/clientes/editar/${codigo}`, {
        foto: nomeLogo,
        nomeArquivo: nomeArquivo?.toLowerCase(),
      })
      .then((resposta) => {
        setCliFoto(resposta.data.dados.foto);
        mostrarMensagem(resposta.data.mensagem, setMostrarSucesso);
      });
  }

  function handleChange(arquivo) {
    setFotoEscolhida(arquivo);
  }

  return (
    <ModalSubmit
      id="cadastroLogo"
      titulo="Cadastro de logos dos"
      tituloSpan="Clientes"
      tamanho="md"
      show={show}
      setShow={setShow}
      onSubmit={(e) => uploadArquivo(e, fotoEscolhida)}
      animation
    >
      <div className="row g-2">
        <div className="col-12">
          <p>
            O tamanho do arquivo deve ser menor ou igual a{" "}
            <span className="badge bg-success">150kB</span>
            <br />Arquivo deve ser em formato{" "}
            <span className="badge bg-success">.jpg</span>
          </p>
        </div>
        {/* Conteudo da modal */}
        <div className="col-12">
          {/* Select clientes  */}
          <Select
            name="cliente"
            onChange={handleSelect}
            options={clientes.map(({ codigo, nome_fantasia }) => ({
              value: codigo,
              label: nome_fantasia,
            }))}
          />
        </div>
        {/* Input de imagens */}
        <div className="col-12">
          {cliFoto ? (
            <FotoCliente
              cliFoto={cliFoto}
              codigo={codigo}
              editarLogo={editarLogo}
            />
          ) : (
            codigo && (
              <InputFile
                nomeArquivo={fotoEscolhida}
                handleChange={handleChange}
                disabled={cliFoto}
              />
            )
          )}
        </div>
        {mostrarSucesso && (
          <label className="text-success small">{mostrarSucesso}</label>
        )}
      </div>
    </ModalSubmit>
  );
};

const FotoCliente = ({ cliFoto, codigo, editarLogo }) => {
  async function deletarArquivo(e) {
    e.preventDefault();
    if (window.confirm(`Você tem certeza que deseja excluir esta foto?`)) {
      api
        .post(`/upload/deletarArquivo/`, {
          nome_arquivo: cliFoto,
          caminho: `clientes/`,
        })
        .then(() => {
          editarLogo(codigo, null, null);
        })
        .catch((e) => alert(e.response.data.mensagem));
    }
  }

  return (
    <div className="card p-1">
      <div className="card-body text-end p-1">
        <BotaoDeletar onClick={deletarArquivo} />
      </div>
      <LogoCliente nomeFoto={cliFoto} />
    </div>
  );
};

export default CadastraLogo;
