import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { isAuthenticated, logout, verificaPermissaoCliente, verificaRota } from "./service/auth";
import Loader from "./components/Loader";
import CadastrosServico from "./screens/base-dados/servicos/CadastrosServico";
import Setores from "./screens/ppra/reconhecimentos-matematicas/Setores";
import AtividadesEspeciais from "./screens/base-dados/atividades-especiais/AtividadesEspeciais";
import EfeitosSaude from "./screens/base-dados/efeitosSaude/EfeitosSaude";
import ChecklistPerguntas from "./screens/base-dados/checklistPerguntas/ChecklistPerguntas";
import FabricanteQuimico from "./screens/base-dados/fabricanteQuimico/FabricanteQuimico";

import {
  urlAmbientes,
  urlAtividades,
  urlAtividadesEspeciais,
  urlCas,
  urlDanos,
  urlEfeitosSaude,
  urlElementos,
  urlEpcs,
  urlEpis,
  urlFerramentas,
  urlFiltrosMascaras,
  urlFrasesPerigo,
  urlFuncionariosClientes,
  urlFuncoesClientes,
  urlJornadasTrabalho,
  urlLinach,
  urlMedidasOrganizacionais,
  urlMetodosAnalise,
  urlPerigos,
  urlProdutosQuimicos,
  urlRequisitosAplicaveis,
  urlServicos,
  urlChecklistPerguntas,
  urlFabricanteQuimico,
  urlBaseDadosErgonomia,
  urlBaseDadosAcidente,
  urlBaseDadosFormasRisco,
  urlBaseDadosObjetivosMetas,
  urlBaseDadosRiscosLocal,
  urlEquipamentosProcessos,
  urlBaseDadosFormaAplicacao,
} from "./routes/UrlBaseDados";
import TabsProdutoQuimico from "./screens/base-dados/produtosQuimicos/TabsProdutoQuimico";
import { dadosToken } from "./components/Util";
import Botao from "./components/Botao";
import { urlColaboradores, urlUsuarios, urlVeiculos } from "./routes/UrlAdministrativo";
import Pgr from "./screens/gro/Pgr";
import Programas from "./screens/gro/Programas";
import Checklist from "./screens/gro/pgr/Checklist";
import FisicoQuimicoBiologico from "./screens/gro/pgr/reconhecimentoDosRiscos/FisicoQuimicoBiologico";
import BaseDadosErgonomia from "./screens/base-dados/ergonomia/Ergonomia";
import BaseDadosAcidente from "./screens/base-dados/acidente/Acidente";
import BaseDadosFormasRisco from "./screens/base-dados/formasRisco/FormasRisco";
import Cadastrar from "./screens/gro/pgr/ensaioVedacao/Cadastrar";
import EnsaioVedacaoEquipamento from "./screens/gro/pgr/ensaioVedacao/Equipamento";
import api from "./service/api";
// import { ModalEstabelecimentos } from "./screens/comercial/clientes/EditarCliente";
// import { Orcamentos } from "./screens/comercial/orcamentos/Orcamentos";

const Login = lazy(() => import("./screens/login/Login"));
const Home = lazy(() => import("./screens/home/Home"));
const Template = lazy(() => import("./screens/template/Template"));

// INFRAESTRUTURA
const Usuarios = lazy(() => import("./screens/empresa/administrativo/usuarios/Usuarios"));
// COMERCIAL
const PrevisoesEntregas = lazy(() => import("./screens/comercial/PrevisoesEntregas"));
const Clientes = lazy(() => import("./screens/comercial/clientes/Clientes"));
const EditarCliente = lazy(() => import("./screens/comercial/clientes/EditarCliente"));
const EditarEstabelecimentos = lazy(() => import("./screens/comercial/clientes/EditarEstabelecimentos"));
const EditarEstabelecimento = lazy(() => import("./screens//comercial/clientes/EditarEstabelecimento"));
const Estabelecimentos = lazy(() => import("./screens/comercial/estabelecimentos/Estabelecimentos"));
const EditaEstabelecimento = lazy(() => import("./screens/comercial/estabelecimentos/EditarEstabelecimento"));
const Informativos = lazy(() => import("./screens/comercial/Informativos"));
//PGR
const Apres = lazy(() => import("./screens/gro/pgr/Apres"));
const ApresPerfil = lazy(() => import("./screens/gro/pgr/apre/ApresPerfil"));
const Acidente = lazy(() => import("./screens/gro/pgr/Acidente"));
const AcidentePerfil = lazy(() => import("./screens/gro/pgr/acidente/AcidentePerfil"));
const PlanoAcaoErgonomia = lazy(() => import("./screens/gro/pgr/planoAcao/ergonomia/PlanoAcaoErgonomia"));
const PlanoAcaoAcidente = lazy(() => import("./screens/gro/pgr/planoAcao/acidente/PlanoAcaoAcidente"));
// PPRA
const ReconhecimentosAmbientes = lazy(() => import("./screens/ppra/reconhecimentos-ambientes/ReconhecimentosAmbientes"));
const Biologico = lazy(() => import("./screens/ppra/reconhecimento/biologico/Biologico"));
const SelecionaPpra = lazy(() => import("./screens/ppra/selecionaPpra/SelecionaPpra"));
const Painel = lazy(() => import("./screens/ppra/Painel"));
const PerfisFuncoes = lazy(() => import("./screens/ppra/perfisFuncoes/PerfisFuncoes"));
const ProdutosQuimicosPpra = lazy(() => import("./screens/ppra/produdosQuimicosPpra/ProdutosQuimicosPpra"));
const Ghes = lazy(() => import("./screens/ppra/ghes/Ghes"));
const GhesErgonomia = lazy(() => import("./screens/gro/pgr/ghe/ergonomia/Ghe"));
const GhesAcidente = lazy(() => import("./screens/gro/pgr/ghe/acidente/Ghe"));
const Linachs = lazy(() => import("./screens/ppra/linachs/Linachs"));
const Cronogramas = lazy(() => import("./screens/ppra/cronograma/Cronogramas"));
// BASE DE DADOS
// const MedidasOrganizacionais = lazy(() =>
//   import("./screens/base-dados/MedidasOrganizacionaisTrabalho/MedidasOrganizacionais")
// );
const MedidasOrganizacionais = lazy(() => import("./screens/base-dados/medidaOrganizacional/MedidasOrganizacionais"));
const Ferramentas = lazy(() => import("./screens/base-dados/ferramentas/Ferramentas"));
const EquipamentosProcessos = lazy(() => import("./screens/base-dados/Equipamentos-processos/EquipamentosProcessos"));
const FrasesPerigo = lazy(() => import("./screens/base-dados/frasesPerigo/FrasesPerigo"));
const Linach = lazy(() => import("./screens/base-dados/linach/Linach"));
const Quimicos = lazy(() => import("./screens/base-dados/produtosQuimicos/ProdutosQuimicosRiscos"));
const TelaEditarFispqs = lazy(() => import("./screens/base-dados/produtosQuimicos/telas/TelaEditarFispqs"));
const Atividades = lazy(() => import("./screens/base-dados/atividades/Atividades"));
const Ambientes = lazy(() => import("./screens/base-dados/ambientes/Ambientes"));

const JornadasTrabalho = lazy(() => import("./screens/base-dados/jornadasTrabalho/JornadasTrabalho"));
const Epcs = lazy(() => import("./screens/base-dados/epcs/Epcs"));
const Epis = lazy(() => import("./screens/base-dados/epis/Epis"));
const Metodos = lazy(() => import("./screens/base-dados/metodosAnalise/MetodosAnalise"));
const Filtros = lazy(() => import("./screens/base-dados/filtros/Filtros"));
const FuncoesClientes = lazy(() => import("./screens/base-dados/funcoesClientes/FuncoesClientes"));
const FuncionariosClientes = lazy(() => import("./screens/base-dados/funcionariosClientes/FuncionariosClientes"));
const RequisitosAplicaveis = lazy(() => import("./screens/base-dados/requisitosAplicaveis/RequisitosAplicaveis"));
const Perigos = lazy(() => import("./screens/base-dados/perigos/Perigos"));
const Danos = lazy(() => import("./screens/base-dados/danos/Danos"));
const Elementos = lazy(() => import("./screens/base-dados/elementos/Elementos"));
const Cas = lazy(() => import("./screens/base-dados/cas/CAs"));
const BaseDadosObjetivosMetas = lazy(() => import("./screens/base-dados/objetivosMetas/ObjetivosMetas"));
const BaseDadosRiscosLocal = lazy(() => import("./screens/base-dados/RiscosLocal/RiscosLocal"));
const BaseDadosFormaAplicacao = lazy(() => import("./screens/base-dados/formaAplicacao/FormaAplicacao"));
// ADMINISTRATIVO
const Colaboradores = lazy(() => import("./screens/empresa/administrativo/colaboradores/Colaboradores"));
const Veiculos = lazy(() => import("./screens/empresa/administrativo/veiculos/Veiculos"));
const EditaVeiculo = lazy(() => import("./screens/empresa/administrativo/veiculos/EditaVeiculo"));
const ReabasteceVeiculo = lazy(() => import("./screens/empresa/administrativo/veiculos/ReabasteceVeiculo"));
const EditaColaborador = lazy(() => import("./screens/empresa/administrativo/colaboradores/EditaColaborador"));
const PerfilColaborador = lazy(() => import("./screens/empresa/administrativo/colaboradores/PerfilColaborador"));
const Servicos = lazy(() => import("./screens/base-dados/servicos/Servicos"));
const Perfil = lazy(() => import("./screens/perfil/Perfil"));
// const Orcamento = lazy(() => import("./screens/comercial/orcamentos/CadastrarOrcamento"));
// PAINEL USUÁRIO
const TarefasPerfis = lazy(() => import("./screens/ppra/perfisFuncoes/tarefas/Tarefas"));
// GRO
const Gro = lazy(() => import("./screens/gro/Painel"));
const Iparo = lazy(() => import("./screens/gro/pgr/Iparo"));
const DocumentoBase = lazy(() => import("./screens/gro/pgr/DocumentoBase"));
const HistoricoRevisao = lazy(() => import("./screens/gro/HistoricoRevisao"));
const PgrRevisao = lazy(() => import("./screens/gro/PgrRevisao"));
const EnsaioVedacao = lazy(() => import("./screens/gro/pgr/EnsaioVedacao"));
const Ppr = lazy(() => import("./screens/gro/pgr/Ppr"));
const FuncionarioEnsaios = lazy(() => import("./screens/gro/pgr/ensaioVedacao/FuncionarioEnsaios"));
const EnsaioVedacaoEditar = lazy(() => import("./screens/gro/pgr/ensaioVedacao/Edicao"));
const ObjetivosMetas = lazy(() => import("./screens/gro/pgr/objetivosMetas/ObjetivosMetas"));
const ObjetivosMetasRevisao = lazy(() => import("./screens/gro/pgr/objetivosMetas/ObjetivosMetasRevisao"));
//Relatorios
const Relatorios = lazy(() => import("./screens/gro/relatorios/relatorios"));

//Suporte
const Ticket = lazy(() => import("./screens/suporte/Ticket"));

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [carregando, setCarregando] = useState(true);
  useEffect(() => {
    api
      .post(`Api/verificaUsuario`, {
        dados: dadosToken(),
      })
      .then((resposta) => {
        if (!resposta.data) {
          logout();
        }
        setCarregando(false);
      });
  }, []);

  return (
    !carregando && (
      <>
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated() ? (
              props.location.pathname === "/" && dadosToken().tipo === "2" ? (
                <Redirect to={{ pathname: verificaPermissaoCliente() }} />
              ) : verificaRota(props.location.pathname) ? (
                <Component {...props} />
              ) : (
                <Redirect to={{ pathname: "/sem-acesso" }} />
              )
            ) : (
              <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
          }
        />
      </>
    )
  );
};

const Routes = () => (
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Home} />
        {/* COMERCIAL */}
        <PrivateRoute exact path="/comercial/previsoes-entrega" component={PrevisoesEntregas} />
        <PrivateRoute exact path="/comercial/clientes" component={Clientes} />
        <PrivateRoute exact path="/comercial/clientes/EditarCliente/:clienteId" component={EditarCliente} />
        <PrivateRoute
          exact
          path="/comercial/clientes/EditarCliente/:clienteId/estabelecimentos"
          component={EditarEstabelecimentos}
        />
        <PrivateRoute
          exact
          path="/comercial/clientes/EditarCliente/:clienteId/editarEstabelecimento/:estabelecimentoId"
          component={EditarEstabelecimento}
        />
        <PrivateRoute exact path="/comercial/Estabelecimentos" component={Estabelecimentos} />
        <PrivateRoute
          exact
          path="/comercial/estabelecimentos/EditarEstabelecimento/:estabelecimentoId"
          component={EditaEstabelecimento}
        />
        <PrivateRoute exact path="/comercial/Informativos" component={Informativos} />
        {/* Informativos */}
        {/* <PrivateRoute exact path={"/comercial/orcamento/"} component={Orcamento} /> */}
        {/* <PrivateRoute exact path={"/comercial/orcamentos/"} component={Orcamentos} /> */}

        {/* GRO */}
        <PrivateRoute exact path="/gro/seleciona-cliente" component={SelecionaPpra} />
        <PrivateRoute exact path="/gro/seleciona-cliente/cliente/:clienteId" component={SelecionaPpra} />
        <PrivateRoute
          exact
          path="/gro/seleciona-cliente/cliente/:clienteId/estabelecimento/:estabelecimentoId"
          component={SelecionaPpra}
        />
        <PrivateRoute
          exact
          path="/gro/seleciona-cliente/cliente/:clienteId/estabelecimento/:estabelecimentoId/contrato/:contratoId"
          component={SelecionaPpra}
        />
        <PrivateRoute exact path="/gro/:ppraId/iparo" component={Iparo} />
        <PrivateRoute exact path="/gro/:ppraId/iparo/:iparoId" component={Iparo} />
        <PrivateRoute exact path="/gro/:clienteId/painel" component={Gro} />
        <PrivateRoute exact path="/gro/:clienteId/pgr" component={Pgr} />
        <PrivateRoute exact path="/gro/:clienteId/pgr/historico-revisao/:ppraId" component={PgrRevisao} />
        <PrivateRoute exact path="/gro/:clienteId/programas" component={Programas} />
        <PrivateRoute exact path="/gro/relatorios" component={Relatorios} />

        {/*Rotas atuais*/}
        <PrivateRoute exact path="/gro/:clienteId/objetivosMetas/" component={ObjetivosMetas} />
        <PrivateRoute exact path="/gro/:clienteId/objetivosMetas/:ppraId" component={ObjetivosMetas} />
        <PrivateRoute exact path="/gro/:clienteId/objetivosMetas/revisao/:revisao_id" component={ObjetivosMetasRevisao} />

        <PrivateRoute exact path="/pgr/:ppraId/checklist" component={Checklist} />
        <PrivateRoute exact path="/pgr/:ppraId/fisicoQuimicoBiologico" component={FisicoQuimicoBiologico} />
        <PrivateRoute exact path="/gro/:clienteId/pgr/documento-base" component={DocumentoBase} />
        <PrivateRoute exact path="/gro/:clienteId/pgr/historico-revisao" component={HistoricoRevisao} />
        <PrivateRoute exact path="/gro/:clienteId/pgr/ensaio-vedacao/:clienteEstabelecimentoId" component={EnsaioVedacao} />
        <PrivateRoute exact path="/gro/:clienteId/pgr/ppr" component={Ppr} />
        <PrivateRoute
          exact
          path="/gro/:clienteId/pgr/ensaio-vedacao/:clienteEstabelecimentoId/funcionario/:funcionarioId"
          component={FuncionarioEnsaios}
        />
        <PrivateRoute exact path="/gro/:clienteId/pgr/ensaio-vedacao/:ensaioVedacaoId/edicao" component={EnsaioVedacaoEditar} />
        <PrivateRoute exact path="/gro/:clienteId/pgr/ensaio-vedacao/cadastro/:clienteEstabelecimentoId" component={Cadastrar} />
        <PrivateRoute exact path="/gro/pgr/ensaio-vedacao/equipamento" component={EnsaioVedacaoEquipamento} />
        <PrivateRoute exact path="/gro/:clienteId/pgr/documento-base/:clienteEstabelecimentoId" component={DocumentoBase} />
        <PrivateRoute exact path="/ppra/:ppraId/acidente" component={Acidente} />
        <PrivateRoute exact path="/ppra/:ppraId/acidente/perfil/:perfilFuncaoId/tarefas" component={AcidentePerfil} />
        <PrivateRoute exact path="/ppra/:ppraId/planoAcaoErgonomia" component={PlanoAcaoErgonomia} />
        <PrivateRoute exact path="/ppra/:ppraId/planoAcaoAcidente" component={PlanoAcaoAcidente} />
        {/*  PPRA */}
        <PrivateRoute exact path="/ppra/:ppraId/ergonomia" component={Apres} />
        <PrivateRoute exact path="/ppra/:ppraId/ergonomia/perfil/:perfilFuncaoId/tarefas" component={ApresPerfil} />
        <PrivateRoute exact path="/ppra/:ppraId/reconhecimentos" component={Setores} />
        <PrivateRoute exact path="/ppra/:ppraId/reconhecimentos/biologicos" component={Biologico} />
        <PrivateRoute exact path="/ppra/:ppraId/painel" component={Painel} />
        <PrivateRoute exact path="/ppra/:ppraId/produtos-quimicos-ppra" component={ProdutosQuimicosPpra} />
        <PrivateRoute exact path="/ppra/:ppraId/reconhecimentos-ambientes" component={ReconhecimentosAmbientes} />
        <PrivateRoute exact path="/ppra/:ppraId/perfis-funcoes" component={PerfisFuncoes} />
        <PrivateRoute exact path="/ppra/:ppraId/perfis-funcoes/:perfilFuncaoId/tarefas" component={TarefasPerfis} />
        <PrivateRoute exact path="/ppra/:ppraId/ghes" component={Ghes} />
        <PrivateRoute exact path="/ppra/:ppraId/ghesErgonomia" component={GhesErgonomia} />
        <PrivateRoute exact path="/ppra/:ppraId/ghesAcidente" component={GhesAcidente} />
        <PrivateRoute exact path="/ppra/:ppraId/linachs" component={Linachs} />
        <PrivateRoute exact path="/ppra/:ppraId/cronograma" component={Cronogramas} />
        {/* BASE DE DADOS */}
        <PrivateRoute exact path={urlMedidasOrganizacionais} component={MedidasOrganizacionais} />
        <PrivateRoute exact path={urlLinach} component={Linach} />
        <PrivateRoute exact path={urlProdutosQuimicos} component={Quimicos} />
        <PrivateRoute exact path={urlProdutosQuimicos + "/:produtoQuimicoId"} component={TabsProdutoQuimico} />
        <PrivateRoute exact path={urlProdutosQuimicos + "/:produtoQuimicoId/fispq/:id"} component={TelaEditarFispqs} />
        <PrivateRoute exact path={urlFerramentas} component={Ferramentas} />
        <PrivateRoute exact path={urlEquipamentosProcessos} component={EquipamentosProcessos} />
        <PrivateRoute exact path={urlFrasesPerigo} component={FrasesPerigo} />
        <PrivateRoute exact path={urlAtividades} component={Atividades} />
        <PrivateRoute exact path={urlAmbientes} component={Ambientes} />
        <PrivateRoute exact path={urlServicos} component={Servicos} />
        <PrivateRoute exact path={urlServicos + "/:servicoId"} component={CadastrosServico} />
        <PrivateRoute exact path={urlJornadasTrabalho} component={JornadasTrabalho} />
        <PrivateRoute exact path={urlAtividadesEspeciais} component={AtividadesEspeciais} />
        <PrivateRoute exact path={urlEpcs} component={Epcs} />
        <PrivateRoute exact path={urlEpis} component={Epis} />
        <PrivateRoute exact path={urlMetodosAnalise} component={Metodos} />
        <PrivateRoute exact path={urlFiltrosMascaras} component={Filtros} />
        <PrivateRoute exact path={urlFuncoesClientes} component={FuncoesClientes} />
        <PrivateRoute exact path={urlFuncoesClientes + "/:clienteId"} component={FuncoesClientes} />
        <PrivateRoute exact path={urlFuncionariosClientes} component={FuncionariosClientes} />
        <PrivateRoute exact path={urlFuncionariosClientes + "/:clienteId"} component={FuncionariosClientes} />
        <PrivateRoute exact path={urlRequisitosAplicaveis} component={RequisitosAplicaveis} />
        <PrivateRoute exact path={urlPerigos} component={Perigos} />
        <PrivateRoute exact path={urlDanos} component={Danos} />
        <PrivateRoute exact path={urlElementos} component={Elementos} />
        <PrivateRoute exact path={urlCas} component={Cas} />
        <PrivateRoute exact path={urlEfeitosSaude} component={EfeitosSaude} />
        <PrivateRoute exact path={urlChecklistPerguntas} component={ChecklistPerguntas} />
        <PrivateRoute exact path={urlFabricanteQuimico} component={FabricanteQuimico} />
        <PrivateRoute exact path={urlBaseDadosErgonomia} component={BaseDadosErgonomia} />
        <PrivateRoute exact path={urlBaseDadosAcidente} component={BaseDadosAcidente} />
        <PrivateRoute exact path={urlBaseDadosFormasRisco} component={BaseDadosFormasRisco} />
        <PrivateRoute exact path={urlBaseDadosObjetivosMetas} component={BaseDadosObjetivosMetas} />
        <PrivateRoute exact path={urlBaseDadosRiscosLocal} component={BaseDadosRiscosLocal} />
        <PrivateRoute exact path={urlBaseDadosFormaAplicacao} component={BaseDadosFormaAplicacao} />

        {/* ADMINISTRATIVO */}
        <PrivateRoute exact path={urlUsuarios} component={Usuarios} />
        <PrivateRoute exact path={urlColaboradores} component={Colaboradores} />
        <PrivateRoute exact path={urlColaboradores + "/:idColaborador"} component={EditaColaborador} />
        <PrivateRoute exact path={urlColaboradores + "/:idColaborador/perfil"} component={PerfilColaborador} />
        <PrivateRoute exact path={urlVeiculos} component={Veiculos} />
        <PrivateRoute exact path={urlVeiculos + "/cadastrar"} component={EditaVeiculo} />
        <PrivateRoute exact path={urlVeiculos + "/:veiculoId/editar"} component={EditaVeiculo} />
        <PrivateRoute exact path={urlVeiculos + "/:veiculoId/reabastecer"} component={ReabasteceVeiculo} />

        {/* Suporte */}
        <PrivateRoute exact path="/suporte/ticket" component={Ticket} />

        {/* Perfil */}
        <PrivateRoute exact path={"/painel/perfil/:colaboradorId"} component={Perfil} />
        <Route
          exact
          path="/sem-acesso"
          component={() => (
            <div>
              <h1 className="text-center">
                Ops, parece que você não tem acesso a essa página!
                <br />
                <Botao
                  conteudo="Voltar Para Página Inicial"
                  alvo={dadosToken().tipo === "2" ? verificaPermissaoCliente() : "/"}
                  router
                />
              </h1>
              <h6 className="text-center mt-5">
                Caso houver problemas, tente relogar e acessar novamente.
                <br />
                <Botao className="mt-3" conteudo="Suporte Tecnico" alvo={"https://www.tegis.com.br/contato.html"} />
              </h6>
            </div>
          )}
        />
        <PrivateRoute
          path="*"
          component={() => (
            <Template>
              <h1 className="text-center">Ops, página não encontrada!</h1>
            </Template>
          )}
        />
      </Switch>
    </Suspense>
  </BrowserRouter>
);
export default Routes;
