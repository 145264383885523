import React, { useEffect, useState } from "react";

import api from "../../../service/api";
import Filtros from "../components/Filtros";
import TabelaPadrao from "../../../components/TabelaPadrao";
import { filtrar, isEmpty } from "../../../components/Util";
import Loader from "../../../components/Loader";

import Botao, { BotaoAdicionar, BotaoDeletar, BotaoRemover } from "../../../components/Botao";

const Riscos = ({ matematicaId, riscoId }) => {
  const [risco, setRisco] = useState([]);
  const [dadosFispq, setDadosFispq] = useState();
  const [filtroRiscos, setFiltroRiscos] = useState("");
  const [filtroRiscosNaoCadastrados, setFiltroRiscosNaoCadastrados] = useState("");
  const [showSobre, setShowSobre] = useState(false);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    api.get(`/ppra/Severidade/obterRiscos/${matematicaId}`).then((resposta) => {
      setRisco(resposta.data);
      if (riscoId === "2") {
        api.get(`/ppra/Severidade/obterDadosFispq/${matematicaId}`).then((resposta) => {
          setDadosFispq(resposta.data);
          setCarregando(false);
        });
      } else {
        setCarregando(false);
      }
    });
  }, [matematicaId, riscoId]);

  function buscarDados() {
    api.get(`/ppra/Severidade/obterRiscos/${matematicaId}`).then((resposta) => {
      setRisco(resposta.data);
      if (riscoId === "2") {
        api.get(`/ppra/Severidade/obterDadosFispq/${matematicaId}`).then((resposta) => {
          setDadosFispq(resposta.data);
          setCarregando(false);
        });
      } else {
        setCarregando(false);
      }
    });
  }

  let riscosCadastrados = risco?.cadastrados?.filter((filtro) => filtrar(filtro.definicao, filtroRiscos));

  let riscosNaoCadastrados = risco?.nao_cadastrados?.filter(
    (filtro) => !isEmpty(filtro.adjetivos) && filtrar(filtro.definicao, filtroRiscosNaoCadastrados)
  );

  let riscos = riscosNaoCadastrados?.filter(
    (filtro) => filtrar(filtro.definicao, filtroRiscosNaoCadastrados) || filtrar(filtro.id, filtroRiscosNaoCadastrados)
  );

  function filtrarRiscosNaoCadastrados(newFilter) {
    setFiltroRiscosNaoCadastrados(newFilter);
  }

  const filtrarRiscos = (newFilter) => {
    setFiltroRiscos(newFilter);
  };

  async function importarRiscosFispq(fispqId) {
    setCarregando(true);
    await api.post(`/ppra/Severidade/importarRiscoFispq/${fispqId}/${matematicaId}`).then(() => {
      api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
        buscarDados();
        setCarregando(false);
      });
    });
  }

  async function exportarRiscosFispq(fispqId) {
    setCarregando(true);
    await api.post(`/ppra/Severidade/exportarRiscoFispq/${fispqId}/${matematicaId}`).then(() => {
      api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
        buscarDados();
        setCarregando(false);
      });
    });
  }

  async function atualizarRiscosFispq(fispqId) {
    setCarregando(true);
    await api.delete(`/ppra/Severidade/atualizarRiscosFispq/${fispqId}/${matematicaId}`).then(() => {
      api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
        buscarDados();
        setCarregando(false);
      });
    });
  }

  async function deletarTodosRiscos(matematicaId) {
    setCarregando(true);
    await api.delete(`/ppra/Severidade/deletarTodosRiscos/${matematicaId}`).then(() => {
      api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
        buscarDados();
        setCarregando(false);
      });
    });
  }

  const RiscosRow = ({ risco, index, adiciona, matematicaId }) => {
    const [mostrarRiscos, setMostrarRiscos] = useState(false);

    async function inserirRisco(risco) {
      setCarregando(true);
      await api.post(`/ppra/Severidade/inserirRiscoAtividade/${matematicaId}`, risco.adjetivos).then(() => {
        api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
          buscarDados();
          setCarregando(false);
        });
      });
    }

    async function deletarRisco(risco) {
      setCarregando(true);
      await api.delete(`/ppra/Severidade/deletarRiscoAtividade/${matematicaId}/${risco.id}`).then(() => {
        api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
          buscarDados();
          setCarregando(false);
        });
      });
    }

    function handleClick() {
      setMostrarRiscos(!mostrarRiscos);
    }

    return (
      <>
        <tr index={index}>
          {!adiciona ? (
            <>
              {risco.riscos_adjetivos_local_severidade_status === "I" ? (
                <>
                  <td className="table-warning">{risco.definicao} </td>
                  <td className="table-warning">
                    <AdjetivosPorRiscoCadastrado
                      risco={risco}
                      matematicaId={matematicaId}
                      buscarDados={buscarDados}
                      adiciona={adiciona}
                    />
                  </td>
                  <td className={"text-center table-warning"}>
                    <BotaoDeletar onClick={() => deletarRisco(risco)} />
                  </td>
                </>
              ) : (
                <>
                  <td>{risco.definicao}</td>
                  <td>
                    <AdjetivosPorRiscoCadastrado
                      risco={risco}
                      matematicaId={matematicaId}
                      buscarDados={buscarDados}
                      adiciona={adiciona}
                    />
                  </td>
                  <td className={"text-center"}>
                    <BotaoDeletar onClick={() => deletarRisco(risco)} />
                  </td>
                </>
              )}
            </>
          ) : (
            <>
              <td onClick={() => handleClick()} style={{ cursor: "pointer" && "pointer" }}>
                {risco.definicao}
              </td>
              <td className={"text-center"}>
                <BotaoAdicionar onClick={() => inserirRisco(risco, matematicaId)} />
              </td>
            </>
          )}
        </tr>
        {mostrarRiscos && (
          <AdjetivosPorRisco risco={risco} matematicaId={matematicaId} buscarDados={buscarDados} adiciona={adiciona} />
        )}
      </>
    );
  };

  const AdjetivosPorRisco = ({ risco, matematicaId, adiciona, buscarDados }) => {
    async function inserirAdjetivoRisco(adjetivo) {
      setCarregando(true);

      await api
        .post(`/ppra/Severidade/inserirAdjetivoRisco`, {
          matematica_id: matematicaId,
          riscos_adjetivos_local_severidade_id: adjetivo.id,
        })
        .then(() => {
          api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
            buscarDados();
            setCarregando(false);
          });
        });
    }

    async function deletarAdjetivoRisco(adjetivo) {
      setCarregando(true);
      await api
        .delete(`/ppra/Severidade/deletarAdjetivoRisco/${adjetivo.matematica_risco_adjetivo_id}/${matematicaId}`)
        .then(() => {
          api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
            buscarDados();
            setCarregando(false);
          });
        });
    }

    return (
      <>
        {isEmpty(risco?.adjetivos) ? (
          <tr className="table-warning">
            <td>Nenhum adjetivo cadastrado</td>
            <td> - </td>
          </tr>
        ) : (
          risco?.adjetivos.map((adjetivo) => (
            <tr className="table-primary">
              <td>{" - " + adjetivo?.adjetivo} <span className="badge bg-primary">{" Severidade: " + adjetivo?.grau_risco}</span></td>
              <td className="text-center">
                {adiciona ? (
                  <BotaoAdicionar
                    onClick={() => {
                      inserirAdjetivoRisco(adjetivo);
                    }}
                  />
                ) : (
                  <BotaoRemover
                    onClick={() => {
                      deletarAdjetivoRisco(adjetivo);
                    }}
                  />
                )}
              </td>
            </tr>
          ))
        )}
      </>
    );
  };

  const AdjetivosPorRiscoCadastrado = ({ risco, matematicaId, adiciona, buscarDados }) => {
    async function inserirAdjetivoRisco(adjetivo) {
      setCarregando(true);

      await api
        .post(`/ppra/Severidade/inserirAdjetivoRisco`, {
          matematica_id: matematicaId,
          riscos_adjetivos_local_severidade_id: adjetivo.id,
        })
        .then(() => {
          api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
            buscarDados();
            setCarregando(false);
          });
        });
    }

    async function deletarAdjetivoRisco(adjetivo) {
      setCarregando(true);
      await api
        .delete(`/ppra/Severidade/deletarAdjetivoRisco/${adjetivo.matematica_risco_adjetivo_id}/${matematicaId}`)
        .then(() => {
          api.get(`/ppra/Severidade/obterSeveridade/${matematicaId}`).then(() => {
            buscarDados();
            setCarregando(false);
          });
        });
    }

    return (
      <>
        {isEmpty(risco?.adjetivos) ? (
          <tr className="table-warning">
            <td>Nenhum adjetivo cadastrado</td>
            <td> - </td>
          </tr>
        ) : (
          risco?.adjetivos.map((adjetivo) => (
            <tr className="d-flex justify-content-between">
              <td className="p-3">{" - " + adjetivo?.adjetivo}<span className="ml-1 badge bg-primary">{adjetivo?.grau_risco}</span></td>
              <td className="justify-content-between">
                {adiciona ? (
                  <BotaoAdicionar
                    onClick={() => {
                      inserirAdjetivoRisco(adjetivo);
                    }}
                  />
                ) : (
                  <BotaoRemover
                    className={"text-center"}
                    onClick={() => {
                      deletarAdjetivoRisco(adjetivo);
                    }}
                  />
                )}
              </td>
            </tr>
          ))
        )}
      </>
    );
  };

  return (
    <>
      {!isEmpty(dadosFispq?.efeitos_saude) && (
        <>
          <div className="row">
            <div className="col">
              <label htmlFor="risco" className="col-form-label">
                <span className="badge bg-primary fs-6">Frase cadastrada na FISPQ:</span> {dadosFispq?.efeitos_saude}
              </label>
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className="col mt-3">
          <Botao conteudo={"Sobre o sistema"} icone="info-circle" cor="secondary" onClick={() => setShowSobre(!showSobre)} />
        </div>

        {isEmpty(risco?.cadastrados) ? (
          <>
            {!isEmpty(dadosFispq?.riscos_cadastrados_fispq) && (
              <>
                <div className="col-auto text-end">
                  <Botao
                    conteudo={"Importar riscos da fispq"}
                    icone="download"
                    cor="success"
                    onClick={() => {
                      const riscosDesatualizados = dadosFispq?.riscos_cadastrados_fispq.find(
                        (item) => item.riscos_adjetivos_local_severidade_status === "I"
                      );

                      if (isEmpty(riscosDesatualizados)) {
                        importarRiscosFispq(dadosFispq?.fispqId);
                      } else {
                        if (window.confirm(`Existem riscos desatualizados na fispq, deseja importar mesmo assim?`)) {
                          importarRiscosFispq(dadosFispq?.fispqId);
                        }
                      }
                    }}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {riscoId === "2" &&
              (isEmpty(dadosFispq?.riscos_cadastrados_fispq) ? (
                <>
                  <div className="col-auto text-end">
                    <Botao
                      conteudo={"Exportar riscos para fispq"}
                      icone="upload"
                      cor="success"
                      onClick={() => exportarRiscosFispq(dadosFispq?.fispqId, risco?.cadastrados)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-auto text-end">
                    <Botao
                      conteudo={"Atualizar fispq x adjetivos"}
                      icone="refresh"
                      cor="warning"
                      onClick={() => atualizarRiscosFispq(dadosFispq?.fispqId, risco?.cadastrados)}
                    />
                  </div>
                </>
              ))}

            <div className="col-auto">
              <Botao
                className="float-end mr-4"
                icone="trash"
                cor="danger"
                conteudo={"Deletar todos os riscos"}
                onClick={() => deletarTodosRiscos(matematicaId)}
              />
            </div>
          </>
        )}
      </div>

      {showSobre && (
        <div className="row mt-2">
          <div className="row">
            <div className="col">
              <span className="badge mb-1" style={{ backgroundColor: "#003a72" }}>
                Severidade:
              </span>{" "}
              <span style={{ fontSize: "9pt" }}>
                Severidade é gerada apartir dos adjetivos cadastrados ao risco selecionado, considerando a maior severidade.
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <Filtros
          itens={[
            {
              placeholder: "Não adicionados",
              filtrar: filtrarRiscosNaoCadastrados,
            },
            {
              placeholder: "Adicionados",
              filtrar: filtrarRiscos,
            },
          ]}
        />
      </div>

      <div className="row">
        <div className="col">
          <TabelaPadrao
            fontSize="14px"
            itens={riscosNaoCadastrados}
            itensFiltrados={riscosNaoCadastrados}
            filtro={filtroRiscosNaoCadastrados}
            carregando={carregando}
            colunas={[{ nome: "Riscos" }, { nome: "Ações", width: "13%", text: "center" }]}
          >
            {carregando ? (
              <Loader />
            ) : (
              <>
                {riscos?.map((item, index) => (
                  <RiscosRow risco={item} index={index} adiciona matematicaId={matematicaId} />
                ))}
              </>
            )}
          </TabelaPadrao>
        </div>
        <div className="col">
          <TabelaPadrao
            fontSize="14px"
            itens={riscosCadastrados}
            itensFiltrados={riscosCadastrados}
            filtro={filtroRiscos}
            carregando={carregando}
            colunas={[{ nome: "Riscos" }, { nome: <>Adjetivos <span className="ml-1 badge bg-primary">Severidade</span></>, width: "45%" }, { nome: "Ações", width: "13%", text: "center" }]}
          >
            {carregando ? (
              <Loader />
            ) : (
              <>
                {riscosCadastrados?.map((item, index) => (
                  <RiscosRow
                    risco={item}
                    index={index}
                    matematicaId={matematicaId}
                    setCarregando={setCarregando}
                    buscarDados={buscarDados}
                  />
                ))}
              </>
            )}
          </TabelaPadrao>
        </div>
      </div>
    </>
  );
};

export default Riscos;
