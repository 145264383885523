import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import Botao, { BotaoAdicionar, BotaoDeletar, BotaoEditar, BotaoGrupo, BotaoSalvar, BotaoVoltar } from "../../../components/Botao";
import { dadosToken, isEmpty, TratarData } from "../../../components/Util";
import { urlProdutosQuimicos } from "../../../routes/UrlBaseDados";
import InputIcone from "../../../components/InputIcone";
import SpanBadge from "../../../components/SpanBadge";
import TabelaPadrao from "../../../components/TabelaPadrao";
import api from "../../../service/api";
import ModalProdutos from "./tabs/ModalProdutos";
import { isLaurence } from "../../../service/auth";

const ProdutoQuimico = ({ produto, buscarProdutoQuimico }) => {
  const [criarFispq, setCriarFispq] = useState(isEmpty(produto.fispqs) ? true : false);

  let verificaPermissao = isLaurence() || ["117", "118", "119", "26"].includes(dadosToken().funcao_id);

  return (
    <>
      <ModalProdutos produto={produto} buscarProdutoQuimico={buscarProdutoQuimico} />
      <div className="row text-end my-4">
        <div className="col">
          {criarFispq ? (
            <BotaoVoltar temConteudo onClick={() => setCriarFispq(false)} />
          ) : (
            <BotaoAdicionar temConteudo conteudo="FISPQ" onClick={() => setCriarFispq(true)} />
          )}
        </div>
      </div>
      {criarFispq ? <CriarFispq setCriarFispq={setCriarFispq} produto={produto} /> : <ListaFispqs produto={produto} verificaPermissao={verificaPermissao} />}
    </>
  );
};

const CriarFispq = ({ setShow, setCriarFispq, produto }) => {
  const [nomeQuimico, setNomeQuimico] = useState(null);
  const [data, setData] = useState(null);
  const [revisao, setRevisao] = useState(null);
  const [observacao, setObservacao] = useState(null);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let dados = {
      produto_quimico_id: produto.id,
      nome_quimico: nomeQuimico,
      data: data,
      revisao: revisao,
      observacao: observacao,
    };

    let url = api.post("/baseDados/fispqs/inserir", dados);

    await url
      .then((resposta) => {
        history.push(urlProdutosQuimicos + `/${produto.id}/fispq/${resposta.data.dados.id}`);
        setCriarFispq(false);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>Nome quimíco:</label>
          <InputIcone tipo="text" icone="flask" placeholder="Digite o nome quimíco" onChange={(e) => setNomeQuimico(e.target.value)} />
        </div>
        <div className="col-3">
          <label>Data:</label>
          <InputIcone tipo="date" icone="calendar-alt" onChange={(e) => setData(e.target.value)} max="9999-01-01" />
        </div>
        <div className="col-3">
          <label>Revisão:</label>
          <div className="input-group flex-nowrap mb-3">
            <InputIcone tipo="text" icone="history" maxLength="8" placeholder="-" onChange={(e) => setRevisao(e.target.value)} />
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <label>Observação:</label>
          <div className="input-group flex-nowrap mb-3">
            <span className="input-group-text" id="addon-wrapping">
              <i className="fa fa-clipboard" aria-hidden="true"></i>
            </span>
            <textarea
              className="form-control"
              tipo="text"
              icone="radiation"
              placeholder="Digite alguma observação aqui"
              onChange={(e) => setObservacao(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="float-end">
        <BotaoSalvar temConteudo="Salvar" onClick={handleSubmit} />
      </div>
    </form>
  );
};

const ListaFispqs = ({ produto, verificaPermissao }) => {
  const [fispqs, setFispqs] = useState([]);
  const [carregarBotaoStatusFispq, setCarregarBotaoStatusFispq] = useState(false);
  const [carregarBotaoStatusRisco, setCarregarBotaoStatusRisco] = useState(false);

  let history = useHistory();

  let cursor = { cursor: "pointer" };

  const goToEditar = (id) => {
    history.push(`${urlProdutosQuimicos}/${produto.id}/fispq/${id}`);
  };

  useEffect(() => {
    carregarFispqs(produto.id);
  }, [produto.id]);

  const carregarFispqs = async (produtoId) => {
    await api.get("/baseDados/fispqs", { params: { produto_quimico_id: produtoId } }).then((resposta) => {
      setFispqs(resposta.data);
    });
  };

  function mudarStatusBotao(novoValor, fispq, tipo) {
    if (tipo === "fispq") {
      setCarregarBotaoStatusFispq(true);
      api
        .put(`/baseDados/fispqs/editarStatusFispq/${fispq.id}`, {
          fispq_status: novoValor,
          risco_status: 1,
        })
        .then(() => {
          setCarregarBotaoStatusFispq(false);
          carregarFispqs(produto.id);
        });
      
    } else {
      setCarregarBotaoStatusRisco(true);
      api
        .put(`/baseDados/fispqs/editarStatusFispq/${fispq.id}`, {
          fispq_status: 0,
          risco_status: novoValor,
        })
        .then(() => {
          setCarregarBotaoStatusRisco(false);
          carregarFispqs(produto.id);
        });
    }
  }

  async function deletar(fispqSelecionada) {
    if (window.confirm(`Tem certeza que deseja remover a FISPQ selecionada?`)) {
      await api
        .delete(`/baseDados/fispqs/deletar/${fispqSelecionada.id}`)
        .then(() => {
          let resto = fispqs.filter((fispq) => fispq.id !== fispqSelecionada.id);
          setFispqs(resto);
        })
        .catch((erro) => {
          alert(erro.response.data.mensagem);
        });
    }
  }

  return (
    <TabelaPadrao
      noOverflowAuto
      fontSize="14px"
      itens={fispqs}
      colunas={[
        { nome: "Data", width: "8%", text: "center" },
        { nome: "Revisão", width: "8%", text: "center" },
        { nome: "Atende", width: "8%", text: "center" },
        { nome: "Observação", width: "40%" },
        { nome: "FISPQ atualizada", width: "8%", text: "center" },
        { nome: "Risco atualizado", width: "8%", text: "center" },
        { nome: "Ações", width: "8%", text: "center" },
      ]}
    >
      {fispqs?.map((fispq, index) => (
        <tr key={index}>
          <td className="text-center" style={cursor} onClick={() => goToEditar(fispq.id)}>
            {isEmpty(fispq.data) ? "-" : TratarData(fispq.data)}
          </td>
          <td className="text-center" style={cursor} onClick={() => goToEditar(fispq.id)}>
            {isEmpty(fispq.revisao) ? "-" : fispq.revisao}
          </td>
          <td
            className="text-center"
            style={cursor}
            onClick={() => goToEditar(fispq.id)}
          >
            {isEmpty(fispq.atende) ? (
              <SpanBadge
                texto={"Sim"}
                cor={"success"}
              />
            ) : (
              <SpanBadge
                texto={"Não"}
                cor={"danger"}
              />
            )}
          </td>
          <td style={cursor} onClick={() => goToEditar(fispq.id)}>
            {fispq.observacao === null ? "-" : fispq.observacao}
          </td>
          <td className="text-center" style={cursor}>
            <Botao
              isLoading={carregarBotaoStatusFispq}
              disabled={carregarBotaoStatusFispq || !verificaPermissao}
              cor={fispq.fispq_status === "1" ? "success" : "danger"}
              conteudo={fispq.fispq_status === "1" ? "Sim" : "Não"}
              onClick={() => {
                setCarregarBotaoStatusFispq(true);
                mudarStatusBotao(fispq.fispq_status === "1" ? 0 : 1, fispq, "fispq");
              }}
            />
          </td>
          <td className="text-center" style={cursor}>
            <Botao
              isLoading={carregarBotaoStatusRisco}
              disabled={carregarBotaoStatusRisco || !verificaPermissao}
              cor={fispq.risco_status === "1" ? "success" : "danger"}
              conteudo={fispq.risco_status === "1" ? "Sim" : "Não"}
              onClick={() => {
                mudarStatusBotao(fispq.risco_status === "1" ? 0 : 1, fispq, "risco");
              }}
            />
          </td>
          <td className="text-center">
            <BotaoGrupo>
              <BotaoEditar onClick={() => goToEditar(fispq.id)} />
              {fispq.fispq_status === "1" ? (
                verificaPermissao ? (
                  <BotaoDeletar onClick={() => deletar(fispq)} />
                ) : (
                  <BotaoDeletar onClick={() => deletar(fispq)} disabled={!verificaPermissao} />
                )
              ) : (
                <>
                  <BotaoDeletar onClick={() => deletar(fispq)} />
                </>
              )}
            </BotaoGrupo>
          </td>
        </tr>
      ))}
    </TabelaPadrao>
  );
};

export default ProdutoQuimico;
