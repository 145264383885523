import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import ModalPadrao from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar, isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import { BotaoAdicionar, BotaoDeletar } from "../../../../components/Botao";
import Filter from "../../../../components/Filter";

const ModalErgonomiaPerigoMedidaControleNecessaria = ({
  show,
  setShow,
  tituloSpan,
  titulo,
  corTitulo,
  perigoMedidaControleNecessaria,
  buscarPerigosMedidaControleNecessaria,
}) => {
  const [medidasControleNecessarias, setMedidasControleNecessarias] = useState();
  const [filtro, setFiltro] = useState("");
  const [filtroNaoCadastrados, setFiltroNaoCadastrados] = useState("");
  const [carregando, setCarregando] = useState(false);

  const perigoId = perigoMedidaControleNecessaria.id;

  useEffect(() => {
    api.get(`/baseDados/ergonomiaBaseDados/buscarPerigosMedidasControle/${perigoId}`).then((resposta) => {
      setMedidasControleNecessarias(resposta.data);
    });
  }, [perigoId]);

  async function buscarDados() {
    setCarregando(true);
    await api.get(`/baseDados/ergonomiaBaseDados/buscarPerigosMedidasControle/${perigoId}`).then((resposta) => {
      setMedidasControleNecessarias(resposta.data);
      setCarregando(false);
    });
  }

  const medidasCadastradaFiltradas = medidasControleNecessarias?.cadastrados?.filter((risco) =>
    filtrar(risco.codigo + " " + risco.definicao, filtro)
  );

  function filtrarMedidaCadastrada(newFilter) {
    setFiltro(newFilter);
  }

  const medidasNaoCadastradasfiltradas = medidasControleNecessarias?.nao_cadastrados?.filter((risco) =>
    filtrar(risco.codigo + " " + risco.definicao, filtroNaoCadastrados)
  );

  function filtroMedidasNaoCadastradas(newFilter) {
    setFiltroNaoCadastrados(newFilter);
  }

  const opcoesResultadoMatematica = [
    {
      value: 0,
      label: "Nenhum",
    },
    {
      value: 1,
      label: "1 - Insignificante",
    },
    {
      value: 2,
      label: "2 - Leve",
    },
    {
      value: 3,
      label: "3 - Moderado",
    },
    {
      value: 4,
      label: "4 - Alto",
    },
    {
      value: 5,
      label: "5 - Grave",
    },
  ];

  function verificarResultadosMatematicas(resultado) {
    let retorno;
    isEmpty(resultado)
      ? (retorno = { value: 0, label: "" })
      : (retorno = opcoesResultadoMatematica.find((opcao) => opcao.value === parseInt(resultado)));

    return retorno;
  }

  function determinarCorResultadoMatematica(resultado) {
    switch (parseInt(resultado)) {
      case 1:
        return "#0099DD"; // Azul
      case 2:
        return "#04D939"; // Verde
      case 3:
        return "#EAF205"; // Amarelo
      case 4:
        return "#F28705"; // Laranja
      case 5:
        return "#BF3604"; // Vermelho
      default:
        return "#FFFFFF"; // Cor padrão
    }
  }

  async function handleAdiciona(medidaControleNecessariaId, tipo) {
    await api
      .post(`/baseDados/ergonomiaBaseDados/inserirPerigoMedidaControleNecessaria/${tipo}`, {
        perigo_definicao_id: perigoId,
        medida_controle_necessaria_id: medidaControleNecessariaId,
      })
      .then(() => {
        buscarDados();
        buscarPerigosMedidaControleNecessaria();
        toast.success("Medida de controle vinculada!");
      });
  }

  async function handleEdita(vinculoId, tipo, resultado) {
    if (resultado === 0) {
      await api.delete(`/baseDados/ergonomiaBaseDados/deletarMedidaControleVinculada/${vinculoId}/${tipo}`).then(() => {
        buscarDados();
        buscarPerigosMedidaControleNecessaria();
        toast.success(tipo === "A" ? "Medida de controle Administrativa deletada!" : "Medida Operacional deletada!");
      });
    } else {
      await api
        .put(`/baseDados/ergonomiaBaseDados/editarMedidaControleVinculada/${vinculoId}/${tipo}`, {
          resultado_matematica: resultado,
        })
        .then(() => {
          buscarDados();
          buscarPerigosMedidaControleNecessaria();
          toast.success(
            tipo === "A" ? "Medida de controle Administrativa editada!" : "Medida de controle Operacional editada!"
          );
        });
    }
  }

  async function handleDeleta(vinculoId, vinculoOperacionalId) {
    await api
      .delete(`/baseDados/ergonomiaBaseDados/deletarMedidaControleVinculAdministrativo/${vinculoId}`)
      .then(() => {
        api
          .delete(`/baseDados/ergonomiaBaseDados/deletarMedidaControleVinculoOperacional/${vinculoOperacionalId}`)
          .then(() => {
            buscarDados();
            buscarPerigosMedidaControleNecessaria();
            toast.success("Medida deletada com sucesso");
          });
      });
  }

  return (
    <>
      <ModalPadrao
        show={show}
        setShow={setShow}
        tituloSpan={tituloSpan}
        titulo={titulo}
        corTitulo={corTitulo}
        tamanho="xl"
        modalCentralizada
      >
        <div className="row">
          <div style={{ fontSize: "18px" }} className="col ms-2">
            <span className="badge bg-primary">Perigo</span>{" "}
            {perigoMedidaControleNecessaria.codigo + " - " + perigoMedidaControleNecessaria.definicao}
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <Filter
              placeholder="Medidas Não Cadastradas"
              handleFilter={(e) => filtroMedidasNaoCadastradas(e.target.value)}
            />
          </div>
          <div className="col">
            <Filter placeholder="Medidas Cadastradas" handleFilter={(e) => filtrarMedidaCadastrada(e.target.value)} />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <TabelaPadrao
              colunas={[
                { nome: "Código", width: "8%", text: "center", },
                { nome: "Medidas de Controle", width: "45%" },
                {
                  nome: "Ações - Administrativo",
                  width: "10%",
                  text: "center",
                },
                {
                  nome: "Ações - Operacional",
                  width: "10%",
                  text: "center",
                },
              ]}
              itens={medidasNaoCadastradasfiltradas}
              itensFiltrados={medidasNaoCadastradasfiltradas}
              carregando={carregando}
            >
              {medidasNaoCadastradasfiltradas?.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">{item.codigo}</td>
                  <td>{item.definicao}</td>
                  <td className="text-center">
                    {isEmpty(item.adminsitrativo_vinculo_id) ? (
                      <BotaoAdicionar
                        onClick={() => {
                          handleAdiciona(item.id, "A");
                        }}
                      />
                    ) : (
                      <span>Cadastrado</span>
                    )}
                  </td>
                  <td className="text-center">
                    {isEmpty(item.operacional_vinculo_id) ? (
                      <BotaoAdicionar
                        onClick={() => handleAdiciona(item.id, "O")}
                      />
                    ) : (
                      <span>Cadastrado</span>
                    )}
                  </td>
                </tr>
              ))}
            </TabelaPadrao>
          </div>
          <div className="col">
            <TabelaPadrao
              colunas={[
                { nome: "Código", width: "8%", text: "center", },
                { nome: "Medidas de Controle", width: "34%" },
                {
                  nome: "A partir de (Matemática) - Administrativo",
                  width: "12%",
                  text: "center",
                },
                {
                  nome: "A partir de (Matemática) - Operacional",
                  width: "15%",
                  text: "center",
                },
                {
                  nome: "Ações",
                  width: "8%",
                  text: "center",
                },
              ]}
              itens={medidasCadastradaFiltradas}
              itensFiltrados={medidasCadastradaFiltradas}
              carregando={carregando}
            >
              {medidasCadastradaFiltradas?.map((item, index) => (
                <tr className={((item.adminsitrativo_vinculo_id && isEmpty(item.administrativo_resultado_matematica)) || (item.operacional_vinculo_id && isEmpty(item.operacional_resultado_matematica))) && "table-warning" } >
                  <td className="text-center">{item.codigo}</td>
                  <td>{item.definicao}</td>
                  <td className="text-center">
                    {!isEmpty(item.adminsitrativo_vinculo_id) ? (
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: determinarCorResultadoMatematica(item.administrativo_resultado_matematica),
                          }),
                        }}
                        onChange={(e) => {
                          handleEdita(item.adminsitrativo_vinculo_id, "A", e.value);
                        }}
                        defaultValue={verificarResultadosMatematicas(item.administrativo_resultado_matematica)}
                        options={opcoesResultadoMatematica}
                      />
                    ) : (
                      <span> - </span>
                    )}
                  </td>
                  <td className="text-center">
                    {!isEmpty(item.operacional_vinculo_id) ? (
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: determinarCorResultadoMatematica(item.operacional_resultado_matematica),
                          }),
                        }}
                        onChange={(e) => {
                          handleEdita(item.operacional_vinculo_id, "O", e.value);
                        }}
                        defaultValue={verificarResultadosMatematicas(item.operacional_resultado_matematica)}
                        options={opcoesResultadoMatematica}
                      />
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td className="text-center">
                    <BotaoDeletar
                      onClick={() => {
                        handleDeleta(item.adminsitrativo_vinculo_id, item.operacional_vinculo_id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </TabelaPadrao>
          </div>
        </div>
      </ModalPadrao>
    </>
  );
};

export default ModalErgonomiaPerigoMedidaControleNecessaria;
