import React, { useState } from "react";

import { BotaoDeletar, BotaoEditar, BotaoGrupo } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import api from "../../../../service/api";
import ModalAcidentePerigo from "./ModalAcidentePerigo";

const ListaAcidentePerigo = ({ perigos, buscarPerigos, acidentePerigo, permissaoEditar }) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={perigos}
        colunas={[
          { nome: "Código", width: "5%", text: "center" },
          { nome: "Perigo" },
          permissaoEditar && { nome: "Ações", width: "8%", text: "center" },
        ]}
      >
        {perigos.map((perigo) => (
          <Perigo
            key={perigo.id}
            perigo={perigo}
            buscarPerigos={buscarPerigos}
            acidentePerigo={acidentePerigo}
            permissaoEditar={permissaoEditar}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const Perigo = ({ perigo, buscarPerigos, acidentePerigo, permissaoEditar }) => {
  const [editando, setEditando] = useState(false);

  const perigoVinculoAcidente = acidentePerigo.find((perigoApre) => perigoApre.perigo_acidente_id === perigo.id);

  async function deletarPerigo(perigo) {
    if (window.confirm(`Tem certeza que deseja excluir o Perigo: ${perigo.definicao}?`)) {
      if (perigoVinculoAcidente) {
        let status = { status: "I" };

        api.put(`/baseDados/acidenteBaseDados/editarPerigo/${perigo.id}`, status).then(() => {
          api
            .delete(`/baseDados/acidenteBaseDados/deletarPerigoRiscosVinculados/${perigo.id}`)
            .then(() => {
              api.delete(`/baseDados/acidenteBaseDados/deletarMedidaControleNecessariaVinculadaPerigo/${perigo.id}`);
            })
            .then(() => {
              buscarPerigos();
              setEditando(false);
            });
        });
      } else {
        await api.delete(`/baseDados/acidenteBaseDados/deletarPerigoRiscosVinculados/${perigo.id}`).then(() => {
          api
            .delete(`/baseDados/ergonomiaBaseDados/deletarMedidaControleNecessariaVinculadaPerigo/${perigo.id}`)
            .then(() => {
              api
                .delete(`/baseDados/acidenteBaseDados/deletarPerigo/${perigo.id}`)
                .then(() => {
                  buscarPerigos();
                  setEditando(false);
                })
                .catch((erro) => {
                  alert(erro.response.data.mensagem);
                });
            })
            .catch((erro) => {
              alert(erro.response.data.mensagem);
            });
        });
      }
    }
  }

  return (
    <>
      <tr key={perigo.id}>
        <td className="text-center align-middle">{perigo.codigo}</td>
        <td className="align-middle">{perigo.definicao}</td>
        {permissaoEditar && (
          <>
            <td className="text-center align-middle">
              <BotaoGrupo>
                <BotaoEditar onClick={() => setEditando(true)} disabled={perigo.id === "30" && true} />
                <BotaoDeletar onClick={() => deletarPerigo(perigo)} disabled={perigo.id === "30" && true} />
              </BotaoGrupo>
            </td>
          </>
        )}
      </tr>
      {editando && (
        <ModalAcidentePerigo
          show={editando}
          setShow={setEditando}
          titulo={`Editar: ${perigo.definicao}`}
          corTitulo="primary"
          perigo={perigo}
          buscarPerigos={buscarPerigos}
          acidentePerigo={acidentePerigo}
        />
      )}
    </>
  );
};

export default ListaAcidentePerigo;
