import React, { useEffect, useState } from "react";

import api from "../../../../service/api";
import Filtros from "../../components/Filtros";
import ModalPadrao from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar, isEmpty } from "../../../../components/Util";
import Botao, { BotaoAdicionar, BotaoDeletar, BotaoRemover } from "../../../../components/Botao";

const AdicionarSeveridade = ({ show, setShow, ambiente, setor, formaRisco, carregarAmbientes }) => {
  const [showSobre, setShowSobre] = useState(false);
  const [riscos, setRiscos] = useState([]);
  const [filtroRiscos, setFiltroRiscos] = useState("");
  const [filtroCadastrado, setFiltroCadastrado] = useState("");
  const [carregando, setCarregando] = useState(true);
  
  useEffect(() => {
    api
      .get(`/gro/pgr/matematica/Matematica/buscarRiscosAdjetivosAmbiente/${ambiente.local_quadro_id}/${formaRisco.id}`)
      .then((resposta) => {
        setRiscos(resposta.data);
        setCarregando(false);
      });
  }, [formaRisco.id, ambiente.local_quadro_id]);

  let riscosFiltrados = riscos?.nao_cadastrados?.filter(
    (filtro) => filtrar(filtro.definicao, filtroRiscos) || (!isEmpty(filtro.codigo) && filtrar(filtro.codigo, filtroRiscos))
  );

  let riscosCadastradosFiltrados = riscos?.cadastrados?.filter(
    (filtro) => filtrar(filtro.definicao, filtroRiscos) || (!isEmpty(filtro.codigo) && filtrar(filtro.codigo, filtroRiscos))
  );

  function filtrarRiscos(newFilter) {
    setFiltroRiscos(newFilter);
  }

  function filtrarRiscosCadastrado(newFilter) {
    setFiltroCadastrado(newFilter);
  }

  const RiscosRow = ({ item, adiciona }) => {
    const [mostrarAdjetivos, setMostrarAdjetivos] = useState(false);
    const [carregandoRow, setCarregandoRow] = useState(false);

    function handleClick() {
      setMostrarAdjetivos(!mostrarAdjetivos);
    }

    function buscarDados() {
      api
        .get(`/gro/pgr/matematica/Matematica/buscarRiscosAdjetivosAmbiente/${ambiente.local_quadro_id}/${formaRisco.id}`)
        .then((resposta) => {
          setRiscos(resposta.data);
          setCarregando(false);
          setCarregandoRow(false);
        });
    }

    function inserirRiscos(localQuadroId, formaRiscoId, riscoId) {
      setCarregandoRow(true);
      api.post(`/gro/pgr/matematica/Matematica/inserirRiscoSetor/${localQuadroId}/${formaRiscoId}/${riscoId}`).then(() => {
        buscarDados();
      });
    }

    function deletarRiscos(localQuadroId, formaRiscoId, riscoId) {
      setCarregandoRow(true);
      api.delete(`/gro/pgr/matematica/Matematica/deletarRiscoSetor/${localQuadroId}/${formaRiscoId}/${riscoId}`).then(() => {
        buscarDados();
      });
    }

    const AdjetivosPorRisco = ({ risco }) => {
      function inserirRiscoAdjetivo(localQuadroId, formaRiscoId, riscoAdjetivoId) {
        setCarregandoRow(true);
        api.post(`/gro/pgr/matematica/Matematica/inserirRiscoAdjetivoSetor/${localQuadroId}/${formaRiscoId}/${riscoAdjetivoId}`).then(() => {
          buscarDados();
        });
      }
      return (
        <>
          {isEmpty(risco?.adjetivo) ? (
            <tr className="table-warning">
              <td>Nenhum adjetivo cadastrado</td>
              <td> - </td>
            </tr>
          ) : (
            risco?.adjetivo.map((adjetivo) => (
              <tr className="table-primary">
                <td>{" - " + adjetivo?.adjetivo}</td>
                <td className="text-center">
                  <BotaoAdicionar onClick={() => inserirRiscoAdjetivo(ambiente.local_quadro_id, formaRisco.id,adjetivo.riscos_adjetivos_id)} isLoading={carregandoRow}/>
                </td>
              </tr>
            ))
          )}
        </>
      );
    };
    return (
      <>
        <tr>
          {!adiciona ? (
            <>
              <td className={item.status === "I" ? "text-center table-warning" : "text-center"}>{item.definicao}</td>
              <td className={item.status === "I" ? "text-center table-warning" : "text-center"}>
                <AdjetivosPorRiscoCadastrado
                  item={item}
                  carregandoRow={carregandoRow}
                  setCarregandoRow={setCarregandoRow}
                  buscarDados={buscarDados}
                  ambiente={ambiente}
                  formaRisco={formaRisco}
                />
              </td>
              <td className={item.status === "I" ? "text-center table-warning" : "text-center"}>
                <BotaoDeletar
                  onClick={() => deletarRiscos(ambiente.local_quadro_id, formaRisco.id, item.id)}
                  isLoading={carregandoRow}
                />
              </td>
            </>
          ) : (
            <>
              <td onClick={() => handleClick()} style={{ cursor: "pointer" && "pointer" }}>
                {item.definicao}
              </td>
              <td className="text-center">
                <BotaoAdicionar
                  onClick={() => inserirRiscos(ambiente.local_quadro_id, formaRisco.id, item.id)}
                  isLoading={carregandoRow}
                />
              </td>
            </>
          )}
        </tr>

        {mostrarAdjetivos && <AdjetivosPorRisco risco={item} />}
      </>
    );
  };
  return (
    <ModalPadrao
      show={show}
      setShow={setShow}
      titulo="Cadastrar severidade"
      handleHide={() => carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id)}
    >
      <>
        <div className="row">
          <div className="col mt-3">
            <Botao conteudo={"Sobre o sistema"} icone="info-circle" cor="secondary" onClick={() => setShowSobre(!showSobre)} />
          </div>
        </div>
        {showSobre && (
          <div className="row mt-2">
            <div className="row">
              <div className="col">
                <span className="badge mb-1" style={{ backgroundColor: "#003a72" }}>
                  Severidade:
                </span>{" "}
                <span style={{ fontSize: "9pt" }}>
                  Severidade é gerada apartir dos adjetivos cadastrados ao risco selecionado, considerando a maior severidade.
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <Filtros
            itens={[
              {
                placeholder: "Não adicionados",
                filtrar: filtrarRiscos,
              },
              {
                placeholder: "Adicionados",
                filtrar: filtrarRiscosCadastrado,
              },
            ]}
          />

          <div className="col">
            <TabelaPadrao
              fontSize="14px"
              itens={riscosFiltrados}
              itensFiltrados={riscosFiltrados}
              filtro={filtroRiscos}
              carregando={carregando}
              colunas={[
                { nome: "Riscos", width: "87%" },
                { nome: "Ações", width: "13%", text: "center" },
              ]}
            >
              {riscosFiltrados?.map((item) => (
                <RiscosRow item={item} adiciona />
              ))}
            </TabelaPadrao>
          </div>
          <div className="col">
            <TabelaPadrao
              fontSize="14px"
              itens={riscosCadastradosFiltrados}
              itensFiltrados={riscosCadastradosFiltrados}
              filtro={filtroCadastrado}
              carregando={carregando}
              colunas={[{ nome: "Riscos" }, { nome: "Adjetivos", width: "45%" }, { nome: "Ações", width: "13%", text: "center" }]}
            >
              {riscosCadastradosFiltrados?.map((item) => (
                <RiscosRow item={item} />
              ))}
            </TabelaPadrao>
          </div>
        </div>
      </>
    </ModalPadrao>
  );
};

const AdjetivosPorRiscoCadastrado = ({ item, carregandoRow, setCarregandoRow, buscarDados, ambiente, formaRisco }) => {
  function deletarRiscoAdjetivoSetor(localQuadroId, formaRiscoId, riscoAdjetivoId) {
    setCarregandoRow(true);
    api
      .delete(`/gro/pgr/matematica/Matematica/deletarRiscoAdjetivoSetor/${localQuadroId}/${formaRiscoId}/${riscoAdjetivoId}`)
      .then(() => {
        buscarDados();
      });
  }
  return (
    <>
      {isEmpty(item?.adjetivo) ? (
        <tr className="table-warning">
          <td>Nenhum adjetivo cadastrado</td>
          <td> - </td>
        </tr>
      ) : (
        item?.adjetivo.map((adjetivo) => (
          <tr className="d-flex justify-content-between">
            <td className="p-3">{" - " + adjetivo?.adjetivo}</td>
            <td>
              <BotaoRemover
                isLoading={carregandoRow}
                onClick={() => deletarRiscoAdjetivoSetor(ambiente.local_quadro_id, formaRisco.id, adjetivo.risco_adjetivo_id)}
              />
            </td>
          </tr>
        ))
      )}
    </>
  );
};

export default AdicionarSeveridade;
