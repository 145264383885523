import React from "react";

import Filter from "./Filter";

const CreatableFilter = ({
  id,
  handleFilter,
  placeholder = "Pesquisar ou adicionar",
  handleAdiciona,
  disableAddButton,
  handleClick,
  isModal,
  defaultValue,
  texto,
}) => {
  return (
    <form onSubmit={handleAdiciona}>
      <Filter
        id={id}
        placeholder={placeholder}
        handleFilter={handleFilter}
        defaultValue={defaultValue}
        disableAddButton={disableAddButton}
        handleClick={handleClick}
        isModal={isModal}
        texto={texto}
        adiciona
      />
    </form>
  );
};

export default CreatableFilter;
