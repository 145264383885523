import { dadosToken } from "../components/Util";
import { useHistory } from "react-router-dom";

export const TOKEN_KEY = "access_token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const isLaurence = () => dadosToken().user_id === "18";
export const revisao = () =>
  dadosToken().funcao_id === "26" ||
  isLaurence() ||
  dadosToken().funcao_id === "115" ||
  dadosToken().funcao_id === "119";
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const verificaRota = (rota) => {
  if (rota.includes("/")) {
    let rotaPai = rota.split("/")[1];
    if (rotaPai === "gro" && (rota.split("/")[3] === "pgr" || rota.split("/")[3] === "painel")) {
      return dadosToken().permissoes.includes(rota.split("/")[3]);
    } else {
      if (rotaPai === "gro") {
        return dadosToken().permissoes.includes("painel");
      } else {
        return dadosToken().permissoes.includes(rotaPai);
      }
    }
  } else {
    return dadosToken().permissoes.includes(rota);
  }
};

export const verificaPermissaoCliente = (cliente_id = null) => {
  let urlPadrao = `/gro/${dadosToken().cliente_id || cliente_id}/`;

  if (verificaRota("pgr")) {
    urlPadrao += "pgr";
  }

  return urlPadrao;
};

export const VerificaBackTo = (cliente_id = null) => {
  let history = useHistory();
  console.log(history.location.pathname.split("/"));

  if (history.location.pathname.split("/")[3] === "pgr") {
    return false;
  } else if (history.location.pathname.split("/")[3] === "painel") {
    return `/gro/${cliente_id}/pgr`;
  }
};
