import React, { useEffect, useState } from "react";
import { useParams, useHistory, useRouteMatch, Redirect } from "react-router-dom";

import { dadosToken } from "../../components/Util";
import Header from "./Header";
import api from "../../service/api";
import ScrollTopButton from "../../components/ScrollTopButton";
import HeaderPpra from "../ppra/containers/painel/HeaderPpra";
import Botao from "../../components/Botao";
import HeaderObjetivosMetas from "../ppra/containers/painel/HeaderObjetivosMetas";

const Template = ({ page, backTo, desabilitaTroca, children, headerGro, setDadosPpra, ScrollTop = true, isObjetivosMetas = false }) => {
  const [ppra, setPpra] = useState([]);

  const { ppraId, clienteId } = useParams();

  let history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    document.title = page + " | Segure";
    ppraId && carregarPpra(ppraId);
  }, [ppraId, page]);

  async function carregarPpra(ppraId) {
    await api
      .get(`/ppras/carregarPpraPorId/${ppraId}`)
      .then((resposta) => setPpra(resposta.data))
      .catch((e) => console.log(e));
  }

  setDadosPpra && setDadosPpra(ppra);

  if (dadosToken().tipo === "2" && clienteId !== dadosToken().cliente_id) {
    return <Redirect to={{ pathname: "/sem-acesso" }} />;
  }

  return (
    <>
      <Header backTo={backTo} />
      {(isObjetivosMetas) ? (
        <HeaderObjetivosMetas/>
      ) : (
        ((ppraId && !headerGro) || isObjetivosMetas) && (
          <HeaderPpra reconhecimento={ppra} desabilitaTroca={desabilitaTroca} history={history} match={match} isObjetivosMetas={isObjetivosMetas} />
        )
      )}

      {headerGro && dadosToken().tipo !== "2" && <HeaderGro clienteId={clienteId} ppraId={ppraId} ppra={ppra} />}
      {/* {dadosToken().tipo !== "2" && clienteId &&
        history.location.pathname.split("/")[
          history.location.pathname.split("/").length - 1
        ] === "painel" && (
          <HeaderPpra
            clienteId={clienteId}
            desabilitaTroca={desabilitaTroca}
            history={history}
            match={match}
          />
        )} */}
      <div className="container-fluid px-5 pt-3">{(ppraId && !isObjetivosMetas) ? children(ppra) : children}</div>
      {ScrollTop && (
        <div className="mt-5">
          <ScrollTopButton />
        </div>
      )}
    </>
  );
};

export const HeaderGro = ({ clienteId, ppraId, ppra }) => {
  const [clienteNome, setClienteNome] = useState([]);

  useEffect(() => {
    async function buscarClientes() {
      await api.get(`/clientes/${clienteId}`).then((resposta) => {
        setClienteNome(resposta.data.nome_fantasia)
      });
    }
    buscarClientes(clienteId);
  }, [clienteId]);
  let history = useHistory();

  let verificaURL = history.location.pathname.split("/")[3];

  return (
    <div className="row mt-2 ms-5">
      <div className="col-auto">
        <div className="card border-dark border-2 rounded-pill">
          <span className="align-middle text-center fw-bold ps-5 pe-5">
            Cliente: {clienteNome}
            {ppraId && (
              <>
              <span className="ms-4"> Estab: {ppra.nomeEstabelecimento}</span>
              <span className="ms-4">Revisão: {ppra.revisao}</span>
              </>
            )}
          </span>
        </div>
      </div>

      {verificaURL === "pgr" && history.location.pathname.split("/").length === 4 && (
        <>
          <div className="col me-5">
            <div className="bg-white rounded float-end">
              <Botao conteudo="Painel GRO" icone="external-link-alt" cor="cinza" externalIcone alvo={`/gro/${clienteId}/painel`} noBlank outline />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Template;
