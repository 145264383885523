import React, { useEffect, useState } from "react";

import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";

import ListaAcidenteMedidaControleNecessaria from "./ListaAcidenteMedidaControleNecessaria";
import ModalAcidenteMedidaControleNecessaria from "./ModalAcidenteMedidaControleNecessaria";

const AcidenteMedidaControleNecessaria = ({ permissaoEditar }) => {
  const [medidasControleNecessaria, setMedidasControleNecessaria] = useState(
    []
  );
  const [
    acidenteMedidasControleNecessaria,
    setAcidenteMedidasControleNecessaria,
  ] = useState([]);
  const [gruposMedidas, setGruposMedidas] = useState([]);
  const [proximoCodigo, setProximoCodigo] = useState()

  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "Medidas de Controle Necessária";

  useEffect(() => {
    buscarMedidasControleNecessaria();
    buscarAcidenteMedidaControleNecessaria();
    buscarGruposMedidas();
  }, []);

  const medidasControleNecessariaFiltradas = medidasControleNecessaria.filter(
    (medidaControle) =>
      filtrar(medidaControle.codigo + " " + medidaControle.definicao, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarMedidasControleNecessaria() {
    await api
      .get("/baseDados/acidenteBaseDados/buscarMedidasControleNecessaria")
      .then((resposta) => {
        setMedidasControleNecessaria(resposta.data);
        if (resposta.data && resposta.data.length > 0) {
          const maxCodigo = Math.max(
            ...resposta.data.map(item => Number(item.codigo))
          );
          // Incrementa o valor do maior código
          setProximoCodigo((maxCodigo + 1).toString());
        }  
      })
      .catch((e) => console.log(e));
  }

  async function buscarAcidenteMedidaControleNecessaria() {
    await api
      .get(
        "/baseDados/acidenteBaseDados/buscarAcidenteMedidaControleNecessaria"
      )
      .then((resposta) => {
        setAcidenteMedidasControleNecessaria(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarGruposMedidas() {
    await api
      .get("/baseDados/acidenteBaseDados/buscarGruposMedidas")
      .then((resposta) => {
        setGruposMedidas(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={medidasControleNecessariaFiltradas}
        totalItens={medidasControleNecessariaFiltradas.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={(e) => permissaoEditar && setShowModalCadastro(true)}
      >
        {(currentItems) => (
          <ListaAcidenteMedidaControleNecessaria
            medidasControleNecessaria={currentItems}
            buscarMedidasControleNecessaria={buscarMedidasControleNecessaria}
            acidenteMedidasControleNecessaria={
              acidenteMedidasControleNecessaria
            }
            gruposMedidas={gruposMedidas}
            permissaoEditar={permissaoEditar}
          />
        )}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalAcidenteMedidaControleNecessaria
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Medida de Controle Necessária"
          corTitulo="success"
          proximoCodigo={proximoCodigo}
          buscarMedidasControleNecessaria={buscarMedidasControleNecessaria}
          novaMedidaControleNecessaria={filtro}
          acidenteMedidasControleNecessaria={acidenteMedidasControleNecessaria}
          gruposMedidas={gruposMedidas}
        />
      )}
    </>
  );
};

export default AcidenteMedidaControleNecessaria;
