import React from "react";

import Icone from "./Icone";

const Construcao = ({ sm }) => {
  return sm ? (
    <Icone
      className="text-warning"
      title="Módulo em construção"
      icone="warning"
    />
  ) : (
    <span className="ml-2 badge bg-warning">
      <small>
        <i className="fa mr-1 fa-warning "></i>Em construção
      </small>
    </span>
  );
};

export default Construcao;
