import React from "react";
import { isEmpty } from "../../../components/Util";
import Dica from "../../../components/Dica";

const MostrarTempo = ({ item, isAmbiente = false }) => {
  return (
    <>
    {isAmbiente || isEmpty(item.ferramenta_tempo) ? (
      <>
        {!item.tempo
        ? !item.tempo_max
          ? "-"
          : item.tempo_min
          ? item.tempo_min + " a " + item.tempo_max
          : item.tempo_max
        : item.tempo}
      {item.tipo_tempo
        ? item.tipo_tempo === "min"
          ? " Minuto(s)"
          : " Hora(s)"
        : ""}
      {(item.frequencia || item.tipo_frequencia) && (
        <span className="badge bg-success ml-1">
          {item.frequencia + " x " + item.tipo_frequencia}
        </span>
      )}
      </>
    ) : (
       <>
       {item.ferramenta_tempo.tempo + " " + (item.ferramenta_tempo.tipo_tempo === "h" ? "Hora(s)" : "Minuto(s)")}          
        <Dica
          colocacao="right"
          conteudo={
            <>
              <div>
                <div className="text-info">Tempo da ferramenta: </div>
                {item.ferramenta_tempo.ferramenta}
              </div>
            </>
          }
        >
        
            
          <span className="badge bg-success ms-1">{item.ferramenta_tempo.frequencia + " x " + item.ferramenta_tempo.tipo_frequencia}</span>
        
        </Dica>
       </>
    )}
      
    </>
  );
};

export default MostrarTempo;
