import React, { useEffect, useState } from "react";

import { filtrar } from "../../../components/Util";
import api from "../../../service/api";
import TemplateBaseDados from "../TemplateBaseDados";
import ListaFabricanteQuimico from "./ListaFabricanteQuimico";
import ModalFabricanteQuimico from "./ModalFabricanteQuimico";

const FabricanteQuimico = () => {
  const [fabricantes, setFabricantes] = useState([]);
  const [produtosQuimicos, setProdutosQuimicos] = useState([]);

  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);

  const item = "Fabricantes Químicos";

  useEffect(() => {
    buscarFabricantesQuimicos();
    buscarProdutosQuimicos();
  }, []);

  const fabricantesFiltrados = fabricantes.filter(
    (fabricante) =>
      filtrar(fabricante.id, filtro) || filtrar(fabricante.nome, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  const buscarFabricantesQuimicos = async () => {
    await api
      .get("/baseDados/FabricantesQuimicos")
      .then((resposta) => {
        setFabricantes(resposta.data);
      })
      .catch((e) => console.log(e));
  };

  const buscarProdutosQuimicos = async () => {
    await api
      .get("/baseDados/FabricantesQuimicos/buscarProdutosQuimicos")
      .then((resposta) => {
        setProdutosQuimicos(resposta.data);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <TemplateBaseDados
        item={item}
        itensFiltrados={fabricantesFiltrados}
        totalItens={fabricantes.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={(e) => setShowModalCadastro(true)}
      >
        {(currentItems) => (
          <ListaFabricanteQuimico
            fabricantes={currentItems}
            buscarFabricantesQuimicos={buscarFabricantesQuimicos}
            produtosQuimicos={produtosQuimicos}
          />
        )}
      </TemplateBaseDados>
      {showModalCadastro && (
        <ModalFabricanteQuimico
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Fabricante Químico"
          corTitulo="success"
          buscarFabricantesQuimicos={buscarFabricantesQuimicos}
          novoFabricante={filtro}
        />
      )}
    </>
  );
};

export default FabricanteQuimico;
