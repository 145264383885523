import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Botao, { BotaoSalvar } from "../../../../components/Botao";
import Loader from "../../../../components/Loader";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";

const Cadastro = ({ ppraId, history, perguntasInativas }) => {
  const [respostas, setRespostas] = useState([]);
  const [nomeResponsavel, setNomeResponsavel] = useState();
  const [funcao, setFuncao] = useState();
  const [data, setData] = useState();
  const [ultimoModelo, setUltimoModelo] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    obterUltimoModelo(ppraId);
  }, [ppraId]);

  async function obterUltimoModelo(ppraId) {
    api.get(`/gro/pgr/checklist/buscarModelos?ppra_id=${ppraId}&ultimo_modelo`).then((resposta) => {
      setUltimoModelo(resposta.data);
      setLoading(false);
    });
  }

  function salvarResposta(perguntaId, resposta) {
    const observacao =
      resposta === "N"
        ? ultimoModelo.perguntas.find((p) => p.checklist_pergunta_id === perguntaId)?.observacao_previa_se_nao
        : "";

    if (respostas.find((resposta) => resposta.pergunta_id === perguntaId)) {
      let posicao = respostas.findIndex((resposta) => resposta.pergunta_id === perguntaId);
      respostas[posicao].resposta = resposta;
      respostas[posicao].observacao = observacao;
    } else {
      setRespostas([
        ...respostas,
        {
          pergunta_id: perguntaId,
          resposta: resposta,
          observacao: observacao,
        },
      ]);
    }

    const observacaoInput = document.getElementById(`observacao_${perguntaId}`);
    observacaoInput.value = observacao;
  }

  function salvarObservacao(perguntaId, observacao) {
    if (respostas.find((resposta) => resposta.pergunta_id === perguntaId)) {
      let posicao = respostas.findIndex((resposta) => resposta.pergunta_id === perguntaId);
      respostas[posicao].observacao = observacao;
    } else {
      setRespostas([
        ...respostas,
        {
          pergunta_id: perguntaId,
          observacao: observacao,
        },
      ]);
    }
  }

  function salvarChecklist() {
    if (isEmpty(nomeResponsavel)) {
      toast.info("Informe nome do responsável pela informação!");
    } else if (isEmpty(funcao)) {
      toast.info("Informe função do responsável!");
    } else if (isEmpty(data)) {
      toast.info("Informe data do checklist!");
    } else if (ultimoModelo.perguntas.length !== respostas.length) {
      toast.info("Responda todas as perguntas!");
    } else {
      api
        .post("/gro/pgr/checklist/inserir", {
          ppra_id: ppraId,
          pgr_checklist_modelo_id: ultimoModelo.id,
          nome_responsavel: nomeResponsavel,
          funcao_responsavel: funcao,
          data: data,
        })
        .then((resposta) => {
          api
            .post("gro/pgr/checklist/inserirRespotas", {
              checklist_id: resposta.data.dados.id,
              respostas: respostas,
            })
            .then(
              () => toast.success("Checklist salvo com sucesso!"),
              window.setTimeout(() => {
                history.push("#3");
                window.location.reload();
              }, 2000)
            );
        });
    }
  }

  return (
    <>
      {isEmpty(ultimoModelo) && loading ? (
        <Loader />
      ) : (
        <>
          {ultimoModelo.ppra_id === ppraId ? (
            <>
              {ultimoModelo.perguntas.find((modeloPergunta) =>
                perguntasInativas.find((perguntaInativa) => perguntaInativa.id === modeloPergunta.checklist_pergunta_id)
              ) && (
                <div className="row mt-2">
                  <div className="col">
                    <div className="alert alert-warning d-flex align-items-center" role="alert">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="currentColor"
                        className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                        viewBox="0 0 16 16"
                        role="img"
                        aria-label="Warning"
                      >
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>
                      <div>Perguntas editadas ou excluídas, verifique modelo!</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col text-end me-1">
                  <Botao
                    icone="file-pdf"
                    cor="secondary"
                    alvo={process.env.REACT_APP_API_URL + "/gro/pgr/pdf/checklist/gerarEmBranco/" + ultimoModelo.id}
                    conteudo="Gerar PDF em Branco"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="nomeResponsavel" className="form-label">
                    Nome do responsável da informação
                  </label>
                  <input
                    type="text"
                    id="nomeResponsavel"
                    className="form-control"
                    placeholder="Nome Resp.Informação"
                    onChange={(nome) => {
                      setNomeResponsavel(nome.target.value);
                    }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="funcaoResponsavel" className="form-label">
                    Função do responsável
                  </label>
                  <input
                    type="text"
                    id="funcaoResponsavel"
                    className="form-control"
                    placeholder="Funcão Resp.Informação"
                    onChange={(funcao) => {
                      setFuncao(funcao.target.value);
                    }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="data" className="form-label">
                    Data da checklist
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="data"
                    onChange={(data) => {
                      setData(data.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <TabelaPadrao
                  noOverflowAuto
                  colunas={[
                    { nome: "Item", width: "5%", text: "center align-middle" },
                    {
                      nome: "Norma",
                      width: "10%",
                      text: "center align-middle",
                    },
                    { nome: "Pergunta", width: "40%", text: " align-middle" },
                    { nome: "Sim", width: "5%", text: "center align-middle" },
                    { nome: "Não", width: "5%", text: "center align-middle" },
                    {
                      nome: "Não Aplicavel",
                      width: "5%",
                      text: "center align-middle",
                    },
                    { nome: "Observação", width: "30%", text: " align-middle" },
                  ]}
                  itens={ultimoModelo.perguntas}
                >
                  {ultimoModelo.perguntas?.map((pergunta) => (
                    <tr key={pergunta.id}>
                      <th className="text-center align-middle">{pergunta.sequencia}</th>
                      <th className="text-center align-middle">{pergunta.norma}</th>
                      <th className="align-middle">{pergunta.pergunta}</th>
                      <th className="text-center align-middle">
                        <input
                          type="radio"
                          id={`sim_${pergunta.checklist_pergunta_id}`}
                          name={pergunta.checklist_pergunta_id}
                          value="S"
                          className="form-check-input"
                          onChange={(e) => salvarResposta(pergunta.checklist_pergunta_id, e.target.value)}
                        />
                      </th>
                      <th className="text-center align-middle">
                        <input
                          type="radio"
                          id={`nao_${pergunta.checklist_pergunta_id}`}
                          name={pergunta.checklist_pergunta_id}
                          value="N"
                          className="form-check-input"
                          onChange={(e) => salvarResposta(pergunta.checklist_pergunta_id, e.target.value)}
                        />
                      </th>
                      <th className="text-center align-middle">
                        <input
                          type="radio"
                          id={`na_${pergunta.checklist_pergunta_id}`}
                          name={pergunta.checklist_pergunta_id}
                          value="NA"
                          className="form-check-input"
                          onChange={(e) => salvarResposta(pergunta.checklist_pergunta_id, e.target.value)}
                        />
                      </th>
                      <th>
                        <input
                          type="text"
                          id={`observacao_${pergunta.checklist_pergunta_id}`}
                          name="Observação"
                          className="form-control"
                          onChange={(e) => salvarObservacao(pergunta.checklist_pergunta_id, e.target.value)}
                        />
                      </th>
                    </tr>
                  ))}
                </TabelaPadrao>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <BotaoSalvar className={"float-end"} onClick={(e) => salvarChecklist(e)} temConteudo />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col">
                  <h6>Cadastre modelo antes.</h6>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Cadastro;
