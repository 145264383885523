import React from "react";
import TabelaPadrao from "../../../components/TabelaPadrao";

import MostrarTempo from "../components/MostrarTempo";

const Funcoes = ({ funcoes, isAmbiente = false }) => {

  return (
    <td colSpan="2">
      <TabelaPadrao
        noOverflowAuto
        colunas={[{ nome: "Função" }, { nome: "Tempo" }]}
        itens={funcoes}
      >
        {funcoes.map((funcao) => (
          <tr key={funcao.perfil_funcao_id}>
            <td>
              {funcao.numero}) {funcao.nome}
              {funcao.numero_ga && (
                <span className="badge bg-info ml-1">
                  {funcao.numero_ga}
                </span>
              )}
            </td>
            <td>
              <MostrarTempo item={funcao} isAmbiente={isAmbiente}/>
            </td>
          </tr>
        ))}
      </TabelaPadrao>
    </td>
  );
};

export default Funcoes;
