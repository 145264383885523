import React, { useState } from "react";
import { ModalSubmit } from "../../../../components/ModalPadrao";
import api from "../../../../service/api";

const ModalGhe = ({ show, setShow, obterDados }) => {
  const [nome, setNome] = useState();
  const [tipoServico, setTipoServico] = useState();

  function salvarDados() {
    api
      .post("/baseDados/ergonomiaBaseDados/inserirGrupoGhe", {
        nome: nome,
        tipo_servico: tipoServico,
      })
      .then(() => {
        obterDados();
        setShow(false);
      });
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      tamanho={"md"}
      onSubmit={(e) => {
        e.preventDefault();
        salvarDados(e);
      }}
      titulo={"Criar Grupo"}
    >
      <div className="row">
        <div className="col">
          <label htmlFor="nome"> Nome: </label>
          <input id="nome" type="text" className="form-control" onChange={(e) => setNome(e.target.value)} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label htmlFor="adm">Administrativo</label>
          <input
            className="form-check-input ml-2 mt-1"
            type="radio"
            name="tipo_servico"
            id="adm"
            onChange={() => setTipoServico("A")}
          />
          <label htmlFor="op" className="ml-4">
            Operacional
          </label>
          <input
            className="form-check-input ml-2"
            type="radio"
            name="tipo_servico"
            id="op"
            onChange={() => setTipoServico("O")}
          />
        </div>
      </div>
    </ModalSubmit>
  );
};

export default ModalGhe;
