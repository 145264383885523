import React from "react";

const Icone = ({ className, style, icone, title }) => {
  return (
    <i
      className={className + " fa fa-" + icone}
      title={title}
      style={style}
    ></i>
  );
};

export default Icone;
