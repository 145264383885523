import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

import Botao, { BotaoSalvar } from "../../../../components/Botao";
import Loader from "../../../../components/Loader";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";

const Modelo = ({ ppraId, perguntas, history }) => {
  const [ultimoModelo, setUltimoModelo] = useState();
  const [perguntasSelecionadas, setPerguntasSelecionadas] = useState([]);
  const [todasPerguntasSelecionadas, setTodasPerguntasSelecionadas] =
    useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function obterUltimoModelo(ppraId) {
      api
        .get(`/gro/pgr/checklist/buscarModelos?ppra_id=${ppraId}&ultimo_modelo`)
        .then((resposta) => {
          setUltimoModelo(resposta.data);
          if (!isEmpty(resposta.data)) {
            setPerguntasSelecionadas(ajustaPerguntas(resposta.data.perguntas));
          }
          setLoading(false);
        });
    }
    obterUltimoModelo(ppraId);
  }, [ppraId]);

  const vinculoModeloChecklist = !isEmpty(ultimoModelo)
    ? isEmpty(ultimoModelo.checklist)
      ? false
      : true
    : false;

  function ordenaPerguntas(perguntas) {
    return perguntas.sort(
      (a, b) => a.checklist_pergunta_id - b.checklist_pergunta_id
    );
  }
  function ajustaPerguntas(perguntas) {
    const perguntasAjustadas = perguntas?.map((pergunta) => {
      return {
        checklist_pergunta_id: pergunta.checklist_pergunta_id,
      };
    });
    return perguntasAjustadas;
  }

  function salvarModelo() {
    const perguntasFiltradasAtivas = perguntasSelecionadas.filter(
      (perguntaSelecionada) =>
        perguntas.find(
          (pergunta) =>
            pergunta.id === perguntaSelecionada.checklist_pergunta_id &&
            pergunta.status === "A"
        )
    );

    api
      .post("gro/pgr/checklist/inserirModelo", {
        ppra_id: ppraId,
      })
      .then((resposta) => {
        api
          .post("gro/pgr/checklist/inserirModeloPerguntas", {
            pgr_checklist_modelo_id: resposta.data.dados.id,
            perguntas: ordenaPerguntas(perguntasFiltradasAtivas),
          })
          .then(
            () => toast.success("Modelo salvo com sucesso!"),
            window.setTimeout(() => {
              history.push("#2");
              window.location.reload();
            }, 2000)
          );
      });
  }

  function verificarSalvarModelo(e) {
    if (
      perguntasSelecionadas.find(
        (perguntaSelecionada) =>
          !ultimoModelo.perguntas.find(
            (pergunta) =>
              perguntaSelecionada.checklist_pergunta_id ===
              pergunta.checklist_pergunta_id
          )
      )
    ) {
      salvarModelo();
    } else if (perguntasSelecionadas.length !== ultimoModelo.perguntas.length) {
      salvarModelo();
    } else {
      toast.info("Necessário alteração para editar!");
    }
  }

  function editarModelo(e, ultimoModelo) {
    const perguntasFiltradasAtivas = perguntasSelecionadas.filter(
      (perguntaSelecionada) =>
        perguntas.find(
          (pergunta) =>
            pergunta.id === perguntaSelecionada.checklist_pergunta_id &&
            pergunta.status === "A"
        )
    );

    const perguntasNovas = perguntasFiltradasAtivas.filter(
      (perguntaNova) =>
        !ultimoModelo.perguntas.find(
          (perguntaAntiga) =>
            perguntaAntiga.checklist_pergunta_id ===
            perguntaNova.checklist_pergunta_id
        )
    );

    const perguntasExcluir = ultimoModelo.perguntas.filter(
      (perguntaModelo) =>
        !perguntasFiltradasAtivas.find(
          (perguntaSelecionada) =>
            perguntaSelecionada.checklist_pergunta_id ===
            perguntaModelo.checklist_pergunta_id
        )
    );

    if (!isEmpty(perguntasNovas)) {
      api
        .post("gro/pgr/checklist/inserirModeloPerguntas", {
          pgr_checklist_modelo_id: ultimoModelo.id,
          perguntas: perguntasNovas,
        })
        .then(
          () => toast.success("Modelo editado com sucesso!"),
          window.setTimeout(() => {
            history.push("#2");
            window.location.reload();
          }, 2000)
        );
    }

    if (!isEmpty(perguntasExcluir)) {
      api
        .delete("gro/pgr/checklist/deletarPerguntasModelo", {
          params: {
            ids: perguntasExcluir.map((pergunta) => pergunta.id),
          },
        })
        .then(() => {
          if (isEmpty(perguntasNovas)) {
            toast.success("Modelo editado com sucesso!");
            window.setTimeout(() => {
              history.push("#2");
              window.location.reload();
            }, 2000);
          }
        });
    }
  }

  function selecionaTodos() {
    setTodasPerguntasSelecionadas(!todasPerguntasSelecionadas);

    let todasPerguntas = [];
    perguntas.map((pergunta) =>
      todasPerguntas.push({ checklist_pergunta_id: pergunta.id })
    );

    setPerguntasSelecionadas(todasPerguntas);
    if (todasPerguntasSelecionadas) {
      setPerguntasSelecionadas([]);
    }
  }

  const PerguntaRow = ({ pergunta }) => {
    const [isChecked, setIsChecked] = useState(
      perguntasSelecionadas.find(
        (perguntaSelecionada) =>
          perguntaSelecionada.checklist_pergunta_id === pergunta.id
      )
        ? true
        : false
    );

    function changeCheck(evento, perguntaId) {
      if (evento.target.checked) {
        setPerguntasSelecionadas([
          ...perguntasSelecionadas,
          {
            checklist_pergunta_id: perguntaId,
          },
        ]);
      } else {
        let perguntasModificadas = perguntasSelecionadas;

        let index = perguntasSelecionadas.findIndex(
          (perguntaSelecionada) =>
            perguntaSelecionada.checklist_pergunta_id === perguntaId
        );
        perguntasModificadas.splice(index, 1);

        setPerguntasSelecionadas(perguntasModificadas);
      }
      setIsChecked(!isChecked);
      setTodasPerguntasSelecionadas(false);
    }

    return (
      <tr key={pergunta.id}>
        <td className="text-center align-middle">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => changeCheck(e, pergunta.id)}
            style={{ cursor: "pointer" }}
          />
        </td>
        <td className="text-center align-middle">{pergunta.sequencia}</td>
        <td className="text-center align-middle">{pergunta.norma}</td>
        <td className="align-middle">{pergunta.pergunta}</td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col">
              <h5 className="form-label">Perguntas do Modelo do Checklist</h5>
            </div>
            {ultimoModelo.ppra_id !== ppraId &&
              !isEmpty(ultimoModelo.ppra_id) && (
                <div className="row">
                  <div className="col">
                    <h6>
                      Perguntas marcadas retiradas do último modelo (revisão
                      anterior).
                    </h6>
                  </div>
                </div>
              )}
          </div>
          <div className="row mt-2">
            <TabelaPadrao
              noOverflowAuto
              colunas={[
                {
                  nome: (
                    <>
                      <Botao
                        cor="light"
                        className="btn-outline-primary"
                        conteudo="Todos"
                        onClick={() => selecionaTodos()}
                      />
                    </>
                  ),

                  width: "5%",
                  text: "center align-middle",
                },
                { nome: "Item", width: "5%", text: "center align-middle" },
                { nome: "Norma", width: "10%", text: "center align-middle" },
                { nome: "Pergunta", width: "40%", text: " align-middle" },
              ]}
              itens={perguntas}
            >
              {perguntas?.map((pergunta) => (
                <PerguntaRow key={pergunta.id} pergunta={pergunta} />
              ))}
            </TabelaPadrao>
          </div>
          <div className="row mt-3">
            <div className="col">
              {isEmpty(ultimoModelo) || ultimoModelo.ppra_id !== ppraId ? (
                <BotaoSalvar
                  className={"float-end"}
                  onClick={(e) => salvarModelo(e)}
                  temConteudo
                />
              ) : (
                <BotaoSalvar
                  className={"float-end"}
                  onClick={(e) => {
                    vinculoModeloChecklist
                      ? verificarSalvarModelo(e)
                      : editarModelo(e, ultimoModelo);
                  }}
                  temConteudo
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Modelo;
