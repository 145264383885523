import React, { useEffect, useState } from "react";

import { filtrar } from "../../../components/Util";
import api from "../../../service/api";
import TemplateBaseDados from "../TemplateBaseDados";
import ListaAtividadesEspeciais from "./ListaAtividadesEspeciais";

const AtividadesEspeciais = () => {
  const [atividadesEspeciais, setAtividadesEspeciais] = useState([]);
  const [carregandoAtividadesEspeciais, setCarregandoAtividadesEspeciais] =
    useState(true);
  const [filtro, setFiltro] = useState("");

  const item = "Atividades especiais";

  useEffect(() => {
    carregarAtividades();
  }, []);

  const atividadesFiltradas = atividadesEspeciais.filter((atividadeEspecial) =>
    filtrar(atividadeEspecial.nome, filtro)
  );

  function handleFilter(novoFiltro) {
    setFiltro(novoFiltro);
  }

  async function carregarAtividades() {
    await api
      .get("/baseDados/AtividadesEspeciais/")
      .then((resposta) => {
        setAtividadesEspeciais(resposta.data);
        setCarregandoAtividadesEspeciais(false);
      })
      .catch((e) => console.log(e));
  }

  async function handleAdiciona(evento) {
    evento.preventDefault();
    if (window.confirm(`Inserir a Atividade especial: ${filtro}?`)) {
      await api
        .post(`/baseDados/AtividadesEspeciais/inserir`, {
          nome: filtro,
        })
        .then(() => {
          carregarAtividades();
        })
        .catch((erro) => alert(erro.response.data.mensagem));
    }
  }

  return (
    <TemplateBaseDados
      item={item}
      carregandoItens={carregandoAtividadesEspeciais}
      totalItensFiltrados={atividadesFiltradas.length}
      totalItens={atividadesEspeciais.length}
      itensFiltrados={atividadesFiltradas}
      handleFilter={(e) => handleFilter(e.target.value)}
      handleAdiciona={(e) => handleAdiciona(e)}
      filtro={filtro}
    >
      {(currentItems) => (
        <ListaAtividadesEspeciais
          atividadesEspeciais={currentItems}
          carregarAtividades={carregarAtividades}
          carregandoAtividadesEspeciais={carregandoAtividadesEspeciais}
          setCarregandoAtividadesEspeciais={setCarregandoAtividadesEspeciais}
        />
      )}
    </TemplateBaseDados>
  );
};

export default AtividadesEspeciais;
