import React, { useEffect, useState } from "react";

import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";
import ListaAcidentePerigo from "./ListaAcidentePerigo";
import ModalAcidentePerigo from "./ModalAcidentePerigo";

const AcidentePerigo = ({ permissaoEditar }) => {
  const [perigos, setPerigos] = useState([]);

  const [acidentePerigo, setAcidentePerigo] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "Perigo";

  useEffect(() => {
    buscarPerigos();
    buscarAprePerigo();
  }, []);

  const perigosFiltrados = perigos.filter(
    (perigo) =>
      filtrar(perigo.codigo, filtro) || filtrar(perigo.definicao, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarPerigos() {
    await api
      .get("/baseDados/acidenteBaseDados/buscarPerigos")
      .then((resposta) => {
        setPerigos(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarAprePerigo() {
    await api
      .get("/baseDados/acidenteBaseDados/buscarAcidentePerigo")
      .then((resposta) => {
        setAcidentePerigo(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={perigosFiltrados}
        totalItens={perigos.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={() => {
          permissaoEditar &&
          setShowModalCadastro(true);
        }}
      >
        {(currentItems) => (
          <ListaAcidentePerigo
            perigos={currentItems}
            buscarPerigos={buscarPerigos}
            acidentePerigo={acidentePerigo}
            permissaoEditar={permissaoEditar}
          />
        )}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalAcidentePerigo
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Perigo"
          corTitulo="success"
          buscarPerigos={buscarPerigos}
          novoPerigo={filtro}
          acidentePerigo={acidentePerigo}
        />
      )}
    </>
  );
};

export default AcidentePerigo;
