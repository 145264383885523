import { isEmpty } from "../../../components/Util";

export const getStatus = (statusItens) => {
  let status = "";
  if (statusItens.includes("1") && statusItens.includes("0")) {
    status = "";
  } else if (statusItens.includes("0")) {
    status = "?status=0";
  } else if (statusItens.includes("1")) {
    status = "?status=1";
  } else if (isEmpty(statusItens)) {
    status = "?status=-1";
  }

  return status;
};

const TrocarStatus = (statusItens, setStatusItens, status, checked) => {
  const statusItensCopy = Array.from(statusItens);

  if (checked) {
    statusItensCopy.push(status);
  } else {
    var index = statusItensCopy.indexOf(status);

    if (index > -1) {
      statusItensCopy.splice(index, 1);
    }
  }

  setStatusItens(statusItensCopy);
};

export default TrocarStatus;
