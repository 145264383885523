import React, { useEffect, useState } from "react";
import "moment/locale/pt-br";
import { dadosToken } from "../../../components/Util";
import ModalPadrao, { ModalSubmit } from "../../../components/ModalPadrao";
import api from "../../../service/api";

const GerarAssinatura = ({ show, setShow }) => {
  const [assinatura, setAssinatura] = useState(0);
  const [nomeColab, setNomeColab] = useState("");
  const [emailColab, setEmailColab] = useState("");
  const [funcaoColab, setFuncaoColab] = useState("");
  const [telefone, setTelefone] = useState("(51) 3468-9000");
  const [celular, setCelular] = useState("(51) 99328-9000");
  const [setor, setSetor] = useState("");

  //  abaixo colocando dados do form no localStorage
  const nomeLocalStorage = (event) => {
    localStorage.setItem("nomeColab", event.target.value);
    setNomeColab(event.target.value);
  };
  const emailLocalStorage = (event) => {
    localStorage.setItem("emailColab", event.target.value);
    setEmailColab(event.target.value);
  };
  const funcaoLocalStorage = (event) => {
    localStorage.setItem("funcaoColab", event.target.value);
    setFuncaoColab(event.target.value);
  };
  const telefoneLocalStorage = (event) => {
    localStorage.setItem("telefone", event.target.value);
    setTelefone(event.target.value);
  };
  const celularLocalStorage = (event) => {
    localStorage.setItem("celular", event.target.value);
    setCelular(event.target.value);
  };
  const setorLocalStorage = (event) => {
    localStorage.setItem("setor", event.target.value);
    setSetor(event.target.value);
  };

  useEffect(() => {
    async function verificarAssinatura(colaboradorId) {
      await api
        .get(`/colaboradores/obterAssinatura/${colaboradorId}`)
        .then((resposta) => setAssinatura(resposta.data.assinatura));
    }

    async function RecebeDadosColab(colaboradorId) {
      await api
        .get(`/colaboradores/obterDadosAssinatura/${colaboradorId}`)
        .then((resposta) => {
          setEmailColab(resposta.data.email);

          if (dadosToken().funcao_id !== "25" && dadosToken().funcao_id !== "119") {
            setFuncaoColab(resposta.data.nome);
          }
          retornarNomeSeparado(resposta.data.email);
        });
    }
    verificarAssinatura(dadosToken().id_colaborador);
    RecebeDadosColab(dadosToken().id_colaborador);
  }, []);

  function retornarNomeSeparado(email) {
    const nome = email.split("@"); // 1º split
    const nomeSeparados = nome[0].split("."); // 2º split
    const primeiroNome =
      nomeSeparados[0].charAt(0).toUpperCase() + nomeSeparados[0].slice(1);
    const segundoNome =
      nomeSeparados[1].charAt(0).toUpperCase() + nomeSeparados[1].slice(1);
    setNomeColab(primeiroNome + " " + segundoNome);
  }
  async function alteraStatusAssinatura(e) {
    e.preventDefault();
    await api
      .put(`/colaboradores/editar/${dadosToken().id_colaborador}`, {
        assinatura: 1,
      })
      .then(() => {
        setAssinatura("1");
      });
  }

  if (assinatura === "1") {
    return (
      <ModalPadrao
        show={show}
        setShow={setShow}
        modalCentralizada
        tituloSpan="Você já possui Assinatura"
        corTitulo="primary"
      >
        <table
          style={{
            margin: 0,
            marginLeft: "auto",
            marginRight: "auto",
            padding: 0,
            fontFamily: "sans-serif",
          }}
        >
          <tbody>
            <tr>
              <th
                style={{
                  backgroundColor: "#9dcd17",
                  fontSize: "5pt",
                  height: "10px",
                  colspan: "1",
                }}
              ></th>
              <th
                style={{
                  fontSize: "5pt",
                  backgroundColor: "#113887",
                  colspan: "3",
                }}
              ></th>
            </tr>
            <tr>
              <td style={{ width: "120px", textAlign: "center" }}>
                <a href="https://www.segure.com.br">
                  <img
                    width="110"
                    src="https://www.segure.com.br/public/assets/img/logo.svg"
                    alt="Logo Segure"
                  />
                </a>
              </td>
              <td
                style={{
                  padding: "0px 20px",
                  textAlign: "left",
                  paddingLeft: "5px",
                }}
              >
                <strong style={{ color: "#113887" }}>{nomeColab}</strong>
                <br />
                <small>{funcaoColab}</small>
                <br />
                <small style={{ color: "#113887", fontSize: "8pt" }}>
                  Setor:{setor}
                </small>
              </td>
              <td style={{ padding: "0px 20px" }}>
                <div
                  style={{
                    marginTop: "-20px",
                    textAlign: "left",
                    fontSize: "10pt",
                  }}
                >
                  <small>
                    <strong>{emailColab}</strong>
                  </small>
                  <br />
                  <small>
                    {telefone} / {celular}
                  </small>
                  <br />
                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.segure.com.br/"
                  >
                    www.segure.com.br
                  </a>
                </div>
              </td>
              {/* <td style={{ paddingRight: "5px", textAlign: "center" }}>
                <div
                  style={{
                    height: "73px",
                    width: "150px",
                    margin: "5px auto",
                  }}
                >
                  <a href="https://www.tegis.com.br">
                    <img
                      src="https://www.segure.com.br/public/assets/img/selos.svg"
                      width="150"
                      alt="Selos Certificacao"
                    />
                  </a>
                </div>
              </td> */}
            </tr>
          </tbody>
        </table>
        <br />
        {/* Código html para o usuario  */}
        <div className="m-1">
          <h5 style={{ color: "#003a72", fontWeight: "bolder" }}>
            Copie o código abaixo:
            <br />
            <br />
          </h5>
          <div
            rows="32"
            cols="85"
            style={{
              display: "block",
              border: "none",
              resize: "none",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "7pt",
            }}
          >
            {/* botão para copiar código da assinatura */}
            <br />
            <code
              maxLength="8000"
              resize="auto"
              style={{
                fontSize: "10px",
                border: "10px",
              }}
            >
              {/* //  começo da assintura  */}
              &lt;head&gt; &lt;meta charset="UTF-8"/&gt; &lt;/head&gt; &lt;table
              style="margin: 0; padding: 0; font-family: sans-serif;"&gt;
              &lt;tbody&gt; &lt;tr&gt; &lt;th style="background-color: #9dcd17;
              font-size: 5pt;" height="10px" colspan="1" &gt;&lt;/th&gt; &lt;th
              style="font-size: 5pt; background-color: #113887;" colspan="3"
              &gt;&lt;/th&gt; &lt;/tr&gt; &lt;tr&gt; &lt;td style="width: 120px;
              text-align: center;"&gt; &lt;a
              href="https://www.segure.com.br"&gt; &lt;img width="110"
              src="https://www.segure.com.br/public/assets/img/logo.svg"
              alt="Logo Segure" /&gt; &lt;/a&gt; &lt;/td&gt; &lt;td
              style="padding:0px 20px;text-align: left;
              padding-left:5px;"&gt;&lt;strong style="color: #113887;"&gt;
              {nomeColab}&lt;/strong&gt; &lt;br/&gt; &lt;small&gt;
              {funcaoColab}
              &lt;/small&gt; &lt;br/&gt; &lt;small style="color: #113887;
              font-size: 8pt;"&gt;Setor: {setor}&lt;/small&gt; &lt;/td&gt;&lt;td
              style="padding:0px 20px;"&lt; &lt;div
              style="margin-top:-20px;text-align: left; font-size:10pt;"&gt;
              &lt;small&gt; &lt;strong&gt;{emailColab}&lt;/strong&gt;
              &lt;/small&gt;&lt;br/&gt;&lt;small&gt;{telefone} / {celular}
              &lt;/small&gt; &lt;br/&gt; &lt;a style="text-decoration: none;"
              href="https://www.segure.com.br/" &gt; www.segure.com.br
              &lt;/a&gt; &lt;/div&gt; &lt;/td&gt;  &lt;/tr&gt; &lt;/tbody&gt; &lt;/table&gt;
              {/* // Fim da assinatura   */}
            </code>
          </div>
        </div>
      </ModalPadrao>
    );
  } else {
    return (
      <ModalSubmit
        show={show}
        setShow={setShow}
        modalCentralizada
        tituloSpan="Gerar Assinatura"
        onSubmit={(e) => alteraStatusAssinatura(e)}
      >
        <form method="post" name="formColab" action="#">
          <div className="row">
            <div className="form-group col-md-6">
              <label className="text-primary" htmlFor="nome">
                Nome:
              </label>
              <input
                className="form-control"
                type="text"
                value={nomeColab}
                required
                name="nome"
                id="nome"
                onChange={nomeLocalStorage}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="text-primary" htmlFor="funcao">
                Função:
              </label>
              <input
                className="form-control"
                value={funcaoColab}
                type="text"
                required
                id="funcao"
                name="funcao"
                onChange={funcaoLocalStorage}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="text-primary" htmlFor="setor">
                Setor:
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="setor"
                id="setor"
                value={setor}
                onChange={setorLocalStorage}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="text-primary" htmlFor="email">
                Email:
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="email"
                id="setor"
                value={emailColab}
                onChange={emailLocalStorage}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="text-primary" htmlFor="telefone">
                Telefone:
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="telefone"
                id="telefone"
                value={telefone}
                onChange={telefoneLocalStorage}
                // onKeyPress={validaForm()}
              />
              <span className="text-danger">Campo automático</span>
            </div>
            <div className="form-group col-md-6">
              <label className="text-primary" htmlFor="celular">
                Celular
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="celular"
                id="celular"
                value={celular}
                onChange={celularLocalStorage}
              />
              <span className="text-danger">Campo automático</span>
            </div>
          </div>
        </form>
      </ModalSubmit>
    );
  }
};
export default GerarAssinatura;
