import React, { useEffect, useState } from "react";

import { filtrar, isEmpty } from "../../../components/Util";
import TemplateBaseDados from "../TemplateBaseDados";
import api from "../../../service/api";
import ListaEfeitosSaude from "./ListaEfeitosSaude";
import ModalEfeitoSaude from "./ModalEfeitoSaude";

const EfeitosSaude = () => {
  const [carregandoEfeitosSaude, setCarregandoEfeitosSaude] = useState(true);
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [efeitosSaude, setEfeitosSaude] = useState([]);
  const [filtro, setFiltro] = useState("");

  const item = "Efeitos à saúde";

  useEffect(() => {
    carregarEfeitosSaude();
  }, []);

  const efeitosSaudeFiltradas = efeitosSaude.filter(
    (efeitoSaude) =>
      filtrar(efeitoSaude.adjetivo, filtro) ||
      filtrar(efeitoSaude.doenca, filtro) ||
      (!isEmpty(efeitoSaude.intensidade) &&
        filtrar(efeitoSaude.intensidade, filtro)) ||
      (!isEmpty(efeitoSaude.local_efesintomaito_saude) &&
        filtrar(efeitoSaude.local_efesintomaito_saude, filtro)) ||
      (!isEmpty(efeitoSaude.sintoma) && filtrar(efeitoSaude.sintoma, filtro))
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  const carregarEfeitosSaude = async () => {
    await api
      .get("/baseDados/efeitosSaude")
      .then((resposta) => {
        setEfeitosSaude(resposta.data);
        setCarregandoEfeitosSaude(false);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  return (
    <>
      <TemplateBaseDados
        item={item}
        carregandoItens={carregandoEfeitosSaude}
        itensFiltrados={efeitosSaudeFiltradas}
        totalItens={efeitosSaude.length}
        handleFilter={(e) => handleFilter(e.target.value)}
        filtro={filtro}
        isModal
        handleClick={() => {
          setShowModalCadastro(true);
        }}
      >
        {(currentItems) => (
          <ListaEfeitosSaude
            efeitosSaude={currentItems}
            carregarEfeitosSaude={carregarEfeitosSaude}
          />
        )}
      </TemplateBaseDados>
      {showModalCadastro && (
        <ModalEfeitoSaude
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar efeito à saúde"
          corTitulo="success"
          carregarEfeitosSaude={carregarEfeitosSaude}
        />
      )}
    </>
  );
};

export default EfeitosSaude;
