import React, { useState } from "react";

import { BotaoAdicionar } from "../../../../../components/Botao";
import AdicionarMedidasTrabalho from "./AdicionarMedidasTrabalho";

const MedidasTrabalho = ({ item, ...other }) => {
  const [showAdicionarMedidasTrabalho, setShowAdicionarMedidasTrabalho] =
    useState(false);

  const mostraQuantidade = !item.tarefa_id && !item.produto_quimico_id;

  return (
    <div className="row mb-2">
      <div className="col-1">
        <BotaoAdicionar
          className="mr-1"
          onClick={() => setShowAdicionarMedidasTrabalho(true)}
        />
      </div>
      <div className="col-2 gx-0">
        <span className="text-muted mr-1">Med. Org. de Trab.</span>
      </div>
      <div className="col gx-0">
        {item.medidas_organizacionais.map((medidaTrabalho, index) => (
          <span key={index} className="badge bg-primary mr-1">
            {medidaTrabalho.definicao}
            {mostraQuantidade ? " (" + medidaTrabalho.quantidade + ")" : ""}
          </span>
        ))}
      </div>
      {showAdicionarMedidasTrabalho && (
        <AdicionarMedidasTrabalho
          showAdicionarMedidasTrabalho={showAdicionarMedidasTrabalho}
          setShowAdicionarMedidasTrabalho={setShowAdicionarMedidasTrabalho}
          item={item}
          mostraQuantidade={mostraQuantidade}
          {...other}
        />
      )}
    </div>
  );
};

export default MedidasTrabalho;
