import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ReactInputMask from "react-input-mask";
import Select from "react-select";

import {
  BotaoFechar,
  BotaoGrupo,
  BotaoSalvar,
} from "../../../../components/Botao";
import { isEmpty, mostrarMensagem } from "../../../../components/Util";
import { urlProdutosQuimicos } from "../../../../routes/UrlBaseDados";
import InputIcone from "../../../../components/InputIcone";
import api from "../../../../service/api";

const ModalProdutos = ({ setShow, filtro, produto, buscarProdutoQuimico }) => {
  const [nomeProduto, setNomeProduto] = useState(
    produto ? produto.nome : filtro
  );
  const [fabricantes, setFabricantes] = useState([]);
  const [fabricante, setFabricante] = useState(
    produto ? produto.fabricante : {}
  );
  const [mostrarSucesso, setMostrarSucesso] = useState("");

  const history = useHistory();

  useEffect(() => {
    carregarFabricantes();
  }, []);

  async function carregarFabricantes(fabricanteId = "") {
    await api
      .get("/baseDados/ProdutosQuimicos/obterFabricantes/" + fabricanteId)
      .then((resposta) =>
        fabricanteId === ""
          ? setFabricantes(resposta.data)
          : setFabricante(resposta.data)
      )
      .catch((e) => console.log(e));
  }

  function handleSelect(option) {
    carregarFabricantes(option.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let dados = {
      nome: nomeProduto,
      fabricante_quimico_id: fabricante.id,
    };

    let url = null;

    if (produto) {
      url = api.put(`/baseDados/ProdutosQuimicos/editar/${produto.id}`, dados);
    } else {
      url = api.post("/baseDados/ProdutosQuimicos/inserir", dados);
    }

    await url
      .then((resposta) => {
        history.push(urlProdutosQuimicos + "/" + resposta.data.dados.id + "#1");
        if (produto) {
          mostrarMensagem(resposta.data.mensagem, setMostrarSucesso);
          buscarProdutoQuimico(produto.id);
        }
      })
      .catch((erro) => alert(erro.response.data.mensagem));
  };

  const valuesFabricante = fabricantes.map((fabricante) => ({
    label: fabricante.nome,
    value: fabricante.id,
  }));

  return (
    <form>
      <div className="mb-3">
        <div className="row">
          <div className="col mb-3">
            <label>Produto Químico:</label>
            <InputIcone
              tipo="text"
              icone="radiation"
              placeholder="Digite o nome do produto"
              onChange={(e) => setNomeProduto(e.target.value)}
              defaultValue={nomeProduto}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Fabricante:</label>
            <Select
              name="fabricanteCnpj"
              placeholder="Selecione o fabricante"
              options={valuesFabricante}
              defaultValue={produto && { label: produto.fabricante.nome }}
              onChange={handleSelect}
            />
          </div>
          <div className="col mb-3">
            <label>CNPJ:</label>
            <ReactInputMask
              maskChar=""
              mask="99.999.999/9999-99"
              type="text"
              className="form-control"
              value={!isEmpty(fabricante) && (fabricante.cnpj || "")}
              placeholder="-"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {mostrarSucesso && (
            <label className="text-success small align-center text-center">
              {mostrarSucesso}
            </label>
          )}
          <div className="float-end">
            <BotaoGrupo>
              {!produto && (
                <BotaoFechar
                  temConteudo="Fechar"
                  onClick={() => {
                    setShow(false);
                  }}
                />
              )}
              <BotaoSalvar
                temConteudo="Salvar"
                onClick={handleSubmit}
                disabled={
                  nomeProduto === "" ||
                  (produto &&
                    nomeProduto === produto.nome &&
                    fabricante.id === produto.fabricante_quimico_id)
                }
              />
            </BotaoGrupo>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ModalProdutos;
