import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import api from "../../../service/api";
import { isEmpty } from "../../../components/Util";
import Botao from "../../../components/Botao";
import TabelaPadrao from "../../../components/TabelaPadrao";

const Visualizacao = ({
  clienteEstabelecimentos,
  nomeDocumento,
  tipoDocumento,
}) => {
  const [clienteEstabelecimentoId, setClienteEstabelecimentoId] = useState();
  const [nomeDocumentoId, setNomeDocumentoId] = useState();
  const [tipoDocumentoId, setTipoDocumentoId] = useState();
  const [uploads, setUploads] = useState();
  const [index, setIndex] = useState();
  const [programa, setPrograma] = useState([]);
  const [dadosTabela, setDadosTabela] = useState([]);
  const [visualizacaoOption, setVisualizacaoOption] = useState([]);
  const [loading, setLoading] = useState(true);

  const { clienteId } = useParams();

  useEffect(() => {
    buscarPrograma(clienteId);
  }, [clienteId]);

  async function buscarPrograma(clienteId) {
    await api
      .get(`/gro/programa/buscarPrograma?cliente_id=${clienteId}`)
      .then((resposta) => {
        setPrograma(resposta.data);
      });
  }

  function pesquisarPrograma(clienteId) {
    api
      .get(`/gro/programa/buscarPrograma?cliente_id=${clienteId}`)
      .then((resposta) => {
        setPrograma(resposta.data);
        pesquisarDadosTabela(resposta.data);
      });
  }

  const programaSelecionado = programa.find(
    (prog) =>
      prog.estabelecimento_id === clienteEstabelecimentoId &&
      prog.gro_programa_nome_documento_id === nomeDocumentoId &&
      prog.gro_programa_tipo_documento_id === tipoDocumentoId
  );

  function obterDadosUpload(programaSelecionado) {
    if (programaSelecionado !== undefined) {
      const programaId = programaSelecionado.id;

      buscarUpload(programaId);

      const index = programa.findIndex((prog) => prog === programaSelecionado);

      setIndex(index);
    }
  }

  const estabelecimentos =
    clienteEstabelecimentos.cliente_estabelecimentos?.map(
      (clienteEstabelecimento) => ({
        value: clienteEstabelecimento,
        label: clienteEstabelecimento.estabelecimento.nome_generico,
      })
    );

  const filtroProgramaTipoDocumentoEstabelecimento = programa.filter(
    (prog) =>
      prog.gro_programa_tipo_documento_id === tipoDocumentoId &&
      prog.estabelecimento_id === clienteEstabelecimentoId
  );

  const nomesDocumentos = isEmpty(clienteEstabelecimentoId)
    ? nomeDocumento?.map((nomeDoc) => ({
        value: nomeDoc.id,
        label: nomeDoc.nome_documento,
      }))
    : isEmpty(tipoDocumentoId)
    ? visualizacaoOption.nomesDocumentos?.map((nomeDoc) => ({
        value: nomeDoc.gro_programa_nome_documento_id,
        label: nomeDoc.nome_documento,
      }))
    : filtroProgramaTipoDocumentoEstabelecimento?.map((nomeDoc) => ({
        value: nomeDoc.gro_programa_nome_documento_id,
        label: nomeDoc.nome_documento,
      }));

  const filtroProgramaNomeDocumentoEstabelecimento = programa.filter(
    (prog) =>
      prog.gro_programa_nome_documento_id === nomeDocumentoId &&
      prog.estabelecimento_id === clienteEstabelecimentoId
  );

  const tiposDocumentos = isEmpty(clienteEstabelecimentoId)
    ? tipoDocumento?.map((tipoDoc) => ({
        value: tipoDoc.id,
        label: tipoDoc.tipo_documento,
      }))
    : isEmpty(nomeDocumentoId)
    ? visualizacaoOption.tiposDocumentos?.map((tipoDoc) => ({
        value: tipoDoc.gro_programa_tipo_documento_id,
        label: tipoDoc.tipo_documento,
      }))
    : filtroProgramaNomeDocumentoEstabelecimento?.map((tipoDoc) => ({
        value: tipoDoc.gro_programa_tipo_documento_id,
        label: tipoDoc.tipo_documento,
      }));

  async function buscarVisualizacaoOption(clienteEstabelecimentoId) {
    await api
      .get(
        `/gro/programa/buscarVisualizacaoOption?estabelecimento_id=${clienteEstabelecimentoId}`
      )
      .then((resposta) => {
        setVisualizacaoOption(resposta.data);
      });
  }

  async function buscarUpload(programaId) {
    await api
      .get(`/gro/programa/buscarUpload?programa_id=${programaId}`)
      .then((resposta) => {
        setUploads(resposta.data);
        setLoading(false);
        isEmpty(resposta.data) && toast.info("Nenhum Upload neste programa.");
      });
  }

  function excluirUpload(uploadId, gro_programa_id) {
    api
      .delete(`/gro/programa/deletarUploadPrograma?id=${uploadId}`)
      .then(
        () =>
          isEmpty(dadosTabela)
            ? buscarUpload(gro_programa_id)
            : pesquisarPrograma(clienteId),
        toast.info("PDF Excluído.")
      );
  }

  function deletarPDF(idDeletar, arquivoDeletar, gro_programa_id) {
    if (window.confirm("Você tem certeza que deseja deletar este PDF:")) {
      api
        .post(`upload/DeletarArquivo`, {
          nome_arquivo: arquivoDeletar,
          caminho: "uploadPrograma/",
        })
        .then(() => excluirUpload(idDeletar, gro_programa_id));
    }
  }

  const colunaFixa = [
    { nome: "Revisão" },
    { nome: "Ano" },
    { nome: "Período" },
    { nome: "Responsável" },
    { nome: "Setor" },
    { nome: "Função" },
    { nome: "Funcionário" },
    { nome: "Visualizar", width: "5%" },
    { nome: "Ações", width: "5%" },
  ];

  function dadoLinhaTabela(filtrosDados, id) {
    if (
      filtrosDados.find(
        (filtroDado) => filtroDado.gro_programa_filtro_id === id
      )
    ) {
      return filtrosDados.find(
        (filtroDado) => filtroDado.gro_programa_filtro_id === id
      ).filtro_dados;
    } else {
      return "-";
    }
  }

  function pesquisarDadosTabela(programa) {
    if (clienteEstabelecimentoId === undefined) {
      toast.warning("Selecione um estabelecimento.");
    } else if (nomeDocumentoId !== undefined && tipoDocumentoId === undefined) {
      //dados estabelecimento + nome doc.
      setDadosTabela(
        programa.filter(
          (prog) =>
            prog.estabelecimento_id === clienteEstabelecimentoId &&
            prog.gro_programa_nome_documento_id === nomeDocumentoId
        )
      );
    } else if (tipoDocumentoId !== undefined && nomeDocumentoId === undefined) {
      //dados estabelecimento + tipo doc.
      setDadosTabela(
        programa.filter(
          (prog) =>
            prog.estabelecimento_id === clienteEstabelecimentoId &&
            prog.gro_programa_tipo_documento_id === tipoDocumentoId
        )
      );
    } else if (programaSelecionado !== undefined) {
      //dados com todos os select preenchidos.
      obterDadosUpload(programaSelecionado);
      setDadosTabela(undefined);
    } else if (
      clienteEstabelecimentoId !== undefined &&
      nomeDocumentoId !== undefined &&
      tipoDocumentoId !== undefined
    ) {
      setUploads(undefined);
      setDadosTabela(undefined);
      toast.warning("Programa não existe.");
    } else {
      //dados apenas com o estabelecimento.
      setDadosTabela(
        programa.filter(
          (prog) => prog.estabelecimento_id === clienteEstabelecimentoId
        )
      );
    }
  }

  return (
    <>
      {isEmpty(programa) && loading ? (
        <h5 className="text-center">Nenhum programa cadastrado.</h5>
      ) : (
        <>
          <div className="row mt-3 ">
            <div className="col">
              <label htmlFor="selectElstab" className="form-label">
                Estabelecimento
              </label>
              <Select
                placeholder="Selecione"
                name="Estabelecimento"
                id="selectElstab"
                onChange={(option) => {
                  setClienteEstabelecimentoId(option.value.codigo);
                  buscarVisualizacaoOption(option.value.codigo);
                }}
                options={estabelecimentos}
              />
            </div>
            <div className="col">
              <label htmlFor="selectDoc" className="form-label">
                Documento
              </label>
              <Select
                placeholder="Selecione"
                name="Documento"
                id="selectDoc"
                onChange={(option) => {
                  setNomeDocumentoId(option.value);
                }}
                options={nomesDocumentos}
              />
            </div>
            <div className="col">
              <label htmlFor="selectTipoDoc" className="form-label">
                Tipo documento
              </label>
              <Select
                placeholder="Selecione"
                name="Tipo documento"
                id="selectTipoDoc"
                onChange={(option) => {
                  setTipoDocumentoId(option.value);
                }}
                options={tiposDocumentos}
              />
            </div>
            <div className="col-1 align-self-end">
              <div className="row mb-1">
                <Botao
                  icone="search"
                  onClick={() => {
                    pesquisarDadosTabela(programa);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <div className="row">
                {isEmpty(dadosTabela || uploads) ? (
                  <h5 className="text-center">Nenhum dado no momento.</h5>
                ) : (
                  <TabelaPadrao
                    colunas={
                      isEmpty(uploads)
                        ? colunaFixa
                        : [
                            ...programa[index].filtros?.map((filtro) => ({
                              nome: filtro.nome_filtro,
                            })),
                            { nome: "Visualizar", width: "5%" },
                            { nome: "Ações", width: "5%" },
                          ]
                    }
                    itens={programa}
                    noOverflowAuto
                    noHover
                  >
                    {isEmpty(uploads)
                      ? dadosTabela?.map((prog) =>
                          prog.uploads?.map((upload) => (
                            <tr key={upload.id}>
                              <td key={"1"} className="text-center">
                                {dadoLinhaTabela(upload.filtrosDados, "1")}
                              </td>
                              <td key={"2"} className="text-center">
                                {dadoLinhaTabela(upload.filtrosDados, "2")}
                              </td>
                              <td key={"3"} className="text-center">
                                {dadoLinhaTabela(upload.filtrosDados, "3")}
                              </td>
                              <td key={"4"} className="text-center">
                                {dadoLinhaTabela(upload.filtrosDados, "4")}
                              </td>
                              <td key={"5"} className="text-center">
                                {dadoLinhaTabela(upload.filtrosDados, "5")}
                              </td>
                              <td key={"6"} className="text-center">
                                {dadoLinhaTabela(upload.filtrosDados, "6")}
                              </td>
                              <td key={"7"} className="text-center">
                                {dadoLinhaTabela(upload.filtrosDados, "7")}
                              </td>
                              <td className="text-center">
                                <Botao
                                  icone="file-pdf"
                                  cor="secondary"
                                  alvo={`${process.env.REACT_APP_CAMINHO_UPLOAD}uploadPrograma/${upload.url_arquivo}`}
                                />
                              </td>
                              <td className="text-center">
                                <Botao
                                  icone="trash"
                                  cor="danger"
                                  onClick={() =>
                                    deletarPDF(
                                      upload.id,
                                      upload.url_arquivo,
                                      upload.gro_programa_id
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))
                        )
                      : uploads?.map((upload) => (
                          <tr key={upload.id}>
                            {upload.filtrosDados.map((filtroDado) => (
                              <td key={filtroDado.id}>
                                {filtroDado.filtro_dados}
                              </td>
                            ))}
                            <td className="text-center">
                              <Botao
                                icone="file-pdf"
                                cor="secondary"
                                alvo={`${process.env.REACT_APP_CAMINHO_UPLOAD}uploadPrograma/${upload.url_arquivo}`}
                              />
                            </td>
                            <td className="text-center">
                              <Botao
                                icone="trash"
                                cor="danger"
                                onClick={() =>
                                  deletarPDF(
                                    upload.id,
                                    upload.url_arquivo,
                                    upload.gro_programa_id
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                  </TabelaPadrao>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Visualizacao;
