import React from "react";
import { BotaoAdicionar } from "./Botao";
import Icone from "./Icone";

const InputIcone = ({
  className,
  tipo = "text",
  icone,
  titulo,
  botaoAdicionar,
  handleClick,
  disabledAddButton,
  ...other
}) => {
  return (
    <div className={`input-group flex-nowrap ${className}`}>
      <span className="input-group-text">
        <Icone
          style={{ width: "1rem" }}
          icone={icone}
          className={`${titulo && "mr-2"}`}
        />
        {titulo}
      </span>
      <input type={tipo} className="form-control" {...other} />
      {botaoAdicionar && (
        <div className="input-group-append">
          <BotaoAdicionar
            type="button"
            onClick={handleClick}
            disabled={disabledAddButton}
          />
        </div>
      )}
    </div>
  );
};

export default InputIcone;
